/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import AvatarUpload from 'components/common/AvatarUpload';
import { useParams } from 'react-router-dom';
import {
  useGetEmployeeByIdQuery,
  useUpdateEmployeeNamesMutation
} from 'api/employeeApi';
import team15 from 'assets/img/team/15.webp';
import Badge from 'components/base/Badge';
import { showCustomAlert } from 'helpers/showCustomAlert';

const EmployeeProfileCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: employeeData, refetch } = useGetEmployeeByIdQuery(id || '');
  const [updateEmployeeNames, { isLoading: isSaving }] =
    useUpdateEmployeeNamesMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    joinDate: ''
  });
  const [errors, setErrors] = useState({
    firstName: ''
  });

  useEffect(() => {
    if (employeeData?.data) {
      setFormData({
        firstName: employeeData.data.names?.firstName || '-',
        lastName: employeeData.data.names?.lastName || '-',
        joinDate: employeeData.data.joinedDate?.formatted || '-'
      });
    }
  }, [employeeData]);

  const handleEditClick = () => setIsEditing(true);

  const handleCancel = () => {
    const hasChanges =
      employeeData?.data &&
      (formData.firstName !== employeeData.data.names?.firstName ||
        formData.lastName !== employeeData.data.names?.lastName);

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan data profil? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        if (employeeData?.data) {
          setFormData({
            firstName: employeeData.data.names?.firstName || '-',
            lastName: employeeData.data.names?.lastName || '-',
            joinDate: employeeData.data.joinedDate?.formatted || '-'
          });
        }
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'firstName') {
      setErrors(prev => ({
        ...prev,
        firstName: ''
      }));
    }
  };

  const handleSave = () => {
    if (!formData.firstName.trim()) {
      setErrors(prev => ({
        ...prev,
        firstName: 'Nama depan harus diisi'
      }));
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Anda yakin ingin menyimpan perubahan data profil ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const dataToSend = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            joinedDate: employeeData?.data.joinedDate.plain || ''
          };

          await updateEmployeeNames({
            id: id || '',
            data: dataToSend
          }).unwrap();

          await refetch();
          setIsEditing(false);
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Perubahan data profil berhasil disimpan.',
            buttonType: 'ok'
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal!',
            deskripsi:
              error?.data?.message || 'Terjadi kesalahan, silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (!employeeData?.data) {
    return null;
  }

  const { data: employee } = employeeData;

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center g-5 text-center text-sm-start">
          <Col xs={12} sm="auto" className="mb-sm-3">
            <AvatarUpload size={isEditing ? '5xl' : '3xl'} src={team15} />
          </Col>
          <Col xs={12} sm="auto" className="flex-1">
            {isEditing ? (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Nama Depan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Nama Belakang
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Tanggal Bergabung
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="joinDate"
                    value={formData.joinDate}
                    onChange={handleInputChange}
                    disabled
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <h3 className="fw-bolder">
                    {employee.names?.fullName || '-'}
                  </h3>
                  <Button variant="ghost" size="sm" onClick={handleEditClick}>
                    <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                  </Button>
                </div>
                <p className="text-body-secondary">
                  Bergabung pada {employee.joinedDate.formatted || '-'}
                </p>
              </>
            )}
          </Col>
        </Row>
        {!isEditing && (
          <Col
            xs={12}
            className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-2 mt-2 mb-3"
          >
            <Badge
              style={{ borderRadius: '8px' }}
              bg="info"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              JABATAN: {employee.jobTitle?.name || '-'}
            </Badge>
            <Badge
              style={{ borderRadius: '8px' }}
              bg="danger"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              STATUS: {employee.isActive ? 'Aktif' : 'Tidak Aktif'}
            </Badge>
            <Badge
              style={{
                borderRadius: '8px',
                border: '1px #CBD0DD solid',
                backgroundColor: '#E3E6ED',
                color: 'black'
              }}
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              PEKERJAAN TERAKHIR: {employee.joinedDate.formatted || '-'}
            </Badge>
          </Col>
        )}

        {!isEditing && (
          <div className="d-flex flex-between-center border-top border-dashed pt-4 fw-bold">
            <div>
              <h6>Total Waktu Bergabung</h6>
              <p className="fs-8 text-body-secondary mb-0">1 Tahun</p>
            </div>
            <div>
              <h6>Performa 1 Bulan</h6>
              <p className="fs-8 text-body-secondary mb-0">12 Pelayanan</p>
            </div>
            <div>
              <h6>Layanan Aktif</h6>
              <p className="fs-8 text-body-secondary mb-0">3 Layanan</p>
            </div>
            <div>
              <h6>Status Gaji</h6>
              <p className="fs-8 text-body-secondary mb-0">Lunas</p>
            </div>
          </div>
        )}

        {isEditing && (
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? 'Menyimpan...' : 'Simpan'}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EmployeeProfileCard;
