/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import {
  useGetPositionByIdQuery,
  useUpdatePositionMutation,
  useRemoveEmployeeFromPositionMutation,
  useAddEmployeeToPositionMutation,
  useDeletePositionMutation
} from 'api/positionApi';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { daftarJabatanDetailBreadcrumbItems } from 'data/commonData';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import PilihPemilikJabatanModal from 'components/modals/PilihPemilikJabatanModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';

const JabatanDetails = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: position,
    isLoading,
    error,
    refetch
  } = useGetPositionByIdQuery(id || '');

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    color: ''
  });

  const [showPilihPemilikModal, setShowPilihPemilikModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatePosition] = useUpdatePositionMutation();
  const [removeEmployee] = useRemoveEmployeeFromPositionMutation();
  const [addEmployee] = useAddEmployeeToPositionMutation();
  const navigate = useNavigate();
  const [deletePosition] = useDeletePositionMutation();

  useEffect(() => {
    if (position?.data) {
      setFormData({
        name: position.data.name,
        description: position.data.description,
        color: position.data.color
      });
    }
  }, [position]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !position) {
    return <div>Error loading position details</div>;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    if (!hasDataChanged()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        if (position?.data) {
          setFormData({
            name: position.data.name,
            description: position.data.description,
            color: position.data.color
          });
        }
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    if (!formData.name.trim()) {
      showCustomAlert({
        icon: 'error',
        label: 'Validasi Gagal',
        deskripsi: 'Nama jabatan tidak boleh kosong',
        buttonType: 'ok'
      });
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updatePosition({
            id: id || '',
            data: {
              name: formData.name,
              description: formData.description,
              color: formData.color,
              employee:
                position?.data?.employee?.map(emp => ({ id: emp.id })) || []
            }
          }).unwrap();

          setIsEditing(false);
          refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok'
          });
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menyimpan data!',
            deskripsi:
              error?.data?.message || 'Terjadi kesalahan saat memperbarui data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRemoveEmployee = (employeeId: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Pegawai akan dihapus dari jabatan.',
      deskripsi: 'Anda yakin untuk menghapus pegawai ini dari jabatan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await removeEmployee({
            positionId: id || '',
            data: {
              employeeId: employeeId
            }
          }).unwrap();

          refetch();
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil dihapus',
            buttonType: 'ok'
          });
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menghapus data!',
            deskripsi:
              error?.data?.message ||
              'Terjadi kesalahan saat menghapus pegawai',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleShowPilihPemilikModal = () => {
    setShowPilihPemilikModal(true);
  };

  const handleHidePilihPemilikModal = () => {
    setShowPilihPemilikModal(false);
  };

  const handleSelectPegawai = (selectedEmployees: any[]) => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await addEmployee({
            positionId: id || '',
            data: {
              employeeId: selectedEmployees.map(emp => emp.id)
            }
          }).unwrap();

          setShowPilihPemilikModal(false);
          refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok'
          });
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menyimpan data!',
            deskripsi:
              error?.data?.message ||
              'Terjadi kesalahan saat menambahkan pemilik jabatan',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleModalMount = (employees: any[]) => {
    console.log('Modal mounted with employees:', employees);
  };

  const hasDataChanged = () => {
    if (!position?.data) return false;
    return (
      formData.name !== position.data.name ||
      formData.description !== position.data.description ||
      formData.color !== position.data.color
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async (reason: string, notes: string) => {
    const hasEmployees = position?.data?.employee?.length > 0;

    showCustomAlert({
      icon: 'warning',
      label: 'Jabatan akan dihapus!',
      deskripsi: hasEmployees
        ? 'Terdapat pegawai yang memiliki jabatan ini. Apakah Anda yakin untuk menghapus jabatan ini?'
        : 'Apakah Anda yakin untuk menghapus jabatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deletePosition({
            notes: notes,
            deletionReason: reason,
            data: [id || '']
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Jabatan berhasil dihapus',
            buttonType: 'ok',
            onConfirm: () => {
              navigate('/pegawai/jabatan');
            }
          });
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal!',
            deskripsi:
              error?.data?.message ||
              'Terjadi kesalahan saat menghapus jabatan',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  return (
    <>
      <PageBreadcrumb items={daftarJabatanDetailBreadcrumbItems} />
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="card-title mb-0">Detail Jabatan</h2>
          <div className="d-flex gap-2">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={handleDeleteClick}
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Hapus Jabatan
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowPilihPemilikModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Pemilik Jabatan
            </Button>
          </div>
        </div>
        <Card>
          <Card.Body>
            <Row className="mb-4">
              <Col xs={12} className="d-flex align-items-center">
                <h3>
                  Informasi Dasar{' '}
                  {!isEditing && (
                    <Button variant="ghost" size="sm" onClick={handleEditClick}>
                      <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                    </Button>
                  )}
                </h3>
                {isEditing && (
                  <div className="ms-auto">
                    <Button
                      variant="link"
                      className="text-danger"
                      onClick={handleCancel}
                    >
                      Batalkan
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Simpan
                    </Button>
                  </div>
                )}
              </Col>
            </Row>

            {!isEditing ? (
              // Mode tampilan
              <Row className="mb-3">
                <Col xs={12} md={6} className="d-flex flex-column pe-3 mb-3">
                  <span className="fw-bold text-muted mb-1">Nama Jabatan</span>
                  <span className="fw-normal">{position.data.name}</span>
                </Col>
                <Col xs={12} md={6} className="d-flex flex-column pe-3 mb-3">
                  <span className="fw-bold text-muted mb-1">Warna</span>
                  <div
                    style={{
                      width: '75px',
                      height: '16px',
                      backgroundColor: position.data.color,
                      borderRadius: '4px'
                    }}
                  />
                </Col>
                <Col xs={12} className="d-flex flex-column pe-3">
                  <span className="fw-bold text-muted mb-1">Deskripsi</span>
                  <span className="fw-normal">{position.data.description}</span>
                </Col>
              </Row>
            ) : (
              // Mode edit
              <Form>
                <Row className="mb-3">
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold fs-8 mb-2 p-0 text-capitalize text-muted">
                        Nama Jabatan <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        isInvalid={isEditing && !formData.name.trim()}
                      />
                      {isEditing && !formData.name.trim() && (
                        <Form.Control.Feedback type="invalid">
                          Nama jabatan harus diisi
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold fs-8 mb-2 p-0 text-capitalize text-muted">
                        Warna
                      </Form.Label>
                      <Form.Control
                        type="color"
                        name="color"
                        className="w-100"
                        value={formData.color}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label className="fw-bold fs-8 mb-2 p-0 text-capitalize text-muted">
                        Deskripsi
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>

        {/* Card Daftar Pemilik Jabatan */}
        <div className="m-4">
          <Row className="mb-2">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-between"
            >
              <h4 className="fw-bolder">Daftar Pemilik Jabatan</h4>
            </Col>
          </Row>

          <div className="table-responsive">
            <table className="table table-hover table">
              <thead>
                <tr className="text-muted fs-9">
                  <th className="ps-0 ">NAMA PEGAWAI</th>
                  <th className="">JENIS KELAMIN</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Perbaikan sementara */}
                {Array.isArray(position.data.employee) &&
                position.data.employee.length > 0 ? (
                  position.data.employee.map((emp: any) => (
                    <tr key={emp.id} className="fs-9">
                      <td>{emp.fullName}</td>
                      <td>{emp.gender}</td>
                      <td className="text-end pe-2">
                        <Button
                          variant="link"
                          className="text-dark p-0 border-0"
                          onClick={() => handleRemoveEmployee(emp.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Tidak ada pegawai yang memiliki jabatan ini
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Row>
            <Col xs={12} className="">
              <Button
                variant="outline-primary"
                size="sm"
                className="w-100 fs-9"
                onClick={handleShowPilihPemilikModal}
              >
                <FontAwesomeIcon icon={faPlus} /> Tambah Pemilik Jabatan
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <PilihPemilikJabatanModal
        show={showPilihPemilikModal}
        onHide={handleHidePilihPemilikModal}
        onSelect={handleSelectPegawai}
        selectedIds={position?.data?.employee?.map(emp => emp.id) || []}
        onMount={handleModalMount}
        currentPositionId={id}
      />
      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={[
          {
            name: position?.data?.name || 'Jabatan',
            totalEmployee: position?.data?.employee?.length || 0
          }
        ]}
        onDelete={handleConfirmDelete}
        title="Hapus Jabatan"
      />
    </>
  );
};

export default JabatanDetails;
