import React, { useState, useEffect } from 'react';
import {
  faChevronDown,
  faFileExport,
  faPlus,
  faTrash,
  faUndo,
  // faFilter,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import PeranKeamananTable, {
  peranKeamananTableColumns
} from 'components/tables/PeranKeamananTable';
import { peranKeamananBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { showCustomAlert } from 'helpers/showCustomAlert';
import {
  useGetAllRolesQuery,
  useDeleteRoleMutation,
  useDuplicateRoleMutation
} from 'api/roleApi';
import { RoleResponse } from 'api/response/RoleResponse';
import TambahPeranModal from 'components/modals/TambahPeranModal';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';
import { debounce } from 'lodash';
// import TambahPeranModal from 'components/modals/TambahPeranModal';

const PeranKeamanan = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  // const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  // const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRolesToDelete, setSelectedRolesToDelete] = useState<
    RoleResponse[]
  >([]);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: rolesData,
    refetch,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isFetching
  } = useGetAllRolesQuery({
    page: currentPage,
    limit: pageSize,
    code: searchQuery
  });

  const table = useAdvanceTable<RoleResponse>({
    data: rolesData?.data || [],
    columns: peranKeamananTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handlePageChange = (page: number) => {
    table.resetRowSelection();
    setCurrentPage(page);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showTambahModal, setShowTambahModal] = useState(false);

  const [deleteRole] = useDeleteRoleMutation();

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-pegawai.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await refetch();
      toast.success('Data berhasil diperbarui');
    } catch (error) {
      toast.error('Gagal memperbarui data');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih peran yang akan dihapus');
      return;
    }

    setSelectedRolesToDelete(selectedRows.map(row => row.original));
    setShowDeleteModal(true);
  };

  const handleDelete = async (reason: string, notes: string) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const hasUsers = selectedRows.some(row => (row.original.count ?? 0) > 0);

    showCustomAlert({
      icon: 'warning',
      label: 'Peran keamanan akan dihapus',
      deskripsi: hasUsers
        ? 'Terdapat akun yang memiliki peran ini. Apakah Anda yakin untuk menghapus peran keamanan ini?'
        : 'Apakah Anda yakin untuk menghapus peran keamanan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteRole({
            id: selectedRolesToDelete.map(role => role.id),
            reason,
            notes: notes || null
          }).unwrap();

          // Reset seleksi dan refresh data
          table.resetRowSelection();
          setSelectedRows(0);
          await refetch();
          setShowDeleteModal(false);

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: `${selectedRolesToDelete.length} peran keamanan berhasil dihapus`,
            buttonType: 'ok'
          });
        } catch (error) {
          toast.error('Gagal menghapus peran keamanan');
          console.error('Error deleting roles:', error);
        }
      }
    });
  };

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  // Tambahkan mutation untuk duplicate role
  const [duplicateRole] = useDuplicateRoleMutation();

  // Tambahkan fungsi handleDuplicateRole mirip dengan CustomerTemplate
  const handleDuplicateRole = async () => {
    const selectedRows = table.getSelectedRowModel().rows;

    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih peran yang akan diduplikasi');
      return;
    }

    if (selectedRows.length > 1) {
      toast.warning('Pilih hanya satu peran untuk diduplikasi');
      return;
    }

    const selectedRole = selectedRows[0].original;

    showCustomAlert({
      icon: 'question',
      label: 'Duplikat Peran Keamanan?',
      deskripsi: 'Peran keamanan akan diduplikasi.',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const response = await duplicateRole(selectedRole.id).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Peran keamanan berhasil diduplikasi.',
              buttonType: 'ok'
            });
            await refetch();
            table.resetRowSelection();
          } else {
            throw new Error(response.message);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Error duplicating role:', error);

          // Jika error timeout, berikan pesan khusus dan jangan refresh data
          if (error?.status === 'TIMEOUT_ERROR') {
            showCustomAlert({
              icon: 'error',
              label: 'Timeout!',
              deskripsi:
                'Proses duplikasi memakan waktu terlalu lama. Silakan coba lagi atau hubungi administrator.',
              buttonType: 'ok'
            });
            return; // Hentikan eksekusi lebih lanjut
          }

          // Untuk error lainnya
          toast.error(
            error instanceof Error
              ? error.message
              : 'Gagal menduplikasi peran keamanan'
          );
        }
      }
    });
  };

  // Fungsi untuk menangani perubahan input pencarian
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    // Prevent default form submission
    e.preventDefault();

    setSearchQuery(query);

    // Optional: debounce the search to reduce unnecessary API calls
    const debouncedSearch = debounce(() => {
      refetch();
    }, 300);

    debouncedSearch();
  };

  const [lastToastTime, setLastToastTime] = useState(0);

  useEffect(() => {
    // Check for empty data
    if (rolesData && rolesData.data.length === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [rolesData, lastToastTime]);

  return (
    <div>
      <PageBreadcrumb items={peranKeamananBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Peran Keamanan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari peran keamanan"
              onChange={handleSearchInputChange}
              style={{ maxWidth: '300px' }}
              value={searchQuery}
            />
            {/* <Button
              variant={showFilterPanel ? 'primary' : 'light'}
              size="sm"
              onClick={() => setShowFilterPanel(!showFilterPanel)}
              className="d-flex align-items-center gap-2"
            >
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </Button> */}
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${isRefreshing ? 'fa-spin' : ''}`}
              />
              {isRefreshing ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_FULL">
              <Button
                variant="outline-danger"
                onClick={handleDeleteClick}
                disabled={selectedRows === 0}
                size="sm"
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Hapus Peran ({selectedRows})
              </Button>
            </ForbiddenWrapper>
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={handleDuplicateRole} // Ganti dengan fungsi baru
                disabled={table.getSelectedRowModel().rows.length !== 1}
              >
                <FontAwesomeIcon icon={faCopy} className="me-2" />
                Duplikat
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowTambahModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Tambah Peran
              </Button>
            </ForbiddenWrapper>
          </div>
        </div>

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <PeranKeamananTable
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={rolesData?.totalPages || 1}
                roleData={{
                  totalRecords: rolesData?.totalItems || 0
                }}
              />
            </div>
          </div>
        </AdvanceTableProvider>

        <TambahPeranModal
          show={showTambahModal}
          onHide={() => setShowTambahModal(false)}
          // onSubmit={handleTambahAkun}
        />
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={selectedRolesToDelete.map(role => ({
          name: role.code
        }))}
        onDelete={handleDelete}
        title="Hapus Peran Keamanan"
      />
    </div>
  );
};

export default PeranKeamanan;
