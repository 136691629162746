/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBill,
  faUsers,
  faCalendarCheck,
  faBuilding,
  faFileLines,
  faMinus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import PilihPemilikJabatanModal from './PilihPemilikJabatanModal';
import { useCreateClinicalServiceMutation } from 'api/serviceApi';
import { ClinicalServiceCreateRequest } from 'api/request/ClinicalServiceCreateReq';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetAllCategoryServiceQuery } from 'api/categoryServiceApi';
import { useGetCustomerQuery } from 'api/customerApi';

interface TambahLayananModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

interface PriceRow {
  id: string;
  title: string;
  duration: string;
  durationUnit: string;
  customerType: string;
  location: string;
  price: string;
}

interface RatedPriceRow {
  id: string;
  title: string;
  rate: string;
  minDuration: string;
  maxDuration: string;
  customerType: string;
  location: string;
  price: string;
}

interface StaffRow {
  id: string;
  name: string;
  position: string;
  additionalFee: string;
}

const TambahLayananModal = ({
  show,
  handleClose,
  onSuccess
}: TambahLayananModalProps) => {
  const [activeTab, setActiveTab] = useState('harga');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    serviceName: '',
    color: '#E3E6EF',
    description: '',
    category: '',
    status: '',
    createdDate: '',
    bufferTime: 'default',
    bufferTimeBeforeValue: '0',
    bufferTimeBeforeUnit: 'Menit',
    bufferTimeAfterValue: '0',
    bufferTimeAfterUnit: 'Menit',
    priceType: 'fixed'
  });

  const [priceRows, setPriceRows] = useState<PriceRow[]>([
    {
      id: '1',
      title: '',
      duration: '0',
      durationUnit: 'Menit',
      customerType: '',
      location: '',
      price: ''
    }
  ]);

  const [ratedPriceRows, setRatedPriceRows] = useState<RatedPriceRow[]>([
    {
      id: '1',
      title: '',
      rate: 'per Menit',
      minDuration: '0',
      maxDuration: '0',
      customerType: '',
      location: '',
      price: ''
    }
  ]);

  const [staffSettings, setStaffSettings] = useState({
    staffPerBooking: '1',
    additionalFeeType: ''
  });

  const [staffRows, setStaffRows] = useState<StaffRow[]>([]);

  const [showPilihPegawai, setShowPilihPegawai] = useState(false);

  const [createClinicalService] = useCreateClinicalServiceMutation();

  // Tambahkan state untuk menyimpan data kategori
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categories, setCategories] = useState<any[]>([]);

  // Tambahkan state untuk tipe kebijakan
  const [bookingSettings, setBookingSettings] = useState({
    policyType: 'default',
    policyId: '',
    isNotedForReasonVisit: false,
    isDiagnosisRecord: false
  });

  // Tambahkan state untuk tracking perubahan form
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Tambahkan state untuk menyimpan data customer
  const [customers, setCustomers] = useState<
    Array<{ id: string; name: string }>
  >([]);

  // Fetch data kategori
  const { data: categoryData } = useGetAllCategoryServiceQuery({
    page: 1,
    limit: 100,
    orderBy: 'name',
    direction: 'ASC'
  });

  // Fetch data customer
  const { data: customerData } = useGetCustomerQuery({
    page: 1,
    limit: 100,
    orderBy: 'fullName',
    direction: 'ASC'
  });

  // Tambahkan useEffect untuk update categories saat categoryData berubah
  useEffect(() => {
    if (categoryData?.data?.result) {
      setCategories(categoryData.data.result);
    }
  }, [categoryData]); // Hanya re-run saat categoryData berubah

  // Update customers saat customerData berubah
  useEffect(() => {
    if (customerData?.data) {
      const formattedCustomers = customerData.data.map(customer => ({
        id: customer.id,
        name: customer.fullName || '-'
      }));
      setCustomers(formattedCustomers);
    }
  }, [customerData]);

  // Fungsi untuk mengecek apakah form sudah diubah
  const checkFormDirty = () => {
    const isDirty =
      formData.serviceName !== '' ||
      formData.description !== '' ||
      formData.category !== '' ||
      formData.createdDate !== '' ||
      priceRows.some(row => row.title !== '' || row.price !== '') ||
      ratedPriceRows.some(row => row.title !== '' || row.price !== '') ||
      staffRows.length > 0;

    setIsFormDirty(isDirty);
  };

  // Fungsi untuk reset form ke nilai default
  const resetForm = () => {
    setFormData({
      serviceName: '',
      color: '#E3E6EF',
      description: '',
      category: '',
      status: '',
      createdDate: '',
      bufferTime: 'default',
      bufferTimeBeforeValue: '0',
      bufferTimeBeforeUnit: 'Menit',
      bufferTimeAfterValue: '0',
      bufferTimeAfterUnit: 'Menit',
      priceType: 'fixed'
    });

    setPriceRows([
      {
        id: '1',
        title: '',
        duration: '0',
        durationUnit: 'Menit',
        customerType: '',
        location: '',
        price: ''
      }
    ]);

    setRatedPriceRows([
      {
        id: '1',
        title: '',
        rate: 'per Menit',
        minDuration: '0',
        maxDuration: '0',
        customerType: '',
        location: '',
        price: ''
      }
    ]);

    setStaffSettings({
      staffPerBooking: '1',
      additionalFeeType: ''
    });

    setStaffRows([]);

    setBookingSettings({
      policyType: 'default',
      policyId: '',
      isNotedForReasonVisit: false,
      isDiagnosisRecord: false
    });

    setValidated(false);
    setIsFormDirty(false);
    setActiveTab('harga');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;

    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Layanan akan ditambahkan',
      deskripsi: 'Apakah Anda yakin data layanan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Update mapping customer untuk handle "all"
          const getCustomerIds = (customerType: string): string[] => {
            if (customerType === 'all') {
              return customers.map(customer => customer.id); // Gunakan semua ID customer
            }
            return [customerType];
          };

          const requestData: ClinicalServiceCreateRequest = {
            name: formData.serviceName,
            description: formData.description,
            categoryId: formData.category,
            color: formData.color,
            beforeTreatmentInMinutes: parseInt(formData.bufferTimeBeforeValue),
            afterTreatmentInMinutes: parseInt(formData.bufferTimeAfterValue),
            pricingType: formData.priceType === 'fixed' ? 'Fixed' : 'Rated',
            status: formData.status === 'aktif',
            additionalCost:
              staffSettings.additionalFeeType === 'fixed'
                ? 'Jumlah Tetap'
                : 'Persentase',
            bookingSettings: {
              policyName: 'Default Policy',
              isNotedForReasonVisit: bookingSettings.isNotedForReasonVisit,
              isDiagnosisRecord: bookingSettings.isDiagnosisRecord
            },
            clinicalServiceNotes: [],
            clinicalServicePricing:
              formData.priceType === 'fixed'
                ? priceRows.map(row => ({
                    title: row.title,
                    duration: parseInt(row.duration),
                    durationType: row.durationUnit.toLowerCase(),
                    customerId: getCustomerIds(row.customerType),
                    location: [
                      row.location === 'all' ? 'klinik' : row.location
                    ],
                    price: parseInt(row.price),
                    taxPolicy: 'VAT'
                  }))
                : ratedPriceRows.map(row => ({
                    title: row.title,
                    min: parseInt(row.minDuration),
                    max: parseInt(row.maxDuration),
                    durationType: 'menit',
                    customerId: getCustomerIds(row.customerType),
                    location: [
                      row.location === 'all' ? 'klinik' : row.location
                    ],
                    price: parseInt(row.price),
                    taxPolicy: 'VAT'
                  }))
          };

          const response = await createClinicalService(requestData).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Layanan baru berhasil ditambahkan',
              buttonType: 'ok',
              onConfirm: () => {
                resetForm();
                handleClose();
                if (onSuccess) onSuccess();
              }
            });
          }
        } catch (error) {
          console.error('Error creating service:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menyimpan data',
            deskripsi: 'Mohon perbaiki masalah yang ditampilkan dan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleCancelClick = () => {
    if (isFormDirty) {
      showCustomAlert({
        icon: 'warning',
        label: 'Layanan tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm(); // Reset form setelah konfirmasi batal
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Set form dirty saat ada perubahan
    setIsFormDirty(true);
  };

  const handleAddPriceRow = () => {
    setPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(), // unique ID
        title: '',
        duration: '0',
        durationUnit: 'Menit',
        customerType: '',
        location: '',
        price: ''
      }
    ]);
  };

  const handleRemovePriceRow = (id: string) => {
    if (priceRows.length > 1) {
      setPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handlePriceRowChange = (
    id: string,
    field: keyof PriceRow,
    value: string
  ) => {
    setPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  const handleAddRatedPriceRow = () => {
    setRatedPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        title: '',
        rate: 'per Menit',
        minDuration: '0',
        maxDuration: '0',
        customerType: '',
        location: '',
        price: ''
      }
    ]);
  };

  const handleRemoveRatedPriceRow = (id: string) => {
    if (ratedPriceRows.length > 1) {
      setRatedPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handleRatedPriceRowChange = (
    id: string,
    field: keyof RatedPriceRow,
    value: string
  ) => {
    setRatedPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  const handleRemoveStaff = (id: string) => {
    setStaffRows(prev => prev.filter(row => row.id !== id));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePilihPegawai = (selectedEmployees: any[]) => {
    // Dapatkan ID pegawai yang sudah ada
    const existingStaffIds = new Set(staffRows.map(staff => staff.id));

    // Buat array baru dengan menggabungkan pegawai yang sudah ada
    // dan pegawai baru yang dipilih
    const updatedStaffRows = [
      // Pertahankan data pegawai yang sudah ada
      ...staffRows,
      // Tambahkan hanya pegawai baru yang belum ada
      ...selectedEmployees
        .filter(employee => !existingStaffIds.has(employee.id))
        .map(employee => ({
          id: employee.id,
          name: employee.fullName,
          position: employee.jobTitle?.name || '-',
          additionalFee: '0' // Set nilai default untuk pegawai baru
        }))
    ];

    setStaffRows(updatedStaffRows);
    setShowPilihPegawai(false);
  };

  // Handler untuk perubahan booking settings
  const handleBookingSettingsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const isInput = e.target instanceof HTMLInputElement;

    if (name === 'policyType') {
      setBookingSettings(prev => ({
        ...prev,
        policyType: value,
        // Reset policyId jika beralih ke default
        policyId: value === 'default' ? '' : prev.policyId
      }));
    } else {
      setBookingSettings(prev => ({
        ...prev,
        [name]:
          isInput && e.target.type === 'checkbox'
            ? (e.target as HTMLInputElement).checked
            : value
      }));
    }
  };

  return (
    <>
      <style>
        {`
          /* Style untuk modal utama TambahLayanan */
          .modal-dialog-centered.tambah-layanan {
            max-width: 1800px !important;
            width: 95% !important;
          }

          /* Style untuk overlay modal Pilih Pegawai */
          .modal-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1050;
          }

          /* Style lainnya tetap sama */
          .vertical-divider {
            position: absolute;
            left: 41.666667%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
        `}
      </style>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        dialogClassName="tambah-layanan"
      >
        {showPilihPegawai && <div className="modal-overlay" />}
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="me-auto fw-bolder fs-6">
            Tambah Layanan
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancelClick}
            >
              Batalkan
            </Button>
            <Button variant="outline-primary">Simpan & Buat Baru</Button>
            <Button variant="primary" type="submit" form="addServiceForm">
              Simpan
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light px-5 py-4 position-relative">
          <Form
            id="addServiceForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row>
              <Col md={5} className="d-flex flex-column gap-3">
                <div>
                  <h4 className="mb-3">Informasi Layanan</h4>

                  {/* Nama Layanan */}
                  <Form.Group controlId="serviceName" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Nama Layanan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="serviceName"
                      value={formData.serviceName}
                      onChange={handleChange}
                      required
                      placeholder="Masukkan nama layanan"
                    />
                    <Form.Control.Feedback type="invalid">
                      Nama layanan harus diisi
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Warna */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 text-capitalize fs-8 p-0">
                      Warna
                    </Form.Label>
                    <div className="d-flex gap-2 align-items-center">
                      <div
                        className="position-relative"
                        style={{ width: '100%' }}
                      >
                        <Form.Control
                          type="color"
                          name="color"
                          value={formData.color}
                          onChange={handleChange}
                          title="Pilih warna"
                          style={{
                            width: '100%',
                            height: '38px',
                            padding: '2px',
                            cursor: 'pointer'
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: '8px 12px',
                          borderRadius: '4px',
                          border: '1px solid #dee2e6',
                          fontSize: '14px',
                          backgroundColor: '#f8f9fa'
                        }}
                      >
                        {formData.color.toUpperCase()}
                      </div>
                    </div>
                  </Form.Group>

                  {/* Deskripsi Layanan */}
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Deskripsi Layanan
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Masukkan deskripsi layanan"
                    />
                  </Form.Group>

                  {/* Kategori dan Status */}
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="category">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Kategori <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="category"
                          value={formData.category}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Pilih Kategori</option>
                          {categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Kategori harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="status">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Status <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Pilih Status</option>
                          <option value="aktif">Aktif</option>
                          <option value="tidak aktif">Tidak Aktif</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Status harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Tanggal Dibuat */}
                  <Form.Group controlId="createdDate" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tanggal Dibuat <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="createdDate"
                      value={formData.createdDate}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Tanggal dibuat harus diisi
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Waktu Jeda */}
                  <Form.Group controlId="bufferTime" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Waktu Jeda
                    </Form.Label>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          id="buffer-default"
                          name="bufferTime"
                          label="Default"
                          value="default"
                          checked={formData.bufferTime === 'default'}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          id="buffer-custom"
                          name="bufferTime"
                          label="Custom"
                          value="custom"
                          checked={formData.bufferTime === 'custom'}
                          onChange={handleChange}
                        />
                      </div>

                      {formData.bufferTime === 'custom' && (
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-2">
                            <InputGroup style={{ width: '220px' }}>
                              <Form.Control
                                type="number"
                                min="0"
                                name="bufferTimeBeforeValue"
                                placeholder="0"
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{ maxWidth: '100px', borderRight: 0 }}
                              />
                              <Form.Select
                                name="bufferTimeBeforeUnit"
                                value={formData.bufferTimeBeforeUnit}
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{
                                  width: '120px',
                                  borderLeft: '0.5px solid #CBD0DD'
                                }}
                              >
                                <option value="Menit">Menit</option>
                                <option value="Jam">Jam</option>
                              </Form.Select>
                            </InputGroup>
                            <span className="text-secondary fs-9">
                              Sebelum booking dimulai
                            </span>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <InputGroup style={{ width: '220px' }}>
                              <Form.Control
                                type="number"
                                min="0"
                                name="bufferTimeAfterValue"
                                placeholder="0"
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{ maxWidth: '100px', borderRight: 0 }}
                              />
                              <Form.Select
                                name="bufferTimeAfterUnit"
                                value={formData.bufferTimeAfterUnit}
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{
                                  width: '120px',
                                  borderLeft: '0.5px solid #CBD0DD'
                                }}
                              >
                                <option value="Menit">Menit</option>
                                <option value="Jam">Jam</option>
                              </Form.Select>
                            </InputGroup>
                            <span className="text-secondary fs-9">
                              Setelah booking selesai
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </Col>

              <Col md={7}>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={k => k && setActiveTab(k)}
                >
                  <Nav
                    variant="tabs"
                    className="mb-3 d-flex justify-content-between flex-wrap gap-3"
                  >
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'harga' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => setActiveTab('harga')}
                      >
                        <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                        Harga Layanan
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pegawai'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pegawai')}
                      >
                        <FontAwesomeIcon icon={faUsers} className="me-2" />
                        Pegawai
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'booking'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('booking')}
                      >
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          className="me-2"
                        />
                        Booking
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'fasilitas'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('fasilitas')}
                      >
                        <FontAwesomeIcon icon={faBuilding} className="me-2" />
                        Fasilitas
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'formTemplat'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('formTemplat')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Form Templat
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="harga">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Harga Layanan</h4>

                        {/* Radio buttons untuk jenis harga */}
                        <div className="mb-2">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Harga
                          </Form.Label>
                          <div className="d-flex gap-3">
                            <Form.Check
                              type="radio"
                              id="fixed-price"
                              name="priceType"
                              label="Fixed Prices"
                              value="fixed"
                              checked={formData.priceType === 'fixed'}
                              onChange={handleChange}
                            />
                            <Form.Check
                              type="radio"
                              id="rated-price"
                              name="priceType"
                              label="Rated Prices"
                              value="rated"
                              checked={formData.priceType === 'rated'}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {/* Price Input Rows */}
                        {formData.priceType === 'fixed'
                          ? priceRows.map((row, index) => (
                              <div
                                key={row.id}
                                className="d-flex gap-2 mb-2 justify-content-between"
                              >
                                {/* Judul */}
                                <div style={{ width: '180px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Judul
                                    </span>
                                  )}
                                  <Form.Control
                                    type="text"
                                    placeholder="judul"
                                    value={row.title}
                                    onChange={e =>
                                      handlePriceRowChange(
                                        row.id,
                                        'title',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Durasi */}
                                <div style={{ width: '200px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Durasi
                                    </span>
                                  )}
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'duration',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '50px', borderRight: 0 }}
                                    />
                                    <Form.Select
                                      value={row.durationUnit}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'durationUnit',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        width: '70px',
                                        borderLeft: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="Menit">Menit</option>
                                      <option value="Jam">Jam</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Pelanggan dan Lokasi */}
                                <div style={{ width: '380px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Pelanggan
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Lokasi
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Select
                                      value={row.customerType}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'customerType',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        borderRight: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="all">
                                        Semua Pelanggan
                                      </option>
                                      {customers.map(customer => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Select
                                      value={row.location}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'location',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="all">Semua Lokasi</option>
                                      <option value="klinik">Klinik</option>
                                      <option value="rumah">Rumah</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Harga */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Harga (Rp)
                                    </span>
                                  )}
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    placeholder="Harga"
                                    value={row.price}
                                    onChange={e =>
                                      handlePriceRowChange(
                                        row.id,
                                        'price',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Button Hapus */}
                                <div
                                  style={{
                                    width: '50px',
                                    marginTop: index === 0 ? '28px' : '0'
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center w-50"
                                    onClick={() => handleRemovePriceRow(row.id)}
                                    disabled={priceRows.length === 1}
                                    style={{
                                      border: '1px solid #dee2e6'
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              </div>
                            ))
                          : ratedPriceRows.map((row, index) => (
                              <div
                                key={row.id}
                                className="d-flex gap-2 mb-2 justify-content-between"
                              >
                                {/* Judul */}
                                <div style={{ width: '130px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Judul
                                    </span>
                                  )}
                                  <Form.Control
                                    type="text"
                                    placeholder="Judul"
                                    value={row.title}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'title',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Tarif */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Tarif
                                    </span>
                                  )}
                                  <Form.Select
                                    value={row.rate}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'rate',
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="per Menit">per Menit</option>
                                    <option value="per Jam">per Jam</option>
                                    <option value="per Hari">per Hari</option>
                                  </Form.Select>
                                </div>

                                {/* Min Max Duration */}
                                <div style={{ width: '190px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Min
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Max
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'minDuration',
                                          e.target.value
                                        )
                                      }
                                      style={{ borderRight: 0 }}
                                    />
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'maxDuration',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </InputGroup>
                                </div>

                                {/* Pelanggan dan Lokasi */}
                                <div style={{ width: '300px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Pelanggan
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Lokasi
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Select
                                      value={row.customerType}
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'customerType',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        borderRight: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="all">
                                        Semua Pelanggan
                                      </option>
                                      {customers.map(customer => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Select
                                      value={row.location}
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'location',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Semua Lokasi</option>
                                      <option value="klinik">Klinik</option>
                                      <option value="rumah">Rumah</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Harga */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Harga (Rp)
                                    </span>
                                  )}
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    placeholder="Harga"
                                    value={row.price}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'price',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Button Hapus */}
                                <div
                                  style={{
                                    width: '50px',
                                    marginTop: index === 0 ? '28px' : '0'
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center w-50"
                                    onClick={() =>
                                      handleRemoveRatedPriceRow(row.id)
                                    }
                                    disabled={ratedPriceRows.length === 1}
                                    style={{
                                      border: '1px solid #dee2e6'
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              </div>
                            ))}

                        {/* Button Tambah */}
                        <Button
                          variant="outline-primary"
                          className="w-100 rounded-3 mb-3"
                          onClick={
                            formData.priceType === 'fixed'
                              ? handleAddPriceRow
                              : handleAddRatedPriceRow
                          }
                        >
                          + Tambah
                        </Button>
                        <Form.Group controlId="tax" className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pajak
                          </Form.Label>
                          <Form.Select name="tax">
                            <option value="">Pilih Pajak</option>
                            <option value="5%">5%</option>
                            <option value="10%">10%</option>
                            <option value="15%">15%</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="pegawai">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Pegawai</h4>

                        {/* Settings Row */}
                        <Row className="mb-4">
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Pegawai per Booking
                              </Form.Label>
                              <Form.Select
                                value={staffSettings.staffPerBooking}
                                onChange={e =>
                                  setStaffSettings(prev => ({
                                    ...prev,
                                    staffPerBooking: e.target.value
                                  }))
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Biaya Tambahan
                              </Form.Label>
                              <Form.Select
                                value={staffSettings.additionalFeeType}
                                onChange={e => {
                                  const newType = e.target.value;
                                  // Reset nilai biaya tambahan saat tipe berubah
                                  setStaffRows(prev =>
                                    prev.map(row => ({
                                      ...row,
                                      // Jika berubah ke persentase, set ke '0'
                                      // Jika berubah ke fixed, set ke '0'
                                      additionalFee: '0'
                                    }))
                                  );
                                  setStaffSettings(prev => ({
                                    ...prev,
                                    additionalFeeType: newType
                                  }));
                                }}
                              >
                                <option value="">Persentase</option>
                                <option value="fixed">Jumlah Tetap</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Label className="text-dark mb-2 p-0 fs-8 text-capitalize">
                          Pegawai
                        </Form.Label>

                        {/* Staff Table */}
                        <div className="table-responsive mb-3">
                          <table className="table">
                            <thead>
                              <tr className="fs-9">
                                <th className="ps-0 text-muted">
                                  NAMA PEGAWAI
                                </th>
                                <th className="text-muted">JABATAN</th>
                                <th className="text-muted">
                                  BIAYA TAMBAHAN{' '}
                                  {staffSettings.additionalFeeType === 'fixed'
                                    ? '(RP)'
                                    : '(%)'}
                                </th>
                                <th style={{ width: '40px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {staffRows.length === 0 ? (
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="text-center text-muted fs-9"
                                  >
                                    Belum ada pegawai
                                  </td>
                                </tr>
                              ) : (
                                staffRows.map(staff => (
                                  <tr
                                    key={staff.id}
                                    className="fs-9 align-middle"
                                  >
                                    <td>{staff.name}</td>
                                    <td>{staff.position}</td>
                                    <td>
                                      {staffSettings.additionalFeeType ===
                                      'fixed' ? (
                                        <Form.Control
                                          type="text"
                                          value={staff.additionalFee}
                                          onChange={e => {
                                            const value =
                                              e.target.value.replace(/\D/g, '');
                                            setStaffRows(prev =>
                                              prev.map(row =>
                                                row.id === staff.id
                                                  ? {
                                                      ...row,
                                                      additionalFee: value
                                                    }
                                                  : row
                                              )
                                            );
                                          }}
                                          className="text-center"
                                          style={{
                                            width: '100%',
                                            border: 'none',
                                            background: 'transparent'
                                          }}
                                        />
                                      ) : (
                                        <div className="d-flex align-items-center">
                                          <Button
                                            variant="light"
                                            size="sm"
                                            onClick={() => {
                                              const value =
                                                parseInt(staff.additionalFee) -
                                                1;
                                              if (value >= 0) {
                                                setStaffRows(prev =>
                                                  prev.map(row =>
                                                    row.id === staff.id
                                                      ? {
                                                          ...row,
                                                          additionalFee: value
                                                            .toString()
                                                            .padStart(2, '0')
                                                        }
                                                      : row
                                                  )
                                                );
                                              }
                                            }}
                                            style={{ padding: '4px 8px' }}
                                          >
                                            <span>{'<'}</span>
                                          </Button>
                                          <Form.Control
                                            type="text"
                                            value={staff.additionalFee.padStart(
                                              2,
                                              '0'
                                            )}
                                            onChange={e => {
                                              const value =
                                                e.target.value.replace(
                                                  /\D/g,
                                                  ''
                                                );
                                              const numValue = parseInt(
                                                value || '0'
                                              );
                                              // Batasi maksimal 999
                                              if (numValue <= 999) {
                                                setStaffRows(prev =>
                                                  prev.map(row =>
                                                    row.id === staff.id
                                                      ? {
                                                          ...row,
                                                          additionalFee:
                                                            numValue
                                                              .toString()
                                                              .padStart(2, '0')
                                                        }
                                                      : row
                                                  )
                                                );
                                              }
                                            }}
                                            className="mx-1 text-center"
                                            style={{
                                              width: '40px',
                                              padding: '4px',
                                              border: 'none',
                                              background: 'transparent'
                                            }}
                                          />
                                          <Button
                                            variant="light"
                                            size="sm"
                                            onClick={() => {
                                              const value =
                                                parseInt(staff.additionalFee) +
                                                1;
                                              if (value <= 999) {
                                                setStaffRows(prev =>
                                                  prev.map(row =>
                                                    row.id === staff.id
                                                      ? {
                                                          ...row,
                                                          additionalFee: value
                                                            .toString()
                                                            .padStart(2, '0')
                                                        }
                                                      : row
                                                  )
                                                );
                                              }
                                            }}
                                            style={{ padding: '4px 8px' }}
                                          >
                                            <span>{'>'}</span>
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      <Button
                                        variant="link"
                                        className="text-dark p-0"
                                        onClick={() =>
                                          handleRemoveStaff(staff.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* Button Tambah Pegawai */}
                        <Button
                          variant="outline-primary"
                          className="w-100 rounded-3"
                          onClick={() => setShowPilihPegawai(true)}
                        >
                          + Tambah Pegawai
                        </Button>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="booking">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Pengaturan Booking</h4>

                        {/* Kebijakan */}
                        <div className="mb-2">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Kebijakan
                          </Form.Label>
                          <div className="d-flex gap-3">
                            <Form.Check
                              type="radio"
                              id="default-policy"
                              name="policyType"
                              label="Kebijakan Default"
                              value="default"
                              checked={bookingSettings.policyType === 'default'}
                              onChange={handleBookingSettingsChange}
                            />
                            <Form.Check
                              type="radio"
                              id="custom-policy"
                              name="policyType"
                              label="Kebijakan Kustom"
                              value="custom"
                              checked={bookingSettings.policyType === 'custom'}
                              onChange={handleBookingSettingsChange}
                            />
                          </div>
                        </div>

                        {/* Dropdown Pilih Kebijakan */}
                        <Form.Group className="mb-2">
                          <Form.Select
                            name="policyId"
                            value={bookingSettings.policyId}
                            onChange={handleBookingSettingsChange}
                            disabled={bookingSettings.policyType === 'default'}
                          >
                            <option value="">Pilih Kebijakan</option>
                            <option value="1">Kebijakan 1</option>
                            <option value="2">Kebijakan 2</option>
                          </Form.Select>
                        </Form.Group>

                        {/* Checkbox Options */}
                        <div>
                          <Form.Check
                            type="checkbox"
                            id="recordVisitReason"
                            name="isNotedForReasonVisit"
                            label="Catat alasan kunjungan"
                            checked={bookingSettings.isNotedForReasonVisit}
                            onChange={handleBookingSettingsChange}
                          />
                        </div>

                        <div className="mb-1">
                          <Form.Check
                            type="checkbox"
                            id="recordDiagnosis"
                            name="isDiagnosisRecord"
                            label="Rekam Diagnosa"
                            checked={bookingSettings.isDiagnosisRecord}
                            onChange={handleBookingSettingsChange}
                          />
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fasilitas">
                      <div className="d-flex flex-column gap-3">
                        <h4>Informasi Fasilitas</h4>
                        {/* Form fields untuk fasilitas */}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="formTemplat">
                      <div className="d-flex flex-column gap-3">
                        <h4>Form Templat</h4>
                        {/* Form fields untuk template */}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal Pilih Pegawai */}
      <PilihPemilikJabatanModal
        show={showPilihPegawai}
        onHide={() => setShowPilihPegawai(false)}
        onSelect={handlePilihPegawai}
        selectedIds={staffRows.map(staff => staff.id)}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
        showPositionAlert={false}
      />
    </>
  );
};

export default TambahLayananModal;
