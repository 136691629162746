/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  faChevronDown,
  faFileExport,
  faTrash,
  faUndo,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import KebijakanTable, {
  kebijakanTableColumns,
  dummyKebijakan,
  Kebijakan
} from 'components/tables/KebijakanTable';
import { daftarKebijakanBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { showCustomAlert } from 'helpers/showCustomAlert';
import TambahKebijakanModal from 'components/modals/TambahKebijakanModal';

const DaftarKebijakan = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTambahKebijakanModal, setShowTambahKebijakanModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Gunakan dummy data untuk sementara
  const kebijakanData = {
    data: {
      result: dummyKebijakan,
      totalPages: 1,
      total: dummyKebijakan.length
    }
  };
  const isLoading = false;
  const isFetching = false;
  const refetch = () => {};

  // Update table data
  const table = useAdvanceTable<Kebijakan>({
    data: dummyKebijakan,
    columns: kebijakanTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    table.setPageIndex(newPage - 1);
  };

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-kebijakan.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await refetch();
      toast.success('Data berhasil diperbarui');
    } catch (error) {
      toast.error('Gagal memperbarui data');
      console.error('Refresh error:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  const handleConfirmDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => row.original.id);

    showCustomAlert({
      icon: 'warning',
      label: 'Kebijakan akan dihapus!',
      deskripsi: 'Apakah Anda yakin untuk menghapus kebijakan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);
          setShowDeleteModal(false);

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: `${selectedIds.length} kebijakan berhasil dihapus`,
            buttonType: 'ok'
          });

          // Refresh data
          refetch();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const errorMessage =
            error.data?.message || 'Gagal menghapus kebijakan';
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: errorMessage,
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      showCustomAlert({
        icon: 'warning',
        label: 'Pilih Kebijakan',
        deskripsi: 'Silakan pilih kebijakan yang akan dihapus',
        buttonType: 'ok'
      });
      return;
    }
    handleConfirmDelete();
  };

  const handleShowTambahKebijakanModal = () => {
    setShowTambahKebijakanModal(true);
  };

  const handleCloseTambahKebijakanModal = () => {
    setShowTambahKebijakanModal(false);
  };

  const handleSaveAndNewSuccess = () => {
    // Refresh data jika perlu
    // ...

    // Buka modal baru
    handleShowTambahKebijakanModal();
  };

  return (
    <div>
      <PageBreadcrumb items={daftarKebijakanBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Daftar Kebijakan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari kebijakan"
              style={{ maxWidth: '300px' }}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${isRefreshing ? 'fa-spin' : ''}`}
              />
              {isRefreshing ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="outline-danger"
              onClick={handleDeleteClick}
              disabled={selectedRows === 0}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              {selectedRows === 0
                ? 'Hapus kebijakan'
                : `Hapus kebijakan (${selectedRows})`}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowTambahKebijakanModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Kebijakan
            </Button>
          </div>
        </div>

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              {isLoading ? (
                <div className="text-center py-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <KebijakanTable
                  onPageChange={handlePageChange}
                  currentPage={page}
                  totalPages={kebijakanData?.data?.totalPages || 1}
                  kebijakanData={{
                    totalRecords: kebijakanData?.data?.total || 0
                  }}
                />
              )}
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel Kebijakan"
      />

      <TambahKebijakanModal
        show={showTambahKebijakanModal}
        handleClose={handleCloseTambahKebijakanModal}
        onSuccess={handleSaveAndNewSuccess}
      />
    </div>
  );
};

export default DaftarKebijakan;
