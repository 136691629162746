import {
  useAddCustomerNoteMutation,
  useGetCustomerByIdQuery,
  useEditCustomerNoteMutation,
  useDeleteCustomerNoteMutation
} from 'api/customerApi';
import classNames from 'classnames';
import Button from 'components/base/Button';
import React, { useState, useEffect } from 'react';
import { ButtonGroup, Card, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencilAlt,
  faXmark,
  faFloppyDisk
} from '@fortawesome/free-solid-svg-icons';

interface NoteProps {
  id: string;
  note: string;
  date: string;
  type: string;
  isLast?: boolean;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  onEdit: (id: string, content: string, type: string) => void;
  onUpdate: (id: string, content: string, type: string) => void;
  onDelete: (id: string) => void;
}

const Note = ({
  id,
  note,
  date,
  type,
  isLast,
  isEditing,
  setIsEditing,
  onUpdate,
  onDelete
}: NoteProps) => {
  const [editedNote, setEditedNote] = useState(note);
  const [editedType, setEditedType] = useState(type);

  const handleSave = () => {
    onUpdate(id, editedNote, editedType);
  };

  const handleCancel = () => {
    const hasChanges = editedNote !== note || editedType !== type;

    if (hasChanges) {
      showCustomAlert({
        icon: 'question',
        label: 'Batalkan Perubahan',
        deskripsi: 'Apakah Anda yakin ingin membatalkan perubahan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setEditedNote(note);
          setEditedType(type);
          setIsEditing(false);
        }
      });
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div
      className={classNames('fs-9 fw-semibold position-relative', {
        'border-bottom border-dashed pb-3 mb-4': !isLast
      })}
    >
      {isEditing ? (
        <>
          <div className="position-relative mb-3">
            <Form.Control
              as="textarea"
              value={editedNote}
              onChange={e => setEditedNote(e.target.value)}
            />
            {editedNote && (
              <Button
                variant="link"
                className="position-absolute top-0 end-0 text-secondary p-2"
                onClick={() => setEditedNote('')}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-2 bg-success bg-opacity-10 rounded px-2 py-1">
                <span className="text-success">UMUM</span>
                <Form.Check
                  type="radio"
                  name="noteType"
                  checked={editedType === 'public'}
                  onChange={() => setEditedType('public')}
                  className="text-success fw-bold custom-radio-as-checkbox"
                />
              </div>
              <div className="d-flex align-items-center gap-2 bg-primary bg-opacity-10 rounded px-2 py-1">
                <span className="text-primary">PRIBADI</span>
                <Form.Check
                  type="radio"
                  name="noteType"
                  checked={editedType === 'private'}
                  onChange={() => setEditedType('private')}
                  className="text-primary fw-bold custom-radio-as-checkbox"
                />
              </div>
            </div>
            <div className="d-flex">
              <Button
                size="sm"
                variant="link"
                className="text-danger"
                onClick={handleCancel}
              >
                <FontAwesomeIcon icon={faXmark} className="me-2" />
                Batalkan
              </Button>
              <Button
                size="sm"
                variant="link"
                onClick={handleSave}
                className="pe-0"
              >
                <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
                Simpan
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="text-body-highlight mb-3">{note}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="text-body-tertiary text-opacity-85 mb-0">{date}</p>
              <span
                className={`badge ${
                  type === 'private'
                    ? 'bg-primary-subtle text-primary'
                    : 'bg-success-subtle text-success'
                }`}
              >
                {type === 'private' ? 'PRIBADI' : 'UMUM'}
              </span>
            </div>
            <div className="d-flex gap-2">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="cursor-pointer text-primary"
                onClick={() => {
                  setIsEditing(true);
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="cursor-pointer text-danger"
                onClick={() => onDelete(id)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CustomerNotesCard = ({ className }: { className?: string }) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: response,
    isLoading,
    error
  } = useGetCustomerByIdQuery(id ?? '', { skip: !id });
  const [addCustomerNote, { isLoading: isAdding }] =
    useAddCustomerNoteMutation();
  const [editCustomerNote] = useEditCustomerNoteMutation();
  const [deleteCustomerNote] = useDeleteCustomerNoteMutation();

  const [notes, setNotes] = useState<
    Array<{ id: string; content: string; type: string; createdDate: string }>
  >([]);
  const [newNote, setNewNote] = useState('');
  const [noteType, setNoteType] = useState('private'); // Default ke private
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);

  useEffect(() => {
    if (response?.data?.notes) {
      const sortedNotes = [...response.data.notes].reverse();
      setNotes(sortedNotes);
    }
  }, [response]);

  const handleAddNote = async () => {
    if (newNote.trim() === '') {
      showCustomAlert({
        icon: 'error',
        label: 'Error',
        deskripsi: 'Catatan tidak boleh kosong!',
        buttonType: 'ok'
      });
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Tambah Catatan',
      deskripsi: 'Apakah Anda yakin ingin menambahkan catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const result = await addCustomerNote({
            customerId: parseInt(id ?? '0', 10),
            content: newNote,
            noteType
          }).unwrap();

          const newNoteData = result.data;
          setNotes(prevNotes => [newNoteData, ...prevNotes]);
          setNewNote('');

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil ditambahkan!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menambahkan catatan',
            deskripsi: 'Catatan minimal 5 karakter.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleNoteTypeChange = (type: string) => {
    setNoteType(type);
  };

  const handleEdit = (id: string) => {
    setEditingNoteId(id);
  };

  const handleUpdate = async (id: string, content: string, type: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await editCustomerNote({
            id: parseInt(id),
            content,
            noteType: type
          }).unwrap();

          setNotes(prevNotes =>
            prevNotes.map(note =>
              note.id === id ? { ...note, content, type } : note
            )
          );

          setEditingNoteId(null);

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil diperbarui!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal memperbarui catatan. Silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleDelete = async (noteId: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Hapus Catatan',
      deskripsi: 'Apakah Anda yakin ingin menghapus catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteCustomerNote(parseInt(noteId)).unwrap();

          setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil dihapus!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menghapus catatan. Silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleAction = () => {
    if (editingNoteId) {
      handleUpdate(editingNoteId, newNote, noteType);
    } else {
      handleAddNote();
    }
  };

  if (isLoading) {
    return (
      <Card className={classNames(className)}>
        <Card.Body className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className={classNames(className)}>
        <Card.Body>
          <p className="text-danger">Gagal memuat data. Silakan coba lagi.</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-start">
          <h3 className="mb-4">Catatan untuk Pelanggan</h3>
          <ButtonGroup>
            <Button
              variant={noteType === 'private' ? 'primary' : 'outline-primary'}
              onClick={() => handleNoteTypeChange('private')}
            >
              Pribadi
            </Button>
            <Button
              variant={noteType === 'public' ? 'primary' : 'outline-primary'}
              onClick={() => handleNoteTypeChange('public')}
            >
              Umum
            </Button>
          </ButtonGroup>
        </div>
        {!editingNoteId && (
          <>
            <div className="position-relative mb-3">
              <Form.Control
                as="textarea"
                rows={4}
                value={newNote}
                onChange={e => setNewNote(e.target.value)}
                placeholder="Tulis catatan baru di sini..."
              />
              {newNote && (
                <Button
                  variant="link"
                  className="position-absolute top-0 end-0 text-secondary p-2"
                  onClick={() => setNewNote('')}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              )}
            </div>
            <Button
              variant="primary"
              className="w-100 mb-4"
              onClick={handleAction}
              disabled={isAdding}
            >
              {isAdding ? (
                <Spinner size="sm" animation="border" />
              ) : (
                'Tambah Catatan'
              )}
            </Button>
          </>
        )}
        {notes.map((note, index) => (
          <Note
            key={note.id}
            id={note.id}
            note={note.content}
            date={note.createdDate}
            type={note.type}
            isLast={index === notes.length - 1}
            isEditing={editingNoteId === note.id}
            setIsEditing={value => {
              setEditingNoteId(value ? note.id : null);
            }}
            onEdit={handleEdit}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default CustomerNotesCard;
