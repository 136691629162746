import { configureStore } from '@reduxjs/toolkit';
import { customerApi } from '../api/customerApi';
import { locationApi } from '../api/locationApi';
import { customerTemplateApi } from '../api/customerTemplateApi';
import { accountApi } from '../api/accountApi';
import { authApi } from 'api/authApi';
import { employeeApi } from 'api/employeeApi';
import { roleApi } from 'api/roleApi';
import { menuServicePrivilegeApi } from 'api/menuServicePrivilegeApi';
import { positionApi } from 'api/positionApi';
import { privilegeSlice } from '../slices/privilegeSlice';
import { employeeTemplateApi } from 'api/employeeTemplateApi';
import { serviceApi } from 'api/serviceApi';
import { categoryServiceApi } from 'api/categoryServiceApi';
import { serviceTemplateApi } from 'api/serviceTemplateApi';

export const store = configureStore({
  reducer: {
    [customerApi.reducerPath]: customerApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [customerTemplateApi.reducerPath]: customerTemplateApi.reducer,
    [employeeTemplateApi.reducerPath]: employeeTemplateApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [positionApi.reducerPath]: positionApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [menuServicePrivilegeApi.reducerPath]: menuServicePrivilegeApi.reducer,
    [privilegeSlice.reducerPath]: privilegeSlice.reducer,
    [serviceTemplateApi.reducerPath]: serviceTemplateApi.reducer,
    [categoryServiceApi.reducerPath]: categoryServiceApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(customerApi.middleware)
      .concat(locationApi.middleware)
      .concat(customerTemplateApi.middleware)
      .concat(employeeTemplateApi.middleware)
      .concat(accountApi.middleware)
      .concat(employeeApi.middleware)
      .concat(positionApi.middleware)
      .concat(roleApi.middleware)
      .concat(authApi.middleware)
      .concat(roleApi.middleware)
      .concat(serviceApi.middleware)
      .concat(menuServicePrivilegeApi.middleware)
      .concat(categoryServiceApi.middleware)
      .concat(serviceTemplateApi.middleware)
});

export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
