import { Modal, Form, Dropdown } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import SearchBox from 'components/common/SearchBox';
import { FilterMenu } from 'components/common/FilterButtonGroup';
import { useGetAccountsQuery } from 'api/accountApi';
import { debounce } from 'lodash';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface PilihPemilikPeranModalProps {
  show: boolean;
  onHide: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (selectedAccounts: any[]) => void;
  selectedIds: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMount: (accounts: any[]) => void;
  style?: React.CSSProperties;
  currentRoleId?: string;
}

const PilihPemilikPeranModal = ({
  show,
  onHide,
  onSelect,
  selectedIds,
  onMount,
  style,
  currentRoleId
}: PilihPemilikPeranModalProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<boolean[]>([]);
  const [selectedAccounts, setSelectedAccounts] =
    useState<string[]>(selectedIds);

  const {
    data: accountData,
    isLoading,
    refetch
  } = useGetAccountsQuery({
    page: currentPage,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'DESC',
    name: searchTerm || undefined,
    status: statusFilter.length > 0 ? statusFilter : undefined
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<FilterMenu[]>([
    {
      label: 'Status',
      items: [
        {
          label: 'Aktif',
          value: 'true',
          checked: false,
          isSelected: false
        },
        {
          label: 'Tidak Aktif',
          value: 'false',
          checked: false,
          isSelected: false
        }
      ]
    }
  ]);

  const handleSearchChange = debounce((value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  }, 300);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleFilterChange = (label: string, selectedItems: any[]) => {
    if (label === 'Status') {
      setFilters(prevFilters =>
        prevFilters.map(menu =>
          menu.label === 'Status'
            ? {
                ...menu,
                items: menu.items.map(item => ({
                  ...item,
                  checked: selectedItems.some(
                    selected => selected.value === item.value
                  )
                }))
              }
            : menu
        )
      );

      const selectedStatus = selectedItems.map(item => item.value === 'true');
      setStatusFilter(selectedStatus);
      setCurrentPage(1);
    }
  };

  const handleSelect = (accountId: string) => {
    setSelectedAccounts(prev =>
      prev.includes(accountId)
        ? prev.filter(id => id !== accountId)
        : [...prev, accountId]
    );
  };

  const handleSubmit = () => {
    const selectedAccountsData = accountData?.data?.result.filter(account =>
      selectedAccounts.includes(account.id)
    );

    // Cek apakah ada akun yang sudah memiliki peran lain
    const accountsWithOtherRole = selectedAccountsData?.filter(
      account =>
        account.role?.id &&
        account.role.id !== currentRoleId &&
        !selectedIds.includes(account.id)
    );

    if (accountsWithOtherRole && accountsWithOtherRole.length > 0) {
      // Jika ada akun dengan peran lain, tampilkan warning dan langsung eksekusi
      showCustomAlert({
        icon: 'warning',
        label: 'Akun yang dipilih sudah memiliki peran lain',
        deskripsi: 'Apakah Anda yakin akan mengubah peran pada akun tersebut?',
        buttonType: 'yes-no',
        onConfirm: () => {
          // Langsung eksekusi tanpa konfirmasi tambahan
          onSelect(selectedAccountsData || []);
        }
      });
    } else {
      // Jika tidak ada akun dengan peran lain, langsung kirim ke parent untuk konfirmasi
      onSelect(selectedAccountsData || []);
    }
  };

  useEffect(() => {
    setSelectedAccounts(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (accountData?.data?.result) {
      onMount(accountData.data.result);
    }
  }, [accountData, onMount]);

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  useEffect(() => {
    if (!show) {
      setSelectedAccounts(selectedIds);
      setCurrentPage(1);
      setSearchTerm('');
      setStatusFilter([]);
      setFilters(prevFilters =>
        prevFilters.map(menu => ({
          ...menu,
          items: menu.items.map(item => ({
            ...item,
            checked: false,
            isSelected: false
          }))
        }))
      );
    }
  }, [show, selectedIds]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static" // Membuat backdrop tetap aktif
      keyboard={false} // Opsional: Menonaktifkan close modal dengan tombol Escape
      centered
      contentClassName="shadow"
      style={{
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '8px',
        ...style
      }}
      backdropClassName="darker-backdrop"
    >
      <Modal.Header className="px-5 py-4">
        <Modal.Title className="fw-bolder">Pilih Akun</Modal.Title>
        <div className="ms-auto">
          <Button variant="link" className="text-danger me-2" onClick={onHide}>
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Pilih Akun
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-5 py-4 bg-light">
        <h4 className="mb-4">Daftar Akun</h4>

        <div className="d-flex gap-3 align-items-center mb-4 justify-content-between">
          <div>
            <SearchBox
              placeholder="Cari"
              onChange={e => handleSearchChange(e.target.value)}
              style={{ maxWidth: '200px' }}
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <div className="d-flex">
              <Dropdown>
                <Dropdown.Toggle
                  variant="white"
                  id="lokasi-dropdown"
                  className="rounded-start rounded-0 border-end-0"
                  style={{ border: '1px solid #E0E0E0' }}
                >
                  Lokasi
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Semua Lokasi</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  variant="white"
                  id="jabatan-dropdown"
                  className="rounded-end rounded-0"
                  style={{ border: '1px solid #E0E0E0' }}
                >
                  Peran Keamanan
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Semua Peran Keamanan</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <Button variant="light" className="d-flex align-items-center gap-2">
              <i className="bi bi-funnel"></i>
              Filter
            </Button>

            <Button variant="light">Reset</Button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '40px' }}></th>
                <th>ALAMAT EMAIL</th>
                <th>NAMA PEGAWAI</th>
                <th>PERAN KEAMANAN</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={4}>Loading...</td>
                </tr>
              ) : (
                accountData?.data?.result.map(account => (
                  <tr key={account.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedAccounts.includes(account.id)}
                        onChange={() => handleSelect(account.id)}
                      />
                    </td>
                    <td>{account.email}</td>
                    <td>{account.employee?.fullName || '-'}</td>
                    <td>{account.role?.code || '-'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PilihPemilikPeranModal;
