/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBill,
  faUsers,
  faCalendarCheck,
  faBuilding,
  faFileLines,
  faMinus,
  faTrash,
  faRotateLeft,
  faCalendarDays,
  faBan,
  faPaperclip,
  faImage,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import TinymceEditor from 'components/base/TinymceEditor';
import Select from 'react-select';
import { Editor as TinyMCEEditor } from 'tinymce';

interface TambahKebijakanModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

interface PriceRow {
  id: string;
  title: string;
  duration: string;
  durationUnit: string;
  customerType: string;
  location: string;
  price: string;
}

interface RatedPriceRow {
  id: string;
  title: string;
  rate: string;
  minDuration: string;
  maxDuration: string;
  customerType: string;
  location: string;
  price: string;
}

interface StaffRow {
  id: string;
  name: string;
  position: string;
  additionalFee: string;
}

const TambahKebijakanModal = ({
  show,
  handleClose,
  onSuccess
}: TambahKebijakanModalProps) => {
  const [activeTab, setActiveTab] = useState('kebijakan');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: '',
    createdDate: '',
    startDate: '',
    endDate: '',
    type: 'Layanan',
    allowCancellation: false,
    allowReschedule: false,
    allowRefund: false,
    allowFormating: false,
    allowSignature: false,
    content: ''
  });

  const [checkboxGroups, setCheckboxGroups] = useState([
    {
      id: '1',
      title: '',
      isRequired: false,
      showInTwoColumns: false,
      options: [
        { id: '1', text: '', isDefault: true },
        { id: '2', text: '', isDefault: false }
      ]
    }
  ]);

  const [paymentForm, setPaymentForm] = useState({
    depositAmount: '0.00',
    depositUnit: 'percent',
    depositSecurityAmount: '0.00',
    depositSecurityUnit: 'rupiah',
    depositOnlineAmount: '0.00',
    depositOnlineUnit: 'percent'
  });

  const [additionalTerms, setAdditionalTerms] = useState<string[]>(['', '']);

  const [formattedContent, setFormattedContent] = useState('');
  const [plainContent, setPlainContent] = useState('');

  const [refundForm, setRefundForm] = useState({
    refundMethods: [] as string[],
    refundDeadline: '1',
    refundDeadlineUnit: 'Menit'
  });

  const [rescheduleForm, setRescheduleForm] = useState({
    rescheduleDeadline: '1',
    rescheduleDeadlineUnit: 'Menit',
    rescheduleFee: '0.00',
    rescheduleFeeUnit: 'percent',
    allowTransfer: 'Tidak'
  });

  const [cancellationForm, setCancellationForm] = useState({
    cancellationDeadline: '1',
    cancellationDeadlineUnit: 'Menit',
    onTimeCancellationFee: '0.00',
    onTimeCancellationFeeUnit: 'percent',
    lateCancellationFee: '0.00',
    lateCancellationFeeUnit: 'percent',
    onTimeDepositHold: '0.00',
    lateDepositHold: '100'
  });

  const refundMethodOptions = [
    { value: 'transfer', label: 'Hanya Transfer' },
    { value: 'cash', label: 'Hanya Cash' },
    { value: 'credit', label: 'Hanya Credit/Voucher' },
    { value: 'all', label: 'Semua Metode' }
  ];

  const [isFormDirty, setIsFormDirty] = useState(false);

  // Tambahkan state untuk errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Tambahkan initial state untuk semua form
  const initialFormData = {
    name: '',
    description: '',
    status: '',
    createdDate: '',
    startDate: '',
    endDate: '',
    type: 'Layanan',
    allowCancellation: false,
    allowReschedule: false,
    allowRefund: false,
    allowFormating: false,
    allowSignature: false,
    content: ''
  };

  const initialPaymentForm = {
    depositAmount: '0.00',
    depositUnit: 'percent',
    depositSecurityAmount: '0.00',
    depositSecurityUnit: 'rupiah',
    depositOnlineAmount: '0.00',
    depositOnlineUnit: 'percent'
  };

  const initialRefundForm = {
    refundMethods: [] as string[],
    refundDeadline: '1',
    refundDeadlineUnit: 'Menit'
  };

  const initialRescheduleForm = {
    rescheduleDeadline: '1',
    rescheduleDeadlineUnit: 'Menit',
    rescheduleFee: '0.00',
    rescheduleFeeUnit: 'percent',
    allowTransfer: 'Tidak'
  };

  const initialCancellationForm = {
    cancellationDeadline: '1',
    cancellationDeadlineUnit: 'Menit',
    onTimeCancellationFee: '0.00',
    onTimeCancellationFeeUnit: 'percent',
    lateCancellationFee: '0.00',
    lateCancellationFeeUnit: 'percent',
    onTimeDepositHold: '0.00',
    lateDepositHold: '100'
  };

  // Tambahkan fungsi resetForm
  const resetForm = () => {
    setFormData(initialFormData);
    setPaymentForm(initialPaymentForm);
    setRefundForm(initialRefundForm);
    setRescheduleForm(initialRescheduleForm);
    setCancellationForm(initialCancellationForm);
    setCheckboxGroups([
      {
        id: '1',
        title: '',
        isRequired: false,
        showInTwoColumns: false,
        options: [
          { id: '1', text: '', isDefault: true },
          { id: '2', text: '', isDefault: false }
        ]
      }
    ]);
    setAdditionalTerms(['', '']);
    setFormattedContent('');
    setPlainContent('');
    setActiveTab('kebijakan');
    setErrors({});
    setIsFormDirty(false);
  };

  // Modifikasi fungsi validateForm
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.name.trim()) newErrors.name = 'Nama kebijakan wajib diisi';
    if (!formData.status) newErrors.status = 'Status wajib diisi';
    if (!formData.createdDate)
      newErrors.createdDate = 'Tanggal dibuat wajib diisi';
    if (!formData.startDate)
      newErrors.startDate = 'Tanggal mulai berlaku wajib diisi';

    // Validasi judul checkbox jika ada grup checkbox
    checkboxGroups.forEach((group, index) => {
      if (!group.title.trim()) {
        newErrors[`checkboxTitle_${group.id}`] = 'Judul checkbox wajib diisi';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (formData.type === 'Subscription') {
      setActiveTab('kebijakan');

      setPaymentForm({
        depositAmount: '0.00',
        depositUnit: 'percent',
        depositSecurityAmount: '0.00',
        depositSecurityUnit: 'rupiah',
        depositOnlineAmount: '0.00',
        depositOnlineUnit: 'percent'
      });

      setFormData(prev => ({
        ...prev,
        allowCancellation: false,
        allowReschedule: false,
        allowRefund: false
      }));
    }
  }, [formData.type]);

  useEffect(() => {
    if (formData.allowFormating) {
      setFormData(prev => ({
        ...prev,
        content: formattedContent
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        content: plainContent
      }));
    }
  }, [formData.allowFormating]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Kebijakan akan disimpan',
      deskripsi: 'Apakah Anda yakin data kebijakan ini sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Implementasi submit form
          if (onSuccess) {
            onSuccess();
          }

          // Tampilkan pesan sukses
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Kebijakan baru berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              resetForm();
              handleClose();
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal Menyimpan data',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setIsFormDirty(true);
    const target = e.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (content: string) => {
    setFormattedContent(content);
    setFormData(prev => ({
      ...prev,
      content
    }));
  };

  const handlePlainContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPlainContent(value);
    setFormData(prev => ({
      ...prev,
      content: value
    }));
  };

  const handleAddOption = (groupId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: [
              ...group.options,
              { id: Date.now().toString(), text: '', isDefault: false }
            ]
          };
        }
        return group;
      })
    );
  };

  const handleRemoveOption = (groupId: string, optionId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.filter(opt => opt.id !== optionId)
          };
        }
        return group;
      })
    );
  };

  const handleCheckboxGroupChange = (
    groupId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            [field]: value
          };
        }
        return group;
      })
    );
  };

  const handleOptionChange = (
    groupId: string,
    optionId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.map(opt => {
              if (opt.id === optionId) {
                return {
                  ...opt,
                  [field]: value
                };
              }
              return opt;
            })
          };
        }
        return group;
      })
    );
  };

  const handlePaymentChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPaymentForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddTerm = () => {
    setAdditionalTerms([...additionalTerms, '']);
  };

  const handleRemoveTerm = (index: number) => {
    const newTerms = additionalTerms.filter((_, i) => i !== index);
    setAdditionalTerms(newTerms);
  };

  const handleTermChange = (index: number, value: string) => {
    const newTerms = [...additionalTerms];
    newTerms[index] = value;
    setAdditionalTerms(newTerms);
  };

  const handleAddCheckboxGroup = () => {
    setCheckboxGroups(prev => {
      const newGroups = [...prev];
      const newId = (newGroups.length + 1).toString();
      return [
        ...newGroups,
        {
          id: newId,
          title: '',
          isRequired: false,
          showInTwoColumns: false,
          options: [
            { id: '1', text: '', isDefault: true },
            { id: '2', text: '', isDefault: false }
          ]
        }
      ];
    });
  };

  const handleRemoveCheckboxGroup = (groupId: string) => {
    setCheckboxGroups(prev => {
      const filteredGroups = prev.filter(group => group.id !== groupId);
      // Reorder remaining groups
      return filteredGroups.map((group, index) => ({
        ...group,
        id: (index + 1).toString()
      }));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefundMethodChange = (selected: any) => {
    setRefundForm(prev => ({
      ...prev,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      refundMethods: selected.map((item: any) => item.value)
    }));
  };

  // Modifikasi handleCancel
  const handleCancel = () => {
    const isFormFilled =
      formData.name.trim() !== '' ||
      formData.content.trim() !== '' ||
      formData.type !== 'Layanan' ||
      formData.status !== '';

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Kebijakan tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm();
          handleClose();
        }
      });
    } else {
      resetForm();
      handleClose();
    }
  };

  // Modifikasi handleSaveAndNew
  const handleSaveAndNew = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Kebijakan akan disimpan',
      deskripsi: 'Apakah Anda yakin data kebijakan ini sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Implementasi submit form
          if (onSuccess) {
            onSuccess();
          }

          // Reset form dan tutup modal
          resetForm();
          handleClose();

          // Tampilkan pesan sukses dan buka modal baru
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Kebijakan baru berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              // Trigger parent untuk membuka modal baru
              if (onSuccess) {
                onSuccess();
              }
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal Menyimpan data',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Modifikasi konfigurasi TinyMCE
  const tinyConfig = {
    height: '300px',
    placeholder: 'Masukkan isi kebijakan...',
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'help',
      'wordcount'
    ],
    toolbar: [
      'undo redo | styles | fontsize | bold italic underline strikethrough | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist | outdent indent | ' +
        'table link image | removeformat | fullscreen'
    ],
    /* enable title field in the Image dialog */
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    file_picker_types: 'image',
    /* and here's our custom image picker */
    file_picker_callback: (
      cb: (url: string, obj?: { title: string }) => void,
      value: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      meta: Record<string, any>
    ) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', e => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const id = 'blobid' + new Date().getTime();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const blobCache = (window as any).tinymce.activeEditor.editorUpload
            .blobCache;
          const base64 = (reader.result as string).split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });

      input.click();
    },
    content_style: `
      body { font-family:Arial,sans-serif; font-size:11pt }
      .tox-toolbar, 
      .tox-toolbar__overflow,
      .tox-toolbar__primary,
      .tox-toolbar-overlord,
      .tox-editor-header { 
        background: none !important;
        background-color: #EFF2F6 !important;
      }
    `
  };

  return (
    <>
      <style>
        {`
          /* Style untuk modal utama TambahKebijakan */
          .modal-dialog-centered.tambah-kebijakan {
            max-width: 1600px !important;
            width: 95% !important;
          }

          /* Style untuk vertical divider */
          .vertical-divider {
            position: absolute;
            left: 41.666667%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
        `}
      </style>
      <Modal
        show={show}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        dialogClassName="tambah-kebijakan"
        style={{ zIndex: 1050 }} // Pastikan lebih rendah dari z-index TinyMCE
      >
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="me-auto fw-bolder fs-6">
            Tambah Kebijakan
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={handleSaveAndNew}
            >
              Simpan & Buat Baru
            </Button>
            <Button variant="primary" type="submit" form="addPolicyForm">
              Simpan
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light px-5 py-4 position-relative">
          <Form
            id="addPolicyForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row>
              <Col md={5} className="d-flex flex-column gap-3">
                <div>
                  <h4 className="mb-3">Informasi Kebijakan</h4>

                  {/* Tipe Kebijakan */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tipe Kebijakan
                    </Form.Label>
                    <div className="d-flex gap-3">
                      <Form.Check
                        type="radio"
                        id="layanan"
                        name="type"
                        label="Layanan"
                        value="Layanan"
                        checked={formData.type === 'Layanan'}
                        onChange={handleChange}
                        isInvalid={!!errors.type}
                      />
                      <Form.Check
                        type="radio"
                        id="subscription"
                        name="type"
                        label="Subscription"
                        value="Subscription"
                        checked={formData.type === 'Subscription'}
                        onChange={handleChange}
                        isInvalid={!!errors.type}
                      />
                    </div>
                    {errors.type && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.type}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  {/* Nama Kebijakan */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Nama Kebijakan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      placeholder="Masukkan nama kebijakan"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Deskripsi Kebijakan */}
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Deskripsi Kebijakan
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Masukan deskripsi kebijakan"
                    />
                  </Form.Group>

                  {/* Kebijakan Pemesanan - hanya tampil jika tipe Layanan */}
                  {formData.type === 'Layanan' && (
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                        Kebijakan Pemesanan
                      </Form.Label>
                      <div className="d-flex flex-column">
                        <Form.Check
                          type="checkbox"
                          id="allowCancellation"
                          label="Izinkan pembatalan"
                          name="allowCancellation"
                          checked={formData.allowCancellation}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="checkbox"
                          id="allowReschedule"
                          label="Izinkan perubahan"
                          name="allowReschedule"
                          checked={formData.allowReschedule}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="checkbox"
                          id="allowRefund"
                          label="Izinkan pengembalian dana"
                          name="allowRefund"
                          checked={formData.allowRefund}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  )}

                  {/* Status */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Status <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Pilih status</option>
                      <option value="active">Aktif</option>
                      <option value="inactive">Tidak Aktif</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Tanggal Dibuat */}
                  <Form.Group controlId="createdDate" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tanggal Dibuat <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="createdDate"
                      value={formData.createdDate}
                      onChange={handleChange}
                      isInvalid={!!errors.createdDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.createdDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="startDate">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Tanggal Mulai Berlaku{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                          isInvalid={!!errors.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="endDate">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Tanggal berakhir
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={7}>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={k => k && setActiveTab(k)}
                >
                  <Nav
                    variant="tabs"
                    className="mb-3 d-flex justify-content-between flex-wrap gap-3"
                  >
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'kebijakan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('kebijakan')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Kebijakan
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pembayaran'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pembayaran')}
                        disabled={formData.type === 'Subscription'}
                      >
                        <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                        Pembayaran
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pengembalian'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pengembalian')}
                        disabled={
                          formData.type === 'Subscription' ||
                          !formData.allowRefund
                        }
                      >
                        <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
                        Pengembalian Dana
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'perubahan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('perubahan')}
                        disabled={
                          formData.type === 'Subscription' ||
                          !formData.allowReschedule
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className="me-2"
                        />
                        Perubahan Jadwal
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pembatalan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pembatalan')}
                        disabled={
                          formData.type === 'Subscription' ||
                          !formData.allowCancellation
                        }
                      >
                        <FontAwesomeIcon icon={faBan} className="me-2" />
                        Pembatalan Jadwal
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="kebijakan">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">Kebijakan</h4>

                        {/* Isi Persetujuan (Consent) */}
                        <Form.Group className="mb-1">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Isi Persetujuan (Consent)
                          </Form.Label>
                          <div className="d-flex gap-4">
                            <Form.Check
                              className="m-0"
                              type="checkbox"
                              id="allowFormating"
                              label="Izinkan pemformatan"
                              name="allowFormating"
                              onChange={handleChange}
                            />
                            <Form.Check
                              className="m-0"
                              type="checkbox"
                              id="allowSignature"
                              label="Rekam tanda tangan praktisi"
                              name="allowSignature"
                              onChange={handleChange}
                            />
                          </div>
                        </Form.Group>
                        {!formData.allowFormating ? (
                          <>
                            <Form.Group className="mb-3 mt-2">
                              <Form.Control
                                type="text"
                                name="content"
                                value={plainContent}
                                onChange={handlePlainContentChange}
                                placeholder="Masukkan consent"
                              />
                            </Form.Group>

                            {/* Ketentuan Tambahan */}
                            <Form.Group>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Ketentuan Tambahan
                              </Form.Label>
                              {additionalTerms.map((term, index) => (
                                <div key={index} className="d-flex gap-2 mb-2">
                                  <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={e =>
                                      handleTermChange(index, e.target.value)
                                    }
                                    placeholder={`Masukan ketentuan tambahan ${
                                      index + 1
                                    }`}
                                  />
                                  <Button
                                    variant="primary"
                                    onClick={() => handleRemoveTerm(index)}
                                    className="px-3"
                                  >
                                    -
                                  </Button>
                                </div>
                              ))}
                              <Button
                                variant="outline-primary"
                                onClick={handleAddTerm}
                                className="w-100 mt-2"
                              >
                                + Tambah Ketentuan
                              </Button>
                            </Form.Group>
                          </>
                        ) : (
                          <Form.Group>
                            <div className="d-flex flex-column">
                              <TinymceEditor
                                value={formattedContent}
                                onChange={handleEditorChange}
                                options={tinyConfig}
                              />
                            </div>
                          </Form.Group>
                        )}
                        {/* Grup Checkbox */}
                        {checkboxGroups.map(group => (
                          <div key={group.id}>
                            <hr
                              style={{
                                borderWidth: '1px',
                                borderColor: '#9FA6BC'
                              }}
                            />
                            <div>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize d-flex justify-content-between align-items-center">
                                {`Grup Checkbox ${parseInt(group.id)}`}
                                <Button
                                  variant="link"
                                  className="text-dark p-0 rounded-circle"
                                  onClick={() =>
                                    handleRemoveCheckboxGroup(group.id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </Button>
                              </Form.Label>

                              <div className="d-flex gap-4 mb-3">
                                <Form.Check
                                  type="checkbox"
                                  id={`required-${group.id}`}
                                  label="Checkbox wajib isi"
                                  checked={group.isRequired}
                                  onChange={e =>
                                    handleCheckboxGroupChange(
                                      group.id,
                                      'isRequired',
                                      e.target.checked
                                    )
                                  }
                                />
                                <Form.Check
                                  type="checkbox"
                                  id={`columns-${group.id}`}
                                  label="Tampilkan dalam 2 kolom"
                                  checked={group.showInTwoColumns}
                                  onChange={e =>
                                    handleCheckboxGroupChange(
                                      group.id,
                                      'showInTwoColumns',
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Judul Checkbox{' '}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Masukkan judul checkbox"
                                value={group.title}
                                onChange={e =>
                                  handleCheckboxGroupChange(
                                    group.id,
                                    'title',
                                    e.target.value
                                  )
                                }
                                isInvalid={
                                  !!errors[`checkboxTitle_${group.id}`]
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`checkboxTitle_${group.id}`]}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div>
                                  <span className="fs-8">Default</span>
                                  <span className="ms-4 fs-8">Isi Pilihan</span>
                                </div>
                                <div></div>
                              </div>

                              {group.options.map(option => (
                                <div
                                  key={option.id}
                                  className="d-flex gap-3 align-items-center mb-2"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    checked={option.isDefault}
                                    onChange={e =>
                                      handleOptionChange(
                                        group.id,
                                        option.id,
                                        'isDefault',
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Masukan pilihan"
                                    value={option.text}
                                    onChange={e =>
                                      handleOptionChange(
                                        group.id,
                                        option.id,
                                        'text',
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Button
                                    variant="outline-primary"
                                    className=""
                                    onClick={() =>
                                      handleRemoveOption(group.id, option.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              ))}
                            </div>

                            <Button
                              variant="outline-primary"
                              className="w-100"
                              onClick={() => handleAddOption(group.id)}
                            >
                              + Tambah Pilihan
                            </Button>
                          </div>
                        ))}
                        <hr
                          style={{ borderWidth: '1px', borderColor: '#9FA6BC' }}
                        />
                        <Button
                          variant="primary"
                          style={{ width: '30%' }}
                          onClick={handleAddCheckboxGroup}
                        >
                          Tambah Checkbox +
                        </Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pembayaran">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">Kebijakan Pembayaran</h4>

                        {/* Jumlah Deposit Offline */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit pada Pelanggan Offline saat Melakukan
                            Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositAmount"
                              onChange={handlePaymentChange}
                              placeholder="0.00"
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositUnit"
                              value={paymentForm.depositUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Jumlah Deposit Online */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit pada Pelanggan Online saat Melakukan
                            Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositOnlineAmount"
                              onChange={handlePaymentChange}
                              placeholder="0.00"
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositOnlineUnit"
                              value={paymentForm.depositOnlineUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Jumlah Deposit Keamanan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit Keamanan yang Harus Dibayar saat
                            Melakukan Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositSecurityAmount"
                              placeholder="0.00"
                              onChange={handlePaymentChange}
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositSecurityUnit"
                              value={paymentForm.depositSecurityUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pengembalian">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">
                          Kebijakan Pengembalian Dana
                        </h4>

                        {/* Metode Pembayaran */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Metode Pembayaran Pengembalian Dana
                          </Form.Label>
                          <Select
                            isMulti
                            options={refundMethodOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={{
                              placeholder: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              option: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              control: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              multiValue: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              multiValueLabel: provided => ({
                                ...provided,
                                fontSize: '12px'
                              })
                            }}
                            onChange={handleRefundMethodChange}
                            value={refundMethodOptions.filter(option =>
                              refundForm.refundMethods.includes(option.value)
                            )}
                            placeholder="Pilih metode pembayaran"
                          />
                        </Form.Group>

                        {/* Batas Waktu Pengajuan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Harus Mengisi Data Ajuan Pengembalian Dana
                            Sebelum
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="refundDeadline"
                              value={refundForm.refundDeadline}
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  refundDeadline: e.target.value
                                }))
                              }
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="refundDeadlineUnit"
                              value={refundForm.refundDeadlineUnit}
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  refundDeadlineUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="Menit">
                                Menit Sebelum Waktu Booking
                              </option>
                              <option value="Jam">
                                Jam Sebelum Waktu Booking
                              </option>
                              <option value="Hari">
                                Hari Sebelum Waktu Booking
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="perubahan">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">
                          Kebijakan Perubahan Jadwal
                        </h4>

                        {/* Batas Waktu Pengajuan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Harus Mengisi Data Ajuan Perubahan Jadwal
                            Sebelum
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="rescheduleDeadline"
                              value={rescheduleForm.rescheduleDeadline}
                              onChange={e =>
                                setRescheduleForm(prev => ({
                                  ...prev,
                                  rescheduleDeadline: e.target.value
                                }))
                              }
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="rescheduleDeadlineUnit"
                              value={rescheduleForm.rescheduleDeadlineUnit}
                              onChange={e =>
                                setRescheduleForm(prev => ({
                                  ...prev,
                                  rescheduleDeadlineUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="Menit">
                                Menit Sebelum Waktu Booking
                              </option>
                              <option value="Jam">
                                Jam Sebelum Waktu Booking
                              </option>
                              <option value="Hari">
                                Hari Sebelum Waktu Booking
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Jumlah Biaya */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Biaya Perubahan Jadwal
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="rescheduleFee"
                              onChange={e =>
                                setRescheduleForm(prev => ({
                                  ...prev,
                                  rescheduleFee: e.target.value
                                }))
                              }
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="rescheduleFeeUnit"
                              value={rescheduleForm.rescheduleFeeUnit}
                              onChange={e =>
                                setRescheduleForm(prev => ({
                                  ...prev,
                                  rescheduleFeeUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Transfer Booking */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Dapat Mentransfer Booking Mereka Ke
                            Pelanggan Lain
                          </Form.Label>
                          <Form.Select
                            name="allowTransfer"
                            value={rescheduleForm.allowTransfer}
                            onChange={e =>
                              setRescheduleForm(prev => ({
                                ...prev,
                                allowTransfer: e.target.value
                              }))
                            }
                          >
                            <option value="Tidak">Tidak</option>
                            <option value="Ya">Ya</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pembatalan">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">
                          Kebijakan Pembatalan Jadwal
                        </h4>

                        {/* Batas Waktu Pengajuan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Harus Mengisi Data Ajuan Pembatalan Jadwal
                            Sebelum
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="cancellationDeadline"
                              value={cancellationForm.cancellationDeadline}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  cancellationDeadline: e.target.value
                                }))
                              }
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="cancellationDeadlineUnit"
                              value={cancellationForm.cancellationDeadlineUnit}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  cancellationDeadlineUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="Menit">
                                Menit Sebelum Waktu Booking
                              </option>
                              <option value="Jam">
                                Jam Sebelum Waktu Booking
                              </option>
                              <option value="Hari">
                                Hari Sebelum Waktu Booking
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Biaya Pembatalan Tepat Waktu */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Biaya Pembatalan Jadwal bila Dibatalkan Tepat
                            Waktu
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="onTimeCancellationFee"
                              value={cancellationForm.onTimeCancellationFee}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  onTimeCancellationFee: e.target.value
                                }))
                              }
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="onTimeCancellationFeeUnit"
                              value={cancellationForm.onTimeCancellationFeeUnit}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  onTimeCancellationFeeUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Biaya Pembatalan Terlambat */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Biaya Pembatalan Jadwal bila Dibatalkan
                            Terlambat
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="lateCancellationFee"
                              value={cancellationForm.lateCancellationFee}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  lateCancellationFee: e.target.value
                                }))
                              }
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="lateCancellationFeeUnit"
                              value={cancellationForm.lateCancellationFeeUnit}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  lateCancellationFeeUnit: e.target.value
                                }))
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="percent">
                                Persen Harga Jual (%)
                              </option>
                              <option value="rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Deposit yang Ditahan Tepat Waktu */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Persentase Deposit yang Ditahan bila Dibatalkan
                            Tepat Waktu
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="onTimeDepositHold"
                              value={cancellationForm.onTimeDepositHold}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  onTimeDepositHold: e.target.value
                                }))
                              }
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                              style={{ width: '70%' }}
                            />
                            <Form.Select style={{ width: '30%' }} disabled>
                              <option>Persen Jumlah Deposit (%)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Deposit yang Ditahan Terlambat */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Persentase Deposit yang Ditahan bila Dibatalkan
                            Terlambat
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="lateDepositHold"
                              value={cancellationForm.lateDepositHold}
                              onChange={e =>
                                setCancellationForm(prev => ({
                                  ...prev,
                                  lateDepositHold: e.target.value
                                }))
                              }
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                              style={{ width: '70%' }}
                            />
                            <Form.Select style={{ width: '30%' }} disabled>
                              <option>Persen Jumlah Deposit (%)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TambahKebijakanModal;
