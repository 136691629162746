// import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import {
  useGetEmployeeByIdQuery,
  useUpdatePersonalInformationMutation
} from 'api/employeeApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
// import { useEditEmployeeMutation } from 'api/employeeApi';
// import { showCustomAlert } from 'helpers/showCustomAlert';
interface InformasiDasarCardProps {
  id?: string;
  code?: string;
  color?: string;
  description?: string;
  onUpdate?: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InformasiPribadiPegawaiCard = ({ color }: InformasiDasarCardProps) => {
  //   const { id } = useParams<{ id: string }>();
  //   const [editEmployee, { isLoading: isSaving }] = useEditEmployeeMutation();
  const { id } = useParams<{ id: string }>();
  const { data: employeeData, refetch } = useGetEmployeeByIdQuery(id || '');
  const [isEditing, setIsEditing] = useState(false);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const [updatePersonalInfo] = useUpdatePersonalInformationMutation();

  // Pindahkan interface ke luar komponen
  interface FormDataInterface {
    employmentType: string;
    gender:
      | {
          plain: string;
          formatted: string;
        }
      | string;
    religion: string;
    idStatus: {
      type: string;
      idNumber: string;
    };
    birthDate: {
      plain: string;
      date: string;
      age: string;
    };
    maritalStatus: string;
    branch: string;
    color: string;
    joinedDate: string;
  }

  // Inisialisasi formData dengan nilai default kosong
  const [formData, setFormData] = useState<FormDataInterface>({
    employmentType: '',
    gender: '',
    religion: '',
    idStatus: {
      type: '',
      idNumber: ''
    },
    birthDate: {
      plain: '',
      date: '',
      age: ''
    },
    maritalStatus: '',
    branch: '',
    color: '',
    joinedDate: ''
  });

  // Update formData ketika data employee berubah
  useEffect(() => {
    if (employeeData?.data) {
      setFormData({
        employmentType: employeeData.data.employmentType || '',
        gender:
          typeof employeeData.data.gender === 'string'
            ? employeeData.data.gender
            : {
                plain: employeeData.data.gender.plain || '',
                formatted: employeeData.data.gender.formatted || ''
              },
        religion: employeeData.data.religion || '',
        idStatus: {
          type: employeeData.data.idStatus.type || '',
          idNumber: employeeData.data.idStatus.idNumber || ''
        },
        birthDate: {
          plain: employeeData.data.birthDate.plain || '',
          date: employeeData.data.birthDate.date || '',
          age: employeeData.data.birthDate.age || ''
        },
        maritalStatus: employeeData.data.maritalStatus || '',
        branch: employeeData?.data?.branch || '',
        color: employeeData?.data?.color || '',
        joinedDate: employeeData.data.joinedDate.plain || ''
      });
    }
  }, [employeeData]);

  // Fungsi untuk mengecek perubahan
  const hasChanges = () => {
    if (!employeeData?.data) return false;

    const oldGender =
      typeof employeeData.data.gender === 'string'
        ? employeeData.data.gender
        : employeeData.data.gender.plain;
    const newGender =
      typeof formData.gender === 'string'
        ? formData.gender
        : formData.gender.plain;

    return (
      newGender !== oldGender ||
      formData.religion !== employeeData.data.religion ||
      formData.idStatus.type !== employeeData.data.idStatus.type ||
      formData.idStatus.idNumber !== employeeData.data.idStatus.idNumber ||
      formData.birthDate.plain !== employeeData.data.birthDate.plain ||
      formData.maritalStatus !== employeeData.data.maritalStatus ||
      formData.color !== employeeData.data.color
    );
  };

  // Handle cancel dengan pengecekan perubahan
  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Pembatalan',
      deskripsi: 'Apakah Anda yakin ingin membatalkan perubahan?',
      buttonType: 'yes-no',
      onConfirm: () => {
        if (employeeData?.data) {
          // Reset formData ke nilai awal
          setFormData({
            employmentType: employeeData.data.employmentType || '',
            gender:
              typeof employeeData.data.gender === 'string'
                ? employeeData.data.gender
                : {
                    plain: employeeData.data.gender.plain || '',
                    formatted: employeeData.data.gender.formatted || ''
                  },
            religion: employeeData.data.religion || '',
            idStatus: {
              type: employeeData.data.idStatus.type || '',
              idNumber: employeeData.data.idStatus.idNumber || ''
            },
            birthDate: {
              plain: employeeData.data.birthDate.plain || '',
              date: employeeData.data.birthDate.date || '',
              age: employeeData.data.birthDate.age || ''
            },
            maritalStatus: employeeData.data.maritalStatus || '',
            branch: employeeData?.data?.branch || '',
            color: employeeData?.data?.color || '',
            joinedDate: employeeData.data.joinedDate.plain || ''
          });

          // Reset editedColor ke nilai awal
          setEditedColor(employeeData.data.color || 'undefined');
        }
        setIsEditing(false);
      }
    });
  };

  if (!employeeData?.data) {
    return null;
  }

  const { data: employee } = employeeData;
  const [editedColor, setEditedColor] = useState(employee.color || 'undefined');
  const [errors, setErrors] = useState({
    gender: '',
    genderFormatted: '',
    religion: '',
    birthDate: '',
    birthDateAge: '',
    maritalStatus: '',
    idType: '',
    idNumber: '',
    joinDate: '',
    endDate: '',
    branch: '',
    color: ''
    // Tanggal berakhir belum ada
  });

  const handleEditClick = () => setIsEditing(true);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));

    if (name === 'gender') {
      setFormData(prev => ({
        ...prev,
        gender: {
          plain: value,
          formatted: value === 'M' ? 'Male' : value === 'F' ? 'Female' : ''
        }
      }));
    } else if (name === 'color') {
      setFormData(prev => ({
        ...prev,
        color: value
      }));
      setEditedColor(value);
    } else if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent as keyof FormDataInterface] as Record<
            string,
            string
          >),
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setEditedColor(newColor);
    setFormData(prev => ({
      ...prev,
      color: newColor
    }));
  };

  const handleSave = async () => {
    // Validasi field yang required
    const newErrors = {
      gender: '',
      genderFormatted: '',
      religion: '',
      birthDate: '',
      birthDateAge: '',
      maritalStatus: '',
      idType: '',
      idNumber: '',
      joinDate: '',
      endDate: '',
      branch: '',
      color: ''
    };

    let hasError = false;

    // Validasi gender
    if (
      typeof formData.gender === 'string'
        ? !formData.gender
        : !formData.gender.plain
    ) {
      newErrors.gender = 'Jenis kelamin harus diisi';
      hasError = true;
    }

    // Validasi religion
    if (!formData.religion) {
      newErrors.religion = 'Agama harus diisi';
      hasError = true;
    }

    // Validasi birthDate
    if (!formData.birthDate.plain) {
      newErrors.birthDate = 'Tanggal lahir harus diisi';
      hasError = true;
    }

    // Validasi joinedDate
    if (!formData.joinedDate) {
      newErrors.joinDate = 'Tanggal bergabung harus diisi';
      hasError = true;
    }

    // Validasi maritalStatus
    if (formData.maritalStatus === '') {
      newErrors.maritalStatus = 'Status perkawinan harus diisi';
      hasError = true;
    }

    // Validasi idStatus type
    if (!formData.idStatus.type) {
      newErrors.idType = 'Tipe identifikasi harus diisi';
      hasError = true;
    }

    // Validasi idStatus number
    if (!formData.idStatus.idNumber) {
      newErrors.idNumber = 'Nomor identifikasi harus diisi';
      hasError = true;
    }

    // Validasi branch
    if (!formData.branch) {
      newErrors.branch = 'Lokasi kerja harus diisi';
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Konfirmasi Perubahan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const personalData = {
            employmentType: formData.employmentType,
            gender:
              typeof formData.gender === 'object'
                ? formData.gender.plain
                : formData.gender,
            religion: formData.religion,
            login: 'johndoe123',
            nickname: employee.names.nickName,
            idType: formData.idStatus.type,
            idNumber: formData.idStatus.idNumber,
            dateOfBirth: formData.birthDate.plain,
            maritalStatus: formData.maritalStatus.toLowerCase(),
            color: formData.color
          };

          await updatePersonalInfo({
            id: id || '',
            ...personalData
          }).unwrap();

          await refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Informasi pribadi berhasil diperbarui',
            buttonType: 'ok'
          });
          setIsEditing(false);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal!',
            deskripsi:
              error?.data?.message || 'Terjadi kesalahan, silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // const handleColorPreviewClick = () => {
  //   if (colorInputRef.current) {
  //     colorInputRef.current.click();
  //   }
  // };

  // Tambahkan fungsi helper di dalam komponen
  const getGenderLabel = (gender: string) => {
    switch (gender) {
      case 'Male':
        return 'Laki-laki';
      case 'Female':
        return 'Perempuan';
      default:
        return gender;
    }
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Informasi Pribadi</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {!isEditing && (
          <>
            <Row className="mb-3">
              <Col
                xs={12}
                md={4}
                className="d-flex flex-column border-end pe-3"
              >
                <span className="fw-bold text-muted">Jenis Kelamin</span>
                <span className="fw-normal">
                  {typeof formData.gender === 'string'
                    ? formData.gender === 'M'
                      ? 'Laki-laki'
                      : 'Perempuan'
                    : getGenderLabel(formData.gender.formatted)}
                </span>
              </Col>
              <Col xs={12} md={4} className="d-flex flex-column">
                <span className="fw-bold text-muted">Agama</span>
                <span className="fw-normal">{formData.religion || '-'}</span>
              </Col>
              <Col
                xs={12}
                md={4}
                className="d-flex flex-column border-start pe-3"
              >
                <span className="fw-bold text-muted">Status Perkawinan</span>
                <span className="fw-normal">
                  {formData.maritalStatus.charAt(0).toUpperCase() +
                    formData.maritalStatus.slice(1).toLowerCase() || '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Warna</span>
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      width: '40px',
                      height: '20px',
                      backgroundColor: employee.color,
                      borderRadius: '4px',
                      border: '1px solid #dee2e6'
                    }}
                  />
                  <span className="fw-normal">
                    {employee.color || editedColor}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Identifikasi</span>
                <span className="fw-normal">
                  {employee.idStatus.type
                    ? `${employee.idStatus.idNumber} (${
                        employee.idStatus.type || '-'
                      })`
                    : '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Tanggal Lahir</span>
                <span className="fw-normal">
                  {employeeData?.data?.birthDate.date || '-'}
                  {employeeData?.data?.birthDate.age
                    ? ` (${employeeData?.data?.birthDate.age})`
                    : ''}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Tanggal Bergabung</span>
                <span className="fw-normal">
                  {employee.joinedDate.plain || '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Tanggal Berakhir</span>
                <span className="fw-normal">
                  {employee.employeeInfo.endDate || '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Lokasi Kerja</span>
                <span className="fw-normal">{employee.branch || '-'}</span>
              </Col>
            </Row>
          </>
        )}

        {/* Mode edit */}
        {isEditing && (
          <>
            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Warna
                  </Form.Label>
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      className="position-relative"
                      style={{ width: '100%' }}
                    >
                      <Form.Control
                        ref={colorInputRef}
                        type="color"
                        value={editedColor}
                        onChange={handleColorChange}
                        title="Pilih warna"
                        style={{
                          width: '100%',
                          height: '38px',
                          padding: '2px',
                          cursor: 'pointer',
                          opacity: editedColor ? '1' : '0.5'
                        }}
                      />
                      {!editedColor && (
                        <div
                          className="position-absolute top-50 start-50 translate-middle"
                          style={{
                            pointerEvents: 'none',
                            color: '#6c757d'
                          }}
                        >
                          Pilih warna
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        padding: '8px 12px',
                        borderRadius: '4px',
                        border: '1px solid #dee2e6',
                        fontSize: '14px',
                        backgroundColor: '#f8f9fa'
                      }}
                    >
                      {editedColor.toUpperCase()}
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tipe Identifikasi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="idStatus.type"
                    value={formData.idStatus.type}
                    onChange={handleInputChange}
                    isInvalid={!!errors.idType}
                  >
                    <option value="">Pilih Tipe Identifikasi</option>
                    <option value="KTP">KTP</option>
                    <option value="SIM">SIM</option>
                    <option value="Passport">Passport</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.idType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Nomor Identifikasi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="idStatus.idNumber"
                    value={formData.idStatus.idNumber}
                    onChange={handleInputChange}
                    isInvalid={!!errors.idNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Jenis Kelamin <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="gender"
                    value={
                      typeof formData.gender === 'string'
                        ? formData.gender
                        : formData.gender.plain
                    }
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                  >
                    <option value="">Pilih Jenis Kelamin</option>
                    <option value="M">Laki-laki</option>
                    <option value="F">Perempuan</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Status Perkawinan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="maritalStatus"
                    value={formData.maritalStatus}
                    onChange={handleInputChange}
                    isInvalid={!!errors.maritalStatus}
                  >
                    <option value="">Pilih Status Perkawinan</option>
                    <option value="single">Belum Menikah</option>
                    <option value="married">Menikah</option>
                    <option value="divorced">Cerai</option>
                    <option value="widowed">Duda</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.maritalStatus}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Agama <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="religion"
                    value={formData.religion}
                    onChange={handleInputChange}
                    isInvalid={!!errors.religion}
                  >
                    <option value="">Pilih Agama</option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen">Kristen</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Buddha">Buddha</option>
                    <option value="Konghucu">Konghucu</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.religion}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tanggal Lahir <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="birthDate.plain"
                    value={formData.birthDate.plain}
                    onChange={handleInputChange}
                    isInvalid={!!errors.birthDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.birthDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tanggal Bergabung <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="joinedDate"
                    value={formData.joinedDate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.joinDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.joinDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tanggal Berakhir <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate" //Belum ada
                    // value={employee.lastDate}
                    // onChange={handleInputChange}
                    // isInvalid={!!errors.lastDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.endDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Lokasi Kerja <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="branch"
                    value={employee.branch}
                    onChange={handleInputChange}
                    isInvalid={!!errors.branch}
                  >
                    <option value="">Pilih Lokasi Kerja</option>
                    <option value="Head Office">Head Office</option>
                    <option value="Branch A">Branch A</option>
                    <option value="Branch B">Branch B</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.branch}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>
                {' '}
                {/*  disabled={isSaving} */}
                {/* {isSaving ? 'Menyimpan...' : 'Simpan'} */}
                Simpan
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InformasiPribadiPegawaiCard;
