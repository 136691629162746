import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';

interface HistoriLogin {
  operatingSystem: string;
  browser: string;
  login: string;
  logout: string;
}

// Data dummy untuk tabel
const historiLoginData: HistoriLogin[] = [
  {
    operatingSystem: 'Mac OS 16.15.6',
    browser: 'Chrome V1.2',
    login: 'Thursday, 24 October, 2024 11:35 AM',
    logout: 'Thursday, 24 October, 2024 11:35 AM'
  },
  {
    operatingSystem: 'Windows 11',
    browser: 'Mozilla 5.2',
    login: 'Thursday, 24 October, 2024 11:35 AM',
    logout: 'Thursday, 24 October, 2024 11:35 AM'
  }
];

const historiLoginColumns: ColumnDef<HistoriLogin>[] = [
  {
    accessorKey: 'operatingSystem',
    header: 'OPERATING SYSTEM',
    cell: ({ row: { original } }) => original.operatingSystem,
    meta: {
      headerProps: { className: 'ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'browser',
    header: 'BROWSER',
    cell: ({ row: { original } }) => original.browser,
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    accessorKey: 'login',
    header: 'LOGIN',
    cell: ({ row: { original } }) => (
      <span className="text-primary">{original.login}</span>
    ),
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    accessorKey: 'logout',
    header: 'LOGOUT',
    cell: ({ row: { original } }) => (
      <span className="text-primary">{original.logout}</span>
    ),
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  }
];

const HistoriLoginTable = () => {
  const table = useAdvanceTable({
    data: historiLoginData,
    columns: historiLoginColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  return (
    <div className="px-4">
      <h3 className="mb-4">Histori Login</h3>
      <AdvanceTableProvider {...table}>
        <div className="border-y border-translucent">
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9',
              style: { minWidth: '768px' }
            }}
          />
          <AdvanceTableFooter
            pagination
            showViewAllBtn={false}
            className="py-2"
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default HistoriLoginTable;
