import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';
import { IAuthorities } from 'slices/privilegeSlice';

export interface IUserLogin {
  email: string;
  password: string;
}

export interface ITokenData {
  token: string;
  refresh: string;
  exp: number;
  type: string;
}

export interface IIntrospectResponse {
  active: boolean;
  id: string;
  role: string;
  authorities: IAuthorities[];
}

export interface ITokenRequest {
  token: string;
}

interface IValidatePassword {
  email: string;
  password: string;
}

export interface IUserLogout {
  token: string;
}

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: ['Authentication'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL
  }),
  endpoints: builder => ({
    userLogin: builder.mutation<
      BaseResponse & { data: ITokenData },
      IUserLogin
    >({
      query: data => ({
        url: `/api/v1/auth/login`,
        method: 'POST',
        body: data
      })
    }),
    introspect: builder.mutation<IIntrospectResponse, ITokenRequest>({
      query: data => ({
        url: `/api/v1/auth/introspect`,
        method: 'POST',
        body: data
      })
    }),
    validatePassword: builder.mutation<BaseResponse, IValidatePassword>({
      query: data => ({
        url: `/api/v1/auth/validate-password`,
        method: 'POST',
        body: data
      })
    }),
    userLogout: builder.mutation<BaseResponse, IUserLogout>({
      query: body => ({
        url: '/api/v1/auth/logout',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useUserLoginMutation,
  useIntrospectMutation,
  useValidatePasswordMutation,
  useUserLogoutMutation
} = authApi;
