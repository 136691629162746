/* eslint-disable no-case-declarations */
import { faPen, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetRoleByIdQuery,
  useUpdateActivationInfoMutation
} from 'api/roleApi';
import Swal from 'sweetalert2';
import { showCustomAlert } from 'helpers/showCustomAlert';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

interface RoleKontrolAksesCardProps {
  activation?: {
    type: string;
    from?: string;
    to?: string;
  };
}

const WORKING_HOURS = {
  start: '08:00',
  end: '17:00'
};

// Fungsi helper untuk konversi format
const convertResponseType = (type: string) => {
  switch (type) {
    case 'ALL_THE_TIME':
      return 'All The Time';
    case 'WORKING_HOURS':
      return 'Working Hours';
    case 'CUSTOM':
      return 'Custom Access';
    default:
      return type;
  }
};

// Fungsi helper untuk menentukan extra hours
const determineExtraHours = (from: string, to: string) => {
  if (!from || !to) return '';

  const fromTime = from.slice(0, 5); // mengambil format HH:mm
  const toTime = to.slice(0, 5); // mengambil format HH:mm

  if (fromTime === '08:30' && toTime === '17:30') {
    return '1';
  }

  if (fromTime === '07:00' && toTime === '18:00') {
    return '2';
  }

  return '';
};

const RoleKontrolAksesCard = ({ activation }: RoleKontrolAksesCardProps) => {
  const { id } = useParams();
  const { data: roleData } = useGetRoleByIdQuery(id || '');
  const [updateActivation] = useUpdateActivationInfoMutation();

  console.log('Role Data:', roleData);
  console.log('Activation from props:', activation);
  console.log('Role Activation:', roleData?.data.activation);

  const [isEditing, setIsEditing] = useState(false);
  const [editedActivation, setEditedActivation] = useState(() => {
    const activationType = activation?.type || 'All The Time';
    return convertResponseType(activationType);
  });
  const [editedFrom, setEditedFrom] = useState(
    activation?.from?.slice(0, 5) || ''
  );
  const [editedTo, setEditedTo] = useState(activation?.to?.slice(0, 5) || '');
  const [editedExtraHours, setEditedExtraHours] = useState('');

  useEffect(() => {
    if (roleData?.data.activation) {
      const activationType = convertResponseType(roleData.data.activation.type);
      setEditedActivation(activationType);
      setEditedFrom(roleData.data.activation.from?.slice(0, 5) || '');
      setEditedTo(roleData.data.activation.to?.slice(0, 5) || '');

      // Tambahkan pengecekan untuk working hours
      if (activationType === 'Working Hours') {
        const extraHours = determineExtraHours(
          roleData.data.activation.from?.slice(0, 5) || '',
          roleData.data.activation.to?.slice(0, 5) || ''
        );
        setEditedExtraHours(extraHours);
      }
    }
  }, [roleData]);

  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px'
  };

  const handleSave = async () => {
    try {
      // Tampilkan konfirmasi sebelum menyimpan
      showCustomAlert({
        icon: 'question',
        label: 'Perubahan data akan disimpan ',
        deskripsi:
          'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          let apiActivationType: 'ALL_THE_TIME' | 'WORKING_HOURS' | 'CUSTOM';

          if (editedActivation === 'All The Time') {
            apiActivationType = 'ALL_THE_TIME';
          } else if (editedActivation === 'Working Hours') {
            apiActivationType = 'WORKING_HOURS';
          } else {
            apiActivationType = 'CUSTOM';
          }

          const payload = {
            id: id || '',
            activation: apiActivationType,
            ...(apiActivationType === 'CUSTOM' && {
              from: `${editedFrom}:00`,
              to: `${editedTo}:00`
            })
          };

          // Tampilkan loading state dengan timeout
          Swal.fire({
            title: 'Menyimpan perubahan...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          // Set timeout untuk request
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
              reject(new Error('Timeout: Koneksi terputus'));
            }, 10000); // timeout setelah 10 detik
          });

          try {
            // Race antara request asli dan timeout
            await Promise.race([
              updateActivation(payload).unwrap(),
              timeoutPromise
            ]);

            // Tutup loading alert
            Swal.close();

            // Tampilkan sukses
            showCustomAlert({
              icon: 'success',
              label: 'Berhasil',
              deskripsi: 'Data berhasil diperbarui.',
              buttonType: 'ok',
              onConfirm: () => {
                setIsEditing(false);
              }
            });
          } catch (error) {
            // Tutup loading alert
            Swal.close();

            // Tampilkan pesan error yang sesuai
            showCustomAlert({
              icon: 'error',
              label: 'Gagal menyimpan data',
              deskripsi:
                error instanceof Error && error.message.includes('Timeout')
                  ? 'Koneksi terputus, silakan coba lagi'
                  : 'Mohon perbaiki masalah yang ditampilkan dan coba lagi',
              buttonType: 'ok'
            });
          }
        }
      });
    } catch (error) {
      console.error('Failed to update activation:', error);
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'Terjadi kesalahan saat menyimpan perubahan',
        buttonType: 'ok'
      });
    }
  };

  const handleCancel = () => {
    // Tampilkan konfirmasi sebelum membatalkan
    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin ingin membatalkan perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        if (roleData?.data.activation) {
          const activationType = convertResponseType(
            roleData.data.activation.type
          );
          setEditedActivation(activationType);
          setEditedFrom(roleData.data.activation.from?.slice(0, 5) || '');
          setEditedTo(roleData.data.activation.to?.slice(0, 5) || '');

          if (activationType === 'Working Hours') {
            const extraHours = determineExtraHours(
              roleData.data.activation.from?.slice(0, 5) || '',
              roleData.data.activation.to?.slice(0, 5) || ''
            );
            setEditedExtraHours(extraHours);
          } else {
            setEditedExtraHours('');
          }
        }
        setIsEditing(false);
      }
    });
  };

  const calculateWorkingHours = (extraHours: string) => {
    const hours = parseInt(extraHours) || 0;
    const [startHour] = WORKING_HOURS.start.split(':');
    const [endHour] = WORKING_HOURS.end.split(':');

    const adjustedStart = `${String(parseInt(startHour) - hours).padStart(
      2,
      '0'
    )}:00`;
    const adjustedEnd = `${String(parseInt(endHour) + hours).padStart(
      2,
      '0'
    )}:00`;

    return { start: adjustedStart, end: adjustedEnd };
  };

  const renderAccessTime = () => {
    if (!isEditing) {
      switch (editedActivation.toUpperCase()) {
        case 'ALL THE TIME':
          return <p className="mb-0">Akses sepanjang waktu</p>;
        case 'WORKING HOURS':
          const times = editedExtraHours
            ? calculateWorkingHours(editedExtraHours)
            : WORKING_HOURS;

          return (
            <>
              <p className="mb-0">Hanya pada jam kerja</p>
              <div className="d-flex align-items-center gap-2 mt-2">
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="text-body-secondary"
                    style={{ fontSize: '14px' }}
                  />
                  <span className="bg-light rounded px-2 py-1">
                    {times.start}
                  </span>
                </div>
                <span>hingga</span>
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="text-body-secondary"
                    style={{ fontSize: '14px' }}
                  />
                  <span className="bg-light rounded px-2 py-1">
                    {times.end}
                  </span>
                </div>
              </div>
              {editedExtraHours && (
                <p className="text-muted mt-1" style={{ fontSize: '14px' }}>
                  (Termasuk {editedExtraHours} jam sebelum dan sesudah jam
                  kerja)
                </p>
              )}
            </>
          );
        case 'CUSTOM':
        case 'CUSTOM ACCESS':
          return (
            <>
              <p className="mb-0">Waktu Akses Khusus</p>
              <div className="d-flex align-items-center gap-2 mt-2">
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="text-body-secondary"
                    style={{ fontSize: '14px' }}
                  />
                  <span className="bg-light rounded px-2 py-1">
                    {editedFrom}
                  </span>
                </div>
                <span>hingga</span>
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="text-body-secondary"
                    style={{ fontSize: '14px' }}
                  />
                  <span className="bg-light rounded px-2 py-1">{editedTo}</span>
                </div>
              </div>
            </>
          );
        default:
          return null;
      }
    }

    return (
      <div className="d-flex flex-column gap-2">
        <Form.Check
          type="radio"
          id="allTheTime"
          name="activation"
          label="Akses sepanjang waktu"
          value="All The Time"
          checked={editedActivation === 'All The Time'}
          onChange={e => setEditedActivation(e.target.value)}
        />
        <Form.Check
          type="radio"
          id="workingHours"
          name="activation"
          label="Hanya pada jam kerja"
          value="Working Hours"
          checked={editedActivation === 'Working Hours'}
          onChange={e => setEditedActivation(e.target.value)}
        />
        {editedActivation === 'Working Hours' && (
          <Form.Group>
            <div className="d-flex align-items-center">
              <p style={{ fontSize: '14px' }}>
                Akses sebelum dan setelah jam kerja
              </p>
              <div className="ms-3">
                <Form.Select
                  value={editedExtraHours}
                  onChange={e => setEditedExtraHours(e.target.value)}
                >
                  <option value="">Tidak ada</option>
                  <option value="1">1 Jam</option>
                  <option value="2">2 Jam</option>
                </Form.Select>
              </div>
            </div>
            <div className="mt-2 text-muted" style={{ fontSize: '14px' }}>
              {editedExtraHours && (
                <>
                  Waktu akses: {calculateWorkingHours(editedExtraHours).start} -{' '}
                  {calculateWorkingHours(editedExtraHours).end}
                </>
              )}
            </div>
          </Form.Group>
        )}

        <Form.Check
          type="radio"
          id="custom"
          name="activation"
          label="Waktu Akses Khusus"
          value="Custom Access"
          checked={editedActivation === 'Custom Access'}
          onChange={e => setEditedActivation(e.target.value)}
        />

        {editedActivation === 'Custom Access' && (
          <div className="d-flex gap-3 align-items-end">
            <Form.Group>
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-body-secondary"
                  style={{ fontSize: '14px' }}
                />
                <Form.Control
                  type="time"
                  value={editedFrom}
                  onChange={e => setEditedFrom(e.target.value)}
                  style={{
                    direction: 'rtl',
                    paddingRight: '8px'
                  }}
                />
              </div>
            </Form.Group>
            <span className="mb-2">hingga</span>
            <Form.Group>
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-body-secondary"
                  style={{ fontSize: '14px' }}
                />
                <Form.Control
                  type="time"
                  value={editedTo}
                  onChange={e => setEditedTo(e.target.value)}
                  style={{
                    direction: 'rtl',
                    paddingRight: '8px'
                  }}
                />
              </div>
            </Form.Group>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card className="mb-1">
      <Card.Body>
        <div style={{ ...sectionHeaderStyle }}>
          <h3 className="d-flex align-items-center">
            Kontrol Akses
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
              {!isEditing && (
                <FontAwesomeIcon
                  icon={faPen}
                  style={{
                    color: '#6B7280',
                    cursor: 'pointer',
                    fontSize: '16px',
                    marginLeft: '16px'
                  }}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </ForbiddenWrapper>
          </h3>
          {isEditing ? <div className="d-flex gap-5 ms-auto"></div> : null}
        </div>

        <div>
          <h5 className="text-body-secondary mb-2">Jam Akses</h5>
          {renderAccessTime()}
        </div>
      </Card.Body>
      {isEditing && (
        <Card.Footer className="d-flex justify-content-end gap-3">
          <Button
            variant="link"
            size="sm"
            className="text-danger p-0"
            onClick={handleCancel}
          >
            Batal
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="text-white ms-2"
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default RoleKontrolAksesCard;
