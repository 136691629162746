import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';
// import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAddPositionMutation } from 'api/positionApi';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddPositionRequest } from 'api/response/PositionResponse';
import PilihPemilikJabatanModal from './PilihPemilikJabatanModal';

interface TambahJabatanModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

interface SelectedUser {
  email: string;
  fullName: string;
  gender: string;
  role: string;
  id: string;
}

// Tambahkan style untuk overlay
const overlayStyle = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 1050
};

// Tambahkan fungsi getGenderLabel
const getGenderLabel = (gender: string) => {
  switch (gender) {
    case 'M':
      return 'Laki-laki';
    case 'F':
      return 'Perempuan';
    default:
      return '-';
  }
};

const TambahJabatanModal = ({
  show,
  handleClose,
  onSuccess
}: TambahJabatanModalProps) => {
  const initialFormData = {
    name: '',
    description: '',
    color: '#E3E6ED', // dengan warna default yang dipilih
    users: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [showPilihPemilik, setShowPilihPemilik] = useState(false);

  const [addPosition, { isLoading }] = useAddPositionMutation();

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.name.trim()) newErrors.name = 'Nama jabatan wajib diisi';
    if (!formData.description.trim())
      newErrors.description = 'Deskripsi wajib diisi';
    if (!/^#[A-Fa-f0-9]{6}$/.test(formData.color))
      newErrors.color = 'Warna harus dalam format HEX dengan #';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      showCustomAlert({
        icon: 'question',
        label: 'Jabatan akan disimpan',
        deskripsi: 'Apakah Anda yakin data jabatan sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            // Persiapkan data untuk dikirim dengan format yang benar
            const positionData = {
              name: formData.name,
              description: formData.description,
              color: formData.color,
              employee: selectedUsers.map(user => ({
                id: user.id
              }))
            };

            // Log data yang akan dikirim
            console.log('Data yang akan dikirim ke API:', positionData);

            // Panggil mutation untuk menambah jabatan
            const response = await addPosition(positionData).unwrap();
            console.log('Response sukses:', response);

            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Jabatan baru berhasil disimpan.',
              buttonType: 'ok',
              onConfirm: () => {
                setFormData(initialFormData);
                setSelectedUsers([]);
                onSuccess();
                handleClose();
              }
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // Log error detail
            console.error('Error saat mengirim data:', {
              error,
              data: error?.data,
              message: error?.data?.message,
              status: error?.status
            });

            showCustomAlert({
              icon: 'error',
              label: 'Gagal membuat jabatan',
              deskripsi:
                error?.data?.message ||
                'Terjadi kesalahan saat menyimpan jabatan.',
              buttonType: 'ok'
            });
          }
        }
      });
    }
  };

  const handleCancel = () => {
    const isFormFilled =
      formData.name.trim() !== '' ||
      formData.description.trim() !== '' ||
      selectedUsers.length > 0;

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Jabatan tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          // Reset form data dan daftar pemilik jabatan
          setFormData(initialFormData);
          setSelectedUsers([]); // Reset daftar pemilik jabatan
          handleClose();
        }
      });
    } else {
      // Menghapus alert saat membatalkan tanpa mengisi form
      handleClose(); // Cukup tutup modal tanpa alert
    }
  };

  const handleDeleteUser = (userId: string) => {
    setSelectedUsers(prev => prev.filter(u => u.id !== userId));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePilihPemilik = (selectedAccounts: any[]) => {
    setSelectedUsers(selectedAccounts);
    setShowPilihPemilik(false);
  };

  useEffect(() => {
    if (show) {
      setFormData(initialFormData);
      setErrors({});
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleCancel}
        size="lg"
        centered
        contentClassName="shadow"
        backdropClassName="darker-backdrop"
        style={{ zIndex: showPilihPemilik ? 1040 : 1050 }}
      >
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="fw-bolder fs-6">Tambah Jabatan</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="tambahJabatanForm"
              disabled={isLoading}
            >
              {isLoading ? 'Menyimpan...' : 'Buat Jabatan'}
            </Button>
          </div>
        </Modal.Header>
        <Form id="tambahJabatanForm" onSubmit={handleSubmit}>
          <Modal.Body className="px-5 py-4 bg-light">
            <h4 className="mb-4">Informasi Jabatan</h4>

            <Form.Group className="mb-3">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Nama Jabatan <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan nama jabatan"
                value={formData.name}
                isInvalid={!!errors.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Warna
              </Form.Label>
              <div className="d-flex gap-2 align-items-center">
                <div className="position-relative" style={{ width: '100%' }}>
                  <Form.Control
                    type="color"
                    value={formData.color}
                    onChange={e =>
                      setFormData({ ...formData, color: e.target.value })
                    }
                    title="Pilih warna"
                    style={{
                      width: '100%',
                      height: '38px',
                      padding: '2px',
                      cursor: 'pointer',
                      opacity: formData.color ? '1' : '0.5'
                    }}
                  />
                  {!formData.color && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        pointerEvents: 'none',
                        color: '#6c757d'
                      }}
                    >
                      Pilih warna
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: '8px 12px',
                    borderRadius: '4px',
                    border: '1px solid #dee2e6',
                    fontSize: '14px',
                    backgroundColor: '#f8f9fa'
                  }}
                >
                  {formData.color.toUpperCase()}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Deskripsi Jabatan <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Masukkan deskripsi jabatan"
                value={formData.description}
                isInvalid={!!errors.description}
                onChange={e =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <h4 className="mb-3">Daftar Pemilik Jabatan</h4>
            <div className="table-responsive mb-3">
              <table className="table">
                <thead>
                  <tr className="fs-9">
                    <th className="ps-0">NAMA PEGAWAI</th>
                    <th>JENIS KELAMIN</th>

                    <th style={{ width: '40px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUsers.length === 0 ? (
                    <tr className="fs-9">
                      <td colSpan={4} className="text-center text-muted">
                        Belum ada pemilik jabatan
                      </td>
                    </tr>
                  ) : (
                    selectedUsers.map(user => (
                      <tr key={user.id} className="fs-9">
                        <td>{user.fullName}</td>
                        <td>{getGenderLabel(user.gender)}</td>
                        <td className="text-end">
                          <Button
                            variant="link"
                            className="text-muted p-0"
                            onClick={() => handleDeleteUser(user.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <Button
              className="w-100 fs-9"
              variant="outline-primary"
              size="lg"
              onClick={() => setShowPilihPemilik(true)}
            >
              + Tambah Pemilik Jabatan
            </Button>
          </Modal.Body>
        </Form>
      </Modal>

      {show && showPilihPemilik && <div style={overlayStyle} />}

      <PilihPemilikJabatanModal
        show={showPilihPemilik}
        onHide={() => setShowPilihPemilik(false)}
        onSelect={handlePilihPemilik}
        selectedIds={selectedUsers.map(user => user.id)}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
      />
    </>
  );
};

export default TambahJabatanModal;
