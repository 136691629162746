import {
  faTrashAlt,
  faSave,
  faHistory
} from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { kontrolAksesBreadcrumbItems } from 'data/commonData';
import { Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { ToastContainer } from 'react-toastify';
import HistoriAktivitasTable from 'components/tables/HistoriAktivitasTable';
import { showCustomAlert } from 'helpers/showCustomAlert';
import {
  useGetAllRolePrivilegesQuery,
  useUpdateRolePrivilegeMutation
} from 'api/roleApi';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';
import { hasPrivilege } from 'slices/privilegeSlice';

interface AccessData {
  [key: string]: string[];
}

interface TableSection {
  title: string;
  data: AccessData;
}

// Interface untuk data dari API/database
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AccessRights {
  id: number;
  staffData: string;
  exportStaff: string;
  staffNotes: string;
  templates: string;
  roleId: number; // misalnya: 1=MASTER, 2=MANAJER, dst
}

// Modifikasi tooltipStyle
const tooltipStyle = {
  position: 'fixed' as const,
  backgroundColor: '#333',
  color: 'white',
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: '12px',
  zIndex: 1000,
  maxWidth: '200px',
  wordWrap: 'break-word' as const,
  pointerEvents: 'none' as const
};

const KontrolAkses = () => {
  const {
    data: rolePrivileges,
    isLoading,
    refetch
  } = useGetAllRolePrivilegesQuery();
  const [updateRolePrivilege] = useUpdateRolePrivilegeMutation();

  // Tambahkan state untuk tracking loading state saat update
  const [isUpdating, setIsUpdating] = useState(false);

  const transformApiDataToTableData = useCallback(() => {
    if (!rolePrivileges?.data) return [];

    return rolePrivileges.data[0]?.menus.map(menu => ({
      title: menu.code,
      data: menu.services.reduce(
        (acc, service) => ({
          ...acc,
          [service.code.toLowerCase()]: rolePrivileges.data.map(
            role =>
              role.menus
                .find(m => m.code === menu.code)
                ?.services.find(s => s.code === service.code)?.privilege ||
              'NONE'
          )
        }),
        {}
      )
    }));
  }, [rolePrivileges]);

  const [tableData, setTableData] = useState<TableSection[]>(
    transformApiDataToTableData()
  );

  const columnHeaders = rolePrivileges?.data.map(role => role.code) || [];

  const [initialValues, setInitialValues] = useState<TableSection[]>(tableData);
  const [hasChanges, setHasChanges] = useState(false);

  // Tambahkan kembali state selectedRole tapi tidak perlu digunakan
  const [selectedRole, setSelectedRole] = useState<string>('');

  // Tambahkan konstanta untuk mengatur lebar kolom
  const CATEGORY_WIDTH = 250;
  const ROLE_COLUMN_WIDTH = 150; // Perbesar sedikit untuk mengisi ruang
  const MAX_VISIBLE_ROLES = 10; // Maksimum role yang ditampilkan sebelum scroll

  // Fungsi untuk mengganti nilai akses
  const handleAccessChange = (
    sectionIndex: number,
    category: string,
    index: number
  ) => {
    const accessTypes = ['NONE', 'READ', 'WRITE', 'FULL'];
    const currentValue = tableData[sectionIndex].data[category][index];
    const currentIndex = accessTypes.indexOf(currentValue);
    const nextValue = accessTypes[(currentIndex + 1) % accessTypes.length];

    setTableData(prev =>
      prev.map((section, idx) => {
        if (idx === sectionIndex) {
          return {
            ...section,
            data: {
              ...section.data,
              [category]: section.data[category].map((value, i) =>
                i === index ? nextValue : value
              )
            }
          };
        }
        return section;
      })
    );

    // Set hasChanges to true when a change is made
    setHasChanges(true);
  };

  // Fungsi untuk mendapatkan warna background
  const getAccessColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FFE0DB';
      case 'WRITE':
        return '#C7EBFF';
      case 'READ':
        return '#D9FBD0';
      case 'NONE':
        return '#E3E6ED';
      default:
        return 'white';
    }
  };

  // Fungsi untuk mendapatkan warna border
  const getBorderColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FABCB3';
      case 'WRITE':
        return '#96D9FF';
      case 'READ':
        return '#BEE8B4';
      case 'NONE':
        return '#CBD0DD';
      default:
        return '#ddd';
    }
  };

  // Fungsi untuk mendapatkan warna teks berdasarkan tipe akses
  const getTextColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#B81800'; // Merah tua untuk background merah muda
      case 'WRITE':
        return '#005585'; // Biru tua untuk background biru muda
      case 'READ':
        return '#1C6C09'; // Hijau tua untuk background hijau muda
      case 'NONE':
        return '#31374A'; // Abu-abu tua untuk background abu-abu muda
      default:
        return '#333';
    }
  };

  const getCategoryLabel = (category: string) => {
    switch (category) {
      case 'customerData':
        return 'Customer Data';
      case 'exportCustomer':
        return 'Export Customer';
      case 'customerNotes':
        return 'Customer Notes';
      case 'staffData':
        return 'Staff Data';
      case 'exportStaff':
        return 'Export Staff';
      case 'staffNotes':
        return 'Staff Notes';
      case 'financeData':
        return 'Finance Data';
      case 'exportFinance':
        return 'Export Finance';
      case 'financeNotes':
        return 'Finance Notes';
      case 'templates':
        return 'Templates';
      default:
        return category;
    }
  };

  // Modifikasi fungsi saveAccessChanges
  const saveAccessChanges = async () => {
    try {
      setIsUpdating(true);

      // Temukan perubahan dari semua role yang dimodifikasi
      const roleChanges: {
        [roleId: string]: { serviceId: string; type: string }[];
      } = {};

      tableData.forEach((section, sectionIndex) => {
        Object.entries(section.data).forEach(([serviceCode, values]) => {
          values.forEach((value, roleIndex) => {
            const roleData = rolePrivileges?.data[roleIndex];
            if (!roleData) return;

            const initialValue =
              initialValues[sectionIndex].data[serviceCode][roleIndex];
            const currentValue = value;

            if (initialValue !== currentValue) {
              // Temukan serviceId dari data asli
              const menu = roleData.menus.find(m => m.code === section.title);
              const service = menu?.services.find(
                s => s.code.toLowerCase() === serviceCode.toLowerCase()
              );

              if (service) {
                if (!roleChanges[roleData.id]) {
                  roleChanges[roleData.id] = [];
                }
                roleChanges[roleData.id].push({
                  serviceId: service.id,
                  type: currentValue
                });
              }
            }
          });
        });
      });

      if (Object.keys(roleChanges).length === 0) {
        showCustomAlert({
          icon: 'info',
          label: 'Info',
          deskripsi: 'Tidak ada perubahan yang perlu disimpan',
          buttonType: 'ok'
        });
        return;
      }

      // Buat request body untuk bulk update
      const requestBody = Object.entries(roleChanges).map(
        ([roleId, services]) => ({
          id: roleId,
          services
        })
      );

      // Panggil API untuk update
      const result = await updateRolePrivilege(requestBody).unwrap();

      if (result.code === 200) {
        await refetch();
        showCustomAlert({
          icon: 'success',
          label: 'Sukses!',
          deskripsi: 'Data berhasil diperbarui.',
          buttonType: 'ok'
        });

        const transformedData = transformApiDataToTableData();
        setTableData(transformedData);
        setInitialValues(transformedData);
        setHasChanges(false);
      } else {
        throw new Error(result.message || 'Gagal memperbarui data');
      }
    } catch (error) {
      console.error('Error saving access rights:', error);
      showCustomAlert({
        icon: 'error',
        label: 'Error',
        deskripsi: 'Terjadi kesalahan saat menyimpan data',
        buttonType: 'ok'
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Panggil fetchAccessRights saat komponen dimount
  useEffect(() => {
    if (rolePrivileges) {
      const transformedData = transformApiDataToTableData();
      setTableData(transformedData);
      setInitialValues(transformedData);
    }
  }, [rolePrivileges, transformApiDataToTableData]);

  // Tambahkan state untuk tooltip
  const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });

  // Fungsi untuk mengecek apakah teks terpotong
  const isTextTruncated = (element: HTMLElement) => {
    return element.offsetWidth < element.scrollWidth;
  };

  // Update fungsi showTooltip
  const showTooltip = (e: React.MouseEvent, text: string) => {
    const element = e.currentTarget as HTMLElement;
    if (isTextTruncated(element)) {
      setTooltip({
        show: true,
        text,
        x: e.clientX + 5,
        y: e.clientY - 25
      });
    }
  };

  const canEdit = hasPrivilege('ROLE_MANAGEMENT_WRITE');

  // Fungsi untuk menyembunyikan tooltip
  const hideTooltip = () => {
    setTooltip({ show: false, text: '', x: 0, y: 0 });
  };

  // Render tabel untuk setiap section
  const renderTable = (section: TableSection, sectionIndex: number) => (
    <div
      style={{
        overflowX: columnHeaders.length > MAX_VISIBLE_ROLES ? 'auto' : 'hidden',
        width: '100%'
      }}
    >
      <Table
        className="mb-4"
        style={{
          borderCollapse: 'collapse',
          border: 'none',
          tableLayout: 'fixed',
          width: '100%',
          minWidth:
            columnHeaders.length > MAX_VISIBLE_ROLES
              ? `${CATEGORY_WIDTH + ROLE_COLUMN_WIDTH * columnHeaders.length}px`
              : '100%'
        }}
      >
        <thead>
          <tr>
            <th
              colSpan={columnHeaders.length + 1}
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #dee2e6',
                borderTop: '1px solid #dee2e6',
                padding: '12px',
                position: 'sticky',
                top: 0,
                backgroundColor: '#F5F7FA',
                zIndex: 3,
                width: '100%'
              }}
            >
              <div
                style={{
                  width: 'max-content',
                  margin: 'auto',
                  position: 'sticky',
                  left: 0
                }}
              >
                {section.title}
              </div>
            </th>
          </tr>
          <tr>
            <th
              style={{
                width: `${CATEGORY_WIDTH}px`,
                borderBottom: '1px solid #dee2e6',
                padding: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                position: 'sticky',
                left: 0,
                backgroundColor: '#F5F7FA',
                zIndex: 2,
                borderRight: '1px solid #dee2e6'
              }}
              onMouseEnter={e => showTooltip(e, 'KATEGORI AKSES')}
              onMouseLeave={hideTooltip}
            >
              KATEGORI AKSES
            </th>
            {columnHeaders.map((header, i) => (
              <th
                key={i}
                style={{
                  width: `${ROLE_COLUMN_WIDTH}px`,
                  textAlign: 'center',
                  borderBottom: '1px solid #dee2e6',
                  padding: '12px',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                onMouseEnter={e => showTooltip(e, header)}
                onMouseLeave={hideTooltip}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(section.data).map(([category, values]) => (
            <tr key={category}>
              <td
                style={{
                  width: `${CATEGORY_WIDTH}px`,
                  borderBottom: '1px solid #dee2e6',
                  padding: '0',
                  paddingTop: '10px',
                  paddingLeft: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#F5F7FA',
                  zIndex: 1,
                  borderRight: '1px solid #dee2e6'
                }}
                onMouseEnter={e => showTooltip(e, getCategoryLabel(category))}
                onMouseLeave={hideTooltip}
              >
                {getCategoryLabel(category)}
              </td>
              {values.map((value, index) => (
                <td
                  key={index}
                  style={{
                    width: `${ROLE_COLUMN_WIDTH}px`,
                    borderBottom: '1px solid #dee2e6',
                    padding: '0',
                    textAlign: 'center'
                  }}
                >
                  <button
                    disabled={!canEdit}
                    onClick={() =>
                      handleAccessChange(sectionIndex, category, index)
                    }
                    style={{
                      backgroundColor: getAccessColor(value),
                      border: `1px solid ${getBorderColor(value)}`,
                      borderRadius: '5px',
                      padding: '8px 16px',
                      width: '100%',
                      height: '100%',
                      cursor: canEdit ? 'pointer' : 'default',
                      transition: 'all 0.2s',
                      boxShadow: 'none',
                      color: getTextColor(value),
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }}
                  >
                    {value}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const [showHistori, setShowHistori] = useState(false);

  // Fungsi untuk toggle histori
  const toggleHistori = () => {
    setShowHistori(prev => !prev);
  };

  // Cek apakah ada perubahan yang nyata
  const checkForChanges = () => {
    for (let i = 0; i < tableData.length; i++) {
      for (const key in tableData[i].data) {
        if (
          JSON.stringify(tableData[i].data[key]) !==
          JSON.stringify(initialValues[i].data[key])
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // Update hasChanges berdasarkan perubahan
  useEffect(() => {
    setHasChanges(checkForChanges());
  }, [tableData]);

  return (
    <div>
      <PageBreadcrumb items={kontrolAksesBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Kontrol Akses</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button
            variant="link"
            style={{ color: showHistori ? '#0D6EFD' : '#000' }}
            onClick={toggleHistori}
          >
            <FontAwesomeIcon icon={faHistory} className="me-2" />
            Histori Aktivitas
          </Button>
          <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
            <Button
              variant="outline-danger"
              disabled={!hasChanges}
              onClick={() => {
                showCustomAlert({
                  icon: 'warning',
                  label: 'Perubahan data tidak akan disimpan',
                  deskripsi:
                    'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
                  buttonType: 'yes-no',
                  onConfirm: () => {
                    // Reset data ke nilai awal
                    setTableData(initialValues);
                    setHasChanges(false); // Reset hasChanges
                    showCustomAlert({
                      icon: 'success',
                      label: 'Perubahan Dibatalkan',
                      deskripsi: 'Perubahan telah berhasil dibatalkan.',
                      buttonType: 'ok'
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
              Batalkan Perubahan
            </Button>
            <Button
              variant="primary"
              disabled={!hasChanges || isUpdating}
              onClick={() => {
                showCustomAlert({
                  icon: 'question',
                  label: 'Perubahan data akan disimpan ',
                  deskripsi:
                    'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
                  buttonType: 'yes-no',
                  onConfirm: saveAccessChanges
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              {isUpdating ? 'Menyimpan...' : 'Simpan Perubahan Akses'}
            </Button>
          </ForbiddenWrapper>
        </Col>
      </Row>

      <Row>
        <Col xs={showHistori ? 7 : 12}>
          <div className="mb-4">
            <h4 className="mb-2">Level Akses</h4>
            <Form.Select
              value={selectedRole}
              onChange={e => setSelectedRole(e.target.value)}
            >
              <option value="">Kustom</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div
              className="scrollable-wrapper"
              style={{
                maxHeight: 'calc(100vh - 350px)',
                overflowY: 'auto',
                overflowX: 'auto',
                position: 'relative',
                padding: '0 15px'
              }}
            >
              {tableData.map((section, index) => renderTable(section, index))}
            </div>
          )}
        </Col>
        <Col
          xs={showHistori ? 5 : 0}
          className={`transition ${showHistori ? 'd-block' : 'd-none'}`}
        >
          <HistoriAktivitasTable onClose={() => setShowHistori(false)} />
        </Col>
      </Row>

      {/* Tambahkan tooltip */}
      {tooltip.show && (
        <div
          style={{
            ...tooltipStyle,
            left: tooltip.x,
            top: tooltip.y
          }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};

export default KontrolAkses;
