import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AccountData, AccountResponse } from './response/AccountResponse';
import { BaseResponse } from './response/BaseResponse';

// Interface for query parameters
interface AccountQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  status?: boolean[];
  role?: string[];
  startAt?: string;
  endAt?: string;
  name?: string;
}

// Interface for create/update account
interface AccountFormData {
  email: string;
  status: boolean;
  roleId: string;
}

// Interface for delete account request
interface DeleteAccountRequest {
  notes: string;
  deletionReason: string;
  data: string[];
}

// Tambahkan interface untuk update account
interface UpdateAccountRequest {
  email: string;
  name: string;
  isChangePassword?: boolean;
  password?: string;
  status: boolean;
  roleId: string;
  placeholder?: string;
}

export const accountApi = createApi({
  reducerPath: 'account',
  tagTypes: ['Account'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: 10000
  }),
  endpoints: builder => ({
    // Get Accounts
    getAccounts: builder.query<AccountData, AccountQueryParams>({
      query: params => {
        const queryParams = new URLSearchParams();

        // Basic parameters
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        // Additional filters
        if (params.status?.length) {
          params.status.forEach(s =>
            queryParams.append('status', s.toString())
          );
        }
        if (params.role?.length) {
          params.role.forEach(r => queryParams.append('role', r));
        }
        if (params.startAt) {
          queryParams.append('startAt', params.startAt);
        }
        if (params.endAt) {
          queryParams.append('endAt', params.endAt);
        }
        if (params.name) {
          queryParams.append('name', params.name);
        }

        const url = `api/v1/user?${queryParams.toString()}`;
        console.log('Request URL:', url);
        return url;
      },
      providesTags: ['Account']
    }),

    // Get Account by ID
    getAccountById: builder.query<
      BaseResponse & { data: AccountResponse },
      string
    >({
      query: id => `api/v1/user/${id}`, // Update the endpoint path to get detailed user info
      transformResponse: (
        response: BaseResponse & { data: AccountResponse }
      ) => {
        return response;
      }
    }),

    // Create Account
    createAccount: builder.mutation<BaseResponse, AccountFormData>({
      query: newData => ({
        url: 'api/v1/user',
        method: 'POST',
        body: newData
      }),
      invalidatesTags: ['Account']
    }),

    // Update Account
    updateAccount: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateAccountRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/user/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Account']
    }),

    // Delete Account
    deleteAccount: builder.mutation<BaseResponse, DeleteAccountRequest>({
      query: deleteRequest => ({
        url: 'api/v1/user',
        method: 'DELETE',
        body: deleteRequest
      }),
      invalidatesTags: ['Account']
    })
  })
});

export const {
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation
} = accountApi;
