import { ColumnDef } from '@tanstack/react-table';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import Badge from 'components/base/Badge';

// Kolom tabel layanan
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const layananTableColumns: ColumnDef<ClinicalServiceResponse>[] = [
  {
    accessorKey: 'color',
    header: 'Warna',
    cell: ({ row: { original } }) => {
      const colorValue = original.hex;
      return (
        <div
          style={{
            width: '80px',
            height: '20px',
            backgroundColor: colorValue || '#CCCCCC',
            borderRadius: '4px'
          }}
        />
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 align-middle' }
    }
  },
  {
    accessorKey: 'name',
    header: 'Nama Layanan',
    cell: ({ row: { original } }) => {
      const serviceName = original.name || '-';
      return (
        <Link
          to={`/layanan/detail-layanan/${original.id}`}
          className="d-flex align-items-center gap-3 text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{serviceName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'Pegawai',
    header: 'Pegawai',
    cell: () => '0', // Default to 0 until employee count is available
    meta: {
      headerProps: {
        style: { width: '10%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 white-space-nowrap text-end' }
    }
  },
  {
    accessorKey: 'Kategori',
    header: 'Kategori',
    cell: ({ row: { original } }) => {
      const categoryName = original.clinicalServiceCategory?.name || '-';
      const categoryColor = original.clinicalServiceCategory?.hex || '#CCCCCC';

      return (
        <Badge
          style={{
            borderRadius: '20px',
            backgroundColor: categoryColor,
            color: isColorLight(categoryColor) ? '#000' : '#fff',
            padding: '6px 14px'
          }}
          variant="phoenix"
          className="fs-10 text-center fw-bold"
        >
          {categoryName}
        </Badge>
      );
    },
    meta: {
      headerProps: {
        style: { width: '10%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'Harga',
    header: 'Harga',
    cell: ({ row: { original } }) => {
      // Tampilkan "Fixed" atau "Rated" berdasarkan pricingType
      return original.pricingType === 'Fixed' ? 'Fixed' : 'Rated';
    },
    meta: {
      headerProps: {
        style: { width: '10%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 white-space-nowrap text-end' }
    }
  },
  {
    accessorKey: 'createdDate',
    header: 'Tanggal Dibuat',
    cell: ({ row: { original } }) => {
      // Format tanggal menjadi "1 September 2025"
      return original.createdDate
        ? new Intl.DateTimeFormat('id-ID', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          }).format(new Date(original.createdDate))
        : '-';
    },
    meta: {
      headerProps: {
        style: { width: '30%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return original.status ? (
        <span className="badge bg-success">Aktif</span>
      ) : (
        <span className="badge bg-danger">Tidak Aktif</span>
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  }
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface LayananTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  serviceData?: {
    totalRecords?: number;
  };
}

// Tambahkan fungsi helper untuk menentukan apakah warna termasuk terang atau gelap
function isColorLight(color: string): boolean {
  // Jika tidak ada warna, anggap gelap
  if (!color) return false;

  // Konversi hex ke RGB
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Hitung kecerahan menggunakan formula YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Nilai YIQ > 128 dianggap warna terang
  return yiq > 128;
}

const LayananTable = ({
  onPageChange,
  currentPage,
  totalPages,
  serviceData
}: LayananTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={serviceData?.totalRecords || 0}
      />
    </div>
  );
};

export default LayananTable;
