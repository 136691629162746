/* eslint-disable @typescript-eslint/no-unused-vars */
// import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import {
  UpdateServiceInfoRequest,
  useGetServiceByIdQuery,
  useUpdatePersonalInformationMutation,
  useUpdateServiceInfoMutation
} from 'api/serviceApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetAllCategoryServiceQuery } from 'api/categoryServiceApi';
// import { useEditServiceMutation } from 'api/serviceApi';
// import { showCustomAlert } from 'helpers/showCustomAlert';
interface InformasiDasarCardProps {
  id?: string;
  code?: string;
  color?: string;
  description?: string;
  onUpdate?: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InformasiLayananCard = ({ color }: InformasiDasarCardProps) => {
  //   const { id } = useParams<{ id: string }>();
  //   const [editService, { isLoading: isSaving }] = useEditServiceMutation();
  const { id } = useParams<{ id: string }>();
  const { data: serviceData, refetch } = useGetServiceByIdQuery(id || '');
  const [isEditing, setIsEditing] = useState(false);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const [updatePersonalInfo] = useUpdatePersonalInformationMutation();

  // Pindahkan interface ke luar komponen
  interface FormDataInterface {
    id: string;
    hex: string;
    status: boolean;
    name: string;
    description: string;
    category: string;
    createdDate: string;
    facility: string;
    beforeTreatmentInMinutes: number;
    afterTreatmentInMinutes: number;
  }

  // Inisialisasi formData dengan nilai default kosong
  const [formData, setFormData] = useState<FormDataInterface>({
    id: '',
    hex: '',
    status: true,
    name: '',
    description: '',
    category: '',
    createdDate: '',
    facility: '',
    beforeTreatmentInMinutes: 0,
    afterTreatmentInMinutes: 0
  });

  // Tambahkan query untuk mengambil data kategori
  const { data: categoryData } = useGetAllCategoryServiceQuery({
    page: 1,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'ASC'
  });

  // Tambahkan state untuk mode waktu jeda
  const [timeMode, setTimeMode] = useState({
    before: 'default',
    after: 'default'
  });

  useEffect(() => {
    if (serviceData?.data) {
      // Set formData dengan nilai dari API
      setFormData({
        id: serviceData.data.id || '',
        hex: serviceData.data.hex || '',
        status: serviceData.data.status || true,
        name: serviceData.data.name || '',
        description: serviceData.data.description || '',
        category: serviceData.data.clinicalServiceCategory.name || '',
        createdDate: serviceData.data.createdDate || '',
        facility: serviceData.data.facility || '',
        beforeTreatmentInMinutes:
          serviceData.data.beforeTreatmentInMinutes || 0,
        afterTreatmentInMinutes: serviceData.data.afterTreatmentInMinutes || 0
      });

      // Set mode waktu jeda berdasarkan nilai dari API
      setTimeMode({
        before:
          serviceData.data.beforeTreatmentInMinutes > 0 ? 'custom' : 'default',
        after:
          serviceData.data.afterTreatmentInMinutes > 0 ? 'custom' : 'default'
      });

      setEditedColor(serviceData.data.hex || 'undefined');
    }
  }, [serviceData]);

  if (!serviceData?.data) {
    return null; // atau loading indicator
  }

  const { data: service } = serviceData;

  // Tambahkan fungsi untuk memformat tanggal ke format input date
  const formatDateForInput = (dateString: string) => {
    if (!dateString) return ''; // Return empty string jika tidak ada tanggal

    try {
      const date = new Date(dateString);
      // Cek apakah tanggal valid
      if (isNaN(date.getTime())) return '';
      return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    } catch (error) {
      return ''; // Return empty string jika ada error
    }
  };

  // Fungsi untuk mengecek perubahan
  const hasChanges = () => {
    if (!serviceData?.data) return false;

    const originalData = {
      id: serviceData.data.id || '',
      hex: serviceData.data.hex || '',
      status: serviceData.data.status || true,
      name: serviceData.data.name || '',
      description: serviceData.data.description || '',
      category: serviceData.data.clinicalServiceCategory.name || '',
      createdDate: formatDateForInput(serviceData.data.createdDate),
      facility: serviceData.data.facility || '',
      beforeTreatmentInMinutes: serviceData.data.beforeTreatmentInMinutes || 0,
      afterTreatmentInMinutes: serviceData.data.afterTreatmentInMinutes || 0
    };

    return (
      JSON.stringify({
        ...formData,
        createdDate: formatDateForInput(formData.createdDate)
      }) !== JSON.stringify(originalData)
    );
  };

  const [updateService] = useUpdateServiceInfoMutation();

  // Handle cancel dengan pengecekan perubahan
  const handleCancel = () => {
    // Cek apakah ada perubahan
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    // Jika ada perubahan, tampilkan konfirmasi
    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        if (serviceData?.data) {
          // Reset formData ke nilai awal
          setFormData({
            id: serviceData.data.id || '',
            hex: serviceData.data.hex || '',
            status: serviceData.data.status || true,
            name: serviceData.data.name || '',
            description: serviceData.data.description || '',
            category: serviceData.data.clinicalServiceCategory.name || '',
            createdDate: serviceData.data.createdDate || '',
            facility: serviceData.data.facility || '',
            beforeTreatmentInMinutes:
              serviceData.data.beforeTreatmentInMinutes || 0,
            afterTreatmentInMinutes:
              serviceData.data.afterTreatmentInMinutes || 0
          });
        }
        setIsEditing(false);
      }
    });
  };

  if (!serviceData?.data) {
    return null;
  }

  //   const { data: service } = serviceData;
  const [editedColor, setEditedColor] = useState(service.hex || 'undefined');
  const [errors, setErrors] = useState({
    name: '',
    hex: '',
    status: '',
    description: '',
    category: '',
    createdDate: '',
    facility: '',
    beforeTreatmentInMinutes: '',
    afterTreatmentInMinutes: ''
  });

  const handleEditClick = () => setIsEditing(true);

  // Tambahkan fungsi validasi
  const validateField = (name: string, value: string | number | boolean) => {
    switch (name) {
      case 'name':
        return !value ? 'Nama harus diisi' : '';
      case 'category':
        return !value ? 'Kategori harus dipilih' : '';
      case 'status':
        return !value ? 'Status harus dipilih' : '';
      case 'facility':
        return !value ? 'Fasilitas harus dipilih' : '';
      case 'beforeTreatmentInMinutes':
        return timeMode.before === 'custom' && Number(value) <= 0
          ? 'Waktu sebelum perawatan harus valid'
          : '';
      case 'afterTreatmentInMinutes':
        return timeMode.after === 'custom' && Number(value) <= 0
          ? 'Waktu setelah perawatan harus valid'
          : '';
      case 'createdDate':
        return !value ? 'Tanggal dibuat harus diisi' : '';
      default:
        return '';
    }
  };

  // Update handleInputChange
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    // Update formData
    if (name === 'status') {
      setFormData(prev => ({
        ...prev,
        status: value === 'true'
      }));
      // Validasi status
      setErrors(prev => ({
        ...prev,
        status: !value ? 'Status harus dipilih' : ''
      }));
    } else if (
      name === 'beforeTreatmentInMinutes' ||
      name === 'afterTreatmentInMinutes'
    ) {
      setFormData(prev => ({
        ...prev,
        [name]: Number(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }

    // Validasi real-time
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setEditedColor(newColor);
    setFormData(prev => ({
      ...prev,
      color: newColor
    }));
  };

  const handleSave = async () => {
    const newErrors = { ...errors };
    let hasError = false;

    // Validasi semua field required
    if (!formData.name) {
      newErrors.name = 'Nama harus diisi';
      hasError = true;
    }
    if (!formData.category) {
      newErrors.category = 'Kategori harus dipilih';
      hasError = true;
    }
    if (!formData.status) {
      newErrors.status = 'Status harus dipilih';
      hasError = true;
    }
    if (!formData.createdDate) {
      newErrors.createdDate = 'Tanggal dibuat harus diisi';
      hasError = true;
    }
    // ... validasi lainnya ...

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    // Lanjutkan dengan proses penyimpanan jika tidak ada error
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updateService({
            serviceId: id || '',
            data: {
              ...formData,
              id: formData.id // Pastikan id terkirim
            }
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              refetch();
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // const handleColorPreviewClick = () => {
  //   if (colorInputRef.current) {
  //     colorInputRef.current.click();
  //   }
  // };

  // Tambahkan fungsi helper di dalam komponen
  const getGenderLabel = (gender: string) => {
    switch (gender) {
      case 'Male':
        return 'Laki-laki';
      case 'Female':
        return 'Perempuan';
      default:
        return gender;
    }
  };

  // Tambahkan fungsi format tanggal
  const formatDate = (dateString: string) => {
    if (!dateString) return '-';

    try {
      const date = new Date(dateString);
      // Cek apakah tanggal valid
      if (isNaN(date.getTime())) return '-';

      const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
      ];

      return `${date.getDate()} ${
        months[date.getMonth()]
      } ${date.getFullYear()}`;
    } catch (error) {
      return '-';
    }
  };

  // Tambahkan fungsi helper untuk menentukan apakah warna termasuk terang atau gelap
  function isColorLight(color: string): boolean {
    // Jika tidak ada warna, anggap gelap
    if (!color) return false;

    // Konversi hex ke RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Hitung kecerahan menggunakan formula YIQ
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Nilai YIQ > 128 dianggap warna terang
    return yiq > 128;
  }

  return (
    <Card className="w-100">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Informasi Pribadi</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {!isEditing && (
          <>
            <Row className="mb-3">
              <Col
                xs={12}
                md={4}
                className="d-flex flex-column border-end pe-3"
              >
                <span className="fw-bold text-muted">ID Service</span>
                <span className="fw-normal">{'-'}</span>
              </Col>
              <Col className="border-end">
                <span className="fw-bold text-muted">Warna</span>
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      width: '40px',
                      height: '20px',
                      backgroundColor: formData.hex,
                      borderRadius: '4px',
                      border: '1px solid #dee2e6'
                    }}
                  />
                  <span className="fw-normal">{formData.hex || '-'}</span>
                </div>
              </Col>
              <Col xs={12} md={4} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Status</span>
                <span>
                  {formData.status ? (
                    <span className="badge bg-success">Aktif</span>
                  ) : (
                    <span className="badge bg-danger">Tidak Aktif</span>
                  )}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Nama Layanan</span>
                <span className="fw-normal">{formData.name || '-'}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Deskripsi Layanan</span>
                <span className="fw-normal w-100">
                  {formData.description || '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Kategori</span>
                <span>
                  <Badge
                    style={{
                      borderRadius: '20px',
                      backgroundColor:
                        serviceData.data.clinicalServiceCategory.color ||
                        '#CCCCCC',
                      color: isColorLight(
                        serviceData.data.clinicalServiceCategory.color
                      )
                        ? '#000'
                        : '#fff',
                      padding: '6px 14px'
                    }}
                    variant="phoenix"
                    className="fs-10 text-center fw-bold"
                  >
                    {formData.category || '-'}
                  </Badge>
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Tanggal Dibuat</span>
                <span className="fw-normal">
                  {formatDate(formData.createdDate)}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">
                  Waktu Sebelum Perawatan
                </span>
                <span className="fw-normal">
                  {formData.beforeTreatmentInMinutes} menit
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">
                  Waktu Setelah Perawatan
                </span>
                <span className="fw-normal">
                  {formData.afterTreatmentInMinutes} menit
                </span>
              </Col>
            </Row>
            {/* Batas */}
          </>
        )}

        {/* Mode edit */}
        {isEditing && (
          <>
            {/* Row 1: Warna dan Status */}
            <Row className="mb-3">
              {/* Warna */}
              <Col xs={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Warna
                  </Form.Label>
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      className="position-relative"
                      style={{ width: '100%' }}
                    >
                      <Form.Control
                        type="color"
                        name="hex"
                        value={formData.hex}
                        onChange={handleInputChange}
                        title="Pilih warna"
                        style={{
                          width: '100%',
                          height: '38px',
                          padding: '2px',
                          cursor: 'pointer',
                          opacity: formData.hex ? '1' : '0.5'
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '8px 12px',
                        borderRadius: '4px',
                        border: '1px solid #dee2e6',
                        fontSize: '14px',
                        backgroundColor: '#f8f9fa',
                        width: '30px'
                      }}
                    >
                      {formData.hex.toUpperCase()}
                    </div>
                  </div>
                </Form.Group>
              </Col>

              {/* Status */}
              <Col xs={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Status <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="status"
                    value={formData.status.toString()}
                    onChange={handleInputChange}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Pilih Status</option>
                    <option value="true">Aktif</option>
                    <option value="false">Tidak Aktif</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 2: Nama Layanan */}
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Nama Layanan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 3: Deskripsi Layanan */}
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Deskripsi Layanan
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 4: Kategori */}
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Kategori <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    isInvalid={!!errors.category}
                  >
                    <option value="">Pilih Kategori</option>
                    {categoryData?.data?.result.map(category => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.category}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 5: Tanggal Dibuat */}
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tanggal Dibuat <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="createdDate"
                    value={formatDateForInput(formData.createdDate) || ''}
                    onChange={handleInputChange}
                    isInvalid={!!errors.createdDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.createdDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 6: Waktu Jeda */}
            <Row className="mb-4">
              <Col xs={12}>
                <span className="fw-bold" style={{ color: '#525b75' }}>
                  Waktu Jeda
                </span>
                <div className="d-flex flex-column">
                  <div className="d-flex gap-3 my-1">
                    <Form.Check
                      type="radio"
                      id="buffer-default"
                      name="bufferTime"
                      label="Default"
                      value="default"
                      checked={
                        timeMode.before === 'default' &&
                        timeMode.after === 'default'
                      }
                      onChange={() =>
                        setTimeMode({ before: 'default', after: 'default' })
                      }
                    />
                    <Form.Check
                      type="radio"
                      id="buffer-custom"
                      name="bufferTime"
                      label="Custom"
                      value="custom"
                      checked={
                        timeMode.before === 'custom' ||
                        timeMode.after === 'custom'
                      }
                      onChange={() =>
                        setTimeMode({ before: 'custom', after: 'custom' })
                      }
                    />
                  </div>

                  {(timeMode.before === 'custom' ||
                    timeMode.after === 'custom') && (
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex align-items-center flex-wrap gap-2">
                        <div
                          className="d-flex"
                          style={{ minWidth: '250px', flex: '1 1 auto' }}
                        >
                          <Form.Control
                            type="number"
                            min="0"
                            name="beforeTreatmentInMinutes"
                            value={formData.beforeTreatmentInMinutes}
                            onChange={handleInputChange}
                            disabled={timeMode.before === 'default'}
                            style={{
                              width: '80px',
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }}
                          />
                          <Form.Select
                            name="bufferTimeBeforeUnit"
                            value={formData.beforeTreatmentInMinutes}
                            onChange={handleInputChange}
                            disabled={timeMode.before === 'default'}
                            style={{
                              flex: '1',
                              borderLeft: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0
                            }}
                          >
                            <option value="Menit">Menit</option>
                            <option value="Jam">Jam</option>
                          </Form.Select>
                        </div>
                        <span className="text-secondary fs-9">
                          Sebelum booking dimulai
                        </span>
                      </div>

                      <div className="d-flex align-items-start flex-wrap gap-2">
                        <div
                          className="d-flex"
                          style={{ minWidth: '250px', flex: '1 1 auto' }}
                        >
                          <Form.Control
                            type="number"
                            min="0"
                            name="afterTreatmentInMinutes"
                            value={formData.afterTreatmentInMinutes}
                            onChange={handleInputChange}
                            disabled={timeMode.after === 'default'}
                            style={{
                              width: '80px',
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }}
                          />
                          <Form.Select
                            name="bufferTimeAfterUnit"
                            value={formData.afterTreatmentInMinutes}
                            onChange={handleInputChange}
                            disabled={timeMode.after === 'default'}
                            style={{
                              flex: '1',
                              borderLeft: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0
                            }}
                          >
                            <option value="Menit">Menit</option>
                            <option value="Jam">Jam</option>
                          </Form.Select>
                        </div>
                        <span className="text-secondary fs-9">
                          Setelah booking selesai
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
        {isEditing && (
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave}>
              Simpan
              {/* {isSaving ? 'Menyimpan...' : 'Simpan'} */}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default InformasiLayananCard;
