import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; // Menggunakan FontAwesomeIcon untuk ikon pena
import {
  useGetCustomerByIdQuery,
  useUpdateEmergencyContactMutation
} from 'api/customerApi';
import { useParams } from 'react-router-dom';
import { showCustomAlert } from 'helpers/showCustomAlert';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

const KontakDaruratCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, error } = useGetCustomerByIdQuery(id!);
  const [updateEmergencyContact, { isLoading: isSavingEmergencyContact }] =
    useUpdateEmergencyContactMutation();

  const [formData, setFormData] = useState({
    namaKontakDarurat: '',
    emergencyPhone: '',
    statusHubungan: '',
    terhubungWA: false,
    alamatDarurat: ''
  });

  // State untuk edit mode
  const [isEditing, setIsEditing] = useState(false);
  const [emergencyContactId, setEmergencyContactId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (response?.data?.emergencyContacts?.[0]) {
      const emergencyContact = response.data.emergencyContacts[0];

      setEmergencyContactId(emergencyContact?.id || null);

      setFormData({
        namaKontakDarurat: emergencyContact?.name || '-',
        emergencyPhone: emergencyContact?.numberDetail?.number || '-',
        statusHubungan: emergencyContact?.status || '-',
        terhubungWA: emergencyContact?.numberDetail?.isWhatsapp || false,
        alamatDarurat: emergencyContact?.adress || '-'
      });
    }
  }, [response]);

  // Cek apakah data sudah ada, jika tidak tampilkan pesan error
  if (isLoading) return <div>Loading...</div>;
  if (error || !response?.data?.emergencyContacts)
    return <div>No customer data found</div>;

  // Fungsi untuk handle input change
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Jika input adalah radio button, pastikan kita mengubah nilai menjadi boolean
    if (name === 'terhubungWA') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value === 'true' // value adalah string, jadi kita ubah ke boolean
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  // Fungsi untuk handle submit (simpan data)
  const handleSave = async () => {
    // Validasi data sebelum menampilkan konfirmasi
    if (!emergencyContactId) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'ID Kontak Darurat tidak ditemukan',
        buttonType: 'ok'
      });
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi:
        'Anda yakin ingin menyimpan perubahan data kontak darurat ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const emergencyContactData = {
            id: emergencyContactId,
            contactName: formData.namaKontakDarurat,
            phoneNumber: formData.emergencyPhone,
            relationshipStatus: formData.statusHubungan,
            isWhatsapp: formData.terhubungWA,
            address: formData.alamatDarurat
          };

          await updateEmergencyContact({
            id: emergencyContactId,
            data: emergencyContactData
          }).unwrap();

          // Tampilkan alert success
          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data kontak darurat berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Error:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: error?.data?.message || 'Terjadi kesalahan pada server.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Fungsi untuk handle cancel edit
  const handleCancel = () => {
    // Cek apakah ada perubahan data dengan membandingkan data saat ini dengan data awal
    const originalContact = response?.data?.emergencyContacts[0];

    const hasChanges =
      originalContact &&
      (formData.namaKontakDarurat !== originalContact.name ||
        formData.emergencyPhone !== originalContact.numberDetail.number ||
        formData.statusHubungan !== originalContact.status ||
        formData.terhubungWA !== originalContact.numberDetail.isWhatsapp ||
        formData.alamatDarurat !== originalContact.adress);

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan data kontak darurat? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        // Reset form ke data awal
        if (response?.data?.emergencyContacts) {
          const emergencyContact = response.data.emergencyContacts[0];
          setFormData({
            namaKontakDarurat: emergencyContact.name,
            emergencyPhone: emergencyContact.numberDetail.number,
            statusHubungan: emergencyContact.status,
            terhubungWA: emergencyContact.numberDetail.isWhatsapp,
            alamatDarurat: emergencyContact.adress
          });
        }
      }
    });
  };

  // Fungsi untuk handle tombol edit
  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Card className="w-100">
      <Card.Body>
        {/* Judul dan Tombol Edit */}
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Kontak Darurat</h3>
            <ForbiddenWrapper privilege="CUSTOMER_DATA_WRITE" isBlank={true}>
              {!isEditing && (
                <Button variant="ghost" size="sm" onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                </Button>
              )}
            </ForbiddenWrapper>
          </Col>
        </Row>

        {/* Mode tampilan (display) */}
        {!isEditing && (
          <>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Alamat</span>
                <span className="fw-normal">
                  {formData.alamatDarurat || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Nama Kontak Darurat</span>
                <span className="fw-normal">
                  {formData.namaKontakDarurat || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">
                  Status Hubungan Kontak Darurat
                </span>
                <span className="fw-normal">
                  {formData.statusHubungan || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Nomor Kontak Darurat</span>
                <span className="fw-normal">
                  {formData.emergencyPhone || '-'}
                </span>
              </Col>
            </Row>

            {/* Batas */}
          </>
        )}

        {/* Mode edit */}

        {isEditing && (
          <>
            <h2 className="mb-2 mt-0 fs-7">Kontak Darurat</h2>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Nama Kontak Darurat
                  </Form.Label>
                  <Form.Control
                    name="namaKontakDarurat"
                    type="text"
                    placeholder="Nama Kontak Darurat"
                    value={formData.namaKontakDarurat}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Nomor Telepon
                  </Form.Label>
                  <Form.Control
                    name="emergencyPhone"
                    type="text"
                    placeholder="Nomor Telepon"
                    value={formData.emergencyPhone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={8}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Status Hubungan
                  </Form.Label>
                  <Form.Select
                    name="statusHubungan"
                    value={formData.statusHubungan}
                    onChange={handleInputChange}
                  >
                    <option>Pilih Status Hubungan</option>
                    <option>Orang Tua</option>
                    <option>Suami/Istri</option>
                    <option>Anak</option>
                    <option>Lainnya</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Terhubung WhatsApp
                  </Form.Label>
                  <div className="d-flex gap-2 pt-1">
                    <Form.Check
                      type="radio"
                      name="terhubungWA"
                      label="Ya"
                      value="true"
                      checked={formData.terhubungWA === true}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      type="radio"
                      name="terhubungWA"
                      label="Tidak"
                      value="false"
                      checked={formData.terhubungWA === false}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Alamat
                  </Form.Label>
                  <Form.Control
                    name="alamatDarurat"
                    type="text"
                    placeholder="Alamat"
                    value={formData.alamatDarurat}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="outline-primary" className="mt-2 w-100">
              + Tambah Kontak Darurat
            </Button>

            {/* Batas */}

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave} disabled={isSavingEmergencyContact}>
                {isSavingEmergencyContact ? 'Menyimpan...' : 'Simpan'}
              </Button>{' '}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default KontakDaruratCard;
