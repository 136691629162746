/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopMedical, faPen } from '@fortawesome/free-solid-svg-icons';
import AvatarUpload from 'components/common/AvatarUpload';
import { useParams } from 'react-router-dom';
import {
  useGetServiceByIdQuery,
  useUpdateServiceNamesMutation
} from 'api/serviceApi';
import team15 from 'assets/img/team/15.webp';
import Badge from 'components/base/Badge';
import { showCustomAlert } from 'helpers/showCustomAlert';

const ServiceProfileCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: serviceData } = useGetServiceByIdQuery(id || '');

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return new Date(dateString).toLocaleDateString('id-ID', options);
  };

  if (!serviceData?.data) {
    return null;
  }

  const { data: service } = serviceData;

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center g-5 text-center text-sm-start">
          <Col xs={12} sm="auto" className="mb-sm-3">
            <FontAwesomeIcon icon={faLaptopMedical} style={{ fontSize: 50 }} />
          </Col>
          <Col xs={12} sm="auto" className="flex-1">
            <div className="d-flex align-items-center">
              <h3 className="fw-bolder">{service.name || '-'}</h3>
            </div>
            <p className="text-body-secondary">
              Dibuat pada: {formatDate(service.createdDate) || '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-2 mt-2 mb-3"
          >
            <Badge
              style={{ borderRadius: '8px' }}
              bg="info"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              Total Pegawai:{' '}
              {service.clinicalServicePricing?.reduce(
                (total, pricing) =>
                  total + (pricing.customerNames?.length || 0),
                0
              ) || '5'}
            </Badge>
            <Badge
              style={{ borderRadius: '8px' }}
              bg="danger"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              Status: {service.status ? 'Aktif' : 'Tidak Aktif'}
            </Badge>
            <Badge
              style={{
                borderRadius: '8px',
                border: '1px #CBD0DD solid',
                backgroundColor: '#E3E6ED',
                color: 'black'
              }}
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3 fw-bolder"
            >
              TOTAL PELAKSANAAN: {'-'}
            </Badge>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ServiceProfileCard;
