import { Just403 } from 'pages/error/Error403';
import { ReactNode } from 'react';
import { hasPrivilege, IAuthorities } from 'slices/privilegeSlice';
import { useSelector } from 'react-redux';
import { AppRootState } from 'app/store';
import IntrospectLoading from 'components/misc/IntrospectLoading';

const ForbiddenWrapper = ({
  children,
  privilege,
  isBlank = false,
  skeleton = <IntrospectLoading />
}: {
  children: ReactNode;
  privilege: IAuthorities;
  isBlank?: boolean;
  skeleton?: JSX.Element;
}) => {
  const isLoading = useSelector(
    (state: AppRootState) => state['privilege-slice'].isloading
  );
  const isAble = hasPrivilege(privilege);

  if (isLoading && isBlank == false) {
    return skeleton;
  }

  if (!isAble) {
    return isBlank ? <></> : <Just403 />;
  }
  return children;
};

export default ForbiddenWrapper;
