import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';

interface CategoryServiceResponse {
  id: string;
  name: string;
  description: string;
  color: string;
  totalServices: number;
  code?: string;
  clinicalServices?: {
    id: string;
    name: string;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    description: string;
    beforeTreatmentInMinutes: number;
    afterTreatmentInMinutes: number;
    pricingType: string;
    status: boolean;
    additionalCost: string;
  }[];
}

interface GetAllCategoryServiceResponse extends BaseResponse {
  data: {
    result: CategoryServiceResponse[];
    total: number;
    totalPages: number;
    page: number;
  };
}

interface CategoryServiceQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  name?: string;
}

interface UpdateCategoryServiceRequest {
  name: string;
  description: string;
  color: string;
  clinicalServices?: string[];
}

interface DeleteCategoryServiceRequest {
  id: string[];
}

interface CreateCategoryServiceRequest {
  name: string;
  description: string;
  color: string;
  clinicalServices?: string[];
}

interface CategoryServiceDetailResponse extends BaseResponse {
  data: {
    id: string;
    name: string;
    description: string;
    color: string;
    clinicalServices: {
      id: string;
      createdDate: string;
      modifiedDate: string;
      createdBy: string;
      modifiedBy: string;
      name: string;
      description: string;
      beforeTreatmentInMinutes: number;
      afterTreatmentInMinutes: number;
      pricingType: string;
      status: boolean;
      additionalCost: string;
    }[];
  };
}

export const categoryServiceApi = createApi({
  reducerPath: 'categoryService',
  tagTypes: ['CategoryService'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({
    getAllCategoryService: builder.query<
      GetAllCategoryServiceResponse,
      CategoryServiceQueryParams
    >({
      query: params => {
        const queryParams = new URLSearchParams();
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        if (params.name) {
          queryParams.append('name', params.name);
        }

        return `api/v1/clinical-service/category?${queryParams.toString()}`;
      },
      providesTags: ['CategoryService']
    }),
    getCategoryServiceById: builder.query<CategoryServiceResponse, string>({
      query: id => `api/v1/clinical-service/category/${id}`,
      transformResponse: (
        response: BaseResponse & {
          data: Omit<CategoryServiceResponse, 'clinicalServices'> & {
            clinicalServices: CategoryServiceResponse['clinicalServices'];
          };
        }
      ) => response.data,
      providesTags: ['CategoryService']
    }),
    updateCategoryService: builder.mutation<
      BaseResponse,
      {
        id: string;
        data: UpdateCategoryServiceRequest;
      }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/category/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['CategoryService']
    }),
    deleteCategoryService: builder.mutation<
      BaseResponse,
      DeleteCategoryServiceRequest
    >({
      query: data => ({
        url: 'api/v1/clinical-service/category',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: ['CategoryService']
    }),
    createCategoryService: builder.mutation<
      BaseResponse,
      CreateCategoryServiceRequest
    >({
      query: data => ({
        url: 'api/v1/clinical-service/category',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['CategoryService']
    }),
    getCategoryServiceDetail: builder.query<
      CategoryServiceDetailResponse,
      string
    >({
      query: id => `api/v1/clinical-service/category/${id}/detail`,
      providesTags: ['CategoryService']
    })
  })
});

export const {
  useGetAllCategoryServiceQuery,
  useGetCategoryServiceByIdQuery,
  useUpdateCategoryServiceMutation,
  useDeleteCategoryServiceMutation,
  useCreateCategoryServiceMutation,
  useGetCategoryServiceDetailQuery
} = categoryServiceApi;
