import {
  faFileExport,
  faPlus,
  faTrash,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu,
  FilterItem
} from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import KelolaAkunTable, {
  kelolaAkunTableColumns
} from 'components/tables/KelolaAkunTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';
import { kelolaAkunBreadcrumbItems } from 'data/commonData';
import TambahAkunModal from 'components/modals/TambahAkunModal';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { useGetAccountsQuery, useDeleteAccountMutation } from 'api/accountApi';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

const KelolaAkun = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showTambahModal, setShowTambahModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<boolean[]>([]);
  const [deleteAccount] = useDeleteAccountMutation();

  // Fetch accounts with current page and filters
  const {
    data: accountData,
    isLoading,
    refetch
  } = useGetAccountsQuery({
    page: currentPage,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC',
    name: searchTerm || undefined,
    status: statusFilter.length > 0 ? statusFilter : undefined
  });

  const table = useAdvanceTable({
    data: accountData?.data?.result || [],
    columns: kelolaAkunTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  // Create a debounced search handler
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setSearchTerm(searchValue);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    debouncedSearch(searchValue);
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await refetch();
      toast.success('Data berhasil diperbarui');
    } catch (error) {
      toast.error('Gagal memperbarui data');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handlePageChange = (page: number) => {
    table.resetRowSelection();
    setCurrentPage(page);
  };

  // State untuk filter
  const [filters, setFilters] = useState<FilterMenu[]>([
    {
      label: 'Status',
      items: [
        {
          label: 'Aktif',
          value: 'true',
          checked: false,
          isSelected: false
        },
        {
          label: 'Tidak Aktif',
          value: 'false',
          checked: false,
          isSelected: false
        }
      ]
    }
  ]);

  const handleFilterChange = (label: string, selectedItems: FilterItem[]) => {
    if (label === 'Status') {
      // Update filters state for UI
      setFilters(prevFilters =>
        prevFilters.map(menu =>
          menu.label === 'Status'
            ? {
                ...menu,
                items: menu.items.map(item => ({
                  ...item,
                  checked: selectedItems.some(
                    selected => selected.value === item.value
                  )
                }))
              }
            : menu
        )
      );

      // Set status filter for API
      const selectedStatus = selectedItems.map(item => item.value === 'true');
      setStatusFilter(selectedStatus);
      setCurrentPage(1);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const handleTambahAkun = (data: any) => {
  //   console.log('Data akun baru:', data);
  //   toast.info('Fitur belum tersedia');
  //   setShowTambahModal(false);
  // };

  // const handleDelete = (reason: string, notes: string) => {
  //   const selectedRows = table.getSelectedRowModel().rows;

  //   console.log('Menghapus akun dengan alasan:', reason);
  //   console.log('Catatan:', notes);
  //   console.log(
  //     'Akun yang dihapus:',
  //     selectedRows.map(row => row.original)
  //   );

  //   toast.info('Fitur belum tersedia');
  //   setShowDeleteModal(false);
  //   table.resetRowSelection();
  // };

  const handleDelete = async (reason: string, notes: string) => {
    const selectedRows = table.getSelectedRowModel().rows;

    try {
      const deleteRequest = {
        notes: notes,
        deletionReason: reason,
        data: selectedRows.map(row => row.original.id)
      };

      await deleteAccount(deleteRequest).unwrap();

      toast.success('Akun berhasil dihapus');
      setShowDeleteModal(false);
      table.resetRowSelection();

      // Refresh the data
      refetch();
    } catch (error) {
      toast.error('Gagal menghapus akun');
      console.error('Delete error:', error);
    }
  };
  const getSelectedAccounts = () => {
    return table.getSelectedRowModel().rows.map(row => ({
      name: row.original.email
    }));
  };

  return (
    <div>
      <PageBreadcrumb items={kelolaAkunBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="d-flex justify-content-between">
        <h2 className="mb-4">Kelola Akun</h2>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
            <div className="d-flex gap-3 align-items-center position-relative">
              <SearchBox
                placeholder="Cari"
                onChange={handleSearchInputChange}
                style={{ maxWidth: '200px' }}
              />
              <FilterButtonGroup
                menus={filters}
                onFilterChange={handleFilterChange}
              />
            </div>

            <div className="ms-auto d-flex align-items-center gap-3 flex-wrap">
              <Button
                variant="link"
                className="text-body px-0"
                onClick={() => {
                  toast.info('Fitur belum tersedia');
                }}
              >
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Unduh Tabel
              </Button>
              <Button
                variant="link"
                className="p-0 text-body text-decoration-none"
                onClick={handleRefresh}
                disabled={isLoading}
              >
                <FontAwesomeIcon
                  icon={faUndo}
                  className={`me-2 ${isLoading ? 'fa-spin' : ''}`}
                />
                {isLoading ? 'Memperbarui...' : 'Refresh'}
              </Button>
              <ForbiddenWrapper privilege="USER_MANAGEMENT_FULL" isBlank={true}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => setShowDeleteModal(true)}
                  disabled={table.getSelectedRowModel().rows.length === 0}
                >
                  <FontAwesomeIcon icon={faTrash} className="me-2" />
                  Hapus Akun
                </Button>
              </ForbiddenWrapper>
              <ForbiddenWrapper
                privilege="USER_MANAGEMENT_WRITE"
                isBlank={true}
              >
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => setShowTambahModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Tambah Akun
                </Button>
              </ForbiddenWrapper>
            </div>
          </div>
        </div>
        <div style={{ width: '95%' }} className="mx-auto">
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <KelolaAkunTable
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={accountData?.data?.totalPages || 1}
              accountData={{
                totalRecords: accountData?.data?.total || 0
              }}
            />
          </div>
        </div>
      </AdvanceTableProvider>

      <TambahAkunModal
        show={showTambahModal}
        onHide={() => setShowTambahModal(false)}
        // onSubmit={handleTambahAkun}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={getSelectedAccounts()}
        onDelete={handleDelete}
        title="Hapus Akun"
      />
    </div>
  );
};

export default KelolaAkun;
