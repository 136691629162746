import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PilihLayananModal from './PilihLayananModal';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';
import { useCreateCategoryServiceMutation } from 'api/categoryServiceApi';

interface TambahKategoriLayananModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

// Tambahkan style untuk overlay
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const overlayStyle = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 1050
};

const TambahKategoriLayananModal = ({
  show,
  handleClose,
  onSuccess
}: TambahKategoriLayananModalProps) => {
  const initialFormData = {
    name: '',
    description: '',
    color: '#E3E6ED', // dengan warna default yang dipilih
    services: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedServices, setSelectedServices] = useState<
    ClinicalServiceResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPilihLayananModal, setShowPilihLayananModal] = useState(false);

  const [createCategory, { isLoading: isCreating }] =
    useCreateCategoryServiceMutation();

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    const normalizedName = formData.name.trim().replace(/\s+/g, ' ');

    if (!normalizedName) {
      newErrors.name = 'Nama kategori wajib diisi';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      showCustomAlert({
        icon: 'question',
        label: 'Kategori akan disimpan',
        deskripsi: 'Apakah Anda yakin data kategori sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            setIsLoading(true);
            const normalizedName = formData.name.trim().replace(/\s+/g, ' ');

            const requestData = {
              name: normalizedName,
              description: formData.description,
              color: formData.color,
              clinicalServices: selectedServices.map(service => service.id)
            };

            const response = await createCategory(requestData).unwrap();

            if (response.code === 200) {
              showCustomAlert({
                icon: 'success',
                label: 'Sukses!',
                deskripsi: 'Kategori baru berhasil disimpan.',
                buttonType: 'ok',
                onConfirm: () => {
                  setFormData(initialFormData);
                  setSelectedServices([]);
                  onSuccess();
                  handleClose();
                }
              });
            } else {
              throw new Error(response.message || 'Gagal membuat kategori');
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            console.error('Error saat mengirim data:', error);

            // Cek apakah error adalah duplikat nama
            if (error?.data?.message?.includes('Duplicate entry for name')) {
              showCustomAlert({
                icon: 'error',
                label: 'Terdapat nama kategori yang sama',
                deskripsi: 'Mohon ubah nama kategori yang akan Anda tambahkan.',
                buttonType: 'ok'
              });
            } else {
              showCustomAlert({
                icon: 'error',
                label: 'Gagal membuat kategori',
                deskripsi:
                  error?.data?.message ||
                  'Terjadi kesalahan saat menyimpan kategori.',
                buttonType: 'ok'
              });
            }
          } finally {
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleCancel = () => {
    const isFormFilled =
      formData.name.trim() !== '' ||
      formData.description.trim() !== '' ||
      selectedServices.length > 0;

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Kategori tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setFormData(initialFormData);
          setSelectedServices([]);
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  const handleDeleteService = (serviceId: string) => {
    setSelectedServices(prev => prev.filter(s => s.id !== serviceId));
  };

  const handlePilihLayanan = (services: ClinicalServiceResponse[]) => {
    setSelectedServices(services);
    setShowPilihLayananModal(false);
  };

  useEffect(() => {
    if (show) {
      setFormData(initialFormData);
      setErrors({});
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleCancel}
        size="lg"
        centered
        contentClassName="shadow"
        backdropClassName="darker-backdrop"
        style={{ zIndex: showPilihLayananModal ? 1050 : 1050 }}
      >
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="fw-bolder fs-6">Tambah Kategori</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="tambahKategoriLayananForm"
              disabled={isLoading || isCreating}
            >
              {isLoading || isCreating ? 'Menyimpan...' : 'Buat Kategori'}
            </Button>
          </div>
        </Modal.Header>
        <Form id="tambahKategoriLayananForm" onSubmit={handleSubmit}>
          <Modal.Body className="px-5 py-4 bg-light">
            <h4 className="mb-4">Informasi Kategori</h4>

            <Form.Group className="mb-3">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Nama Kategori <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan nama kategori"
                value={formData.name}
                isInvalid={!!errors.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                onBlur={e =>
                  setFormData({
                    ...formData,
                    name: e.target.value.trim().replace(/\s+/g, ' ')
                  })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Warna
              </Form.Label>
              <div className="d-flex gap-2 align-items-center">
                <div className="position-relative" style={{ width: '100%' }}>
                  <Form.Control
                    type="color"
                    value={formData.color}
                    onChange={e =>
                      setFormData({ ...formData, color: e.target.value })
                    }
                    title="Pilih warna"
                    style={{
                      width: '100%',
                      height: '38px',
                      padding: '2px',
                      cursor: 'pointer',
                      opacity: formData.color ? '1' : '0.5'
                    }}
                  />
                  {!formData.color && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        pointerEvents: 'none',
                        color: '#6c757d'
                      }}
                    >
                      Pilih warna
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: '8px 12px',
                    borderRadius: '4px',
                    border: '1px solid #dee2e6',
                    fontSize: '14px',
                    backgroundColor: '#f8f9fa'
                  }}
                >
                  {formData.color.toUpperCase()}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="text-capitalize fs-8 p-0 fw-bolder">
                Deskripsi Kategori
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Masukkan deskripsi kategori"
                value={formData.description}
                isInvalid={!!errors.description}
                onChange={e =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <h4 className="mb-3">Daftar Layanan</h4>
            <div className="table-responsive mb-3">
              <table className="table">
                <thead>
                  <tr className="fs-9">
                    <th className="ps-0">NAMA LAYANAN</th>
                    <th style={{ width: '40px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedServices.length === 0 ? (
                    <tr className="fs-9">
                      <td colSpan={3} className="text-center text-muted">
                        Belum ada layanan yang ditambahkan
                      </td>
                    </tr>
                  ) : (
                    selectedServices.map(service => (
                      <tr key={service.id} className="fs-9">
                        <td>{service.name}</td>
                        <td className="text-end">
                          <Button
                            variant="link"
                            className="text-muted p-0"
                            onClick={() => handleDeleteService(service.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <Button
              className="w-100 fs-9"
              variant="outline-primary"
              size="lg"
              onClick={() => setShowPilihLayananModal(true)}
            >
              + Tambah Layanan
            </Button>
          </Modal.Body>
        </Form>
      </Modal>

      {show && showPilihLayananModal && <div style={overlayStyle} />}

      <PilihLayananModal
        show={show && showPilihLayananModal}
        onHide={() => setShowPilihLayananModal(false)}
        onSelect={handlePilihLayanan}
        selectedIds={selectedServices.map(service => service.id)}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
        currentCategoryId=""
      />
    </>
  );
};

export default TambahKategoriLayananModal;
