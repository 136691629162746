import { useAddCustomerMutation } from 'api/customerApi';
import {
  FormData,
  CustomerTemplateResponseData
} from 'api/request/CustomerCreateReq';
import Avatar from 'components/base/Avatar';
import { showCustomAlert } from 'helpers/showCustomAlert';
import React, { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { Modal, Button, Form, Row, Col, Nav, Tab } from 'react-bootstrap';
import {
  useGetDistrictsQuery,
  useGetProvincesQuery,
  useGetRegenciesQuery,
  useGetVillagesQuery
} from 'api/locationApi';
import {
  useGetCustomerTemplateQuery,
  useGetCustomerTemplateFieldQuery,
  customerTemplateApi
} from 'api/customerTemplateApi';
import {
  CustomerTemplateFieldDetail,
  CustomerTemplate
} from 'api/response/CustomerTemplateResponse';
import {
  faMapLocation,
  faCreditCard,
  faFileLines,
  faContactBook
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

interface AddCustomerModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

const useLocationQueries = (
  provinceId: string,
  regencyId: string,
  districtId: string
) => {
  const { data: regenciesResponse, isLoading: isLoadingRegencies } =
    useGetRegenciesQuery(provinceId || '', { skip: !provinceId });

  const { data: districtsResponse, isLoading: isLoadingDistricts } =
    useGetDistrictsQuery(regencyId || '', { skip: !regencyId });

  const { data: villagesResponse, isLoading: isLoadingVillages } =
    useGetVillagesQuery(districtId || '', { skip: !districtId });

  return {
    regencies: regenciesResponse?.data || [],
    districts: districtsResponse?.data || [],
    villages: villagesResponse?.data || [],
    isLoadingRegencies,
    isLoadingDistricts,
    isLoadingVillages
  };
};

const AddCustomerModal = ({
  show,
  handleClose,
  onSuccess
}: AddCustomerModalProps) => {
  const [validated, setValidated] = useState(false);
  const [addCustomer] = useAddCustomerMutation();

  // Definisikan initial state sekali saja
  const initialFormState: FormData = {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    religion: '',
    maritalStatus: '',
    idType: '',
    idNumber: '',
    occupation: '',
    clientGroup: '',
    branch: '',
    accountingId: 'B-123123',
    email: [{ emailAddress: '', usageType: '' }],
    phoneNumber: [{ phoneNumber: '', usageType: '', isWhatsapp: false }],
    emergencyContact: [
      {
        contactName: '',
        phoneNumber: '',
        relationshipStatus: '',
        isWhatsapp: false,
        address: ''
      }
    ],
    address: [
      {
        streetAddress: '',
        state: 'NY',
        province: 0,
        city: 0,
        district: 0,
        village: 0,
        country: '',
        postalCode: '',
        addressType: ''
      }
    ],
    customerTemplateResponse: [],
    customerNote: [{ noteType: 'private', content: '' }],
    createdBy: 'SYSTEM',
    modifiedBy: 'SYSTEM',
    clinicId: 0,
    customerType: 'Individual',
    joinDate: ''
  };

  // Gunakan initialFormState sebagai nilai awal useState
  const [formData, setFormData] = useState<FormData>(initialFormState);

  const location0 = useLocationQueries(
    formData.address[0]?.province?.toString() || '',
    formData.address[0]?.city?.toString() || '',
    formData.address[0]?.district?.toString() || ''
  );
  const location1 = useLocationQueries(
    formData.address[1]?.province?.toString() || '',
    formData.address[1]?.city?.toString() || '',
    formData.address[1]?.district?.toString() || ''
  );
  const location2 = useLocationQueries(
    formData.address[2]?.province?.toString() || '',
    formData.address[2]?.city?.toString() || '',
    formData.address[2]?.district?.toString() || ''
  );
  const location3 = useLocationQueries(
    formData.address[3]?.province?.toString() || '',
    formData.address[3]?.city?.toString() || '',
    formData.address[3]?.district?.toString() || ''
  );
  const location4 = useLocationQueries(
    formData.address[4]?.province?.toString() || '',
    formData.address[4]?.city?.toString() || '',
    formData.address[4]?.district?.toString() || ''
  );
  const location5 = useLocationQueries(
    formData.address[5]?.province?.toString() || '',
    formData.address[5]?.city?.toString() || '',
    formData.address[5]?.district?.toString() || ''
  );
  // ... lanjutkan hingga location9

  const locationQueries = useMemo(() => {
    return [
      location0,
      location1,
      location2,
      location3,
      location4,
      location5
    ].slice(0, formData.address.length);
  }, [
    location0,
    location1,
    location2,
    location3,
    location4,
    location5,

    formData.address.length
  ]);
  // Tambahkan state untuk tracking ID
  const [selectedProvinceIds, setSelectedProvinceIds] = useState<string[]>([
    ''
  ]);
  const [selectedRegencyIds, setSelectedRegencyIds] = useState<string[]>(['']);
  const [selectedDistrictIds, setSelectedDistrictIds] = useState<string[]>([
    ''
  ]);

  // Fetch data lokasi
  const { data: provincesResponse } = useGetProvincesQuery();

  // Tambahkan state untuk tracking error
  const [sectionErrors, setSectionErrors] = useState<{
    template: string[];
    personalInfo: string[];
    address: string[];
    contact: string[];
  }>({
    personalInfo: [],
    address: [],
    contact: [],
    template: []
  });

  // Tambahkan state untuk tracking detail visibility
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showErrorDetails, setShowErrorDetails] = useState<{
    personalInfo: boolean;
    address: boolean;
    contact: boolean;
  }>({
    personalInfo: false,
    address: false,
    contact: false
  });

  // Tambahkan state untuk loading
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  // Tambahkan useEffect untuk simulasi loading
  useEffect(() => {
    if (show) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500); // Delay 500ms untuk efek loading
      return () => clearTimeout(timer);
    }
  }, [show]);

  // Tambahkan fungsi untuk mengecek validasi per section
  const validateSections = () => {
    const errors = {
      personalInfo: [] as string[],
      address: [] as string[],
      contact: [] as string[],
      template: [] as string[]
    };

    // Validasi Informasi Pribadi (urutan sesuai layout visual)
    const personalInfoValidation = [
      { field: 'firstName', message: 'Nama depan harus diisi' },
      // { field: 'lastName', message: 'Nama belakang harus diisi' },
      { field: 'customerType', message: 'Tipe pelanggan harus dipilih' },
      { field: 'gender', message: 'Jenis kelamin harus dipilih' },
      { field: 'religion', message: 'Agama harus dipilih' },
      { field: 'maritalStatus', message: 'Status perkawinan harus dipilih' },
      { field: 'idType', message: 'Tipe identifikasi harus dipilih' },
      { field: 'idNumber', message: 'Nomor identifikasi harus diisi' },
      { field: 'dateOfBirth', message: 'Tanggal lahir harus diisi' },
      { field: 'branch', message: 'Cabang harus dipilih' },
      { field: 'joinDate', message: 'Tanggal bergabung harus diisi' }
    ];

    personalInfoValidation.forEach(({ field, message }) => {
      if (!formData[field as keyof FormData]) {
        errors.personalInfo.push(message);
      }
    });

    // Validasi Alamat (urutan sesuai layout visual)
    formData.address.forEach((addr, index) => {
      const addressValidation = [
        {
          field: 'streetAddress',
          message: 'Alamat jalan harus diisi',
          id: `address[${index}].streetAddress`
        },
        {
          field: 'province',
          message: 'Provinsi harus dipilih',
          id: `address[${index}].province`
        },
        {
          field: 'city',
          message: 'Kota harus dipilih',
          id: `address[${index}].city`
        },
        {
          field: 'district',
          message: 'Kecamatan harus dipilih',
          id: `address[${index}].district`
        },
        {
          field: 'village',
          message: 'Desa/Kelurahan harus dipilih',
          id: `address[${index}].village`
        },
        {
          field: 'country',
          message: 'Negara harus dipilih',
          id: `address[${index}].country`
        },
        {
          field: 'addressType',
          message: 'Jenis alamat harus dipilih',
          id: `address[${index}].addressType`
        }
      ];

      addressValidation.forEach(({ field, message }) => {
        if (!addr[field as keyof typeof addr]) {
          errors.address.push(message);
        }
      });
    });
    // Validasi Kontak
    if (!formData.phoneNumber[0]?.phoneNumber)
      errors.contact.push('Nomor telepon harus diisi');
    if (!formData.phoneNumber[0]?.usageType)
      errors.contact.push('Jenis penggunaan telepon harus dipilih');

    // Add template validation
    // Template validation
    if (templates?.data.result) {
      templates.data.result
        .filter(template => template.status)
        .forEach(template => {
          const templateFields = allFields?.data.filter(
            field =>
              field.fieldGroup === 'customer' &&
              template.customerTemplateFields.some(tf => tf.id === field.id)
          );

          templateFields?.forEach(field => {
            if (field.isRequired) {
              const response = formData.customerTemplateResponse.find(
                resp => resp.fieldId === Number(field.id)
              );

              let isEmpty = false;
              // In validateSections function, update the switch case:
              switch (field.fieldType.code) {
                case 'RADIO_BUTTONS':
                  // Radio buttons should check if any option is selected in optionResponse
                  isEmpty = !response?.optionResponse?.some(
                    opt => opt.selectedChoice
                  );
                  break;
                case 'MULTI_SELECT_LIST':
                  isEmpty =
                    !response?.responseValue ||
                    response.responseValue.length === 0;
                  break;
                case 'CHECKBOXES':
                  isEmpty = !response?.optionResponse?.some(
                    opt => opt.selectedChoice
                  );
                  break;
                default:
                  isEmpty = !response || !response.responseValue;
              }

              if (isEmpty) {
                errors.template.push(
                  `${field.label} pada ${template.templateName} harus diisi`
                );
              }
            }
          });
        });
    }

    setSectionErrors(errors);
    setValidated(true);
    return Object.values(errors).every(section => section.length === 0);
  };

  // Tambahkan fungsi untuk scroll ke elemen dan berikan animasi
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('highlight-animation');
      setTimeout(() => {
        element.classList.remove('highlight-animation');
      }, 2000);
    }
  };

  // Modifikasi fungsi handleErrorClick
  const handleErrorClick = (
    section: 'personalInfo' | 'address' | 'contact'
  ) => {
    if (section === 'contact') {
      // Urutan field kontak yang akan dicek
      const contactFieldOrder = [
        'phoneNumber[0].phoneNumber',
        'phoneNumber[0].usageType'
      ];

      // Cari field kontak pertama yang kosong berdasarkan urutan
      const firstEmptyField = contactFieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        // Pastikan tab kontak aktif sebelum scroll
        setActiveTab('kontak');

        // Beri delay untuk memastikan tab sudah aktif
        setTimeout(() => {
          scrollToAndHighlightElement(firstEmptyField);
        }, 100);
      }
    } else if (section === 'address') {
      // Urutan field alamat yang akan dicek
      const addressFieldOrder = [
        'address[0].streetAddress',
        'address[0].province',
        'address[0].city',
        'address[0].district',
        'address[0].village',
        'address[0].country',
        'address[0].addressType'
      ];

      const firstEmptyField = addressFieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        // Pastikan tab alamat aktif sebelum scroll
        setActiveTab('alamat');

        // Beri delay untuk memastikan tab sudah aktif
        setTimeout(() => {
          scrollToAndHighlightElement(firstEmptyField);
        }, 100);
      }
    } else if (section === 'personalInfo') {
      // Existing personal info validation logic
      const fieldOrder = [
        'firstName',
        // 'lastName',
        'customerType',
        'gender',
        'religion',
        'maritalStatus',
        'idType',
        'idNumber',
        'dateOfBirth',
        'branch',
        'joinDate'
      ];

      const firstEmptyField = fieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        scrollToAndHighlightElement(firstEmptyField);
      }
    }
  };

  // Fungsi untuk scroll dan highlight yang lebih robust
  const scrollToAndHighlightElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      // Tunggu sebentar untuk memastikan tab transition selesai
      setTimeout(() => {
        // Scroll ke element dengan offset
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        // Tambahkan class untuk animasi highlight
        element.classList.add('highlight-animation');

        // Focus ke element
        setTimeout(() => {
          element.focus();
        }, 500); // Delay focus sedikit untuk memastikan scroll selesai

        // Hapus class setelah animasi selesai
        setTimeout(() => {
          element.classList.remove('highlight-animation');
        }, 2000);
      }, 150); // Tunggu tab transition
    }
  };
  // Handle New Adress
  const addNewAddress = () => {
    setFormData(prevData => ({
      ...prevData,
      address: [
        ...prevData.address,
        {
          streetAddress: '',
          state: 'NY',
          province: 0,
          city: 0,
          district: 0,
          village: 0,
          country: '',
          postalCode: '',
          addressType: ''
        }
      ]
    }));

    // Tambahkan state baru untuk lokasi
    setSelectedProvinceIds(prev => [...prev, '']);
    setSelectedRegencyIds(prev => [...prev, '']);
    setSelectedDistrictIds(prev => [...prev, '']);
  };

  const removeAddress = (indexToRemove: number) => {
    if (formData.address.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        address: prevData.address.filter((_, index) => index !== indexToRemove)
      }));

      // Hapus state lokasi untuk alamat yang dihapus
      setSelectedProvinceIds(prev =>
        prev.filter((_, i) => i !== indexToRemove)
      );
      setSelectedRegencyIds(prev => prev.filter((_, i) => i !== indexToRemove));
      setSelectedDistrictIds(prev =>
        prev.filter((_, i) => i !== indexToRemove)
      );
    }
  };

  const addNewPhoneNumber = () => {
    setFormData(prevData => ({
      ...prevData,
      phoneNumber: [
        ...prevData.phoneNumber,
        { phoneNumber: '', usageType: '', isWhatsapp: false }
      ]
    }));
  };

  const removePhoneNumber = (indexToRemove: number) => {
    if (formData.phoneNumber.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        phoneNumber: prevData.phoneNumber.filter(
          (_, index) => index !== indexToRemove
        )
      }));
    }
  };

  const addNewEmergencyContact = () => {
    setFormData(prevData => ({
      ...prevData,
      emergencyContact: [
        ...prevData.emergencyContact,
        {
          contactName: '',
          phoneNumber: '',
          relationshipStatus: '',
          isWhatsapp: false,
          address: ''
        }
      ]
    }));
  };

  const removeEmergencyContact = (indexToRemove: number) => {
    if (formData.emergencyContact.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        emergencyContact: prevData.emergencyContact.filter(
          (_, index) => index !== indexToRemove
        )
      }));
    }
  };

  const addNewEmail = () => {
    setFormData(prevData => ({
      ...prevData,
      email: [...prevData.email, { emailAddress: '', usageType: '' }]
    }));
  };

  const removeEmail = (indexToRemove: number) => {
    if (formData.email.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        email: prevData.email.filter((_, index) => index !== indexToRemove)
      }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Ekstrak index dari nama field
    const match = name.match(/address\[(\d+)\]\.(\w+)/);

    if (match) {
      const index = parseInt(match[1]);
      const field = match[2];

      // Update lokasi berdasarkan index
      if (field === 'province') {
        const newProvinceIds = [...selectedProvinceIds];
        newProvinceIds[index] = value;
        setSelectedProvinceIds(newProvinceIds);

        // Reset kota dan kecamatan untuk alamat ini
        const newRegencyIds = [...selectedRegencyIds];
        newRegencyIds[index] = '';
        setSelectedRegencyIds(newRegencyIds);

        const newDistrictIds = [...selectedDistrictIds];
        newDistrictIds[index] = '';
        setSelectedDistrictIds(newDistrictIds);

        // Reset form data untuk kota, kecamatan, dan desa
        setFormData(prev => ({
          ...prev,
          address: prev.address.map((addr, idx) =>
            idx === index
              ? {
                  ...addr,
                  city: 0,
                  district: 0,
                  village: 0
                }
              : addr
          )
        }));
      }

      if (field === 'city') {
        const newRegencyIds = [...selectedRegencyIds];
        newRegencyIds[index] = value;
        setSelectedRegencyIds(newRegencyIds);

        // Reset kecamatan dan desa untuk alamat ini
        const newDistrictIds = [...selectedDistrictIds];
        newDistrictIds[index] = '';
        setSelectedDistrictIds(newDistrictIds);

        // Reset form data untuk kecamatan dan desa
        setFormData(prev => ({
          ...prev,
          address: prev.address.map((addr, idx) =>
            idx === index
              ? {
                  ...addr,
                  district: 0,
                  village: 0
                }
              : addr
          )
        }));
      }

      if (field === 'district') {
        const newDistrictIds = [...selectedDistrictIds];
        newDistrictIds[index] = value;
        setSelectedDistrictIds(newDistrictIds);

        // Reset desa untuk alamat ini
        setFormData(prev => ({
          ...prev,
          address: prev.address.map((addr, idx) =>
            idx === index
              ? {
                  ...addr,
                  village: 0
                }
              : addr
          )
        }));
      }
    }

    // Existing form data update logic
    setFormData(prevData => {
      if (name.includes('[')) {
        const [arrayField, indexAndField] = name.split('[');
        const index = parseInt(indexAndField.split(']')[0]);
        const fieldName = indexAndField.split('.')[1];

        return {
          ...prevData,
          [arrayField]: Array.isArray(prevData[arrayField as keyof FormData])
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (prevData[arrayField as keyof FormData] as any[]).map(
                (item, idx) =>
                  idx === index ? { ...item, [fieldName]: value } : item
              )
            : prevData[arrayField as keyof FormData]
        };
      }

      return {
        ...prevData,
        [name]: value
      };
    });
  };

  // Modifikasi fungsi handleTemplateFieldChange untuk menangani date dengan benar
  const handleTemplateFieldChange = (
    field: CustomerTemplateFieldDetail,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    setFormData(prev => {
      // Cari response yang sudah ada untuk field ini
      const existingResponseIndex = prev.customerTemplateResponse?.findIndex(
        resp => resp.fieldId === Number(field.id)
      );

      const newResponse: CustomerTemplateResponseData = {
        fieldId: Number(field.id),
        responseValue: '',
        optionResponse: []
      };

      switch (field.fieldType.code) {
        case 'DATE': {
          // Konversi tanggal ke format ISO string
          const dateValue = value ? new Date(value).toISOString() : '';
          newResponse.responseValue = dateValue;
          break;
        }
        case 'CHECKBOXES': {
          newResponse.optionResponse = value;
          break;
        }
        case 'RADIO_BUTTONS':
        case 'DROPDOWN_LIST': {
          const selectedOption = field.customerTemplateFieldOption?.find(
            opt => opt.optionValue === value
          );
          if (selectedOption) {
            newResponse.optionResponse = field.customerTemplateFieldOption.map(
              opt => ({
                optionId: Number(opt.id),
                selectedChoice: opt.optionValue === value
              })
            );
          }
          break;
        }
        case 'SINGLE_LINE_TEXT':
        case 'MULTIPLE_LINE_TEXT':
        case 'MULTI_SELECT_LIST': {
          newResponse.responseValue = value;
          break;
        }
      }

      const newCustomerTemplateResponse = [
        ...(prev.customerTemplateResponse || [])
      ];
      if (existingResponseIndex >= 0) {
        newCustomerTemplateResponse[existingResponseIndex] = newResponse;
      } else {
        newCustomerTemplateResponse.push(newResponse);
      }

      return {
        ...prev,
        customerTemplateResponse: newCustomerTemplateResponse
      };
    });
  };

  // Ambil semua template yang tersedia dengan refetch
  const { data: templates, refetch: refetchTemplates } =
    useGetCustomerTemplateQuery({
      page: 1,
      limit: 100
    });

  // Ambil semua field sekaligus dengan refetch
  const { data: allFields, refetch: refetchFields } =
    useGetCustomerTemplateFieldQuery(0);

  // Tambahkan useEffect untuk merefetch data setiap modal dibuka
  useEffect(() => {
    if (show) {
      refetchTemplates();
      refetchFields();
    }
  }, [show, refetchTemplates, refetchFields]);

  // Tambahkan useEffect untuk menginisialisasi nilai default dari template fields
  useEffect(() => {
    if (allFields?.data) {
      const defaultResponses: CustomerTemplateResponseData[] = [];

      allFields.data.forEach(field => {
        if (field.customerTemplateFieldOption) {
          // Cek apakah ada opsi yang memiliki selectedChoice true
          const defaultOption = field.customerTemplateFieldOption.find(
            opt => opt.selectedChoice
          );

          if (defaultOption) {
            switch (field.fieldType.code) {
              case 'CHECKBOXES': {
                // Untuk checkbox, kita perlu menyimpan semua opsi dengan status selectedChoice masing-masing
                const optionResponses = field.customerTemplateFieldOption.map(
                  opt => ({
                    optionId: Number(opt.id),
                    selectedChoice: opt.selectedChoice
                  })
                );

                defaultResponses.push({
                  fieldId: Number(field.id),
                  responseValue: '',
                  optionResponse: optionResponses
                });
                break;
              }
              case 'RADIO_BUTTONS':
              case 'DROPDOWN_LIST': {
                // Untuk radio dan dropdown, kita hanya perlu menyimpan opsi yang selectedChoice-nya true
                defaultResponses.push({
                  fieldId: Number(field.id),
                  responseValue: '',
                  optionResponse: field.customerTemplateFieldOption.map(
                    opt => ({
                      optionId: Number(opt.id),
                      selectedChoice: opt.selectedChoice
                    })
                  )
                });
                break;
              }
            }
          }
        }
      });

      // Update formData dengan nilai default
      if (defaultResponses.length > 0) {
        setFormData(prev => ({
          ...prev,
          customerTemplateResponse: defaultResponses
        }));
      }
    }
  }, [allFields?.data]);

  const isFieldInvalid = (field: CustomerTemplateFieldDetail) => {
    if (!field.isRequired) return false;

    const response = formData.customerTemplateResponse.find(
      resp => resp.fieldId === Number(field.id)
    );

    return !response || !response.responseValue;
  };
  // Modifikasi renderDynamicField untuk menampilkan tanggal dengan benar
  const renderDynamicField = (field: CustomerTemplateFieldDetail) => {
    // Cari nilai dari customerTemplateResponse
    const fieldResponse = formData.customerTemplateResponse.find(
      resp => resp.fieldId === Number(field.id)
    );

    // Untuk date, konversi ISO string ke format YYYY-MM-DD untuk input type="date"
    const fieldValue = (() => {
      if (fieldResponse) {
        if (field.fieldType.code === 'DATE' && fieldResponse.responseValue) {
          // Konversi ISO string ke format YYYY-MM-DD
          return new Date(fieldResponse.responseValue)
            .toISOString()
            .split('T')[0];
        }
        if (fieldResponse.responseValue) {
          return fieldResponse.responseValue;
        }
        if (fieldResponse.optionResponse?.length) {
          const selectedOption = field.customerTemplateFieldOption?.find(
            opt =>
              fieldResponse.optionResponse?.find(
                resp => resp.optionId === Number(opt.id) && resp.selectedChoice
              )
          );
          return selectedOption?.optionValue || '';
        }
      } else {
        // Jika belum ada response, cek apakah ada nilai default dari selectedChoice
        const defaultOption = field.customerTemplateFieldOption?.find(
          opt => opt.selectedChoice
        );
        return defaultOption?.optionValue || '';
      }
      return '';
    })();

    // Untuk checkbox, kita perlu mengetahui status checked untuk setiap opsi
    const getCheckboxStatus = (optionId: string) => {
      if (fieldResponse) {
        return (
          fieldResponse.optionResponse?.find(
            resp => resp.optionId === Number(optionId)
          )?.selectedChoice || false
        );
      }
      // Jika belum ada response, gunakan selectedChoice dari opsi
      const option = field.customerTemplateFieldOption?.find(
        opt => opt.id === optionId
      );
      return option?.selectedChoice || false;
    };

    // Modifikasi bagian pengurutan options
    const sortedOptions = field.customerTemplateFieldOption
      ? [...field.customerTemplateFieldOption].sort((a, b) => {
          return a.optionValue.localeCompare(b.optionValue);
        })
      : [];

    switch (field.fieldType.code) {
      case 'RADIO_BUTTONS':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div>
              {sortedOptions.map((option, idx) => (
                <Form.Check
                  key={idx}
                  type="radio"
                  id={`${field.fieldName}-${idx}`}
                  name={field.fieldName}
                  label={option.optionValue}
                  value={option.optionValue}
                  checked={fieldValue === option.optionValue}
                  onChange={e =>
                    handleTemplateFieldChange(field, e.target.value)
                  }
                  required={field.isRequired}
                  isInvalid={validated && isFieldInvalid(field)}
                />
              ))}
            </div>
          </Form.Group>
        );

      case 'DROPDOWN_LIST':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Select
              name={field.fieldName}
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              required={field.isRequired}
              isInvalid={validated && isFieldInvalid(field)}
            >
              <option value="">Pilih {field.label}</option>
              {sortedOptions.map((option, idx) => (
                <option key={idx} value={option.optionValue}>
                  {option.optionValue}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );

      case 'CHECKBOXES':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div>
              {sortedOptions.map((option, idx) => (
                <Form.Check
                  key={idx}
                  type="checkbox"
                  id={`${field.fieldName}-${idx}`}
                  name={field.fieldName}
                  label={option.optionValue}
                  checked={getCheckboxStatus(option.id)}
                  isInvalid={validated && isFieldInvalid(field)}
                  onChange={e => {
                    const currentResponse =
                      formData.customerTemplateResponse?.find(
                        resp => resp.fieldId === Number(field.id)
                      );

                    // Buat array optionResponse baru dengan nilai yang diupdate
                    const newOptionResponses =
                      field.customerTemplateFieldOption.map(opt => ({
                        optionId: Number(opt.id),
                        selectedChoice:
                          opt.id === option.id
                            ? e.target.checked // Update nilai checkbox yang diubah
                            : currentResponse?.optionResponse?.find(
                                resp => resp.optionId === Number(opt.id)
                              )?.selectedChoice ?? opt.selectedChoice // Pertahankan nilai checkbox lain
                      }));

                    handleTemplateFieldChange(field, newOptionResponses);
                  }}
                  required={field.isRequired}
                />
              ))}
            </div>
          </Form.Group>
        );

      case 'MULTIPLE_LINE_TEXT':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name={field.fieldName}
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              placeholder={`Masukkan ${field.label}`}
              required={field.isRequired}
              isInvalid={validated && isFieldInvalid(field)}
            />
          </Form.Group>
        );

      case 'SINGLE_LINE_TEXT':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type="text"
              name={field.fieldName}
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              placeholder={`Masukkan ${field.label}`}
              required={field.isRequired}
              isInvalid={validated && isFieldInvalid(field)}
            />
          </Form.Group>
        );

      case 'MULTI_SELECT_LIST':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Select
              isMulti
              name={field.fieldName}
              className="custom-select"
              classNamePrefix="select"
              styles={{
                option: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                multiValue: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                clearIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                })
              }}
              value={field.customerTemplateFieldOption
                .filter(option => fieldValue.includes(option.optionValue))
                .map(option => ({
                  value: option.optionValue,
                  label: option.optionValue
                }))}
              onChange={selectedOptions => {
                const selectedValues = selectedOptions.map(
                  option => option.value
                );
                handleTemplateFieldChange(field, selectedValues);
              }}
              options={field.customerTemplateFieldOption.map(option => ({
                value: option.optionValue,
                label: option.optionValue
              }))}
              placeholder={`Pilih ${field.label}`}
            />
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      case 'DATE':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type="date"
              name={field.fieldName}
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              required={field.isRequired}
            />
          </Form.Group>
        );

      default:
        return null;
    }
  };

  // Modifikasi handleSubmit untuk menyimpan data template fields
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidated(true);

    if (!validateSections()) {
      showCustomAlert({
        icon: 'error',
        label: 'Validasi Gagal',
        deskripsi:
          'Silakan periksa kembali form anda dan isi semua field yang wajib diisi',
        buttonType: 'ok'
      });
      return;
    }

    try {
      const transformedData = {
        ...formData,
        gender: formData.gender === 'male' ? 'M' : 'F',
        dateOfBirth: new Date(formData.dateOfBirth).toISOString(),
        joinDate: new Date(formData.joinDate).toISOString(),
        clinicId: Number(formData.clinicId),
        address: formData.address.map(addr => ({
          ...addr,
          province: Number(addr.province),
          city: Number(addr.city),
          district: Number(addr.district),
          village: Number(addr.village)
        }))
      };

      showCustomAlert({
        icon: 'question',
        label: 'Konfirmasi Submit',
        deskripsi: 'Apakah anda yakin ingin menyimpan data pelanggan ini?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            const response = await addCustomer(transformedData).unwrap();

            // Jika ada customerTemplateResponse, kirim ke endpoint terpisah
            if (transformedData.customerTemplateResponse?.length > 0) {
              await customerTemplateApi.endpoints.addCustomerTemplateResponse.initiate(
                {
                  customerId: response.data.id,
                  responses: transformedData.customerTemplateResponse
                }
              );
            }

            showCustomAlert({
              icon: 'success',
              label: 'Berhasil!',
              deskripsi: response.message || 'Data client berhasil ditambahkan',
              buttonType: 'ok',
              onConfirm: () => {
                handleClose();
                onSuccess?.();
              }
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            console.error('Error:', error);
            showCustomAlert({
              icon: 'error',
              label: 'Gagal!',
              deskripsi:
                error?.data?.message || 'Gagal menambahkan data client',
              buttonType: 'ok'
            });
          }
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSaveClick = () => {
    const form = document.getElementById('addClientForm') as HTMLFormElement;
    if (form) {
      form.requestSubmit();
    }
  };

  const [activeTab, setActiveTab] = useState('alamat');

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  // Modifikasi handleCancelClick
  const handleCancelClick = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Batal',
      deskripsi:
        'Apakah anda yakin ingin membatalkan? Semua perubahan akan hilang',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormData(initialFormState); // Reset form ke nilai awal
        setValidated(false);
        setSelectedProvinceIds([]);
        setSelectedRegencyIds([]);
        setSelectedDistrictIds([]);
        setSectionErrors({
          personalInfo: [],
          address: [],
          contact: [],
          template: []
        });
        setShowErrorDetails({
          personalInfo: false,
          address: false,
          contact: false
        });
        setActiveTab('alamat');
        handleClose();
      }
    });
  };

  // Modifikasi fungsi renderTemplateSection
  const renderTemplateSection = (template: CustomerTemplate) => {
    if (!allFields?.data) return null;

    // Filter field berdasarkan template.customerTemplateFields dan pastikan hanya mengambil field yang benar-benar milik template ini
    const templateFieldNames = template.customerTemplateFields.map(
      f => f.fieldName
    );
    const customerFields = allFields.data.filter(
      field =>
        field.fieldGroup === 'customer' &&
        templateFieldNames.includes(field.fieldName) &&
        // Gunakan id field untuk pengecekan
        template.customerTemplateFields.some(
          tf =>
            Number(field.id) === Number(tf.id) &&
            tf.fieldName === field.fieldName
        )
    );

    if (customerFields.length === 0) return null;

    return (
      <div key={template.id} className="mb-4">
        <h5 className="mb-3">{template.templateName}</h5>
        {customerFields.map((field, idx) => (
          <div key={`${template.id}-${idx}`}>{renderDynamicField(field)}</div>
        ))}
      </div>
    );
  };

  return (
    <>
      <style>
        {`
          /* Styling untuk alert error di bagian atas */
          .alert-danger {
            color: rgba(132, 32, 41, 0.8) !important;
            background-color: #f8d7da80 !important;
            border-color: #f5c2c7aa !important;
          }

          /* Styling untuk link "lihat" */
          .text-danger.text-decoration-none {
            text-decoration: underline !important;
            opacity: 0.8;
          }
          .text-danger.text-decoration-none:hover {
            opacity: 1;
          }

          /* Styling untuk label */
          .form-label {
            font-weight: 800;
            font-size: 0.8rem;
            margin-bottom: 0.3rem;
            margin-left: 0rem;
          }

          /* Menghapus margin yang tidak perlu */
          .form-group {
            margin-bottom: 0.5rem;
          }

          .alert {
            border-radius: 8px;
          }
          .alert ul {
            margin-bottom: 0;
          }
          .error-section {
            margin-bottom: 0.5rem;
          }
          .error-section:last-child {
            margin-bottom: 0;
          }
          .alert .fw-bold {
            font-size: 1.1em;
            color: #842029;
          }

          /* Styling untuk radio button */
          .form-check {
            margin: 0;
          }
          
          .form-check-input {
            margin-top: 0;
          }
          
          .form-check-label {
            margin-bottom: 0;
          }

          /* Tambahkan style untuk loading state */
          .loading {
            opacity: 0.6;
            pointer-events: none;
            transition: opacity 0.3s ease;
          }
          
          .content {
            transition: opacity 0.3s ease;
          }

          .loading-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-body {
            position: relative;
          }

          /* Perbaiki animasi highlight */
          @keyframes highlight {
            0% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.4); /* Warna merah dari bootstrap */
            }
            50% {
              transform: scale(1.02);
              box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
            }
          }

          .highlight-animation {
            animation: highlight 1s ease-in-out;
            z-index: 1;
            position: relative;
          }

          /* Tambahkan style untuk focus state */
          input:focus, select:focus {
            box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
            border-color: #dc3545 !important;
          }

          /* Tambahkan style untuk container form di dalam tab */
          .tab-pane {
            padding: 1rem;
            border-radius: 0.5rem;
            opacity: 0;
          }

          .tab-pane.active {
            opacity: 1;
          }

          /* Pastikan transisi tetap smooth */
          .tab-content {
            transition: opacity 0.15s linear;
          }

          /* Ensure smooth tab transitions */
          .fade {
            transition: opacity 0.15s linear;
          }

          .tab-content > .tab-pane {
            display: none;
          }

          .tab-content > .active {
            display: block;
          }

          /* Update animasi highlight */
          @keyframes highlight {
            0% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.4);
            }
            50% {
              transform: scale(1.02);
              box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
            }
          }

          .highlight-animation {
            animation: highlight 1s ease-in-out;
            z-index: 1000; /* Pastikan element yang di-highlight selalu di atas */
            position: relative;
          }

          /* Improve focus styles */
          input:focus, 
          select:focus {
            box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
            border-color: #dc3545 !important;
            outline: none !important;
          }

          /* Ensure smooth tab transitions */
          .fade {
            transition: opacity 0.15s linear;
          }

          .tab-content > .tab-pane {
            display: none;
          }

          .tab-content > .active {
            display: block;
          }

          /* Style untuk pembatas vertikal */
          .vertical-divider {
            width: 1px;
            background-color: #CBD0DD;
            margin: 0 24px;
            height: auto;
            align-self: stretch;
          }

          /* Pastikan Row memiliki display flex */
          .row {
            display: flex;
            align-items: stretch;
          }

          /* Style untuk container pembatas */
          .divider-container {
            position: relative;
          }

          /* Style untuk pembatas vertikal */
          .vertical-divider {
            position: absolute;
            left: 48.5%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }

          /* Pastikan Row memiliki position relative */
          .row {
            position: relative;
          }
        `}
      </style>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="me-auto fw-bolder">
            Tambah Pelanggan
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="btn-link"
              className="text-danger"
              onClick={handleCancelClick}
            >
              Batalkan
            </Button>
            <Button
              variant="outline-primary"
              type="submit"
              form="addClientForm"
              onClick={handleSaveClick}
            >
              Simpan & Buat Baru
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="addClientForm"
              onClick={handleSaveClick}
            >
              Simpan
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="bg-light px-5 py-4">
          {(sectionErrors.personalInfo.length > 0 ||
            sectionErrors.address.length > 0 ||
            sectionErrors.contact.length > 0 ||
            sectionErrors.template.length > 0) && (
            <div className="alert alert-danger mb-4">
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-exclamation-circle"></i>
                <strong>
                  Harap perbaiki masalah berikut dan coba kembali:
                </strong>
              </div>

              {sectionErrors.personalInfo.length > 0 && (
                <div className="error-section">
                  <div className="d-flex align-items-center">
                    <span>Informasi pribadi masih belum lengkap.</span>
                    <Button
                      variant="link"
                      className="text-danger p-0 ms-1 text-decoration-none"
                      onClick={() => handleErrorClick('personalInfo')}
                    >
                      lihat
                    </Button>
                  </div>
                </div>
              )}

              {sectionErrors.address.length > 0 && (
                <div className="error-section">
                  <div className="d-flex align-items-center">
                    <span>Alamat masih belum lengkap.</span>
                    <Button
                      variant="link"
                      className="text-danger p-0 ms-1 text-decoration-none"
                      onClick={() => handleErrorClick('address')}
                    >
                      lihat
                    </Button>
                  </div>
                </div>
              )}

              {sectionErrors.contact.length > 0 && (
                <div className="error-section">
                  <div className="d-flex align-items-center">
                    <span>Telepon pribadi masih belum lengkap.</span>
                    <Button
                      variant="link"
                      className="text-danger p-0 ms-1 text-decoration-none"
                      onClick={() => handleErrorClick('contact')}
                    >
                      lihat
                    </Button>
                  </div>
                </div>
              )}

              {sectionErrors.template.length > 0 && (
                <div className="error-section">
                  <div className="d-flex align-items-center">
                    <span>Form template masih belum lengkap:</span>
                    <Button
                      variant="link"
                      className="text-danger p-0 ms-1 text-decoration-none"
                      onClick={() => {
                        setActiveTab('infoTambahan');
                        setTimeout(() => {
                          const firstErrorField =
                            document.querySelector('.is-invalid');
                          if (firstErrorField) {
                            firstErrorField.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center'
                            });
                          }
                        }, 100);
                      }}
                    >
                      lihat
                    </Button>
                  </div>
                  <ul className="mb-0 ps-3">
                    {sectionErrors.template.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          <Form
            id="addClientForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row className="divider-container">
              <Col md={6} className="d-flex flex-column gap-3">
                <h4 className="mt-2">Informasi Pribadi</h4>
                <Row>
                  <Col
                    md={4}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Avatar size="4xl" />
                  </Col>
                  <Col md={8}>
                    <Form.Group controlId="firstName">
                      <Form.Label className="p-0 text-capitalize">
                        Nama Depan <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        placeholder="Masukkan nama depan"
                        isInvalid={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama depan harus diisi.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="lastName" className="mt-2">
                      <Form.Label className="p-0 text-capitalize">
                        Nama Belakang
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Masukkan nama belakang"
                        isInvalid={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        Nama belakang harus diisi.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={12}>
                    <Form.Group controlId="customerType">
                      <Form.Label className="p-0 text-capitalize">
                        Tipe <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="d-flex align-items-center gap-3 mt-1">
                        <Form.Check
                          inline
                          type="radio"
                          label="Perorangan"
                          name="customerType"
                          value="Individual"
                          checked={formData.customerType === 'Individual'}
                          onChange={handleChange}
                          required
                          className="d-flex align-items-center gap-2"
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Bisnis"
                          name="customerType"
                          value="Business"
                          checked={formData.customerType === 'Business'}
                          onChange={handleChange}
                          required
                          className="d-flex align-items-center gap-2"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="gender">
                      <Form.Label className="p-0 text-capitalize">
                        Jenis Kelamin <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Pilih jenis kelamin</option>
                        <option value="male">Laki-laki</option>
                        <option value="female">Perempuan</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="religion">
                      <Form.Label className="p-0 text-capitalize">
                        Agama <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="religion"
                        value={formData.religion}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Pilih agama</option>
                        <option value="islam">Islam</option>
                        <option value="kristen">Kristen</option>
                        <option value="hindu">Hindu</option>
                        <option value="buddha ">Buddha</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="maritalStatus">
                      <Form.Label className="p-0 text-capitalize">
                        Status Perkawinan <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="maritalStatus"
                        value={formData.maritalStatus}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Pilih status perkawinan</option>
                        <option value="single">Belum Menikah</option>
                        <option value="married">Menikah</option>
                        <option value="divorced">Cerai</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="clientGroup">
                      <Form.Label className="p-0 text-capitalize">
                        Jenis Pelanggan
                      </Form.Label>
                      <Form.Select
                        name="clientGroup"
                        value={formData.clientGroup}
                        onChange={handleChange}
                      >
                        <option value="">Pilih grup klien</option>
                        <option value="vip">VIP</option>
                        <option value="regular">Regular</option>
                        <option value="new">Baru</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="idType">
                      <Form.Label className="p-0 text-capitalize">
                        Tipe Identifikasi <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="idType"
                        value={formData.idType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Pilih tipe identifikasi</option>
                        <option value="ktp">KTP</option>
                        <option value="sim">SIM</option>
                        <option value="passport">Passport</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="idNumber">
                      <Form.Label className="p-0 text-capitalize">
                        Nomor Identifikasi{' '}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="idNumber"
                        value={formData.idNumber}
                        onChange={handleChange}
                        placeholder="Masukkan nomor identifikasi"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="dateOfBirth">
                  <Form.Label className="p-0 text-capitalize">
                    Tanggal Lahir <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="occupation">
                      <Form.Label className="p-0 text-capitalize">
                        Pekerjaan
                      </Form.Label>
                      <Form.Select
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                      >
                        <option value="">Pilih pekerjaan</option>
                        <option value="karyawan">Karyawan</option>
                        <option value="wiraswasta">Wiraswasta</option>
                        <option value="freelancer">Freelancer</option>
                        <option value="mahasiswa">Mahasiswa</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="branch">
                      <Form.Label className="p-0 text-capitalize">
                        Cabang <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="branch"
                        value={formData.branch}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Pilih cabang</option>
                        <option value="jakarta">Jakarta</option>
                        <option value="bandung">Bandung</option>
                        <option value="surabaya">Surabaya</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={12}>
                    <Form.Group controlId="joinDate">
                      <Form.Label className="p-0 text-capitalize">
                        Tanggal Bergabung <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="joinDate"
                        value={formData.joinDate}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Tab.Container activeKey={activeTab} onSelect={handleSelect}>
                  <Nav
                    variant="tabs"
                    className="mb-1 px-3 d-flex flex-nowrap gap-1"
                  >
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill"
                        variant={
                          activeTab === 'alamat' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => handleSelect('alamat')}
                      >
                        <FontAwesomeIcon
                          icon={faMapLocation}
                          className="me-2"
                        />
                        Alamat
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill"
                        variant={
                          activeTab === 'kontak' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => handleSelect('kontak')}
                      >
                        <FontAwesomeIcon
                          icon={faContactBook}
                          className="me-2"
                        />
                        Kontak
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill"
                        variant={
                          activeTab === 'payment'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => handleSelect('payment')}
                      >
                        <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                        Payment
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill"
                        variant={
                          activeTab === 'infoTambahan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => handleSelect('infoTambahan')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Form Templat
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="alamat">
                      {formData.address.map((address, index) => {
                        const queries = locationQueries[index] || {
                          regencies: [],
                          districts: [],
                          villages: [],
                          isLoadingRegencies: false,
                          isLoadingDistricts: false,
                          isLoadingVillages: false
                        };

                        return (
                          <div
                            key={index}
                            className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h4 className="mt-1">Alamat {index + 1}</h4>
                              {formData.address.length > 1 && (
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  size="sm"
                                  onClick={() => removeAddress(index)}
                                >
                                  Hapus Alamat
                                </Button>
                              )}
                            </div>
                            {/* Form fields untuk alamat, gunakan `address[${index}]` untuk name */}
                            <Form.Group
                              controlId={`address[${index}].streetAddress`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Alamat <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`address[${index}].streetAddress`}
                                value={formData.address[index]?.streetAddress}
                                onChange={handleChange}
                                placeholder="Masukkan alamat jalan"
                                required
                              />
                            </Form.Group>
                            <Form.Group controlId="country">
                              <Form.Label className="p-0 text-capitalize">
                                Negara <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                name={`address[${index}].country`}
                                value={formData.address[index]?.country || ''}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Pilih negara</option>
                                <option value="indonesia">Indonesia</option>
                                <option value="singapore">Singapura</option>
                                <option value="malaysia">Malaysia</option>
                                <option value="thailand">Thailand</option>
                              </Form.Select>
                            </Form.Group>
                            <Row>
                              <Col md={6}>
                                <Form.Group controlId="province">
                                  <Form.Label className="p-0 text-capitalize">
                                    Provinsi
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].province`}
                                    value={address.province || ''}
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        province: Number(e.target.value),
                                        city: 0,
                                        district: 0,
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                  >
                                    <option value="">Pilih Provinsi</option>
                                    {provincesResponse?.data?.map(province => (
                                      <option
                                        key={province.id}
                                        value={province.id}
                                      >
                                        {province.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="p-0 text-capitalize">
                                    Kota <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].city`}
                                    value={formData.address[index]?.city || ''}
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        city: Number(e.target.value),
                                        district: 0,
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={
                                      !formData.address[index]?.province
                                    }
                                  >
                                    <option value="">Pilih Kota</option>
                                    {queries.regencies.map(city => (
                                      <option key={city.id} value={city.id}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="p-0 text-capitalize">
                                    Kecamatan{' '}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].district`}
                                    value={
                                      formData.address[index]?.district || ''
                                    }
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        district: Number(e.target.value),
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={!formData.address[index]?.city}
                                  >
                                    <option value="">Pilih Kecamatan</option>
                                    {queries.districts.map(district => (
                                      <option
                                        key={district.id}
                                        value={district.id}
                                      >
                                        {district.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="p-0 text-capitalize">
                                    Desa/Kelurahan{' '}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].village`}
                                    value={
                                      formData.address[index]?.village || ''
                                    }
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        village: Number(e.target.value)
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={
                                      !formData.address[index]?.district
                                    }
                                  >
                                    <option value="">
                                      Pilih Desa/Kelurahan
                                    </option>
                                    {queries.villages.map(village => (
                                      <option
                                        key={village.id}
                                        value={village.id}
                                      >
                                        {village.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group controlId="postalCode">
                              <Form.Label className="p-0 text-capitalize">
                                Kode Pos
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`address[${index}].postalCode`}
                                value={
                                  formData.address[index]?.postalCode || ''
                                }
                                onChange={handleChange}
                                placeholder="Masukkan Kode Pos"
                              />
                            </Form.Group>

                            <Form.Group controlId="addressType">
                              <Form.Label className="p-0 text-capitalize">
                                Jenis Alamat{' '}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                name={`address[${index}].addressType`}
                                value={
                                  formData.address[index]?.addressType || ''
                                }
                                onChange={handleChange}
                                required
                              >
                                <option value="">Pilih jenis alamat</option>
                                <option value="Rumah">Rumah</option>
                                <option value="Kos">Kos</option>
                                <option value="Apartemen">Apartemen</option>
                                <option value="Lainnya">Lainnya</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        );
                      })}

                      <Button
                        variant="outline-primary"
                        className="mt-2 mb-2 w-100"
                        onClick={addNewAddress}
                      >
                        + Tambah Alamat
                      </Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="kontak">
                      <div className="d-flex flex-column gap-2">
                        <h4 className="mt-2">Telepon Pribadi</h4>
                        {formData.phoneNumber.map((phone, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mt-2">Telepon {index + 1}</h5>
                              {formData.phoneNumber.length > 1 && (
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  size="sm"
                                  onClick={() => removePhoneNumber(index)}
                                >
                                  Hapus Alamat
                                </Button>
                              )}
                            </div>
                            <Form.Group
                              controlId={`phoneNumber[${index}].phoneNumber`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Nomor Telepon{' '}
                                {index === 0 && (
                                  <span className="text-danger">*</span>
                                )}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`phoneNumber[${index}].phoneNumber`}
                                value={phone.phoneNumber}
                                onChange={handleChange}
                                placeholder="Masukkan Nomor Telepon"
                                required={index === 0}
                              />
                            </Form.Group>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  controlId={`phoneNumber[${index}].usageType`}
                                >
                                  <Form.Label className="p-0 text-capitalize">
                                    Jenis Penggunaan{' '}
                                    {index === 0 && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </Form.Label>
                                  <Form.Select
                                    name={`phoneNumber[${index}].usageType`}
                                    value={phone.usageType}
                                    onChange={handleChange}
                                    required={index === 0}
                                  >
                                    <option value="">
                                      Pilih jenis penggunaan
                                    </option>
                                    <option value="Individual">Pribadi</option>
                                    <option value="Corporate/Business">
                                      Bisnis
                                    </option>
                                    <option value="Group">Grup</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  controlId={`phoneNumber[${index}].isWhatsapp`}
                                >
                                  <Form.Label className="p-0 text-capitalize">
                                    Terhubung WhatsApp
                                  </Form.Label>
                                  <div className="d-flex align-items-center gap-3 mt-1">
                                    <Form.Check
                                      inline
                                      label="Ya"
                                      type="radio"
                                      name={`phoneNumber[${index}].isWhatsapp`}
                                      checked={phone.isWhatsapp === true}
                                      onChange={() => {
                                        handleChange({
                                          target: {
                                            name: `phoneNumber[${index}].isWhatsapp`,
                                            value: true
                                          }
                                        } as unknown as ChangeEvent<HTMLInputElement>);
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    />
                                    <Form.Check
                                      inline
                                      label="Tidak"
                                      type="radio"
                                      name={`phoneNumber[${index}].isWhatsapp`}
                                      checked={phone.isWhatsapp === false}
                                      onChange={() => {
                                        handleChange({
                                          target: {
                                            name: `phoneNumber[${index}].isWhatsapp`,
                                            value: false
                                          }
                                        } as unknown as ChangeEvent<HTMLInputElement>);
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        ))}

                        <Button
                          variant="outline-primary"
                          className="mb-2 w-100"
                          onClick={addNewPhoneNumber}
                        >
                          + Tambah Telepon
                        </Button>

                        {formData.emergencyContact.map((contact, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mt-2">
                                Kontak Darurat {index + 1}
                              </h5>
                              {formData.emergencyContact.length > 1 && (
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  size="sm"
                                  onClick={() => removeEmergencyContact(index)}
                                >
                                  Hapus Alamat
                                </Button>
                              )}
                            </div>
                            <Form.Group
                              controlId={`emergencyContact[${index}].contactName`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Nama Kontak Darurat
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`emergencyContact[${index}].contactName`}
                                value={contact.contactName}
                                onChange={handleChange}
                                placeholder="Masukkan nama kontak darurat"
                              />
                            </Form.Group>

                            <Form.Group
                              controlId={`emergencyContact[${index}].phoneNumber`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Nomor Telepon
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`emergencyContact[${index}].phoneNumber`}
                                value={contact.phoneNumber}
                                onChange={handleChange}
                                placeholder="Masukkan nomor telepon"
                              />
                            </Form.Group>

                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  controlId={`emergencyContact[${index}].relationshipStatus`}
                                >
                                  <Form.Label className="p-0 text-capitalize">
                                    Status Hubungan
                                  </Form.Label>
                                  <Form.Select
                                    name={`emergencyContact[${index}].relationshipStatus`}
                                    value={contact.relationshipStatus}
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      Pilih status hubungan
                                    </option>
                                    <option value="family">Keluarga</option>
                                    <option value="friend">Teman</option>
                                    <option value="colleague">
                                      Rekan Kerja
                                    </option>
                                    <option value="other">Lainnya</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  controlId={`emergencyContact[${index}].isWhatsapp`}
                                >
                                  <Form.Label className="p-0 text-capitalize">
                                    Terhubung WhatsApp
                                  </Form.Label>
                                  <div className="d-flex align-items-center gap-3 mt-1">
                                    <Form.Check
                                      inline
                                      label="Ya"
                                      type="radio"
                                      name={`emergencyContact[${index}].isWhatsapp`}
                                      checked={contact.isWhatsapp === true}
                                      onChange={() => {
                                        handleChange({
                                          target: {
                                            name: `emergencyContact[${index}].isWhatsapp`,
                                            value: true
                                          }
                                        } as unknown as ChangeEvent<HTMLInputElement>);
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    />
                                    <Form.Check
                                      inline
                                      label="Tidak"
                                      type="radio"
                                      name={`emergencyContact[${index}].isWhatsapp`}
                                      checked={contact.isWhatsapp === false}
                                      onChange={() => {
                                        handleChange({
                                          target: {
                                            name: `emergencyContact[${index}].isWhatsapp`,
                                            value: false
                                          }
                                        } as unknown as ChangeEvent<HTMLInputElement>);
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group
                              controlId={`emergencyContact[${index}].address`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Alamat
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name={`emergencyContact[${index}].address`}
                                value={contact.address}
                                onChange={handleChange}
                                placeholder="Masukkan alamat kontak darurat"
                              />
                            </Form.Group>
                          </div>
                        ))}

                        <Button
                          variant="outline-primary"
                          className="mb-2 w-100"
                          onClick={addNewEmergencyContact}
                        >
                          + Tambah Kontak Darurat
                        </Button>

                        {formData.email.map((emailData, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mt-2">Email {index + 1}</h5>
                              {formData.email.length > 1 && (
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  size="sm"
                                  onClick={() => removeEmail(index)}
                                >
                                  Hapus Alamat
                                </Button>
                              )}
                            </div>
                            <Form.Group
                              controlId={`email[${index}].emailAddress`}
                            >
                              <Form.Label className="p-0 text-capitalize">
                                Alamat Email
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`email[${index}].emailAddress`}
                                value={emailData.emailAddress}
                                onChange={handleChange}
                                placeholder="Masukan alamat email"
                              />
                            </Form.Group>

                            <Form.Group controlId={`email[${index}].usageType`}>
                              <Form.Label className="p-0 text-capitalize">
                                Jenis Penggunaan
                              </Form.Label>
                              <Form.Select
                                name={`email[${index}].usageType`}
                                value={emailData.usageType}
                                onChange={handleChange}
                              >
                                <option value="">Pilih jenis penggunaan</option>
                                <option value="personal">Pribadi</option>
                                <option value="work">Pekerjaan</option>
                                <option value="other">Lainnya</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        ))}

                        <Button
                          variant="outline-primary"
                          className="mb-2 w-100"
                          onClick={addNewEmail}
                        >
                          + Tambah Email
                        </Button>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="payment">
                      <h4 className="mt-2">Payment</h4>
                      <div className="text-muted text-center py-4">
                        Belum ada informasi payment yang harus ditambahkan
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="infoTambahan">
                      <div className="d-flex flex-column gap-3">
                        {templates?.data.result
                          .filter(template => template.status)
                          .map(template => renderTemplateSection(template))}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
