import Swal, { SweetAlertIcon } from 'sweetalert2';

// Tipe untuk parameter showCustomAlert
interface CustomAlertProps {
  icon: SweetAlertIcon; // Tipe icon bawaan SweetAlert
  label: string; // Judul alert
  deskripsi: string; // Deskripsi alert
  buttonType: 'ok' | 'yes-no' | 'cancel-yes-no'; // Jenis tombol
  onConfirm?: (() => void) | (() => Promise<void>); // Fungsi saat tombol konfirmasi diklik
  onCancel?: () => void; // Fungsi saat tombol batal diklik
}

// Fungsi helper untuk menampilkan SweetAlert
export const showCustomAlert = ({
  icon,
  label,
  deskripsi,
  buttonType,
  onConfirm,
  onCancel
}: CustomAlertProps) => {
  // Konfigurasi tombol dasar
  let buttonsConfig: {
    confirmButtonText: string;
    showCancelButton: boolean;
    showDenyButton: boolean;
    cancelButtonText?: string;
    denyButtonText?: string;
    reverseButtons?: boolean;
  } = {
    confirmButtonText: 'OK',
    showCancelButton: false,
    showDenyButton: false
  };

  // Atur tombol berdasarkan jenis buttonType
  if (buttonType === 'yes-no') {
    buttonsConfig = {
      confirmButtonText: 'Ya',
      denyButtonText: 'Tidak',
      showDenyButton: true,
      showCancelButton: false,
      reverseButtons: true // Tukar posisi tombol
    };
  } else if (buttonType === 'cancel-yes-no') {
    buttonsConfig = {
      confirmButtonText: 'Ya',
      denyButtonText: 'Tidak',
      cancelButtonText: 'Batal',
      showDenyButton: true,
      showCancelButton: true,
      reverseButtons: true // Tukar posisi tombol
    };
  }

  // Tampilkan SweetAlert dengan konfigurasi tombol
  Swal.fire({
    icon: icon,
    title: label,
    text: deskripsi,
    confirmButtonText: buttonsConfig.confirmButtonText,
    showCancelButton: buttonsConfig.showCancelButton,
    showDenyButton: buttonsConfig.showDenyButton,
    cancelButtonText: buttonsConfig.cancelButtonText,
    denyButtonText: buttonsConfig.denyButtonText,
    reverseButtons: buttonsConfig.reverseButtons,
    customClass: {
      confirmButton: 'my-confirm-button',
      cancelButton: 'my-cancel-button',
      denyButton: 'my-deny-button',
      title: 'fs-7 fw-bold',
      popup: 'custom-popup'
    },
    // Nonaktifkan tombol close (X) saat loading
    allowOutsideClick: () => !Swal.isLoading(),
    allowEscapeKey: () => !Swal.isLoading(),
    // Tambahkan preConfirm untuk handle loading state
    preConfirm: async () => {
      if (onConfirm) {
        try {
          // Tampilkan loading state
          Swal.showLoading();
          // Jalankan fungsi onConfirm
          await Promise.resolve(onConfirm());
          // Sembunyikan loading setelah selesai
          Swal.hideLoading();
          return true;
        } catch (error) {
          // Jika terjadi error, sembunyikan loading dan tampilkan pesan error
          Swal.hideLoading();
          Swal.showValidationMessage(
            error instanceof Error ? error.message : 'Terjadi kesalahan'
          );
          return false;
        }
      }
      return true;
    }
  }).then(result => {
    // Handle hasil setelah alert ditutup
    if (result.isDismissed && result.dismiss === Swal.DismissReason.close) {
      // Jika tombol close (X) diklik, jalankan onCancel
      onCancel?.();
    } else if (result.isDenied) {
      // Jika tombol "Tidak" diklik
      onCancel?.();
    }
    // Note: onConfirm sudah dihandle di preConfirm
  });
};
