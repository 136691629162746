import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { AccountResponse } from 'api/response/AccountResponse';

export const kelolaAkunTableColumns: ColumnDef<AccountResponse>[] = [
  {
    accessorKey: 'email',
    header: 'Alamat Email',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to={`/manajemen-akun/kelola-akun/detail-akun/${original.id}`}
          className="fw-bold text-secondary"
        >
          {original.email}
        </Link>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'py-0 pe-3 ps-0' }
    }
  },
  {
    accessorKey: 'role_name',
    header: 'Nama Pegawai Terkait',
    cell: ({ row: { original } }) => {
      if (!original.employee) {
        return (
          <Badge
            style={{ borderRadius: '5px' }}
            bg="danger"
            variant="phoenix"
            className="fs-10 py-1 px-2"
          >
            TIDAK ADA PEGAWAI TERKAIT
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="ms-1"
              style={{ fontSize: '10px' }}
            />
          </Badge>
        );
      }

      return <span>{original.employee.fullName}</span>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-1 text-end' },
      cellProps: { className: 'pe-3 ps-1 text-end' }
    }
  },

  {
    accessorKey: 'role_security',
    header: 'Peran Keamanan',
    cell: ({ row: { original } }) => {
      if (!original.role) {
        return (
          <Badge
            style={{ borderRadius: '5px' }}
            bg="danger"
            variant="phoenix"
            className="fs-10 py-1 px-2"
          >
            TIDAK ADA PERAN TERKAIT
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="ms-1"
              style={{ fontSize: '10px' }}
            />
          </Badge>
        );
      }

      return (
        <Badge
          style={{
            borderRadius: '15px',
            backgroundColor: original.role.color || '#6c757d',
            color: isLightColor(original.role.color || '') ? '#000' : '#fff'
          }}
          variant="phoenix"
          className="fs-10 py-1 px-2"
        >
          {original.role.code}
        </Badge>
      );
    },
    meta: {
      headerProps: { className: 'ps-3' },
      cellProps: { className: 'ps-3' }
    }
  },
  {
    accessorKey: 'lastActive',
    header: 'Terakhir Aktif',
    cell: ({ row: { original } }) => formatDate(original.lastActive),
    meta: {
      headerProps: { className: 'text-end px-2' },
      cellProps: { className: 'text-end px-2' }
    }
  },
  {
    accessorKey: 'joinedDate',
    header: 'Tanggal Bergabung',
    cell: ({ row: { original } }) => formatDate(original.joinedDate),
    meta: {
      headerProps: { className: 'text-end px-2' },
      cellProps: { className: 'text-end px-2' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return (
        <span
          className={`badge ${original.status ? 'bg-success' : 'bg-danger'}`}
        >
          {original.status ? 'AKTIF' : 'TIDAK AKTIF'}
        </span>
      );
    },
    meta: {
      headerProps: { className: 'text-end px-2' },
      cellProps: { className: 'text-end px-2' }
    }
  }
];

interface KelolaAkunTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  accountData?: {
    totalRecords?: number;
  };
}

const KelolaAkunTable = ({
  onPageChange,
  currentPage,
  totalPages,
  accountData
}: KelolaAkunTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={accountData?.totalRecords || 0}
      />
    </div>
  );
};

const isLightColor = (color: string): boolean => {
  if (!color) return false;

  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128;
};

// Tambahkan fungsi format tanggal
const formatDate = (dateString: string | null) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return date.toLocaleDateString('id-ID', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

export default KelolaAkunTable;
