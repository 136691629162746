import { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import {
  useGetEmployeeByIdQuery,
  useUpdateEmployeeInfoMutation,
  useGetJobTitlesQuery
} from 'api/employeeApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import Badge from 'components/base/Badge';

const InformasiPekerjaanCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: employeeData, isLoading } = useGetEmployeeByIdQuery(id!);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    employeeStatus: false,
    employmentRelationship: '',
    registrationNumber: '',
    jobTitle: {
      id: '',
      name: ''
    },
    annualReview: ''
  });
  const [updateEmployeeInfo] = useUpdateEmployeeInfoMutation();
  const { data: jobTitles } = useGetJobTitlesQuery();
  const [errors, setErrors] = useState({
    jobTitle: ''
  });

  useEffect(() => {
    if (employeeData?.data?.employeeInfo) {
      const info = employeeData.data.employeeInfo;
      setFormData({
        id: info.id,
        employeeStatus: info.employeeStatus,
        employmentRelationship: info.employmentRelationship || '',
        registrationNumber: info.registrationNumber || '',
        jobTitle: {
          id: info.jobTitle?.id || '',
          name: info.jobTitle?.name || ''
        },
        annualReview: info.annualReview || ''
      });
    }
  }, [employeeData]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi: 'Anda yakin ingin membatalkan perubahan?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    const newErrors = {
      jobTitle: ''
    };

    let hasError = false;

    if (!formData.jobTitle.id) {
      newErrors.jobTitle = 'Jabatan harus diisi';
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Anda yakin ingin menyimpan perubahan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updateEmployeeInfo({
            employeeId: id!,
            data: {
              id: formData.id,
              employeeStatus: formData.employeeStatus,
              employmentRelationship: formData.employmentRelationship,
              registrationNumber: formData.registrationNumber,
              annualReview: formData.annualReview,
              jobTitle: formData.jobTitle.id
            }
          }).unwrap();

          setIsEditing(false);
          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data berhasil disimpan',
            buttonType: 'ok'
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: error?.data?.message || 'Gagal menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Card>
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Informasi Pekerjaan</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {!isEditing ? (
          <>
            <Row className="mb-3">
              <Col xs={12} md={6} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Status Kepegawaian</span>
                <Badge
                  style={{ borderRadius: '8px', width: 'fit-content' }}
                  bg={formData.employeeStatus ? 'success' : 'danger'}
                  variant="phoenix"
                  className="fs-10 text-center py-2 px-3"
                >
                  {formData.employeeStatus ? 'AKTIF' : 'TIDAK AKTIF'}
                </Badge>
              </Col>
              <Col xs={12} md={6} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Nomor Registrasi</span>
                <span className="fw-normal">
                  {formData.registrationNumber || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Jabatan</span>
                <span className="fw-normal">
                  {formData.jobTitle.name || '-'}
                </span>
              </Col>
              <Col xs={12} md={6} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">
                  Bulan Tinjauan Tahunan
                </span>
                <span className="fw-normal">
                  {formData.annualReview
                    ? new Date(formData.annualReview).toLocaleString('id-ID', {
                        month: 'long',
                        year: 'numeric'
                      })
                    : '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Jenis Hubungan Kerja</span>
                <span className="fw-normal">
                  {formData.employmentRelationship || '-'}
                </span>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Form>
              {/* Row 1: Status Kepegawaian */}
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label className="text-capitalize fs-8 ps-0 mb-2">
                      Status Kepegawaian
                    </Form.Label>
                    <div className="d-flex gap-3">
                      <Form.Check
                        type="radio"
                        name="employmentStatus"
                        label="Aktif"
                        id="active"
                        checked={formData.employeeStatus}
                        onChange={() =>
                          setFormData(prev => ({
                            ...prev,
                            employeeStatus: true
                          }))
                        }
                      />
                      <Form.Check
                        type="radio"
                        name="employmentStatus"
                        label="Tidak Aktif"
                        id="inactive"
                        checked={!formData.employeeStatus}
                        onChange={() =>
                          setFormData(prev => ({
                            ...prev,
                            employeeStatus: false
                          }))
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Row 2: Jabatan */}
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label className="text-capitalize fs-8 ps-0 mb-2">
                      Jabatan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      value={formData.jobTitle.id}
                      onChange={e => {
                        const selectedOption =
                          e.target.options[e.target.selectedIndex];
                        setFormData(prev => ({
                          ...prev,
                          jobTitle: {
                            id: e.target.value,
                            name: selectedOption.text
                          }
                        }));
                      }}
                      isInvalid={!!errors.jobTitle}
                    >
                      <option value="">Pilih Jabatan</option>
                      {jobTitles?.map(job => (
                        <option key={job.id} value={job.id}>
                          {job.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.jobTitle}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Row 3: Jenis Hubungan Kerja & Nomor Registrasi */}
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label className="text-capitalize fs-8 ps-0 mb-2">
                      Jenis Hubungan Kerja
                    </Form.Label>
                    <Form.Select
                      value={formData.employmentRelationship}
                      onChange={e =>
                        setFormData(prev => ({
                          ...prev,
                          employmentRelationship: e.target.value
                        }))
                      }
                    >
                      <option value="">Tetap</option>
                      <option value="kontrak">Kontrak</option>
                      <option value="freelance">Freelance</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label className="text-capitalize fs-8 ps-0 mb-2">
                      Nomor Registrasi
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.registrationNumber}
                      onChange={e =>
                        setFormData(prev => ({
                          ...prev,
                          registrationNumber: e.target.value
                        }))
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Row 4: Bulan Tinjauan Tahunan */}
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label className="text-capitalize fs-8 ps-0 mb-2">
                      Bulan Tinjauan Tahunan
                    </Form.Label>
                    <Form.Select
                      value={formData.annualReview.substring(0, 7)}
                      onChange={e =>
                        setFormData(prev => ({
                          ...prev,
                          annualReview: e.target.value + '-01'
                        }))
                      }
                    >
                      <option value="">
                        Pilih bulan untuk tinjauan tahunan pegawai
                      </option>
                      <option value="2024-01">Januari 2024</option>
                      <option value="2024-02">Februari 2024</option>
                      {/* ... opsi bulan lainnya ... */}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InformasiPekerjaanCard;
