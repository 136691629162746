import EmailPelangganForm from 'components/forms/EmailPelangganForm';
import { showCustomAlert } from 'helpers/showCustomAlert';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const EmailPelangganModal: React.FC<ModalProps> = ({ show, onClose }) => {
  const handleCancelClick = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Pengiriman pesan akan dibatalkan',
      deskripsi: 'Apakah Anda ingin memindahkan pesan ke draft?',
      buttonType: 'cancel-yes-no',
      onConfirm: () => {
        onClose();
      }
    });
  };

  // Fungsi handler untuk tombol Simpan
  const handleSaveClick = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Pesan akan dikirim',
      deskripsi: 'Apakah Anda yakin untuk mengirimkan pesan?',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Jika user mengonfirmasi simpan, munculkan alert kedua
        showCustomAlert({
          icon: 'success',
          label: 'Sukses!',
          deskripsi: 'Pesan berhasil dikirim',
          buttonType: 'ok',
          onConfirm: onClose
        });
      }
    });
  };
  return (
    <Modal closeButton={false} show={show} onHide={onClose} size="lg" centered>
      <Modal.Header className="d-flex justify-content-between p-4 bg-modal-header">
        <Modal.Title>
          <h4 className="fw-bolder m-0">Buat Pesan Baru</h4>
        </Modal.Title>
        <div className="d-flex gap-3">
          <Button
            className="px-1"
            variant="link"
            style={{ color: 'red' }}
            onClick={handleCancelClick}
          >
            Batalkan
          </Button>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={handleSaveClick}
          >
            Kirim & Buat Baru
          </Button>
          <Button variant="primary" type="submit" onClick={handleSaveClick}>
            Kirim
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <EmailPelangganForm />
      </Modal.Body>
    </Modal>
  );
};

export default EmailPelangganModal;
