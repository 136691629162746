import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface CheckboxState {
  showServiceBookingConfiguration: boolean;
  showServicePrice: boolean;
  showServiceEmployee: boolean;
  showFormTemplat: boolean;
  [key: string]: boolean;
}

interface Props {
  show: boolean;
  onHide: () => void;
  checkboxes: CheckboxState;
  onCheckboxChange: (updatedVisibility: CheckboxState) => void;
}

const CheckboxDetailLayananModal = ({
  show,
  onHide,
  checkboxes,
  onCheckboxChange
}: Props) => {
  // State sementara untuk menyimpan perubahan sebelum disimpan
  const [tempCheckboxes, setTempCheckboxes] =
    useState<CheckboxState>(checkboxes);

  // Reset tempCheckboxes ketika modal dibuka
  React.useEffect(() => {
    if (show) {
      setTempCheckboxes(checkboxes);
    }
  }, [show, checkboxes]);

  const handleCheckboxChange = (key: keyof CheckboxState) => {
    setTempCheckboxes(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSave = () => {
    onCheckboxChange(tempCheckboxes);
    showCustomAlert({
      icon: 'success',
      label: 'Berhasil',
      deskripsi: 'Pengaturan tampilan berhasil disimpan',
      buttonType: 'ok',
      onConfirm: onHide
    });
  };

  const handleCancel = () => {
    setTempCheckboxes(checkboxes); // Reset ke nilai awal
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pengaturan Tampilan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showServiceBookingConfiguration"
              label="Booking"
              checked={tempCheckboxes.showServiceBookingConfiguration}
              onChange={() =>
                handleCheckboxChange('showServiceBookingConfiguration')
              }
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showServicePrice"
              label="Harga Layanan"
              checked={tempCheckboxes.showServicePrice}
              onChange={() => handleCheckboxChange('showServicePrice')}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showServiceEmployee"
              label="Pegawai"
              checked={tempCheckboxes.showServiceEmployee}
              onChange={() => handleCheckboxChange('showServiceEmployee')}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleCancel} style={{ color: 'red' }}>
          Batalkan
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckboxDetailLayananModal;
