import React from 'react';
import { Card, Table, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const UlasanPegawaiCard = () => {
  return (
    <Card>
      <Card.Body>
        <h3 className="mb-4">Peringkat & Ulasan Pelayanan (8)</h3>

        <Table responsive className="fs-9">
          <thead>
            <tr>
              <th>PELAYANAN</th>
              <th>PERINGKAT</th>
              <th>ULASAN</th>
              <th>STATUS</th>
              <th>TANGGAL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cabut Gigi</td>
              <td>
                {[1, 2, 3, 4, 5].map(star => (
                  <FontAwesomeIcon
                    key={star}
                    icon={faStar}
                    className="text-warning me-1"
                  />
                ))}
              </td>
              <td>
                Pelayanan cabut gigi di klinik ini sangat profesional dan minim
                rasa sakit. Dokter serta asisten sangat sabar dalam menjelaskan
                setiap proses. Aku sangat senang.
              </td>
              <td>
                <span className="badge bg-success">Success</span>
              </td>
              <td>Just now</td>
              <td>
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" className="p-0">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Detail</Dropdown.Item>
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item>Hapus</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>Scaling Gigi</td>
              <td>
                {[1, 2, 3, 4].map(star => (
                  <FontAwesomeIcon
                    key={star}
                    icon={faStar}
                    className="text-warning me-1"
                  />
                ))}
              </td>
              <td>
                Pelayanan scalling gigi profesional, dan tidak menyakitkan walau
                saya sedikit takut. gigi saya menjadi kinclong, Terima kasih !!!
              </td>
              <td>
                <span className="badge bg-success">Success</span>
              </td>
              <td>Just now</td>
              <td>
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" className="p-0">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Detail</Dropdown.Item>
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item>Hapus</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default UlasanPegawaiCard;
