import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface HistoryData {
  user: string;
  activity: string;
  time: string;
}

// Data dummy untuk tabel
const historyData: HistoryData[] = [
  {
    user: 'master@purely.com',
    activity: 'Change Customer Data',
    time: 'Thursday, 24 October, 2024 11:35 AM'
  },
  {
    user: 'admin@purely.com',
    activity: 'Change Staff Notes',
    time: 'Thursday, 24 October, 2024 11:35 AM'
  }
];

const historyColumns: ColumnDef<HistoryData>[] = [
  {
    accessorKey: 'user',
    header: 'USER',
    cell: ({ row: { original } }) => original.user,
    meta: {
      headerProps: { className: 'ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'activity',
    header: 'ACTIVITY',
    cell: ({ row: { original } }) => original.activity,
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    accessorKey: 'time',
    header: 'TIME',
    cell: ({ row: { original } }) => (
      <span className="text-primary">{original.time}</span>
    ),
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  }
];

const HistoriAktivitasTable = ({ onClose }: { onClose: () => void }) => {
  const table = useAdvanceTable({
    data: historyData,
    columns: historyColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="mb-0">Histori Aktivitas</h3>
        <FontAwesomeIcon
          icon={faTimes}
          className="cursor-pointer"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            console.log('Close button clicked');
            onClose();
          }}
        />
      </div>
      <AdvanceTableProvider {...table}>
        <div className="border-y border-translucent">
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9',
              style: { width: '100%', minWidth: 'auto' }
            }}
          />
          <AdvanceTableFooter
            pagination
            showViewAllBtn={false}
            className="py-2"
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default HistoriAktivitasTable;
