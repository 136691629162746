import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';
import {
  RoleData,
  RoleResponse,
  CreateRoleRequest
} from './response/RoleResponse';

// Interface for query parameters
interface RoleQueryParams {
  page: number;
  limit: number;
  name?: string;
  code?: string;
}

interface RoleForFormQueryParams {
  page: number;
}

// Interface for create/update role
export interface RoleFormData {
  code: string;
  color: string;
  description: string;
  activation?: {
    type: string;
    from: string;
    to: string;
  };
  // Tambahkan field lain yang diperlukan untuk update base role
  status?: boolean; // Optional, tergantung kebutuhan
}

// Interface for assign/unassign user
interface UserRoleRequest {
  roleId: string;
  userId: number;
}

// Tambahkan interface baru
interface ServicePrivilege {
  id: string;
  code: string;
  description: string;
  privilege: string;
}

interface MenuPrivilege {
  id: string;
  code: string;
  description: string;
  services: ServicePrivilege[];
}

interface RolePrivilege {
  id: string;
  code: string;
  menus: MenuPrivilege[];
}

interface RolePrivilegeResponse extends BaseResponse {
  data: RolePrivilege[];
}

// Interface untuk payload update privilege
export interface UpdatePrivilegePayload {
  id: string;
  services: {
    serviceId: string;
    privilege: 'FULL' | 'WRITE' | 'READ' | 'NONE';
  }[];
}

// Tambahkan interface untuk request
interface PrivilegeUpdateRequest {
  id: string;
  services: {
    serviceId: string;
    type: string;
  }[];
}

// Modifikasi interface untuk response update privilege
interface PrivilegeUpdateResponse extends BaseResponse {
  data: {
    id: string;
    services: {
      id: string;
      code: string;
    }[];
  }[];
}

// Tambahkan interface untuk response getRoleUsers
interface RoleUser {
  id: string;
  email: string;
}

interface RoleUsersResponse extends BaseResponse {
  data: RoleUser[];
}

// Tambahkan interface untuk delete request
interface DeleteRoleRequest {
  id: string[];
  reason: string;
  notes: string | null;
}

export const roleApi = createApi({
  reducerPath: 'role',
  tagTypes: ['Role'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: 30000
  }),
  endpoints: builder => ({
    getAllRoles: builder.query<RoleData, RoleQueryParams>({
      query: params => ({
        url: 'api/v1/role/all',
        params: {
          page: params.page,
          limit: params.limit,
          // orderBy: params.orderBy || 'id',
          // direction: params.direction || 'ASC',
          code: params.code || ''
        }
      }),
      providesTags: ['Role']
    }),

    // Get Role by ID
    getRoleById: builder.query<BaseResponse & { data: RoleResponse }, string>({
      query: id => `api/v1/role/${id}`,
      transformResponse: (response: BaseResponse & { data: RoleResponse }) => {
        return response;
      }
    }),

    // Get Role for Form
    getRoleForForm: builder.query<RoleData, RoleForFormQueryParams>({
      query: () => 'api/v1/role/form'
    }),

    // Get All Users in Role
    getAllUsersInRole: builder.query<BaseResponse, number>({
      query: roleId => `api/v1/role/users/${roleId}`
    }),

    // Check If User has Role
    checkIfUserHasRole: builder.query<
      BaseResponse,
      { userId: number; roleId: number }
    >({
      query: ({ userId, roleId }) => `api/v1/role/check/${userId}/${roleId}`
    }),

    // Add Role
    addRole: builder.mutation<BaseResponse, CreateRoleRequest>({
      query: data => ({
        url: 'api/v1/role/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Role']
    }),
    // Duplicate Role
    duplicateRole: builder.mutation<BaseResponse, string>({
      query: id => ({
        url: `api/v1/role/duplicate/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['Role']
    }),

    // Assign User
    assignUser: builder.mutation<BaseResponse, UserRoleRequest>({
      query: body => ({
        url: 'api/v1/role/assign',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Role']
    }),

    // Unassign User From Role
    unassignUserFromRole: builder.mutation<BaseResponse, string>({
      query: userId => ({
        url: `api/v1/role/unassign/user/${userId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Role']
    }),

    // Update Base Role Info
    updateBaseRoleInfo: builder.mutation<
      BaseResponse,
      { id: string; data: RoleFormData }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/role/update/base`,
        method: 'PUT',
        body: {
          ...data,
          id
        }
      }),
      invalidatesTags: ['Role']
    }),
    updateActivationInfo: builder.mutation<
      BaseResponse,
      {
        id: string;
        activation: 'ALL_THE_TIME' | 'WORKING_HOURS' | 'CUSTOM';
        from?: string;
        to?: string;
      }
    >({
      query: data => ({
        url: `/api/v1/role/update/activation`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Role']
    }),

    // Tambahkan endpoint baru
    getAllRolePrivileges: builder.query<RolePrivilegeResponse, void>({
      query: () => 'api/v1/role/all/privilege',
      providesTags: ['Role']
    }),

    updateRolePrivilegeSingle: builder.mutation<
      BaseResponse,
      UpdatePrivilegePayload[]
    >({
      query: payload => ({
        url: 'api/v1/role/update/privilege',
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['Role'] // Invalidate cache untuk role setelah update
    }),

    // Tambahkan endpoint untuk update privilege
    updateRolePrivilege: builder.mutation<
      PrivilegeUpdateResponse,
      PrivilegeUpdateRequest[]
    >({
      query: body => ({
        url: 'api/v1/role/update/privilege/bulk',
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Role']
    }),

    // Tambahkan endpoint delete
    deleteRole: builder.mutation<BaseResponse, DeleteRoleRequest>({
      query: body => ({
        url: 'api/v1/role',
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['Role']
    }),

    // Tambahkan endpoint getRoleUsers
    getRoleUsers: builder.query<RoleUsersResponse, string>({
      query: roleId => `api/v1/role/${roleId}/user`,
      providesTags: ['Role']
    })
  })
});

export const {
  useGetAllRolesQuery,
  useGetRoleByIdQuery,
  useGetRoleForFormQuery,
  useGetAllUsersInRoleQuery,
  useCheckIfUserHasRoleQuery,
  useAddRoleMutation,
  useDuplicateRoleMutation,
  useAssignUserMutation,
  useUnassignUserFromRoleMutation,
  useUpdateBaseRoleInfoMutation,
  useUpdateActivationInfoMutation,
  useGetAllRolePrivilegesQuery,
  useUpdateRolePrivilegeSingleMutation,
  useUpdateRolePrivilegeMutation,
  useDeleteRoleMutation,
  useGetRoleUsersQuery
} = roleApi;
