import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const ServiceEmployeeCard = () => {
  const employeeData = [
    {
      nama: 'Dr. Ani Susanti',
      jabatan: 'Dokter Gigi Utama',
      biayaTambahan: 10
    },
    {
      nama: 'drg. Budi Santoso',
      jabatan: 'Dokter Spesialis Ortodonti',
      biayaTambahan: 15
    },
    { nama: 'Siti Rahayu', jabatan: 'Asisten Dokter', biayaTambahan: 5 },
    { nama: 'Rina Kartika', jabatan: 'Perawat Gigi', biayaTambahan: 7 },
    {
      nama: 'Ahmad Fauzi',
      jabatan: 'Konsultan Kesehatan Gigi',
      biayaTambahan: 12
    }
  ];
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="mb-0">
            Pegawai <FontAwesomeIcon icon={faPencil} className="ms-2 fs-9" />
          </h3>
        </div>

        <Row className="mb-3">
          <Col xs={6} md={6} className="d-flex flex-column">
            <span className="fw-bold text-muted">Pegawai per Booking</span>
            <span className="fw-normal">dummy</span>
          </Col>
          <Col xs={6} md={6} className="d-flex flex-column">
            <span className="fw-bold text-muted">Biaya Tambahan</span>
            <span className="fw-normal">dummy</span>
          </Col>
        </Row>

        <Table responsive className="fs-9">
          <thead>
            <tr>
              <th>Nama Pegawai</th>
              <th>Jabatan</th>
              <th>Biaya Tambahan (%)</th>
            </tr>
          </thead>
          <tbody>
            {employeeData.map((employee, index) => (
              <tr key={index}>
                <td>{employee.nama}</td>
                <td>{employee.jabatan}</td>
                <td>{employee.biayaTambahan}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ServiceEmployeeCard;
