import { ColumnDef } from '@tanstack/react-table';
import { KategoriLayanan } from 'api/response/ServiceResponse';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';

export const kategoriLayananTableColumns: ColumnDef<KategoriLayanan>[] = [
  {
    accessorKey: 'color',
    header: 'Warna',
    cell: ({ row: { original } }) => {
      return (
        <div
          style={{
            width: '80px',
            height: '20px',
            backgroundColor: original.color || 'undefined',
            borderRadius: '4px'
          }}
        />
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 align-middle' }
    }
  },
  {
    accessorKey: 'name',
    header: 'Nama Kategori',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to={`/layanan/kategori-layanan/detail-kategori/${original.id}`}
          className="d-flex align-items-center gap-3 text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{original.name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'description',
    header: 'Deskripsi',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.description}</span>;
    },
    meta: {
      headerProps: { style: { width: '60%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'totalServices',
    header: 'Jumlah Layanan',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.totalServices}</span>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  }
];

interface KategoriLayananTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  categoryData?: {
    totalRecords?: number;
  };
}

const KategoriLayananTable = ({
  onPageChange,
  currentPage,
  totalPages,
  categoryData
}: KategoriLayananTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={categoryData?.totalRecords || 0}
      />
    </div>
  );
};

export default KategoriLayananTable;
