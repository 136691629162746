import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddPositionRequest,
  PositionDetailResponse,
  PositionListResponse,
  PositionResponse
  //   PositionDetailResponse,
  //   AddPositionRequest
} from './response/PositionResponse';
import { BaseResponse } from './response/BaseResponse';

interface PositionQueryParams {
  name: string;
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
}

interface DeletePositionRequest {
  notes: string;
  deletionReason: string;
  data: string[];
}

interface UpdatePositionRequest {
  name: string;
  description: string;
  color: string;
  employee: Array<{ id: string }>;
}

interface RemoveEmployeeRequest {
  employeeId: string;
}

interface AddEmployeeRequest {
  employeeId: string[];
}

export const positionApi = createApi({
  reducerPath: 'positionApi',
  tagTypes: ['Position'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({
    getAllPositions: builder.query<PositionListResponse, PositionQueryParams>({
      query: params => ({
        url: 'api/v1/employee/job-title',
        params: {
          page: params.page,
          limit: params.limit,
          orderBy: params.orderBy,
          direction: params.direction,
          name: params.name || ''
        }
      })
    }),
    getPositionById: builder.query<PositionDetailResponse, string>({
      query: id => ({
        url: `/api/v1/employee/job-title/${id}`,
        method: 'GET'
      })
      //   providesTags: ['Position']
    }),

    addPosition: builder.mutation<PositionResponse, AddPositionRequest>({
      query: position => ({
        url: '/api/v1/employee/job-title',
        method: 'POST',
        body: position
      })
      //   invalidatesTags: ['Position']
    }),

    deletePosition: builder.mutation<BaseResponse, DeletePositionRequest>({
      query: deleteRequest => ({
        url: '/api/v1/employee/job-title',
        method: 'DELETE',
        body: deleteRequest
      }),
      invalidatesTags: ['Position']
    }),

    updatePosition: builder.mutation<
      PositionResponse,
      { id: string; data: UpdatePositionRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/job-title/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Position']
    }),

    addEmployeeToPosition: builder.mutation<
      PositionResponse,
      { positionId: string; data: AddEmployeeRequest }
    >({
      query: ({ positionId, data }) => ({
        url: `/api/v1/employee/job-title/${positionId}/revise-employee`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Position']
    }),

    removeEmployeeFromPosition: builder.mutation<
      PositionResponse,
      { positionId: string; data: RemoveEmployeeRequest }
    >({
      query: ({ positionId, data }) => ({
        url: `/api/v1/employee/job-title/${positionId}/revise-employee`,
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: ['Position']
    })
  })
});

export const {
  useGetAllPositionsQuery,
  useGetPositionByIdQuery,
  useAddPositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
  useAddEmployeeToPositionMutation,
  useRemoveEmployeeFromPositionMutation
} = positionApi;
