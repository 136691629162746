import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ServiceTemplateResponse,
  SingleServiceTemplateResponse,
  ServiceTemplateFieldResponse,
  AddTemplateFieldRequest,
  AddTemplateFieldResponse,
  ServiceTemplateFieldDetail
} from './response/ServiceTemplateResponse';
import { BaseResponse } from './response/BaseResponse';

export interface ServiceTemplateParams {
  page?: number;
  limit?: number;
  orderBy?: string;
  direction?: 'ASC' | 'DESC';
  templateName?: string;
  status?: string;
}

interface AddTemplateRequest {
  templateName: string;
  formType: string;
  status: boolean;
}

interface TemplateFieldOption {
  id: string;
  optionValue: string;
  selectedChoice: boolean;
}

export interface ServiceTemplateResponseDetail {
  id: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  responseValue: string;
  service: {
    id: string;
    // ... service fields
  };
  field: {
    id: string;
    fieldName: string;
    fieldGroup: string;
    label: string;
    isRequired: boolean;
    fieldSize: string;
    fieldTextLength: number;
    fieldType: {
      id: string;
      name: string;
      code: string;
    };
    serviceTemplateFieldOption: TemplateFieldOption[];
    value?: string;
  };
  serviceTemplateOptionResponse: TemplateFieldOption[];
}

export interface ServiceTemplateResponseResult {
  code: number;
  message: string;
  data: ServiceTemplateResponseDetail[];
}

export interface UpdateTemplateResponseRequest {
  serviceTemplateResponseId: string;
  serviceId: string;
  fieldId: string;
  responseValue: string;
  optionResponse?: {
    id: string;
    optionId: string;
    selectedChoice: boolean;
  }[];
}

interface DuplicateTemplateRequest {
  serviceTemplateId: string;
  serviceTemplateDuplicateName: string;
}

export const serviceTemplateApi = createApi({
  reducerPath: 'serviceTemplate',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['ServiceTemplateField'],
  endpoints: builder => ({
    getServiceTemplate: builder.query<
      ServiceTemplateResponse,
      ServiceTemplateParams
    >({
      query: (
        params = {
          page: 1,
          limit: 10,
          orderBy: 'createdDate',
          direction: 'DESC'
        }
      ) => ({
        url: '/api/v1/service/template',
        params
      })
    }),

    addServiceTemplate: builder.mutation<
      SingleServiceTemplateResponse,
      AddTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/service/template',
        method: 'POST',
        body
      })
    }),

    getServiceTemplateField: builder.query<
      ServiceTemplateFieldResponse,
      number
    >({
      query: templateId => ({
        url: `/api/v1/service/template/field`,
        params: { templateId }
      }),
      providesTags: ['ServiceTemplateField']
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getServiceTemplateFieldTypes: builder.query<any, void>({
      query: () => ({
        url: '/api/v1/service/template-field-type'
      })
    }),

    deleteServiceTemplate: builder.mutation<
      { code: number; message: string },
      string
    >({
      query: id => ({
        url: `/api/v1/service/template/${id}`,
        method: 'DELETE'
      })
    }),

    getServiceTemplateById: builder.query<
      SingleServiceTemplateResponse,
      string
    >({
      query: id => ({
        url: `/api/v1/service/template/${id}`
      })
    }),

    addServiceTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      AddTemplateFieldRequest
    >({
      query: body => ({
        url: '/api/v1/service/template/field',
        method: 'POST',
        body
      }),
      invalidatesTags: ['ServiceTemplateField']
    }),

    deleteServiceTemplateField: builder.mutation<BaseResponse, string>({
      query: id => ({
        url: `/api/v1/service/template/field/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ServiceTemplateField']
    }),

    editServiceTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      { id: string; data: AddTemplateFieldRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/service/template/field/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ServiceTemplateField']
    }),

    getServiceTemplateFieldById: builder.query<
      { code: number; message: string; data: ServiceTemplateFieldDetail },
      string
    >({
      query: id => ({
        url: `/api/v1/service/template/field/${id}`
      }),
      providesTags: ['ServiceTemplateField']
    }),

    editServiceTemplate: builder.mutation<
      SingleServiceTemplateResponse,
      { id: string; data: AddTemplateRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/service/template/${id}`,
        method: 'PUT',
        body: data
      })
    }),

    addServiceTemplateResponse: builder.mutation<
      BaseResponse,
      {
        serviceId: number;
        responses: ServiceTemplateResponseDetail[];
      }
    >({
      query: ({ serviceId, responses }) => ({
        url: '/api/v1/service/template-response',
        method: 'POST',
        body: { serviceId, responses }
      })
    }),

    getServiceTemplateResponse: builder.query<
      ServiceTemplateResponseResult,
      {
        templateId: string;
        serviceId: string;
        status?: boolean;
        limit?: number;
      }
    >({
      query: ({ templateId, serviceId, limit = 100 }) =>
        `/api/v1/service/template/template-response/service-template/${templateId}/service/${serviceId}?limit=${limit}`
    }),

    getServiceTemplateResponseById: builder.mutation<
      ServiceTemplateResponseResult,
      string
    >({
      query: id => ({
        url: `/api/v1/service/template/template-response/${id}`,
        method: 'GET'
      })
    }),

    updateServiceTemplateResponse: builder.mutation<
      BaseResponse,
      UpdateTemplateResponseRequest[]
    >({
      query: body => ({
        url: '/api/v1/service/template/template-response/',
        method: 'PUT',
        body
      })
    }),

    updateTemplateFieldPosition: builder.mutation<
      BaseResponse,
      { fieldId: string; newPosition: number }
    >({
      query: ({ fieldId, newPosition }) => ({
        url: `/api/v1/service/template/field/drag-and-drop/${fieldId}`,
        method: 'PUT',
        body: { newPosition }
      }),
      invalidatesTags: ['ServiceTemplateField']
    }),
    duplicateServiceTemplate: builder.mutation<
      SingleServiceTemplateResponse,
      DuplicateTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/service/template/duplicate',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetServiceTemplateQuery,
  useAddServiceTemplateMutation,
  useDeleteServiceTemplateMutation,
  useGetServiceTemplateFieldQuery,
  useGetServiceTemplateFieldTypesQuery,
  useGetServiceTemplateByIdQuery,
  useAddServiceTemplateFieldMutation,
  useDeleteServiceTemplateFieldMutation,
  useEditServiceTemplateFieldMutation,
  useGetServiceTemplateFieldByIdQuery,
  useEditServiceTemplateMutation,
  useAddServiceTemplateResponseMutation,
  useGetServiceTemplateResponseQuery,
  useGetServiceTemplateResponseByIdMutation,
  useUpdateServiceTemplateResponseMutation,
  useUpdateTemplateFieldPositionMutation,
  useDuplicateServiceTemplateMutation
} = serviceTemplateApi;
