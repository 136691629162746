import { Modal, Form, Dropdown } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import SearchBox from 'components/common/SearchBox';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FilterMenu } from 'components/common/FilterButtonGroup';
import { useGetEmployeeQuery } from 'api/employeeApi';
import { debounce } from 'lodash';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface PilihPemilikJabatanModalProps {
  show: boolean;
  onHide: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (selectedEmployees: any[]) => void;
  selectedIds: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMount: (employees: any[]) => void;
  style?: React.CSSProperties;
  currentPositionId?: string;
  showPositionAlert?: boolean;
}

const PilihPemilikJabatanModal = ({
  show,
  onHide,
  onSelect,
  selectedIds,
  onMount,
  style,
  currentPositionId,
  showPositionAlert = true
}: PilihPemilikJabatanModalProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusFilter, setStatusFilter] = useState<boolean[]>([]);
  const [selectedEmployees, setSelectedEmployees] =
    useState<string[]>(selectedIds);

  const {
    data: employeeData,
    isLoading,
    refetch
  } = useGetEmployeeQuery({
    page: currentPage,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  const handleSearchChange = debounce((value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  }, 300);

  const handleSelect = (employeeId: string) => {
    setSelectedEmployees(prev =>
      prev.includes(employeeId)
        ? prev.filter(id => id !== employeeId)
        : [...prev, employeeId]
    );
  };

  const handleSubmit = () => {
    const selectedEmployeesData = employeeData?.data?.filter(employee =>
      selectedEmployees.includes(employee.id)
    );

    // Cek apakah ada pegawai yang sudah memiliki jabatan lain
    const employeesWithOtherPosition = selectedEmployeesData?.filter(
      employee =>
        showPositionAlert &&
        employee.jobTitle !== null &&
        employee.jobTitle?.id !== currentPositionId &&
        !selectedIds.includes(employee.id)
    );

    if (employeesWithOtherPosition && employeesWithOtherPosition.length > 0) {
      showCustomAlert({
        icon: 'warning',
        label: 'Pegawai yang dipilih sudah memiliki jabatan',
        deskripsi:
          'Apakah Anda yakin akan mengubah jabatan pada pegawai tersebut?',
        buttonType: 'yes-no',
        onConfirm: () => {
          onSelect(selectedEmployeesData || []);
        }
      });
    } else {
      onSelect(selectedEmployeesData || []);
    }
  };

  useEffect(() => {
    setSelectedEmployees(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (employeeData?.data) {
      onMount(employeeData.data);
    }
  }, [employeeData, onMount]);

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  const getGenderLabel = (gender: string) => {
    switch (gender) {
      case 'M':
        return 'Laki-laki';
      case 'F':
        return 'Perempuan';
      default:
        return '-';
    }
  };

  const hasSelectionChanged = () => {
    if (selectedEmployees.length !== selectedIds.length) return true;
    return selectedEmployees.some(id => !selectedIds.includes(id));
  };

  const handleHide = () => {
    if (hasSelectionChanged()) {
      showCustomAlert({
        icon: 'warning',
        label: 'Perubahan data tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setSelectedEmployees(selectedIds);
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="lg"
      centered
      contentClassName="shadow"
      style={{
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '8px',
        ...style
      }}
      backdropClassName="darker-backdrop"
    >
      <Modal.Header className="px-5 py-4">
        <Modal.Title className="fw-bolder fs-6">Pilih Pegawai</Modal.Title>
        <div className="ms-auto">
          <Button
            variant="link"
            className="text-danger me-2"
            onClick={handleHide}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Pilih Pegawai
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-5 py-4 bg-light">
        <h4 className="mb-4">Daftar Pegawai</h4>

        <div className="d-flex gap-3 align-items-center mb-4 justify-content-between">
          <div>
            <SearchBox
              placeholder="Cari"
              onChange={e => handleSearchChange(e.target.value)}
              style={{ maxWidth: '200px' }}
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <div className="d-flex">
              <Dropdown>
                <Dropdown.Toggle
                  variant="white"
                  id="lokasi-dropdown"
                  className="rounded-start rounded-0 border-end-0"
                  style={{ border: '1px solid #E0E0E0' }}
                >
                  Lokasi
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Semua Lokasi</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  variant="white"
                  id="jenis-kelamin-dropdown"
                  className="rounded-end rounded-0"
                  style={{ border: '1px solid #E0E0E0' }}
                >
                  Jenis Kelamin
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Laki-laki</Dropdown.Item>
                  <Dropdown.Item>Perempuan</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <Button variant="light" className="d-flex align-items-center gap-2">
              <i className="bi bi-funnel"></i>
              Filter
            </Button>

            <Button variant="light">Reset</Button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="fs-9">
                <th style={{ width: '40px' }}></th>
                <th>NAMA PEGAWAI</th>
                <th>JENIS KELAMIN</th>
                <th>JABATAN</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={5}>Loading...</td>
                </tr>
              ) : (
                employeeData?.data?.map(employee => (
                  <tr key={employee.id} className="fs-9">
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedEmployees.includes(employee.id)}
                        onChange={() => handleSelect(employee.id)}
                      />
                    </td>
                    <td>{employee.fullName}</td>
                    <td>
                      {getGenderLabel(
                        typeof employee.gender === 'string'
                          ? employee.gender
                          : employee.gender.plain
                      )}
                    </td>
                    <td>{employee.jobTitle?.name || '-'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PilihPemilikJabatanModal;
