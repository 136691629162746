import React, { useState, useMemo } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import type { EmployeeTemplate } from 'api/response/EmployeeTemplateResponse';
import {
  useGetEmployeeTemplateResponseQuery,
  useUpdateEmployeeTemplateResponseMutation,
  useGetEmployeeTemplateResponseByIdMutation
} from 'api/employeeTemplateApi';
import Select from 'react-select';

interface EmployeeTemplateCardProps {
  template: EmployeeTemplate;
  employeeId: string;
}

const EmployeeTemplateCard: React.FC<EmployeeTemplateCardProps> = ({
  template,
  employeeId
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedResponses, setEditedResponses] = useState<{
    [key: string]: {
      responseValue?: string;
      options?: { id: string; selectedChoice: boolean }[];
    };
  }>({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const { data: templateResponseData, refetch } =
    useGetEmployeeTemplateResponseQuery({
      templateId: template.id,
      employeeId,
      limit: 100
    });
  const [updateTemplateResponse] = useUpdateEmployeeTemplateResponseMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getResponseById] = useGetEmployeeTemplateResponseByIdMutation();

  const employeeTemplateResponses = useMemo(() => {
    if (!templateResponseData?.data) return [];

    const filteredResponses = templateResponseData.data.filter(
      response =>
        response.employee &&
        response.employee.id === employeeId &&
        response.field &&
        template.employeeTemplateFields.some(
          templateField =>
            templateField.id === response.field.id && template.status
        )
    );

    // Pastikan semua field dari template memiliki response
    const allFields = template.employeeTemplateFields.map(templateField => {
      const existingResponse = filteredResponses.find(
        response => response.field.id === templateField.id
      );

      // Jika tidak ada response, buat response default
      if (!existingResponse) {
        return {
          id: templateField.id,
          field: {
            ...templateField,
            isRequired: false,
            fieldSize: 'medium',
            fieldTextLength: 255,
            fieldType: {
              code: templateField.fieldType,
              id: '',
              name: ''
            },
            employeeTemplateFieldOption: []
          },
          responseValue: '-',
          employeeTemplateOptionResponse: []
        };
      }

      return existingResponse;
    });

    return allFields.sort((a, b) => {
      const fieldA = template.employeeTemplateFields.find(
        field => field.id === a.field.id
      );
      const fieldB = template.employeeTemplateFields.find(
        field => field.id === b.field.id
      );
      return (fieldA?.position || 0) - (fieldB?.position || 0);
    });
  }, [templateResponseData, employeeId, template]);

  // Hapus pengecekan length karena kita ingin selalu menampilkan card
  if (!template.employeeTemplateFields.length) {
    return null;
  }

  // Fungsi validasi field
  const validateFields = () => {
    const errors: { [key: string]: string } = {};

    employeeTemplateResponses.forEach(response => {
      const { field } = response;
      if (field.isRequired) {
        const editedResponse = editedResponses[field.id];
        const value = editedResponse?.responseValue ?? response.responseValue;

        switch (field.fieldType.code) {
          case 'CHECKBOXES': {
            const editedOptions = editedResponse?.options;
            if (editedOptions) {
              const hasSelected = editedOptions.some(opt => opt.selectedChoice);
              if (!hasSelected) {
                errors[field.id] = 'Data tidak boleh kosong';
              }
            } else {
              const hasSelected = response.employeeTemplateOptionResponse.some(
                opt => opt.selectedChoice
              );
              if (!hasSelected) {
                errors[field.id] = 'Data tidak boleh kosong';
              }
            }
            break;
          }
          case 'RADIO_BUTTONS':
          case 'DROPDOWN_LIST': {
            const hasSelectedOption =
              editedResponse?.options?.some(opt => opt.selectedChoice) ||
              response.employeeTemplateOptionResponse.some(
                opt => opt.selectedChoice
              );
            if (!hasSelectedOption) {
              errors[field.id] = 'Data tidak boleh kosong';
            }
            break;
          }
          case 'DATE':
            if (!value || value.trim() === '') {
              errors[field.id] = `${field.label} tidak boleh kosong`;
            }
            break;
          default: {
            if (!value || value.trim() === '') {
              errors[field.id] = `${field.label} tidak boleh kosong`;
            }
          }
        }
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Fungsi helper untuk parse JSON dengan aman
  const safeJSONParse = (value: string | undefined | null) => {
    try {
      if (!value) return [];
      const parsed = JSON.parse(value);
      return Array.isArray(parsed) ? parsed : [parsed];
    } catch {
      try {
        // Coba parse format lama {value1,value2}
        return (
          value
            ?.replace(/[{}]/g, '')
            .split(',')
            .map(v => v.trim().replace(/"/g, ''))
            .filter(Boolean) || []
        );
      } catch {
        return [];
      }
    }
  };

  // Fungsi render field
  const renderTemplateFields = () => {
    if (!employeeTemplateResponses.length) return null;

    return employeeTemplateResponses.map(response => {
      const { field } = response;
      if (!field) return null;

      return !isEditing ? (
        <Row className="mb-3" key={field.id}>
          <Col xs={12} md={12} className="d-flex flex-column pe-3">
            <span className="fw-bold text-muted">{field.label || '-'}</span>
            <span className="fw-normal">
              {field.fieldType.code === 'MULTI_SELECT_LIST' ? (
                <div>
                  {response.responseValue
                    ? safeJSONParse(response.responseValue).join(', ')
                    : '-'}
                </div>
              ) : field.fieldType.code === 'RADIO_BUTTONS' ||
                field.fieldType.code === 'DROPDOWN_LIST' ? (
                <div>
                  {field.employeeTemplateFieldOption.find(
                    (option, idx) =>
                      response.employeeTemplateOptionResponse[idx]
                        ?.selectedChoice
                  )?.optionValue || '-'}
                </div>
              ) : field.fieldType.code === 'CHECKBOXES' ? (
                <div>
                  {field.employeeTemplateFieldOption
                    .filter(
                      (_, idx) =>
                        response.employeeTemplateOptionResponse[idx]
                          ?.selectedChoice
                    )
                    .map(option => option.optionValue)
                    .join(', ') || '-'}
                </div>
              ) : field.fieldType.code === 'MULTIPLE_LINE_TEXT' ? (
                <div style={{ whiteSpace: 'pre-line' }}>
                  {response.responseValue || '-'}
                </div>
              ) : (
                response.responseValue || '-'
              )}
            </span>
          </Col>
        </Row>
      ) : (
        <Form.Group key={field.id} className="mb-3">
          <Form.Label className="fw-bold p-0 fs-8 text-capitalize">
            {field.label}
            {field.isRequired && <span className="text-danger">*</span>}
          </Form.Label>

          {field.fieldType.code === 'MULTI_SELECT_LIST' ? (
            <>
              <Select
                isMulti
                name={field.fieldName}
                className={`custom-select ${
                  fieldErrors[field.id] ? 'is-invalid' : ''
                }`}
                classNamePrefix="select"
                styles={{
                  control: base => ({
                    ...base,
                    borderColor: fieldErrors[field.id]
                      ? '#dc3545'
                      : base.borderColor,
                    cursor: 'pointer'
                  }),
                  option: base => ({
                    ...base,
                    cursor: 'pointer'
                  }),
                  multiValue: base => ({
                    ...base,
                    cursor: 'pointer'
                  })
                }}
                value={
                  editedResponses[field.id]?.responseValue
                    ? safeJSONParse(
                        editedResponses[field.id].responseValue
                      ).map((value: string) => ({
                        value,
                        label: value
                      }))
                    : response.responseValue
                    ? safeJSONParse(response.responseValue).map(
                        (value: string) => ({
                          value,
                          label: value
                        })
                      )
                    : []
                }
                onChange={selectedOptions => {
                  const selectedValues =
                    selectedOptions?.map(
                      (option: { value: string }) => option.value
                    ) || [];
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: JSON.stringify(selectedValues)
                    }
                  }));
                }}
                options={field.employeeTemplateFieldOption.map(option => ({
                  value: option.optionValue,
                  label: option.optionValue
                }))}
                isClearable={true}
                placeholder={`Pilih ${field.label}`}
              />
              {fieldErrors[field.id] && (
                <div className="text-danger small mt-1">
                  {fieldErrors[field.id]}
                </div>
              )}
            </>
          ) : field.fieldType.code === 'DROPDOWN_LIST' ? (
            <>
              <Form.Select
                value={
                  editedResponses[field.id]?.options?.findIndex(
                    opt => opt.selectedChoice
                  ) ??
                  response.employeeTemplateOptionResponse.findIndex(
                    opt => opt.selectedChoice
                  )
                }
                onChange={e => {
                  const selectedIndex = Number(e.target.value);
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      options: field.employeeTemplateFieldOption.map(
                        (_, idx) => ({
                          id: response.employeeTemplateOptionResponse[idx].id,
                          selectedChoice: idx === selectedIndex
                        })
                      )
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
              >
                <option value="">Pilih {field.label}</option>
                {field.employeeTemplateFieldOption.map((option, idx) => (
                  <option key={option.id} value={idx}>
                    {option.optionValue}
                  </option>
                ))}
              </Form.Select>
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          ) : field.fieldType.code === 'RADIO_BUTTONS' ? (
            <div>
              {field.employeeTemplateFieldOption.map((option, idx) => {
                const currentResponse = response
                  .employeeTemplateOptionResponse?.[idx] || {
                  id: `temp-${idx}`,
                  selectedChoice: false
                };

                return (
                  <Form.Check
                    key={option.id}
                    type="radio"
                    name={`radio-${field.id}`}
                    label={option.optionValue}
                    checked={
                      editedResponses[field.id]?.options
                        ? editedResponses[field.id].options?.[idx]
                            ?.selectedChoice
                        : currentResponse?.selectedChoice
                    }
                    onChange={() => {
                      setEditedResponses(prev => ({
                        ...prev,
                        [field.id]: {
                          responseValue: option.optionValue,
                          options: field.employeeTemplateFieldOption.map(
                            (_, optIdx) => ({
                              id:
                                response.employeeTemplateOptionResponse?.[
                                  optIdx
                                ]?.id || `temp-${optIdx}`,
                              optionId:
                                field.employeeTemplateFieldOption[optIdx].id,
                              selectedChoice: idx === optIdx
                            })
                          )
                        }
                      }));
                    }}
                  />
                );
              })}
              {fieldErrors[field.id] && (
                <div className="text-danger small mt-1">
                  {fieldErrors[field.id]}
                </div>
              )}
            </div>
          ) : field.fieldType.code === 'CHECKBOXES' ? (
            <div>
              {field.employeeTemplateFieldOption.map((option, idx) => {
                const currentResponse =
                  response.employeeTemplateOptionResponse[idx];
                return (
                  <Form.Check
                    key={option.id}
                    type="checkbox"
                    label={option.optionValue}
                    checked={
                      editedResponses[field.id]?.options?.[idx]
                        ?.selectedChoice ?? currentResponse?.selectedChoice
                    }
                    onChange={() => {
                      const currentOptions =
                        editedResponses[field.id]?.options ||
                        response.employeeTemplateOptionResponse.map(opt => ({
                          id: opt.id,
                          selectedChoice: opt.selectedChoice
                        }));

                      setEditedResponses(prev => ({
                        ...prev,
                        [field.id]: {
                          options: currentOptions.map((opt, optIdx) =>
                            optIdx === idx
                              ? { ...opt, selectedChoice: !opt.selectedChoice }
                              : opt
                          )
                        }
                      }));
                    }}
                  />
                );
              })}
              {fieldErrors[field.id] && (
                <div className="text-danger small mt-1">
                  {fieldErrors[field.id]}
                </div>
              )}
            </div>
          ) : field.fieldType.code === 'MULTIPLE_LINE_TEXT' ? (
            <>
              <Form.Control
                as="textarea"
                rows={3}
                value={
                  editedResponses[field.id]?.responseValue ??
                  response.responseValue
                }
                onChange={e => {
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: e.target.value
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
                maxLength={field.fieldTextLength}
                placeholder={`Masukkan ${field.label}`}
              />
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          ) : field.fieldType.code === 'DATE' ? (
            <>
              <Form.Control
                type="date"
                value={
                  editedResponses[field.id]?.responseValue ??
                  response.responseValue
                }
                onChange={e => {
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: e.target.value
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
              />
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            <>
              <Form.Control
                type="text"
                value={
                  editedResponses[field.id]?.responseValue ??
                  response.responseValue
                }
                onChange={e => {
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: e.target.value
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
                maxLength={field.fieldTextLength}
              />
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>
      );
    });
  };

  // Tambahkan fungsi untuk mengecek perubahan
  const hasChanges = () => {
    return Object.keys(editedResponses).length > 0;
  };

  // Update fungsi handleSave
  const handleSave = async () => {
    if (!validateFields()) return;

    // Tambahkan konfirmasi sebelum menyimpan
    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Simpan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const updatePayload = employeeTemplateResponses.map(response => {
            const editedResponse = editedResponses[response.field.id];
            const hasOptions = [
              'CHECKBOXES',
              'RADIO_BUTTONS',
              'DROPDOWN_LIST',
              'MULTI_SELECT_LIST'
            ].includes(response.field.fieldType.code);

            // Untuk radio button, hanya satu opsi yang bisa dipilih
            const optionResponse = hasOptions
              ? response.employeeTemplateOptionResponse.map((option, idx) => {
                  const isEdited =
                    editedResponse?.options?.[idx]?.selectedChoice;
                  // Jika ada perubahan, gunakan nilai yang diedit
                  if (editedResponse?.options) {
                    return {
                      id: option.id,
                      optionId:
                        response.field.employeeTemplateFieldOption[idx].id,
                      selectedChoice: isEdited || false
                    };
                  }
                  // Jika tidak ada perubahan, gunakan nilai asli
                  return {
                    id: option.id,
                    optionId:
                      response.field.employeeTemplateFieldOption[idx].id,
                    selectedChoice: option.selectedChoice
                  };
                })
              : undefined;

            return {
              employeeTemplateResponseId: response.id,
              employeeId: employeeId,
              fieldId: response.field.id,
              responseValue:
                editedResponse?.responseValue ?? response.responseValue,
              ...(hasOptions && { optionResponse })
            };
          });

          await updateTemplateResponse(updatePayload).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data template berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              setEditedResponses({});
              refetch();
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Update fungsi handleCancel
  const handleCancel = () => {
    if (hasChanges()) {
      showCustomAlert({
        icon: 'warning',
        label: 'Konfirmasi Batal',
        deskripsi:
          'Perubahan yang Anda buat akan hilang. Apakah Anda yakin ingin membatalkan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setIsEditing(false);
          setEditedResponses({});
        }
      });
    } else {
      setIsEditing(false);
    }
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>{template?.templateName || '-'}</h3>
            {!isEditing && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(true)}
              >
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {!isEditing ? (
          <div>{renderTemplateFields()}</div>
        ) : (
          <Form>
            {renderTemplateFields()}

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default EmployeeTemplateCard;
