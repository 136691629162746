import { Row, Spinner } from 'react-bootstrap';

const IntrospectLoading = () => {
  return (
    <Row className="flex-center content-min-h pb-9">
      <Spinner
        animation="grow"
        variant="primary"
        style={{
          width: '4rem',
          height: '4rem'
        }}
      />
    </Row>
  );
};

export default IntrospectLoading;
