import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CustomerTemplateResponse,
  SingleCustomerTemplateResponse,
  CustomerTemplateFieldResponse,
  AddTemplateFieldRequest,
  AddTemplateFieldResponse,
  CustomerTemplateFieldDetail
} from './response/CustomerTemplateResponse';
import { BaseResponse } from './response/BaseResponse';
import { CustomerTemplateResponseData } from './request/CustomerCreateReq';

export interface CustomerTemplateParams {
  page?: number;
  limit?: number;
  orderBy?: string;
  direction?: 'ASC' | 'DESC';
  templateName?: string;
  status?: string;
}

interface AddTemplateRequest {
  templateName: string;
  formType: string;
  status: boolean;
}

interface TemplateFieldOption {
  id: string;
  optionValue: string;
  selectedChoice: boolean;
}

export interface CustomerTemplateResponseDetail {
  id: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  responseValue: string;
  customer: {
    id: string;
    // ... customer fields
  };
  field: {
    id: string;
    fieldName: string;
    fieldGroup: string;
    label: string;
    isRequired: boolean;
    fieldSize: string;
    fieldTextLength: number;
    fieldType: {
      id: string;
      name: string;
      code: string;
    };
    customerTemplateFieldOption: TemplateFieldOption[];
    value?: string;
  };
  customerTemplateOptionResponse: TemplateFieldOption[];
}

export interface CustomerTemplateResponseResult {
  code: number;
  message: string;
  data: CustomerTemplateResponseDetail[];
}

interface UpdateTemplateResponseRequest {
  customerTemplateResponseId: string;
  customerId: number;
  fieldId: number;
  responseValue?: string;
  optionResponse?: {
    id: number;
    optionId: number;
    selectedChoice: boolean;
  }[];
}

interface DuplicateTemplateRequest {
  customerTemplateId: string;
  customerTemplateDuplicateName: string;
}

export const customerTemplateApi = createApi({
  reducerPath: 'customerTemplate',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['CustomerTemplateField'],
  endpoints: builder => ({
    getCustomerTemplate: builder.query<
      CustomerTemplateResponse,
      CustomerTemplateParams
    >({
      query: (
        params = {
          page: 1,
          limit: 10,
          orderBy: 'createdDate',
          direction: 'DESC'
        }
      ) => ({
        url: '/api/v1/customer-template',
        params
      })
    }),

    addCustomerTemplate: builder.mutation<
      SingleCustomerTemplateResponse,
      AddTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/customer-template',
        method: 'POST',
        body
      })
    }),

    getCustomerTemplateField: builder.query<
      CustomerTemplateFieldResponse,
      number
    >({
      query: templateId => ({
        url: `/api/v1/customer-template-field`,
        params: { templateId }
      }),
      providesTags: ['CustomerTemplateField']
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCustomerTemplateFieldTypes: builder.query<any, void>({
      query: () => ({
        url: '/api/v1/customer-template-field-type'
      })
    }),

    deleteCustomerTemplate: builder.mutation<
      { code: number; message: string },
      string
    >({
      query: id => ({
        url: `/api/v1/customer-template/${id}`,
        method: 'DELETE'
      })
    }),

    getCustomerTemplateById: builder.query<
      SingleCustomerTemplateResponse,
      number
    >({
      query: id => ({
        url: `/api/v1/customer-template/${id}`
      })
    }),

    addCustomerTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      AddTemplateFieldRequest
    >({
      query: body => ({
        url: '/api/v1/customer-template-field',
        method: 'POST',
        body
      }),
      invalidatesTags: ['CustomerTemplateField']
    }),

    deleteCustomerTemplateField: builder.mutation<
      { code: number; message: string },
      number
    >({
      query: id => ({
        url: `/api/v1/customer-template-field/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CustomerTemplateField']
    }),

    editCustomerTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      { id: string; data: AddTemplateFieldRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/customer-template-field/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['CustomerTemplateField']
    }),

    getCustomerTemplateFieldById: builder.query<
      { code: number; message: string; data: CustomerTemplateFieldDetail },
      number
    >({
      query: id => ({
        url: `/api/v1/customer-template-field/${id}`
      }),
      providesTags: ['CustomerTemplateField']
    }),

    editCustomerTemplate: builder.mutation<
      SingleCustomerTemplateResponse,
      { id: string; data: AddTemplateRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/customer-template/${id}`,
        method: 'PUT',
        body: data
      })
    }),

    addCustomerTemplateResponse: builder.mutation<
      BaseResponse,
      {
        customerId: number;
        responses: CustomerTemplateResponseData[];
      }
    >({
      query: ({ customerId, responses }) => ({
        url: '/api/v1/customer-template-response',
        method: 'POST',
        body: { customerId, responses }
      })
    }),

    getCustomerTemplateResponse: builder.query<
      CustomerTemplateResponseResult,
      void
    >({
      query: () => '/api/v1/customer-template-response'
    }),

    getCustomerTemplateResponseById: builder.query<
      CustomerTemplateResponseResult,
      string
    >({
      query: id => `/api/v1/customer-template-response/${id}`
    }),

    updateCustomerTemplateResponse: builder.mutation<
      BaseResponse,
      UpdateTemplateResponseRequest[]
    >({
      query: body => ({
        url: '/api/v1/customer-template-response',
        method: 'PUT',
        body
      })
    }),

    updateTemplateFieldPosition: builder.mutation<
      BaseResponse,
      { fieldId: number; newPosition: number }
    >({
      query: ({ fieldId, newPosition }) => ({
        url: `/api/v1/customer-template-field/drag-and-drop/${fieldId}`,
        method: 'PUT',
        body: { newPosition }
      }),
      invalidatesTags: ['CustomerTemplateField']
    }),
    duplicateCustomerTemplate: builder.mutation<
      SingleCustomerTemplateResponse,
      DuplicateTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/customer-template/duplicate',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetCustomerTemplateQuery,
  useAddCustomerTemplateMutation,
  useDeleteCustomerTemplateMutation,
  useGetCustomerTemplateFieldQuery,
  useGetCustomerTemplateFieldTypesQuery,
  useGetCustomerTemplateByIdQuery,
  useAddCustomerTemplateFieldMutation,
  useDeleteCustomerTemplateFieldMutation,
  useEditCustomerTemplateFieldMutation,
  useGetCustomerTemplateFieldByIdQuery,
  useEditCustomerTemplateMutation,
  useAddCustomerTemplateResponseMutation,
  useGetCustomerTemplateResponseQuery,
  useGetCustomerTemplateResponseByIdQuery,
  useUpdateCustomerTemplateResponseMutation,
  useUpdateTemplateFieldPositionMutation,
  useDuplicateCustomerTemplateMutation
} = customerTemplateApi;
