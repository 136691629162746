import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

// Definisikan interface untuk data kebijakan
export interface Kebijakan {
  id: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  status: 'active' | 'inactive' | 'expired';
}

// Dummy data untuk testing
export const dummyKebijakan: Kebijakan[] = [
  {
    id: 1,
    name: 'Kebijakan Work From Home',
    description:
      'Kebijakan terkait pelaksanaan kerja dari rumah selama pandemi',
    startDate: '2024-01-01',
    endDate: '2024-12-31',
    createdAt: '2023-12-25',
    status: 'active'
  },
  {
    id: 2,
    name: 'Kebijakan Cuti Tahunan',
    description: 'Ketentuan pengambilan cuti tahunan karyawan',
    startDate: '2024-01-01',
    endDate: '2024-12-31',
    createdAt: '2023-12-20',
    status: 'active'
  },
  {
    id: 3,
    name: 'Kebijakan Lembur',
    description: 'Aturan dan kompensasi kerja lembur',
    startDate: '2023-06-01',
    endDate: '2023-12-31',
    createdAt: '2023-05-15',
    status: 'expired'
  }
];

const getStatusBadge = (status: Kebijakan['status']) => {
  const variants = {
    active: 'success',
    inactive: 'warning',
    expired: 'danger'
  };
  const labels = {
    active: 'Aktif',
    inactive: 'Tidak Aktif',
    expired: 'Kadaluarsa'
  };
  return (
    <Badge bg={variants[status]} className="fs-10">
      {labels[status]}
    </Badge>
  );
};

export const kebijakanTableColumns: ColumnDef<Kebijakan>[] = [
  {
    accessorKey: 'name',
    header: 'Nama Kebijakan',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to={`/layanan/kebijakan/detail/${original.id}`}
          className="d-flex align-items-center text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{original.name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'description',
    header: 'Deskripsi',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.description}</span>;
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'startDate',
    header: 'Tanggal Berlaku',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.startDate}</span>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'endDate',
    header: 'Tanggal Berakhir',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.endDate}</span>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Tanggal Dibuat',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.createdAt}</span>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return <div className="text-end">{getStatusBadge(original.status)}</div>;
    },
    meta: {
      headerProps: { style: { width: '9%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2' }
    }
  }
];

interface KebijakanTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  kebijakanData?: {
    totalRecords?: number;
  };
}

const KebijakanTable = ({
  onPageChange,
  currentPage,
  totalPages,
  kebijakanData
}: KebijakanTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={kebijakanData?.totalRecords || 0}
      />
    </div>
  );
};

export default KebijakanTable;
