import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppRootState } from 'app/store';
import { useSelector } from 'react-redux';

export type IBaseAutorities =
  | 'EXPORT_CUSTOMER'
  | 'CUSTOMER_DATA'
  | 'CUSTOMER_NOTE'
  | 'CUSTOMER_TEMPLATE'
  | 'CUSTOMER_SETTINGS'
  | 'USER_MANAGEMENT'
  | 'ROLE_MANAGEMENT';

export type IAuthoritiesAction = 'NONE' | 'READ' | 'WRITE' | 'FULL';

export type IAuthorities =
  | 'EXPORT_CUSTOMER_READ'
  | 'EXPORT_CUSTOMER_WRITE'
  | 'EXPORT_CUSTOMER_FULL'
  | 'CUSTOMER_DATA_READ'
  | 'CUSTOMER_DATA_WRITE'
  | 'CUSTOMER_DATA_FULL'
  | 'CUSTOMER_NOTE_READ'
  | 'CUSTOMER_NOTE_WRITE'
  | 'CUSTOMER_NOTE_FULL'
  | 'CUSTOMER_TEMPLATE_READ'
  | 'CUSTOMER_TEMPLATE_WRITE'
  | 'CUSTOMER_TEMPLATE_FULL'
  | 'CUSTOMER_SETTINGS_READ'
  | 'CUSTOMER_SETTINGS_WRITE'
  | 'CUSTOMER_SETTINGS_FULL'
  | 'USER_MANAGEMENT_READ'
  | 'USER_MANAGEMENT_WRITE'
  | 'USER_MANAGEMENT_FULL'
  | 'ROLE_MANAGEMENT_READ'
  | 'ROLE_MANAGEMENT_WRITE'
  | 'ROLE_MANAGEMENT_FULL';

export interface IAuthoritiesStatus {
  isloading: boolean;
  privileges: IAuthorities[];
}

export const privilegeSlice = createSlice({
  name: 'privilege-slice',
  initialState: {
    isloading: true,
    privileges: []
  } as IAuthoritiesStatus,
  reducers: {
    update: (state, action: PayloadAction<IAuthoritiesStatus>) => {
      return {
        isloading: action.payload.isloading,
        privileges: action.payload.privileges
      };
    }
  }
});

export const hasPrivilege = (privilege: IAuthorities): boolean => {
  const privilegeState = useSelector(
    (state: AppRootState) => state['privilege-slice']
  );

  const types = ['READ', 'WRITE', 'FULL'];
  const menuList = new String(privilege).split('_');
  const menu = menuList.slice(0, -1).join('_');

  const userAccess = privilegeState.privileges.filter(p => p.startsWith(menu));

  if (userAccess.length == 0) return false;

  const currentAccessLevel = userAccess[0].split('_').at(-1);

  const menulevel = types.findIndex(t => t == menuList.at(-1));
  const currentlevel = types.findIndex(t => t == currentAccessLevel);

  return currentlevel >= menulevel;
};

export const { update } = privilegeSlice.actions;

export default privilegeSlice.reducer;
