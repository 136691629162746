import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

const ConnectionStatusToast = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const toastId = useRef<string | number | null>(null);
  const hasConnectionChanged = useRef(false);

  useEffect(() => {
    // Handler ketika koneksi online
    const handleOnline = () => {
      setIsOnline(true);

      // Tampilkan toast hanya jika sebelumnya pernah offline
      if (hasConnectionChanged.current) {
        // Dismiss toast error sebelumnya jika ada
        if (toastId.current) {
          toast.dismiss(toastId.current);
        }

        toast.success('Anda terhubung dengan internet', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: 'online-toast'
        });
      }
    };

    // Handler ketika koneksi offline
    const handleOffline = () => {
      setIsOnline(false);
      hasConnectionChanged.current = true;

      // Simpan ID toast error untuk bisa di-dismiss nanti
      toastId.current = toast.error('Tidak ada koneksi internet', {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: 'offline-toast'
      });
    };

    // Menambahkan event listener
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cek status koneksi awal
    if (!navigator.onLine) {
      handleOffline();
    }

    // Cleanup event listener
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);

      // Bersihkan semua toast saat komponen unmount
      toast.dismiss('online-toast');
      toast.dismiss('offline-toast');
    };
  }, []); // Dependency array kosong agar effect hanya dijalankan sekali

  return null;
};

export default ConnectionStatusToast;
