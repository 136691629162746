import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  EmployeeTemplateResponse,
  SingleEmployeeTemplateResponse,
  EmployeeTemplateFieldResponse,
  AddTemplateFieldRequest,
  AddTemplateFieldResponse,
  EmployeeTemplateFieldDetail
} from './response/EmployeeTemplateResponse';
import { BaseResponse } from './response/BaseResponse';

export interface EmployeeTemplateParams {
  page?: number;
  limit?: number;
  orderBy?: string;
  direction?: 'ASC' | 'DESC';
  templateName?: string;
  status?: string;
}

interface AddTemplateRequest {
  templateName: string;
  formType: string;
  status: boolean;
}

interface TemplateFieldOption {
  id: string;
  optionValue: string;
  selectedChoice: boolean;
}

export interface EmployeeTemplateResponseDetail {
  id: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  responseValue: string;
  employee: {
    id: string;
    // ... employee fields
  };
  field: {
    id: string;
    fieldName: string;
    fieldGroup: string;
    label: string;
    isRequired: boolean;
    fieldSize: string;
    fieldTextLength: number;
    fieldType: {
      id: string;
      name: string;
      code: string;
    };
    employeeTemplateFieldOption: TemplateFieldOption[];
    value?: string;
  };
  employeeTemplateOptionResponse: TemplateFieldOption[];
}

export interface EmployeeTemplateResponseResult {
  code: number;
  message: string;
  data: EmployeeTemplateResponseDetail[];
}

export interface UpdateTemplateResponseRequest {
  employeeTemplateResponseId: string;
  employeeId: string;
  fieldId: string;
  responseValue: string;
  optionResponse?: {
    id: string;
    optionId: string;
    selectedChoice: boolean;
  }[];
}

interface DuplicateTemplateRequest {
  employeeTemplateId: string;
  employeeTemplateDuplicateName: string;
}

export const employeeTemplateApi = createApi({
  reducerPath: 'employeeTemplate',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['EmployeeTemplateField'],
  endpoints: builder => ({
    getEmployeeTemplate: builder.query<
      EmployeeTemplateResponse,
      EmployeeTemplateParams
    >({
      query: (
        params = {
          page: 1,
          limit: 10,
          orderBy: 'createdDate',
          direction: 'DESC'
        }
      ) => ({
        url: '/api/v1/employee/template',
        params
      })
    }),

    addEmployeeTemplate: builder.mutation<
      SingleEmployeeTemplateResponse,
      AddTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/employee/template',
        method: 'POST',
        body
      })
    }),

    getEmployeeTemplateField: builder.query<
      EmployeeTemplateFieldResponse,
      number
    >({
      query: templateId => ({
        url: `/api/v1/employee/template/field`,
        params: { templateId }
      }),
      providesTags: ['EmployeeTemplateField']
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEmployeeTemplateFieldTypes: builder.query<any, void>({
      query: () => ({
        url: '/api/v1/employee/template-field-type'
      })
    }),

    deleteEmployeeTemplate: builder.mutation<
      { code: number; message: string },
      string
    >({
      query: id => ({
        url: `/api/v1/employee/template/${id}`,
        method: 'DELETE'
      })
    }),

    getEmployeeTemplateById: builder.query<
      SingleEmployeeTemplateResponse,
      string
    >({
      query: id => ({
        url: `/api/v1/employee/template/${id}`
      })
    }),

    addEmployeeTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      AddTemplateFieldRequest
    >({
      query: body => ({
        url: '/api/v1/employee/template/field',
        method: 'POST',
        body
      }),
      invalidatesTags: ['EmployeeTemplateField']
    }),

    deleteEmployeeTemplateField: builder.mutation<BaseResponse, string>({
      query: id => ({
        url: `/api/v1/employee/template/field/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['EmployeeTemplateField']
    }),

    editEmployeeTemplateField: builder.mutation<
      AddTemplateFieldResponse,
      { id: string; data: AddTemplateFieldRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/template/field/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['EmployeeTemplateField']
    }),

    getEmployeeTemplateFieldById: builder.query<
      { code: number; message: string; data: EmployeeTemplateFieldDetail },
      string
    >({
      query: id => ({
        url: `/api/v1/employee/template/field/${id}`
      }),
      providesTags: ['EmployeeTemplateField']
    }),

    editEmployeeTemplate: builder.mutation<
      SingleEmployeeTemplateResponse,
      { id: string; data: AddTemplateRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/template/${id}`,
        method: 'PUT',
        body: data
      })
    }),

    addEmployeeTemplateResponse: builder.mutation<
      BaseResponse,
      {
        employeeId: number;
        responses: EmployeeTemplateResponseDetail[];
      }
    >({
      query: ({ employeeId, responses }) => ({
        url: '/api/v1/employee/template-response',
        method: 'POST',
        body: { employeeId, responses }
      })
    }),

    getEmployeeTemplateResponse: builder.query<
      EmployeeTemplateResponseResult,
      {
        templateId: string;
        employeeId: string;
        status?: boolean;
        limit?: number;
      }
    >({
      query: ({ templateId, employeeId, limit = 100 }) =>
        `/api/v1/employee/template/template-response/employee-template/${templateId}/employee/${employeeId}?limit=${limit}`
    }),

    getEmployeeTemplateResponseById: builder.mutation<
      EmployeeTemplateResponseResult,
      string
    >({
      query: id => ({
        url: `/api/v1/employee/template/template-response/${id}`,
        method: 'GET'
      })
    }),

    updateEmployeeTemplateResponse: builder.mutation<
      BaseResponse,
      UpdateTemplateResponseRequest[]
    >({
      query: body => ({
        url: '/api/v1/employee/template/template-response/',
        method: 'PUT',
        body
      })
    }),

    updateTemplateFieldPosition: builder.mutation<
      BaseResponse,
      { fieldId: string; newPosition: number }
    >({
      query: ({ fieldId, newPosition }) => ({
        url: `/api/v1/employee/template/field/drag-and-drop/${fieldId}`,
        method: 'PUT',
        body: { newPosition }
      }),
      invalidatesTags: ['EmployeeTemplateField']
    }),
    duplicateEmployeeTemplate: builder.mutation<
      SingleEmployeeTemplateResponse,
      DuplicateTemplateRequest
    >({
      query: body => ({
        url: '/api/v1/employee/template/duplicate',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetEmployeeTemplateQuery,
  useAddEmployeeTemplateMutation,
  useDeleteEmployeeTemplateMutation,
  useGetEmployeeTemplateFieldQuery,
  useGetEmployeeTemplateFieldTypesQuery,
  useGetEmployeeTemplateByIdQuery,
  useAddEmployeeTemplateFieldMutation,
  useDeleteEmployeeTemplateFieldMutation,
  useEditEmployeeTemplateFieldMutation,
  useGetEmployeeTemplateFieldByIdQuery,
  useEditEmployeeTemplateMutation,
  useAddEmployeeTemplateResponseMutation,
  useGetEmployeeTemplateResponseQuery,
  useGetEmployeeTemplateResponseByIdMutation,
  useUpdateEmployeeTemplateResponseMutation,
  useUpdateTemplateFieldPositionMutation,
  useDuplicateEmployeeTemplateMutation
} = employeeTemplateApi;
