import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useValidatePasswordMutation } from 'api/authApi';
import { toast } from 'react-toastify';

interface KonfirmasiPasswordModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  userEmail?: string;
}

const KonfirmasiPasswordModal = ({
  show,
  onHide,
  onConfirm,
  userEmail
}: KonfirmasiPasswordModalProps) => {
  const [formData, setFormData] = useState({
    email: userEmail || '',
    password: ''
  });
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [validatePassword, { isLoading }] = useValidatePasswordMutation();

  const validateForm = () => {
    const newErrors: { email?: string; password?: string } = {};

    // Validasi password saja
    if (!formData.password.trim()) {
      newErrors.password = 'Password harus diisi';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await validatePassword({
        email: userEmail || '', // Gunakan userEmail yang diterima dari props
        password: formData.password
      }).unwrap();

      if (response.code === 200) {
        setFormData({ email: userEmail || '', password: '' });
        setErrors({});
        onConfirm();
      } else {
        setErrors({
          ...errors,
          password: 'Password yang Anda masukkan salah'
        });
      }
    } catch (error) {
      console.error('Validation error:', error);
      setErrors({
        ...errors,
        password: 'Password yang Anda masukkan salah'
      });
      toast.error('Gagal memvalidasi akun');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="px-5 py-4 bg-modal-header">
        <Modal.Title className="fw-bolder">Ubah Informasi Akun</Modal.Title>
        <div className="ms-auto">
          <Button variant="link" className="text-danger me-2" onClick={onHide}>
            Batalkan
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="konfirmasiPasswordForm"
            disabled={isLoading}
          >
            {isLoading ? 'Memproses' : 'Konfirmasi'}
          </Button>
        </div>
      </Modal.Header>
      <Form id="konfirmasiPasswordForm" onSubmit={handleSubmit}>
        <Modal.Body className="px-5 py-4 bg-light">
          <p>
            Untuk mengubah informasi pada akun, silakan masukkan kata sandi Anda
            terlebih dahulu untuk memastikan bahwa Anda adalah pemilik sah akun
            tersebut.
          </p>

          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
              Password <span className="text-danger">*</span>
            </Form.Label>
            <div className="position-relative">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Masukkan password akun"
                value={formData.password}
                onChange={e => {
                  setFormData({ ...formData, password: e.target.value });
                  setErrors({ ...errors, password: undefined });
                }}
                isInvalid={!!errors.password}
                style={{
                  paddingRight: '40px',
                  backgroundImage: 'none'
                }}
              />
              <Button
                variant="link"
                className="position-absolute top-50 end-0 translate-middle-y text-body pe-3"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
                style={{ zIndex: 5 }}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="fs-9"
                />
              </Button>
            </div>
            {errors.password && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default KonfirmasiPasswordModal;
