import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Button,
  Form,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useGetServiceByIdQuery } from 'api/serviceApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetCustomerQuery } from 'api/customerApi';

interface PriceRow {
  id: string;
  title: string;
  duration: string;
  durationUnit: string;
  customerType: string;
  location: string;
  price: string;
}

interface RatedPriceRow {
  id: string;
  title: string;
  rate: string;
  minDuration: string;
  maxDuration: string;
  customerType: string;
  location: string;
  price: string;
}

interface ServicePricing {
  id: string;
  title: string;
  duration: number;
  durationType: string;
  min: number | null;
  max: number | null;
  location: string[];
  price: string;
  taxPolicy: string;
  customer: string[];
}

// Tambahkan interface untuk mapping customer
interface CustomerMapping {
  [fullName: string]: string; // key: fullName, value: id
}

const ServicePriceCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: serviceData, refetch } = useGetServiceByIdQuery(id || '');
  const [isEditing, setIsEditing] = useState(false);
  const [priceType, setPriceType] = useState('fixed');
  const [formData, setFormData] = useState({
    price: '',
    tax: '',
    pricingType: ''
  });

  // State untuk fixed price rows
  const [priceRows, setPriceRows] = useState<PriceRow[]>([
    {
      id: '1',
      title: '',
      duration: '0',
      durationUnit: 'Menit',
      customerType: '',
      location: '',
      price: ''
    }
  ]);

  // State untuk rated price rows
  const [ratedPriceRows, setRatedPriceRows] = useState<RatedPriceRow[]>([
    {
      id: '1',
      title: '',
      rate: 'per Menit',
      minDuration: '0',
      maxDuration: '0',
      customerType: '',
      location: '',
      price: ''
    }
  ]);

  // Fetch customer data
  const { data: customerData } = useGetCustomerQuery({
    page: 1,
    limit: 100,
    orderBy: 'fullName',
    direction: 'ASC'
  });

  // Tambahkan state untuk mapping customer
  const [customerMapping, setCustomerMapping] = useState<CustomerMapping>({});

  useEffect(() => {
    // Mapping customer fullName ke id
    if (customerData?.data) {
      const mapping: CustomerMapping = {};
      customerData.data.forEach(customer => {
        mapping[customer.fullName] = customer.id;
      });
      setCustomerMapping(mapping);
    }
  }, [customerData]);

  useEffect(() => {
    if (serviceData?.data) {
      setFormData({
        price: '',
        tax: '',
        pricingType: serviceData.data.pricingType || ''
      });
      setPriceType(serviceData.data.pricingType?.toLowerCase() || 'fixed');

      // Set initial price rows based on API data
      if (serviceData.data.clinicalServicePricing?.length > 0) {
        if (serviceData.data.pricingType?.toLowerCase() === 'fixed') {
          const initialPriceRows = serviceData.data.clinicalServicePricing.map(
            pricing => ({
              id: pricing.id || Date.now().toString(),
              title: pricing.title || '',
              duration: pricing.duration?.toString() || '0',
              durationUnit: pricing.durationType || 'Menit',
              customerType: pricing.customer?.[0] || '', // Simpan fullName untuk display
              location: pricing.location?.[0] || '',
              price: pricing.price || ''
            })
          );
          setPriceRows(initialPriceRows);
        } else {
          const initialRatedRows = serviceData.data.clinicalServicePricing.map(
            pricing => ({
              id: pricing.id || Date.now().toString(),
              title: pricing.title || '',
              rate: `per ${pricing.durationType || 'Menit'}`,
              minDuration: pricing.min?.toString() || '0',
              maxDuration: pricing.max?.toString() || '0',
              customerType: pricing.customer?.[0] || '', // Simpan fullName untuk display
              location: pricing.location?.[0] || '',
              price: pricing.price || ''
            })
          );
          setRatedPriceRows(initialRatedRows);
        }
      }
    }
  }, [serviceData]);

  // Tambahkan useEffect terpisah untuk update customerType setelah mapping tersedia
  useEffect(() => {
    if (Object.keys(customerMapping).length > 0) {
      if (priceType === 'fixed') {
        setPriceRows(prev =>
          prev.map(row => ({
            ...row,
            customerType: row.id.length > 5 ? row.customerType : ''
          }))
        );
      } else {
        setRatedPriceRows(prev =>
          prev.map(row => ({
            ...row,
            customerType: row.id.length > 5 ? row.customerType : ''
          }))
        );
      }
    }
  }, [customerMapping, priceType]);

  // Tambahkan fungsi untuk mengecek perubahan data
  const hasChanges = () => {
    if (!serviceData?.data?.clinicalServicePricing) return false;

    const currentPricing = priceType === 'fixed' ? priceRows : ratedPriceRows;
    const originalPricing = serviceData.data.clinicalServicePricing;

    // Jika jumlah row berbeda, berarti ada perubahan
    if (currentPricing.length !== originalPricing.length) return true;

    // Bandingkan setiap row
    return currentPricing.some((row, index) => {
      const original = originalPricing[index];
      if (!original) return true;

      if (priceType === 'fixed') {
        const fixedRow = row as PriceRow;
        return (
          fixedRow.title !== original.title ||
          fixedRow.duration !== original.duration?.toString() ||
          fixedRow.durationUnit.toLowerCase() !== original.durationType ||
          fixedRow.customerType !== original.customer?.[0] ||
          fixedRow.location !== (original.location?.[0] || '') ||
          fixedRow.price !== original.price
        );
      } else {
        const ratedRow = row as RatedPriceRow;
        return (
          ratedRow.title !== original.title ||
          ratedRow.rate !== `per ${original.durationType}` ||
          ratedRow.minDuration !== original.min?.toString() ||
          ratedRow.maxDuration !== original.max?.toString() ||
          ratedRow.customerType !== original.customer?.[0] ||
          ratedRow.location !== (original.location?.[0] || '') ||
          ratedRow.price !== original.price
        );
      }
    });
  };

  const handleEditClick = () => setIsEditing(true);

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        // Reset ke data original
        if (serviceData?.data?.clinicalServicePricing) {
          if (priceType === 'fixed') {
            const initialPriceRows =
              serviceData.data.clinicalServicePricing.map(pricing => ({
                id: pricing.id || Date.now().toString(),
                title: pricing.title || '',
                duration: pricing.duration?.toString() || '0',
                durationUnit: pricing.durationType || 'Menit',
                customerType: pricing.customer?.[0] || '', // Simpan fullName
                location: pricing.location?.[0] || '',
                price: pricing.price || ''
              }));
            setPriceRows(initialPriceRows);
          } else {
            const initialRatedRows =
              serviceData.data.clinicalServicePricing.map(pricing => ({
                id: pricing.id || Date.now().toString(),
                title: pricing.title || '',
                rate: `per ${pricing.durationType || 'Menit'}`,
                minDuration: pricing.min?.toString() || '0',
                maxDuration: pricing.max?.toString() || '0',
                customerType: pricing.customer?.[0] || '',
                location: pricing.location?.[0] || '',
                price: pricing.price || ''
              }));
            setRatedPriceRows(initialRatedRows);
          }
        }
      }
    });
  };

  const handleSave = () => {
    const formatPricingData = () => {
      if (priceType === 'fixed') {
        return priceRows.map(row => ({
          id: row.id,
          title: row.title,
          duration: parseInt(row.duration) || null,
          durationType: row.durationUnit.toLowerCase(),
          min: null,
          max: null,
          location: row.location ? [row.location] : [],
          price: row.price,
          taxPolicy: 'VAT',
          customer: row.customerType ? [row.customerType] : [] // Kirim customer ID
        }));
      } else {
        return ratedPriceRows.map(row => ({
          id: row.id,
          title: row.title,
          duration: null,
          durationType: row.rate.split(' ')[1].toLowerCase(),
          min: parseInt(row.minDuration) || null,
          max: parseInt(row.maxDuration) || null,
          location: row.location ? [row.location] : [],
          price: row.price,
          taxPolicy: 'VAT',
          customer: row.customerType ? [row.customerType] : [] // Kirim customer ID
        }));
      }
    };

    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi: 'Apakah Anda yakin data harga layanan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Format data sesuai kebutuhan API
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const pricingData = formatPricingData();

          // Implementasi save API dengan data yang sudah diformat
          // await updateService({ id, clinicalServicePricing: pricingData });

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              refetch();
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleAddPriceRow = () => {
    setPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        title: '',
        duration: '0',
        durationUnit: 'Menit',
        customerType: '',
        location: '',
        price: ''
      }
    ]);
  };

  const handleRemovePriceRow = (id: string) => {
    if (priceRows.length > 1) {
      setPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handlePriceRowChange = (
    id: string,
    field: keyof PriceRow,
    value: string
  ) => {
    setPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  const handleAddRatedPriceRow = () => {
    setRatedPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        title: '',
        rate: 'per Menit',
        minDuration: '0',
        maxDuration: '0',
        customerType: '',
        location: '',
        price: ''
      }
    ]);
  };

  const handleRemoveRatedPriceRow = (id: string) => {
    if (ratedPriceRows.length > 1) {
      setRatedPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handleRatedPriceRowChange = (
    id: string,
    field: keyof RatedPriceRow,
    value: string
  ) => {
    setRatedPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex align-items-center mb-2">
          <h3 className="mb-0">Harga Layanan</h3>
          {!isEditing && (
            <Button variant="ghost" size="sm" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
            </Button>
          )}
        </div>

        {!isEditing ? (
          <>
            {/* Tampilkan tipe harga hanya saat mode display */}
            <Row className="mb-3">
              <Col xs={6}>
                <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                  Tipe Harga
                </Form.Label>
                <span className="d-block">{formData.pricingType || '-'}</span>
              </Col>
              <Col xs={6}>
                <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                  Pajak
                </Form.Label>
                <span className="d-block">
                  {formData.tax ? `${formData.tax}%` : '-'}
                </span>
              </Col>
            </Row>

            <Table responsive className="fs-9">
              <thead>
                <tr>
                  <th className="ps-0">NAMA LAYANAN</th>
                  <th>DURASI</th>
                  <th>PELANGGAN</th>
                  <th>LOKASI</th>
                  <th>HARGA</th>
                </tr>
              </thead>
              <tbody>
                {serviceData?.data?.clinicalServicePricing &&
                serviceData.data.clinicalServicePricing.length > 0 ? (
                  serviceData.data.clinicalServicePricing.map(
                    (pricing: ServicePricing) => (
                      <tr key={pricing.id}>
                        <td>{pricing.title || '-'}</td>
                        <td>
                          {pricing.duration
                            ? `${pricing.duration} ${pricing.durationType}`
                            : '-'}
                        </td>
                        <td>{pricing.customer?.length || 0}</td>
                        <td>
                          {pricing.location && pricing.location.length > 0
                            ? pricing.location.join(', ')
                            : '-'}
                        </td>
                        <td>
                          {pricing.price
                            ? `Rp ${parseInt(
                                pricing.price,
                                10
                              ).toLocaleString()}`
                            : '-'}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Tidak ada data harga
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <div className="mb-2">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Harga
              </Form.Label>
              <div className="d-flex gap-3">
                <Form.Check
                  type="radio"
                  id="fixed-price"
                  name="priceType"
                  label="Fixed Prices"
                  value="fixed"
                  checked={priceType === 'fixed'}
                  onChange={e => setPriceType(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  id="rated-price"
                  name="priceType"
                  label="Rated Prices"
                  value="rated"
                  checked={priceType === 'rated'}
                  onChange={e => setPriceType(e.target.value)}
                />
              </div>
            </div>

            {/* Price Input Rows */}
            {priceType === 'fixed'
              ? priceRows.map((row, index) => (
                  <div
                    key={row.id}
                    className="d-flex gap-2 mb-2 justify-content-between"
                  >
                    {/* Judul */}
                    <div style={{ width: '180px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Judul
                        </span>
                      )}
                      <Form.Control
                        type="text"
                        placeholder="judul"
                        value={row.title}
                        onChange={e =>
                          handlePriceRowChange(row.id, 'title', e.target.value)
                        }
                      />
                    </div>

                    {/* Durasi */}
                    <div style={{ width: '200px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Durasi
                        </span>
                      )}
                      <InputGroup>
                        <Form.Control
                          type="number"
                          min="0"
                          placeholder="0"
                          value={row.duration}
                          onChange={e =>
                            handlePriceRowChange(
                              row.id,
                              'duration',
                              e.target.value
                            )
                          }
                          style={{ borderRight: 0 }}
                        />
                        <Form.Select
                          value={row.durationUnit}
                          onChange={e =>
                            handlePriceRowChange(
                              row.id,
                              'durationUnit',
                              e.target.value
                            )
                          }
                          style={{
                            borderLeft: '0.5px solid #CBD0DD'
                          }}
                        >
                          <option value="Menit">Menit</option>
                          <option value="Jam">Jam</option>
                        </Form.Select>
                      </InputGroup>
                    </div>

                    {/* Pelanggan */}
                    <div style={{ width: '200px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Pelanggan
                        </span>
                      )}
                      <Form.Select
                        value={row.customerType}
                        onChange={e =>
                          handlePriceRowChange(
                            row.id,
                            'customerType',
                            e.target.value
                          )
                        }
                      >
                        <option value="">Semua Pelanggan</option>
                        {customerData?.data?.map(customer => (
                          <option key={customer.id} value={customer.id}>
                            {customer.fullName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    {/* Lokasi */}
                    <div style={{ width: '140px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Lokasi
                        </span>
                      )}
                      <Form.Select
                        value={row.location}
                        onChange={e =>
                          handlePriceRowChange(
                            row.id,
                            'location',
                            e.target.value
                          )
                        }
                      >
                        <option value="">Semua Lokasi</option>
                        <option value="klinik">Klinik</option>
                        <option value="rumah">Rumah</option>
                      </Form.Select>
                    </div>

                    {/* Harga */}
                    <div style={{ width: '140px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Harga (Rp)
                        </span>
                      )}
                      <Form.Control
                        type="number"
                        min="0"
                        placeholder="Harga"
                        value={row.price}
                        onChange={e =>
                          handlePriceRowChange(row.id, 'price', e.target.value)
                        }
                      />
                    </div>

                    {/* Button Hapus */}
                    <div
                      style={{
                        width: '50px',
                        marginTop: index === 0 ? '28px' : '0'
                      }}
                    >
                      <Button
                        variant="primary"
                        className="d-flex align-items-center justify-content-center w-50"
                        onClick={() => handleRemovePriceRow(row.id)}
                        disabled={priceRows.length === 1}
                        style={{
                          border: '1px solid #dee2e6'
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </div>
                  </div>
                ))
              : // Rated Price Rows
                ratedPriceRows.map((row, index) => (
                  <div
                    key={row.id}
                    className="d-flex gap-2 mb-2 justify-content-between"
                  >
                    {/* Judul */}
                    <div style={{ width: '130px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Judul
                        </span>
                      )}
                      <Form.Control
                        type="text"
                        placeholder="Judul"
                        value={row.title}
                        onChange={e =>
                          handleRatedPriceRowChange(
                            row.id,
                            'title',
                            e.target.value
                          )
                        }
                      />
                    </div>

                    {/* Tarif */}
                    <div style={{ width: '140px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Tarif
                        </span>
                      )}
                      <Form.Select
                        value={row.rate}
                        onChange={e =>
                          handleRatedPriceRowChange(
                            row.id,
                            'rate',
                            e.target.value
                          )
                        }
                      >
                        <option value="per Menit">per Menit</option>
                        <option value="per Jam">per Jam</option>
                        <option value="per Hari">per Hari</option>
                      </Form.Select>
                    </div>

                    {/* Min Max Duration */}
                    <div style={{ width: '190px' }}>
                      {index === 0 && (
                        <div className="d-flex gap-2">
                          <div style={{ width: '50%' }}>
                            <span className="d-block fs-9 text-secondary mb-2">
                              Min
                            </span>
                          </div>
                          <div style={{ width: '50%' }}>
                            <span className="d-block fs-9 text-secondary mb-2">
                              Max
                            </span>
                          </div>
                        </div>
                      )}
                      <InputGroup>
                        <Form.Control
                          type="number"
                          min="0"
                          placeholder="0"
                          onChange={e =>
                            handleRatedPriceRowChange(
                              row.id,
                              'minDuration',
                              e.target.value
                            )
                          }
                          style={{ borderRight: 0 }}
                        />
                        <Form.Control
                          type="number"
                          min="0"
                          placeholder="0"
                          onChange={e =>
                            handleRatedPriceRowChange(
                              row.id,
                              'maxDuration',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </div>

                    {/* Pelanggan */}
                    <div style={{ width: '200px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Pelanggan
                        </span>
                      )}
                      <Form.Select
                        value={row.customerType}
                        onChange={e =>
                          handleRatedPriceRowChange(
                            row.id,
                            'customerType',
                            e.target.value
                          )
                        }
                      >
                        <option value="">Semua Pelanggan</option>
                        {customerData?.data?.map(customer => (
                          <option key={customer.id} value={customer.id}>
                            {customer.fullName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    {/* Lokasi */}
                    <div style={{ width: '140px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Lokasi
                        </span>
                      )}
                      <Form.Select
                        value={row.location}
                        onChange={e =>
                          handleRatedPriceRowChange(
                            row.id,
                            'location',
                            e.target.value
                          )
                        }
                      >
                        <option value="">Semua Lokasi</option>
                        <option value="klinik">Klinik</option>
                        <option value="rumah">Rumah</option>
                      </Form.Select>
                    </div>

                    {/* Harga */}
                    <div style={{ width: '140px' }}>
                      {index === 0 && (
                        <span className="d-block fs-9 text-secondary mb-2">
                          Harga (Rp)
                        </span>
                      )}
                      <Form.Control
                        type="number"
                        min="0"
                        placeholder="Harga"
                        value={row.price}
                        onChange={e =>
                          handleRatedPriceRowChange(
                            row.id,
                            'price',
                            e.target.value
                          )
                        }
                      />
                    </div>

                    {/* Button Hapus */}
                    <div
                      style={{
                        width: '50px',
                        marginTop: index === 0 ? '28px' : '0'
                      }}
                    >
                      <Button
                        variant="primary"
                        className="d-flex align-items-center justify-content-center w-50"
                        onClick={() => handleRemoveRatedPriceRow(row.id)}
                        disabled={ratedPriceRows.length === 1}
                        style={{
                          border: '1px solid #dee2e6'
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </div>
                  </div>
                ))}

            {/* Button Tambah */}
            <Button
              variant="outline-primary"
              className="w-100 rounded-3 mb-3"
              onClick={
                priceType === 'fixed'
                  ? handleAddPriceRow
                  : handleAddRatedPriceRow
              }
            >
              + Tambah
            </Button>
            <Form.Group controlId="tax" className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Pajak
              </Form.Label>
              <Form.Select name="tax">
                <option value="">Pilih Pajak</option>
                <option value="5%">5%</option>
                <option value="10%">10%</option>
                <option value="15%">15%</option>
              </Form.Select>
            </Form.Group>
            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ServicePriceCard;
