import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MenuServicePrivilegeResponse } from './response/MenuServicePrivilegeResponse';

export const menuServicePrivilegeApi = createApi({
  reducerPath: 'menuServicePrivilegeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: 10000
  }),
  endpoints: builder => ({
    getMenuServicePrivilege: builder.query<MenuServicePrivilegeResponse, void>({
      query: () => 'api/v1/menu/service'
    })
  })
});

export const { useGetMenuServicePrivilegeQuery } = menuServicePrivilegeApi;
