import React from 'react';
import { Card, Table, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const LayananPegawaiTableCard = () => {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="mb-0">Pelayanan (3)</h3>
          <div className="d-flex gap-2">
            <Dropdown>
              <Dropdown.Toggle variant="link" className="text-secondary p-0">
                Metode Bayar
              </Dropdown.Toggle>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="link" className="text-secondary p-0">
                Status
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>

        <Table responsive className="fs-9">
          <thead>
            <tr>
              <th>LAYANAN</th>
              <th>STATUS</th>
              <th>LOKASI</th>
              <th>TOTAL</th>
              <th>PAYMENT</th>
              <th>TANGGAL INVOICE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>INV-0114</td>
              <td>
                <span className="badge bg-success">SELESAI</span>
              </td>
              <td>Mirza MS Dental Care</td>
              <td>160.000.00</td>
              <td>Transfer Bank</td>
              <td>24 October 2024</td>
              <td>
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" className="p-0">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Detail</Dropdown.Item>
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item>Hapus</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </Table>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="text-secondary">
            1 to 2 Items of 2{' '}
            <a href="#" className="text-primary ms-2">
              View all
            </a>
          </div>
          <div className="text-end">
            <strong>TOTAL: 690.000.00</strong>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LayananPegawaiTableCard;
