/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faEdit,
  faPen,
  faRightFromBracket,
  faTrashAlt,
  faTrash,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetRoleByIdQuery,
  useUpdateRolePrivilegeMutation,
  useGetRoleUsersQuery,
  useAssignUserMutation,
  useUnassignUserFromRoleMutation,
  useDeleteRoleMutation
} from 'api/roleApi';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { detailPeranKeamananBreadcrumbItems } from 'data/commonData';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import RoleInformasiDasarCard from 'components/cards/RoleInformasiDasarCard';
import RoleKontrolAksesCard from 'components/cards/RoleKontrolAksesCard';
import RoleLevelAksesCard from 'components/cards/RoleLevelAksesCard';
import PilihPemilikPeranModal from 'components/modals/PilihPemilikPeranModal';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { toast } from 'react-toastify';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

// Tambahkan interface untuk data user
interface SelectedUser {
  email: string;
  fullName: string;
  role: string;
  id: string;
}

const PeranKeamananDetails = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: roleData,
    isLoading,
    error,
    refetch
  } = useGetRoleByIdQuery(id || '');
  const role = roleData?.data;

  const [updateRolePrivilege] = useUpdateRolePrivilegeMutation();
  const [assignUser] = useAssignUserMutation();
  const [unassignUser] = useUnassignUserFromRoleMutation();
  const navigate = useNavigate();
  const [deleteRole] = useDeleteRoleMutation();

  const [isEditing, setIsEditing] = useState(false);
  const [isAccessModified, setIsAccessModified] = useState(false);
  const [editableData, setEditableData] = useState({
    roleName: 'Dokter Gigi',
    color: '#E2F5FF',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit magni neque dolorum aut impedit quas ratione recusandae praesentium cumque, perspiciatis maxime. Sed repudiandae hic sint mollitia voluptas natus laboriosam deleniti.',
    accessTime: '09.00 hingga 22.00',
    accessRestrictions: [
      'BATASI HAK AKSES KE MENU PEGAWAI',
      'BATASI HAK AKSES KE MENU KEUANGAN'
    ]
  });
  // Define the type first
  type AccessLevel = 'FULL' | 'WRITE' | 'READ' | 'NONE';

  // Ubah state accessData untuk menggunakan data dari API
  const [accessData, setAccessData] = useState<{
    [menuCode: string]: {
      category: string;
      access: AccessLevel;
      description: string;
      serviceId: string;
    }[];
  }>({});
  // Tambahkan state untuk menyimpan nilai awal
  const [initialAccessData, setInitialAccessData] = useState<{
    [menuCode: string]: {
      category: string;
      access: AccessLevel;
      description: string;
      serviceId: string;
    }[];
  }>({});

  // Effect untuk mentransformasi data role menjadi format accessData
  useEffect(() => {
    if (role && role.menus) {
      const transformedAccessData = role.menus.reduce<
        Record<
          string,
          {
            category: string;
            access: AccessLevel;
            description: string;
            serviceId: string;
          }[]
        >
      >((acc, menu) => {
        acc[menu.code] = menu.services.map(service => ({
          category: service.code,
          access: service.privilege || 'NONE',
          description: service.description,
          serviceId: service.id
        }));
        return acc;
      }, {});

      setAccessData(transformedAccessData);
      setInitialAccessData(transformedAccessData); // Simpan nilai awal
    }
  }, [role]);

  useEffect(() => {
    if (role) {
      console.log('Role Data Structure:', {
        id: role.id,
        menus: role.menus.map(menu => ({
          code: menu.code,
          services: menu.services.map(service => ({
            id: service.id,
            code: service.code,
            privilege: service.privilege
          }))
        }))
      });
    }
  }, [role]);

  // Fungsi untuk mengubah akses
  // Fungsi untuk mengubah akses
  const handleAccessChange = (menuCode: string, index: number) => {
    const accessTypes = ['NONE', 'READ', 'WRITE', 'FULL'];
    const currentValue = accessData[menuCode][index].access;
    const currentIndex = accessTypes.indexOf(currentValue);
    const nextValue = accessTypes[(currentIndex + 1) % accessTypes.length];

    setAccessData(prev => ({
      ...prev,
      [menuCode]: prev[menuCode].map((item, i) =>
        i === index
          ? { ...item, access: nextValue as 'FULL' | 'WRITE' | 'READ' | 'NONE' }
          : item
      )
    }));

    setIsAccessModified(true);
  };

  // Render bagian Level Akses
  const renderAccessTable = (menuCode: string, title: string) => {
    if (!accessData[menuCode]) return null;

    return (
      <table className="table table-sm mb-4">
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #dee2e6',
                borderTop: '1px solid #dee2e6',
                padding: '12px'
              }}
            >
              {title}
            </th>
          </tr>
          <tr>
            <th>KATEGORI AKSES</th>
            <th className="d-flex justify-content-center">
              {role?.code || 'ROLE'}
            </th>
          </tr>
        </thead>
        <tbody>
          {accessData[menuCode].map((item, index) => (
            <tr key={index}>
              <td>
                {item.category}
                <small className="d-block text-muted">{item.description}</small>
              </td>
              <td>
                <button
                  onClick={() => handleAccessChange(menuCode, index)}
                  style={{
                    backgroundColor: getAccessColor(item.access),
                    border: `1px solid ${getBorderColor(item.access)}`,
                    borderRadius: '5px',
                    padding: '8px 16px',
                    width: '100%',
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    color: getTextColor(item.access),
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}
                >
                  {item.access}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Handler untuk menyimpan perubahan akses
  // Types untuk memperjelas struktur data
  interface Service {
    id: string;
    code: string;
    privilege: 'FULL' | 'WRITE' | 'READ' | 'NONE';
  }

  interface Menu {
    code: string;
    services: Service[];
  }

  interface Role {
    id: string;
    menus: Menu[];
  }

  interface AccessData {
    [menuCode: string]: {
      category: string;
      access: 'FULL' | 'WRITE' | 'READ' | 'NONE';
    }[];
  }

  interface UpdatePrivilegePayload {
    id: string;
    services: {
      serviceId: string;
      privilege: 'FULL' | 'WRITE' | 'READ' | 'NONE';
    }[];
  }

  // Fungsi helper untuk mengubah data menjadi format yang dibutuhkan API
  const transformToUpdatePayload = (
    role: Role | null,
    accessData: AccessData
  ): UpdatePrivilegePayload => {
    if (!role) {
      throw new Error('Role data tidak tersedia');
    }

    const serviceIdMapping = role.menus.reduce(
      (acc, menu) => {
        menu.services.forEach(service => {
          acc[service.code] = service.id;
        });
        return acc;
      },
      {} as Record<string, string>
    );

    const services = Object.keys(accessData)
      .flatMap(menuCode =>
        accessData[menuCode].map(item => {
          const serviceId = serviceIdMapping[item.category];
          if (!serviceId) {
            console.warn(
              `ServiceId tidak ditemukan untuk category: ${item.category}`
            );
            return null;
          }
          return {
            serviceId: serviceId,
            privilege: item.access
          };
        })
      )
      .filter((item): item is NonNullable<typeof item> => item !== null);

    return {
      id: role.id,
      services: services
    };
  };

  // Fungsi utama untuk menyimpan perubahan akses
  const handleSaveAccessChanges = async () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          if (!role) {
            throw new Error('Data role tidak tersedia');
          }

          const updatePayload = [
            {
              id: role.id,
              services: Object.entries(accessData).flatMap(
                ([menuCode, items]) =>
                  items.map(item => ({
                    serviceId: item.serviceId,
                    type: item.access
                  }))
              )
            }
          ];

          const result = await updateRolePrivilege(updatePayload).unwrap();

          if (result.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Data berhasil diperbarui.',
              buttonType: 'ok'
            });
            setIsAccessModified(false);
            refetch();
          }
        } catch (error) {
          console.error('Error updating privileges:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: 'Terjadi kesalahan saat memperbarui hak akses',
            buttonType: 'ok'
          });
        }
      }
    });
  };
  console.log('Raw Role Data:', role);
  console.log('Access Data:', accessData);

  // Handler untuk menyimpan perubahan
  const handleSave = () => {
    // Di sini Anda bisa menambahkan logika untuk menyimpan data ke API
    console.log('Saving changes:', editableData);
    setIsEditing(false);
  };

  // Handler untuk membatalkan perubahan
  const handleCancel = () => {
    if (!isAccessModified) return;

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setAccessData(initialAccessData);
        setIsAccessModified(false);
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Fungsi untuk mendapatkan warna background
  const getAccessColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FFE0DB';
      case 'WRITE':
        return '#C7EBFF';
      case 'READ':
        return '#D9FBD0';
      case 'NONE':
        return '#E3E6ED';
      default:
        return 'white';
    }
  };

  // Fungsi untuk mendapatkan warna border
  const getBorderColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FABCB3';
      case 'WRITE':
        return '#96D9FF';
      case 'READ':
        return '#BEE8B4';
      case 'NONE':
        return '#CBD0DD';
      default:
        return '#ddd';
    }
  };

  // Fungsi untuk mendapatkan warna teks
  const getTextColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#B81800';
      case 'WRITE':
        return '#005585';
      case 'READ':
        return '#1C6C09';
      case 'NONE':
        return '#31374A';
      default:
        return '#333';
    }
  };

  // Tambahkan style untuk section headers
  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px'
  };

  const sectionTitleStyle = {
    fontSize: '20px',
    fontWeight: '600',
    margin: 0
  };

  const editIconStyle = {
    color: '#6B7280',
    cursor: 'pointer',
    fontSize: '16px'
  };

  // Tambahkan state untuk modal dan selected users
  const [showPilihPemilikModal, setShowPilihPemilikModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  // Tambahkan query untuk mengambil data users
  const { data: roleUsersData, refetch: refetchRoleUsers } =
    useGetRoleUsersQuery(id || '');

  // Tambahkan ref untuk menyimpan data akun dari modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accountsData, setAccountsData] = useState<any[]>([]);

  // Tambahkan handler untuk menyimpan data akun dari modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModalMount = (accounts: any[]) => {
    setAccountsData(accounts);
  };

  // Update useEffect untuk mengisi selectedUsers dengan data lengkap
  useEffect(() => {
    if (roleUsersData?.data && accountsData.length > 0) {
      const userIds = roleUsersData.data.map(user => user.id);
      setSelectedUserIds(userIds);

      // Ambil data lengkap dari accountsData untuk user yang ada di roleUsersData
      const initialUsers = roleUsersData.data.map(user => {
        const accountData = accountsData.find(
          account => account.id === user.id
        );
        return {
          id: user.id,
          email: user.email,
          fullName: accountData?.employee?.fullName || '-',
          role: role?.code || '-'
        };
      });
      setSelectedUsers(initialUsers);
    }
  }, [roleUsersData, accountsData, role?.code]);

  // Update handleSelectPemilikPeran
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectPemilikPeran = (selectedAccounts: any[]) => {
    const processUpdate = () => {
      try {
        // Identifikasi user yang akan di-unassign (yang tidak ada di selectedAccounts)
        const usersToUnassign = selectedUsers.filter(
          existingUser =>
            !selectedAccounts.some(selected => selected.id === existingUser.id)
        );

        // Unassign users yang tidak dipilih lagi
        const unassignPromises = usersToUnassign.map(user =>
          unassignUser(user.id).unwrap()
        );

        // Assign users baru yang dipilih
        const assignPromises = selectedAccounts
          .filter(account => !selectedUserIds.includes(account.id))
          .map(account =>
            assignUser({
              userId: account.id,
              roleId: id || ''
            }).unwrap()
          );

        // Jalankan semua promises
        Promise.all([...unassignPromises, ...assignPromises])
          .then(async () => {
            // Refresh data setelah assign/unassign
            await refetchRoleUsers();

            // Update state dengan data lengkap
            const newSelectedUsers = selectedAccounts.map(account => ({
              id: account.id,
              email: account.email,
              fullName: account.employee?.fullName || '-',
              role: role?.code || '-'
            }));

            setSelectedUsers(newSelectedUsers);
            setSelectedUserIds(newSelectedUsers.map(user => user.id));
            setShowPilihPemilikModal(false);

            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Daftar pemilik peran berhasil diperbarui.',
              buttonType: 'ok'
            });
          })
          .catch(() => {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal memperbarui pemilik peran',
              deskripsi:
                'Terjadi kesalahan saat memperbarui daftar pemilik peran.',
              buttonType: 'ok'
            });
          });
      } catch (error) {
        showCustomAlert({
          icon: 'error',
          label: 'Gagal memperbarui pemilik peran',
          deskripsi: 'Terjadi kesalahan saat memperbarui daftar pemilik peran.',
          buttonType: 'ok'
        });
      }
    };

    // Cek apakah ada perubahan pada daftar akun
    const hasChanges =
      selectedAccounts.length !== selectedUserIds.length ||
      selectedAccounts.some(account => !selectedUserIds.includes(account.id)) ||
      selectedUserIds.some(
        id => !selectedAccounts.some(account => account.id === id)
      );

    // Cek apakah ada akun yang sudah memiliki peran lain
    const hasAccountsWithOtherRole = selectedAccounts.some(
      account =>
        account.role?.id &&
        account.role.id !== role?.id &&
        !selectedUserIds.includes(account.id)
    );

    if (hasChanges && !hasAccountsWithOtherRole) {
      showCustomAlert({
        icon: 'question',
        label: 'Pemilik peran akan diperbarui',
        deskripsi: 'Apakah Anda yakin ingin memperbarui daftar pemilik peran?',
        buttonType: 'yes-no',
        onConfirm: processUpdate
      });
    } else {
      // Langsung proses jika ada akun dengan peran lain (warning sudah ditampilkan di modal)
      processUpdate();
    }
  };

  // Update handleDeleteUser untuk menampilkan konfirmasi
  const handleDeleteUser = (userId: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Hapus pemilik peran',
      deskripsi: 'Apakah Anda yakin ingin menghapus pemilik peran ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await unassignUser(userId).unwrap();

          // Refresh data setelah unassign
          await refetchRoleUsers();

          // Update local state
          setSelectedUsers(prev => prev.filter(user => user.id !== userId));
          setSelectedUserIds(prev => prev.filter(id => id !== userId));

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Pemilik peran berhasil dihapus.',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menghapus pemilik peran',
            deskripsi: 'Terjadi kesalahan saat menghapus pemilik peran.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Tambahkan state untuk modal delete
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Tambahkan handler untuk delete
  const handleDeleteClick = () => {
    if (!role) return;
    setShowDeleteModal(true);
  };

  const handleDelete = async (reason: string, notes: string) => {
    // Cek jumlah pengguna dari data roleUsersData
    const hasUsers = roleUsersData?.data && roleUsersData.data.length > 0;

    showCustomAlert({
      icon: 'warning',
      label: 'Peran keamanan akan dihapus',
      deskripsi: hasUsers
        ? 'Terdapat akun yang memiliki peran ini. Apakah Anda yakin untuk menghapus peran keamanan ini?'
        : 'Apakah Anda yakin untuk menghapus peran keamanan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteRole({
            id: [role!.id],
            reason,
            notes: notes || null
          }).unwrap();

          setShowDeleteModal(false);

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Peran keamanan berhasil dihapus',
            buttonType: 'ok',
            onConfirm: () => {
              navigate('/manajemen-akun/peran-keamanan');
            }
          });
        } catch (error) {
          toast.error('Gagal menghapus peran keamanan');
          console.error('Error deleting role:', error);
        }
      }
    });
  };

  return (
    <>
      <div>
        <PageBreadcrumb items={detailPeranKeamananBreadcrumbItems} />
        <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
          <Col xs="auto">
            <h2 className="mb-0">Detail Peran Keamanan</h2>
          </Col>
          <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_FULL">
              <Button
                variant="outline-danger"
                onClick={handleDeleteClick}
                disabled={!role} // Disable jika data role belum tersedia
                size="sm"
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Hapus Peran
              </Button>
            </ForbiddenWrapper>
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
              <Button
                variant="primary"
                onClick={() => {
                  setShowPilihPemilikModal(true);
                }}
                // disabled={selectedRows === 0}
                size="sm"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Tambah Pemilik Peran
              </Button>
            </ForbiddenWrapper>
          </Col>
        </Row>

        <Row className="g-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={7}>
            <Row className="">
              {/* Menggunakan komponen InformasiDasarCard */}
              <RoleInformasiDasarCard
                id={role?.id}
                code={role?.code}
                color={role?.color}
                description={role?.description}
                onUpdate={refetch}
              />

              {/* Menggunakan komponen KontrolAksesCard */}
              <RoleKontrolAksesCard activation={roleData?.data.activation} />

              {/* Card Daftar Pemilik Peran tetap sama */}
              <Card className="bg-transparent border-0">
                <Card.Body>
                  <Card.Title as="h3" className="mb-4">
                    Daftar Pemilik Peran
                  </Card.Title>
                  <div className="table-responsive mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ALAMAT EMAIL</th>
                          <th>NAMA PEGAWAI</th>
                          <th>PERAN KEAMANAN</th>
                          <th style={{ width: '40px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedUsers.length === 0 ? (
                          <tr>
                            <td colSpan={4} className="text-center text-muted">
                              Belum ada pemilik peran
                            </td>
                          </tr>
                        ) : (
                          selectedUsers.map(user => (
                            <tr key={user.id}>
                              <td className="ms-1">
                                <Link
                                  to={`/manajemen-akun/kelola-akun/detail-akun/${user.id}`}
                                  style={{
                                    color: '#3874FF',
                                    textDecoration: 'none'
                                  }}
                                >
                                  {user.email}
                                </Link>
                              </td>
                              <td>{user.fullName}</td>
                              <td>{user.role}</td>
                              <td className="text-end">
                                <ForbiddenWrapper
                                  isBlank={true}
                                  privilege="ROLE_MANAGEMENT_WRITE"
                                >
                                  <Button
                                    variant="link"
                                    className="text-secondary p-0"
                                    onClick={() => handleDeleteUser(user.id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </ForbiddenWrapper>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <ForbiddenWrapper
                    isBlank={true}
                    privilege="ROLE_MANAGEMENT_WRITE"
                  >
                    <Button
                      className="w-100"
                      variant="outline-primary"
                      size="lg"
                      onClick={() => {
                        setShowPilihPemilikModal(true);
                      }}
                    >
                      + Tambah Pemilik Peran
                    </Button>
                  </ForbiddenWrapper>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <RoleLevelAksesCard
              accessData={accessData}
              isAccessModified={isAccessModified}
              onCancel={handleCancel}
              onSave={handleSaveAccessChanges}
              onAccessChange={handleAccessChange}
              roleCode={role?.code}
            />
          </Col>
        </Row>
      </div>

      {/* Tambahkan Modal Pilih Pemilik Peran */}
      <PilihPemilikPeranModal
        show={showPilihPemilikModal}
        onHide={() => setShowPilihPemilikModal(false)}
        onSelect={handleSelectPemilikPeran}
        selectedIds={selectedUserIds}
        onMount={handleModalMount}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={role ? [{ name: role.code }] : []}
        onDelete={handleDelete}
        title="Hapus Peran Keamanan"
      />
    </>
  );
};

export default PeranKeamananDetails;
