import { ColumnDef } from '@tanstack/react-table';
import { RoleResponse } from 'api/response/RoleResponse';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';

export const peranKeamananTableColumns: ColumnDef<RoleResponse>[] = [
  {
    accessorKey: 'warna',
    header: 'Warna',
    cell: ({ row: { original } }) => {
      return (
        <div
          style={{
            width: '80px',
            height: '20px',
            backgroundColor: original.color,
            borderRadius: '4px'
          }}
        />
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-1' },
      cellProps: { className: 'py-0 pe-3 ps-1' }
    }
  },
  {
    accessorKey: 'namaPeran',
    header: 'Nama Peran',
    cell: ({ row: { original } }) => (
      <Link
        to={`/manajemen-akun/peran-keamanan/detail-peran-keamanan/${original.id}`}
        className="text-dark fw-bold"
      >
        {original.code}
      </Link>
    ),
    meta: {
      headerProps: { className: 'pe-3 ps-1' },
      cellProps: { className: 'pe-3 ps-1' }
    }
  },
  {
    accessorKey: 'deskripsi',
    header: 'Deskripsi',
    cell: ({ row: { original } }) => <>{original.description}</>,
    meta: {
      headerProps: { className: 'pe-3 ps-1' },
      cellProps: { className: 'pe-3 ps-1' }
    }
  },
  {
    accessorKey: 'jumlahPengguna',
    header: 'Jumlah Pengguna',
    cell: ({ row: { original } }) => <>{original.count}</>,
    meta: {
      headerProps: { className: 'pe-3 ps-1 text-end' },
      cellProps: { className: 'pe-3 ps-1 text-end' }
    }
  }
  // {
  //   accessorKey: 'status',
  //   header: 'Status',
  //   cell: ({ row: { original } }) => {
  //     return (
  //       <span
  //         className={`badge ${
  //           original.status === 'AKTIF' ? 'bg-success' : 'bg-danger'
  //         }`}
  //       >
  //         {original.status}
  //       </span>
  //     );
  //   },
  //   meta: {
  //     headerProps: { style: { width: '15%' }, className: 'px-3 text-end' },
  //     cellProps: { className: 'px-3 py-2 text-end' }
  //   }
  // }
];

interface PeranKeamananTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  roleData?: {
    totalRecords?: number;
  };
}

const PeranKeamananTable = ({
  onPageChange,
  currentPage,
  totalPages,
  roleData
}: PeranKeamananTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={roleData?.totalRecords || 0}
      />
    </div>
  );
};

export default PeranKeamananTable;
