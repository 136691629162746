import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';
import { EmployeeData, EmployeeResponse } from './response/EmployeeResponse';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { FormData } from './request/EmployeeCreateReq';

// Tambahkan interface untuk parameter query
interface EmployeeQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  gender?: string[];
  location?: string[];
  jobTitle?: string[];
  isActive?: string[];
  startAt?: string;
  endAt?: string;
  name?: string;
}

// Tambahkan interface untuk request delete employee
interface DeleteEmployeeRequest {
  notes: string;
  deletionReason: string;
  data: string[];
}

interface EmployeeCreateResponse extends BaseResponse {
  data: {
    id: number;
  };
}

// Di employeeApi.ts, tambahkan interface untuk request location
interface LocationRequest {
  street: string;
  province: number; // Pastikan tipe number
  regency: number; // Pastikan tipe number
  district: number; // Pastikan tipe number
  village: number; // Pastikan tipe number
  country: string;
  postalCode: string;
  addressType: string;
}

// Tambahkan interface untuk request update names
interface UpdateEmployeeNamesRequest {
  firstName: string;
  lastName: string;
  joinedDate: string;
}

// Tambahkan interface untuk request update personal information
interface UpdatePersonalInformationRequest {
  employmentType: string;
  gender: string;
  religion: string;
  login: string;
  nickname: string;
  idType: string;
  idNumber: string;
  dateOfBirth: string;
  maritalStatus: string;
  color: string;
}

// Tambahkan interface untuk request
interface AddressContactRequest {
  emails: Array<{
    id?: string;
    emailAddress: string;
    usageType: string;
  }>;
  contacts: Array<{
    id?: string;
    phoneNumber: string;
    usageType: string;
    isWhatsapp: boolean;
  }>;
  locations: Array<{
    id?: string;
    streetAddress: string;
    state: string;
    country: string;
    postalCode: string;
    addressType: string;
    province: number;
    city: number;
    district: number;
    village: number;
  }>;
}

// Tambahkan interface untuk request emergency contact
interface EmergencyContactRequest {
  id?: string;
  contactName: string;
  phoneNumber: string;
  relationshipStatus: string;
  isWhatsapp: boolean;
  address: string;
}

// Tambahkan interface untuk request note
interface CreateNoteRequest {
  employeeId: string;
  content: string;
  noteType: string;
}

interface UpdateNoteRequest {
  content: string;
  noteType: string;
}

// Tambahkan interface untuk request
interface UpdateEmployeeInfoRequest {
  id: string; // employee info id
  employeeStatus: boolean;
  employmentRelationship: string;
  registrationNumber: string;
  annualReview: string;
  jobTitle: string; // job title id
}

// Tambahkan interface untuk response job title
interface JobTitleResponse {
  id: string;
  name: string;
  description: string;
  color: string;
  totalOfEmployee: number;
}

export const employeeApi = createApi({
  reducerPath: 'employee',
  tagTypes: ['Employee'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: 10000
  }),
  endpoints: builder => ({
    getEmployee: builder.query<EmployeeData, EmployeeQueryParams>({
      query: params => {
        const queryParams = new URLSearchParams();

        // Parameter dasar
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        // Filter tambahan
        if (params.gender?.length) {
          params.gender.forEach(g => queryParams.append('gender', g));
        }
        if (params.location?.length) {
          params.location.forEach(l =>
            queryParams.append('branch', l.toLowerCase())
          );
        }
        if (params.startAt) {
          queryParams.append('startAt', params.startAt);
        }
        if (params.endAt) {
          queryParams.append('endAt', params.endAt);
        }
        if (params.name) {
          queryParams.append('name', params.name);
        }

        const url = `api/v1/employee?${queryParams.toString()}`;
        console.log('Request URL:', url);
        return url;
      },
      transformResponse: (baseQueryReturnValue: unknown): EmployeeData => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = baseQueryReturnValue as any;

        return {
          code: response.code,
          message: response.message,
          status: response.status || 'success', // Add status to match BaseResponse
          data: response.data?.result || [],
          totalPages: response.data?.totalPages || 0,
          totalRecords: response.data?.total || 0,
          currentPage: parseInt(response.data?.page || '1')
        };
      },
      providesTags: ['Employee']
    }),
    addEmployee: builder.mutation<EmployeeCreateResponse, FormData>({
      query: newData => ({
        url: 'api/v1/employee',
        method: 'POST',
        body: newData
      })
    }),
    getEmployeeById: builder.query<
      BaseResponse & { data: EmployeeResponse },
      string
    >({
      query: id => `api/v1/employee/${id}`,
      transformResponse: (
        response: BaseResponse & { data: EmployeeResponse }
      ) => {
        const joinedDate = response.data.joinedDate;
        return {
          ...response,
          data: {
            ...response.data,
            joinedDate: {
              plain:
                typeof joinedDate === 'string' ? joinedDate : joinedDate.plain,
              formatted: format(
                new Date(
                  typeof joinedDate === 'string' ? joinedDate : joinedDate.plain
                ),
                'd MMMM yyyy',
                { locale: id }
              )
            }
          }
        };
      }
    }),
    editEmployee: builder.mutation({
      query: data => ({
        url: '/api/v1/employee/detail/edit',
        method: 'PUT',
        body: data // Semua data dikirimkan melalui body
      })
    }),
    updateLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/location/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmail: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/email/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmergencyContact: builder.mutation<
      BaseResponse,
      { id: string; data: EmergencyContactRequest[] }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/${id}/emergency-contact`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    addEmployeeNote: builder.mutation<
      BaseResponse & {
        data: {
          id: string;
          content: string;
          type: string;
          createdDate: string;
        };
      },
      {
        employeeId: number;
        content: string;
        noteType: string;
      }
    >({
      query: newNote => ({
        url: '/api/v1/employeenote/create',
        method: 'POST',
        body: newNote
      })
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteEmployee: builder.mutation<BaseResponse, DeleteEmployeeRequest>({
      query: deleteRequest => ({
        url: '/api/v1/employee',
        method: 'DELETE',
        body: deleteRequest
      }),
      invalidatesTags: ['Employee']
    }),
    editEmployeeNote: builder.mutation<
      BaseResponse & {
        data: {
          id: string;
          content: string;
          type: string;
          createdDate: string;
        };
      },
      {
        id: number;
        content: string;
        noteType: string;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/api/v1/employeenote/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteEmployeeNote: builder.mutation<BaseResponse, string>({
      query: id => ({
        url: `/api/v1/employee/note/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Employee']
    }),
    getUniqueBranches: builder.query<string[], void>({
      query: () => 'api/v1/employee?page=1&limit=1000',
      transformResponse: (response: EmployeeData) => {
        if (!response.data) return [];

        const branches = response.data
          .map(employee => employee.branch)
          .filter(
            (branch): branch is string =>
              Boolean(branch) && typeof branch === 'string'
          )
          .filter(
            (branch, index, self) =>
              self.findIndex(b => b.toLowerCase() === branch.toLowerCase()) ===
              index
          )
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        console.log('Transformed branches:', branches);
        return branches;
      }
    }),
    createLocation: builder.mutation<
      BaseResponse,
      { employeeId: string; data: LocationRequest }
    >({
      query: ({ employeeId, data }) => ({
        url: `/api/v1/location/create/${employeeId}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    updateEmployeeNames: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateEmployeeNamesRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/${id}/names`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    updatePersonalInformation: builder.mutation<
      BaseResponse,
      UpdatePersonalInformationRequest & { id: string }
    >({
      query: ({ id, ...data }) => ({
        url: `/api/v1/employee/${id}/personal-information`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    updateAddressContact: builder.mutation<
      BaseResponse,
      { id: string; data: AddressContactRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/${id}/address-contact`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    createEmployeeNote: builder.mutation<BaseResponse, CreateNoteRequest>({
      query: data => ({
        url: '/api/v1/employee/note',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    updateEmployeeNote: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateNoteRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/employee/note/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    updateEmployeeInfo: builder.mutation<
      BaseResponse,
      { employeeId: string; data: UpdateEmployeeInfoRequest }
    >({
      query: ({ employeeId, data }) => ({
        url: `api/v1/employee/${employeeId}/info`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    getJobTitles: builder.query<JobTitleResponse[], void>({
      query: () => 'api/v1/employee/job-title?limit=1000',
      transformResponse: (response: {
        data: {
          result: JobTitleResponse[];
        };
      }) => response.data.result
    })
  })
});

export const {
  useGetEmployeeQuery,
  useAddEmployeeMutation,
  useGetEmployeeByIdQuery,
  useEditEmployeeMutation,
  useUpdateLocationMutation,
  useUpdateEmailMutation,
  useUpdateEmergencyContactMutation,
  useAddEmployeeNoteMutation,
  useDeleteEmployeeMutation,
  useEditEmployeeNoteMutation,
  useDeleteEmployeeNoteMutation,
  useGetUniqueBranchesQuery,
  useCreateLocationMutation,
  useUpdateEmployeeNamesMutation,
  useUpdatePersonalInformationMutation,
  useUpdateAddressContactMutation,
  useCreateEmployeeNoteMutation,
  useUpdateEmployeeNoteMutation,
  useUpdateEmployeeInfoMutation,
  useGetJobTitlesQuery
} = employeeApi;
