import { Modal, Form, Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useAddRoleMutation } from 'api/roleApi';
import { ToastContainer } from 'react-toastify';
import { useGetMenuServicePrivilegeQuery } from 'api/menuServicePrivilegeApi';
import PilihPemilikPeranModal from './PilihPemilikPeranModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CreateRoleRequest } from 'api/response/RoleResponse';

interface TambahPeranModalProps {
  show: boolean;
  onHide: () => void;
}

// Tambahkan interface untuk data user
interface SelectedUser {
  email: string;
  fullName: string;
  role: string;
  id: string;
}

// Tambahkan style untuk overlay pada PilihPemilikPeranModal
const overlayStyle = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 1050 // Pastikan nilai ini lebih tinggi dari modal utama
};

const TambahPeranModal = ({ show, onHide }: TambahPeranModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createRole, { isLoading }] = useAddRoleMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAccessModified, setIsAccessModified] = useState(false);

  const { data: menuServiceData, isLoading: isLoadingMenuService } =
    useGetMenuServicePrivilegeQuery();

  const [accessData, setAccessData] = useState<{
    [key: string]: { category: string; access: string; serviceId: string }[];
  }>({});

  useEffect(() => {
    if (menuServiceData?.data) {
      const newAccessData: {
        [key: string]: {
          category: string;
          access: string;
          serviceId: string;
        }[];
      } = {};

      menuServiceData.data.forEach(menu => {
        newAccessData[menu.code.toLowerCase()] = menu.services.map(service => ({
          category: service.name,
          access: 'NONE',
          serviceId: service.id
        }));
      });

      setAccessData(newAccessData);
    }
  }, [menuServiceData]);

  const handleAccessChange = (section: string, index: number) => {
    const accessTypes = ['NONE', 'READ', 'WRITE', 'FULL'];
    const currentValue = accessData[section][index].access;
    const currentIndex = accessTypes.indexOf(currentValue);
    const nextValue = accessTypes[(currentIndex + 1) % accessTypes.length];

    setAccessData(prev => ({
      ...prev,
      [section]: prev[section].map((item, i) =>
        i === index ? { ...item, access: nextValue } : item
      )
    }));

    // Set access modification flag to true
    setIsAccessModified(true);
  };

  const getAccessColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FFE0DB';
      case 'WRITE':
        return '#C7EBFF';
      case 'READ':
        return '#D9FBD0';
      case 'NONE':
        return '#E3E6ED';
      default:
        return 'white';
    }
  };

  const getBorderColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FABCB3';
      case 'WRITE':
        return '#96D9FF';
      case 'READ':
        return '#BEE8B4';
      case 'NONE':
        return '#CBD0DD';
      default:
        return '#ddd';
    }
  };

  const getTextColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#B81800';
      case 'WRITE':
        return '#005585';
      case 'READ':
        return '#1C6C09';
      case 'NONE':
        return '#31374A';
      default:
        return '#333';
    }
  };

  const initialFormData = {
    code: '',
    description: '',
    color: '#E5EDFF',
    activation: 'ALL_THE_TIME',
    from: '',
    to: '',
    users: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Validasi nama peran
    if (!formData.code.trim()) {
      newErrors.code = 'Nama peran wajib diisi';
    } else if (formData.code.trim().length < 3) {
      newErrors.code = 'Nama peran minimal 3 karakter';
    }

    // Validasi deskripsi
    if (!formData.description.trim()) {
      newErrors.description = 'Deskripsi wajib diisi';
    } else if (formData.description.trim().length < 5) {
      newErrors.description = 'Deskripsi minimal 5 karakter';
    }

    if (!formData.activation) newErrors.activation = 'Jam akses wajib dipilih';
    if (formData.activation === 'CUSTOM' && (!formData.from || !formData.to)) {
      newErrors.from = 'Waktu akses mulai wajib diisi';
      newErrors.to = 'Waktu akses selesai wajib diisi';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validasi form terlebih dahulu
    if (!validateForm()) {
      return;
    }

    // Gunakan default color jika tidak ada yang dipilih
    const submittedColor = formData.color || '#E5EDFF';

    // Jika validasi berhasil, lanjutkan dengan konfirmasi
    showCustomAlert({
      icon: 'question',
      label: 'Peran keamanan akan disimpan',
      deskripsi: 'Apakah Anda yakin data peran keamanan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Mengumpulkan semua menuService
          const menuService = Object.values(accessData)
            .flat()
            .map(item => ({
              serviceId: item.serviceId,
              type: item.access
            }));

          const roleData: CreateRoleRequest = {
            code: formData.code,
            description: formData.description,
            color: submittedColor,
            activation: formData.activation as
              | 'ALL_THE_TIME'
              | 'WORKING_HOURS'
              | 'CUSTOM',
            from:
              formData.activation === 'CUSTOM'
                ? `${formData.from}:00`
                : undefined,
            to:
              formData.activation === 'CUSTOM'
                ? `${formData.to}:00`
                : undefined,
            users: selectedUsers.map(user => user.id), // Gunakan ID dari selectedUsers
            menuService
          };

          const response = await createRole(roleData).unwrap();
          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Peran keamanan baru berhasil disimpan.',
              buttonType: 'ok',
              onConfirm: () => {
                resetModalData();
                onHide();
              }
            });
          } else {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal membuat peran',
              deskripsi:
                response.message || 'Terjadi kesalahan saat menyimpan peran.',
              buttonType: 'ok'
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Error saat mengirim data:', error);

          // Cek pesan error untuk nama peran yang sudah ada
          if (error?.data?.message === 'Role Code Already Exists') {
            showCustomAlert({
              icon: 'error',
              label: 'Terdapat nama peran keamanan yang sama',
              deskripsi:
                'Mohon ubah nama peran keamanan yang akan Anda tambahkan.',
              buttonType: 'ok'
            });
          } else {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal membuat peran',
              deskripsi:
                error?.data?.message ||
                'Terjadi kesalahan saat menyimpan peran.',
              buttonType: 'ok'
            });
          }
        }
      }
    });
  };

  const handleCancel = () => {
    const isFormFilled =
      formData.code.trim() !== '' || selectedUsers.length > 0;

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Peran tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setShowPilihPemilikModal(false);
          setTimeout(() => {
            resetModalData();
            onHide();
          }, 100);
        }
      });
    } else {
      setShowPilihPemilikModal(false);
      setTimeout(() => {
        resetModalData();
        onHide();
      }, 100);
    }
  };

  useEffect(() => {
    if (show) {
      resetModalData();
    }
  }, [show]);

  const [showPilihPemilikModal, setShowPilihPemilikModal] = useState(false);

  // Tambahkan state untuk menyimpan user yang dipilih
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  // Update handler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectPemilikPeran = (selectedAccounts: any[]) => {
    // Pisahkan akun yang sudah ada di daftar dan yang belum
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const existingAccounts = selectedAccounts.filter(newAccount =>
      selectedUsers.some(existingUser => existingUser.id === newAccount.id)
    );

    const newAccounts = selectedAccounts.filter(
      newAccount =>
        !selectedUsers.some(existingUser => existingUser.id === newAccount.id)
    );

    // Cek akun yang rolenya sudah terassign
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const accountsWithAssignedRole = newAccounts.filter(
      account => account.role
    );

    //   if (existingAccounts.length > 0 || accountsWithAssignedRole.length > 0) {
    //     showCustomAlert({
    //       icon: 'warning',
    //       label: 'Akun yang dipilih sudah memiliki peran',
    //       deskripsi: `Apakah Anda yakin akan mengubah peran keamanan pada akun tersebut?`,
    //       buttonType: 'yes-no',
    //       onConfirm: () => {
    //         const newSelectedUsers = selectedAccounts.map(account => ({
    //           id: account.id,
    //           email: account.email,
    //           fullName: account.employee?.fullName || '-',
    //           role: account.role?.code || '-'
    //         }));
    //         setSelectedUsers(newSelectedUsers);
    //         setSelectedUserIds(newSelectedUsers.map(user => user.id));
    //         setShowPilihPemilikModal(false);
    //       }
    //     });
    //   } else {
    //     // Jika tidak ada yang perlu dikonfirmasi, langsung tambahkan
    //     const newSelectedUsers = selectedAccounts.map(account => ({
    //       id: account.id,
    //       email: account.email,
    //       fullName: account.employee?.fullName || '-',
    //       role: account.role?.code || '-'
    //     }));
    //     setSelectedUsers(newSelectedUsers);
    //     setSelectedUserIds(newSelectedUsers.map(user => user.id));
    //     setShowPilihPemilikModal(false);
    //   }
    // };

    // Hapus alert dan langsung tambahkan pengguna yang dipilih
    const newSelectedUsers = selectedAccounts.map(account => ({
      id: account.id,
      email: account.email,
      fullName: account.employee?.fullName || '-',
      role: account.role?.code || '-'
    }));
    setSelectedUsers(newSelectedUsers);
    setSelectedUserIds(newSelectedUsers.map(user => user.id));
    setShowPilihPemilikModal(false);
  };

  // Update handler delete
  const handleDeleteUser = (userId: string) => {
    setSelectedUsers(prev => prev.filter(u => u.id !== userId));
    setSelectedUserIds(prev => prev.filter(id => id !== userId));
  };

  useEffect(() => {
    if (!show) {
      setShowPilihPemilikModal(false);
    }
  }, [show]);

  // Tambahkan state untuk reset data
  const resetModalData = () => {
    setFormData(initialFormData);
    setErrors({});
    setSelectedUsers([]);
    setSelectedUserIds([]);
  };

  return (
    <>
      <style>{`/* Style untuk pembatas vertikal */
          .vertical-divider {
            width: 1px;
            background-color: #CBD0DD;
            margin: 0 24px;
            height: auto;
            align-self: stretch;
          }

          /* Pastikan Row memiliki display flex */
          .row {
            display: flex;
            align-items: stretch;
          }

          /* Style untuk container pembatas */
          .divider-container {
            position: relative;
          }

          /* Style untuk pembatas vertikal */
          .vertical-divider {
            position: absolute;
            left: 52%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }`}</style>
      <Modal
        show={show}
        onHide={handleCancel}
        size="xl"
        centered
        keyboard={false}
        style={{ zIndex: showPilihPemilikModal ? 1040 : 1050 }}
      >
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="fw-bolder">Tambah Peran Keamanan</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="tambahPeranForm"
              disabled={isLoading}
            >
              {isLoading ? 'Membuat...' : 'Buat Peran'}
            </Button>
          </div>
        </Modal.Header>
        <Form id="tambahPeranForm" onSubmit={handleSubmit}>
          <Modal.Body className="px-5 py-4">
            <Row>
              <Col md={6}>
                <h4 className="mb-4">Informasi Peran Keamanan</h4>

                <Form.Group className="mb-3">
                  <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                    Nama Peran <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukkan nama peran"
                    value={formData.code}
                    isInvalid={!!errors.code}
                    onChange={e =>
                      setFormData({ ...formData, code: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="vertical-divider" />
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                        Warna
                      </Form.Label>
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          className="position-relative"
                          style={{ width: '100%' }}
                        >
                          <Form.Control
                            type="color"
                            value={formData.color || '#E5EDFF'}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                color: e.target.value
                              })
                            }
                            title="Pilih warna"
                            style={{
                              width: '100%',
                              height: '38px',
                              padding: '2px',
                              cursor: 'pointer',
                              opacity: formData.color ? '1' : '0.2'
                            }}
                          />
                          {!formData.color && (
                            <div
                              className="position-absolute top-50 start-50 translate-middle"
                              style={{
                                pointerEvents: 'none',
                                color: 'black'
                              }}
                            >
                              Pilih warna
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            padding: '8px 12px',
                            borderRadius: '4px',
                            border: '1px solid #dee2e6',
                            fontSize: '14px',
                            backgroundColor: '#f8f9fa'
                          }}
                        >
                          {formData.color.toUpperCase()}
                        </div>
                      </div>
                      {errors.color && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: 'block' }}
                        >
                          {errors.color}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group>
                      <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                        Status <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select>
                        <option value="">Pilih status</option>
                        <option value="active">Aktif</option>
                        <option value="inactive">Tidak Aktif</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                </Row>

                <Form.Group className="mb-4">
                  <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                    Deskripsi Peran <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Masukkan deskripsi peran"
                    value={formData.description}
                    isInvalid={!!errors.description}
                    onChange={e =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <h4 className="mb-4">Kontrol Akses</h4>
                <h5 className="mb-3">Jam Akses</h5>

                <Form.Group className="mb-4">
                  <div className="d-flex flex-column gap-2">
                    <Form.Check
                      type="radio"
                      id="allTheTime"
                      name="activation"
                      label="Akses sepanjang waktu"
                      value="ALL_THE_TIME"
                      checked={formData.activation === 'ALL_THE_TIME'}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          activation: e.target.value,
                          from: '',
                          to: ''
                        })
                      }
                    />
                    <Form.Check
                      type="radio"
                      id="workingHours"
                      name="activation"
                      label="Hanya pada jam kerja"
                      value="WORKING_HOURS"
                      checked={formData.activation === 'WORKING_HOURS'}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          activation: e.target.value,
                          from: '',
                          to: ''
                        })
                      }
                    />
                    {formData.activation === 'WORKING_HOURS' && (
                      <Form.Group>
                        <div className="d-flex align-items-center">
                          <p style={{ fontSize: '14px' }}>
                            Akses sebelum dan setelah jam kerja
                          </p>
                          <div className="ms-3">
                            <Form.Select
                              onChange={e =>
                                setFormData({
                                  ...formData,
                                  users: e.target.value
                                })
                              }
                            >
                              <option value="">Pilih waktu</option>
                              <option>Keluar Secara Langsung</option>
                              <option value="15">15 menit</option>
                              <option value="30">30 menit</option>
                              <option value="60">1 Jam</option>
                              <option value="120">2 Jam</option>
                            </Form.Select>
                          </div>
                        </div>
                      </Form.Group>
                    )}

                    <Form.Check
                      type="radio"
                      id="custom"
                      name="activation"
                      label="Waktu Akses Khusus"
                      value="CUSTOM"
                      checked={formData.activation === 'CUSTOM'}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          activation: e.target.value
                        })
                      }
                    />
                  </div>

                  {formData.activation === 'CUSTOM' && (
                    <div className="d-flex gap-3 align-items-end">
                      <Form.Group>
                        <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                          Waktu Mulai
                        </Form.Label>
                        <Form.Control
                          type="time"
                          value={formData.from}
                          isInvalid={!!errors.from}
                          onChange={e =>
                            setFormData({ ...formData, from: e.target.value })
                          }
                        />
                      </Form.Group>
                      <span className="mb-2">hingga</span>
                      <Form.Group>
                        <Form.Label className="text-capitalize fs-9 p-0 fw-bolder">
                          Waktu Selesai
                        </Form.Label>
                        <Form.Control
                          type="time"
                          value={formData.to}
                          isInvalid={!!errors.to}
                          onChange={e =>
                            setFormData({ ...formData, to: e.target.value })
                          }
                        />
                      </Form.Group>
                    </div>
                  )}
                </Form.Group>

                <h4 className="mb-3">Daftar Pemilik Peran</h4>
                <div className="table-responsive mb-3">
                  <table className="table">
                    <thead>
                      <tr className="fs-9">
                        <th>ALAMAT EMAIL</th>
                        <th>NAMA PEGAWAI</th>
                        <th>PERAN KEAMANAN</th>
                        <th style={{ width: '40px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUsers.length === 0 ? (
                        <tr>
                          <td colSpan={4} className="text-center text-muted">
                            Belum ada pemilik peran
                          </td>
                        </tr>
                      ) : (
                        selectedUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.fullName}</td>
                            <td>{user.role}</td>
                            <td className="text-end">
                              <Button
                                variant="link"
                                className="text-danger p-0"
                                onClick={() => handleDeleteUser(user.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Button
                  className="w-100"
                  variant="outline-primary"
                  size="lg"
                  onClick={() => setShowPilihPemilikModal(true)}
                >
                  + Tambah Pemilik Peran
                </Button>
              </Col>
              <Col md={1} />
              <Col md={5}>
                <h4 className="mb-3">Level Akses</h4>
                <Form.Label className="text-capitalize fs-9 p-0 fw-bolder mb-2">
                  Pilih jenis akses
                </Form.Label>
                <Form.Select className="mb-4">
                  <option value="">Custom</option>
                </Form.Select>

                {isLoadingMenuService ? (
                  <div>Loading...</div>
                ) : (
                  menuServiceData?.data.map(menu => (
                    <table key={menu.id} className="table table-sm mb-4">
                      <thead>
                        <tr>
                          <th
                            colSpan={2}
                            style={{
                              textAlign: 'center',
                              borderBottom: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              padding: '12px'
                            }}
                          >
                            {menu.name}
                          </th>
                        </tr>
                        <tr>
                          <th>KATEGORI AKSES</th>
                          <th className="d-flex justify-content-center">
                            HAK AKSES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {accessData[menu.code.toLowerCase()]?.map(
                          (item, index) => (
                            <tr key={item.serviceId}>
                              <td>{item.category}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault();
                                    handleAccessChange(
                                      menu.code.toLowerCase(),
                                      index
                                    );
                                  }}
                                  style={{
                                    backgroundColor: getAccessColor(
                                      item.access
                                    ),
                                    border: `1px solid ${getBorderColor(
                                      item.access
                                    )}`,
                                    borderRadius: '5px',
                                    padding: '8px 16px',
                                    width: '100%',
                                    cursor: 'pointer',
                                    transition: 'all 0.2s',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                    color: getTextColor(item.access),
                                    fontWeight: 'bold',
                                    fontSize: '14px'
                                  }}
                                >
                                  {item.access}
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ))
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Form>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Modal>

      {/* Tambahkan div overlay saat PilihPemilikPeranModal aktif */}
      {show && showPilihPemilikModal && <div style={overlayStyle} />}

      <PilihPemilikPeranModal
        show={show && showPilihPemilikModal}
        onHide={() => setShowPilihPemilikModal(false)}
        onSelect={handleSelectPemilikPeran}
        selectedIds={selectedUserIds}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
      />
    </>
  );
};

export default TambahPeranModal;
