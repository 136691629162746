import { useEffect, useState } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; // Menggunakan FontAwesomeIcon untuk ikon pena
import { useParams } from 'react-router-dom';
import {
  useEditCustomerMutation,
  useGetCustomerByIdQuery
} from 'api/customerApi';
import { format, parseISO } from 'date-fns';
import { showCustomAlert } from 'helpers/showCustomAlert';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

const InformasiPribadiCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, error } = useGetCustomerByIdQuery(id!);
  const [editCustomer, { isLoading: isSaving }] = useEditCustomerMutation();

  // State untuk menyimpan data form
  const [formData, setFormData] = useState({
    customerType: '',
    religion: '',
    gender: '',
    genderFormatted: '',
    idType: '',
    type: '',
    dateOfBirth: '',
    age: '',
    maritalStatus: '',
    occupation: '',
    accountingId: '',
    clientGroup: '',
    branch: ''
  });

  // State untuk mengatur edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Tambahkan state untuk error
  const [errors, setErrors] = useState({
    gender: '',
    religion: '',
    maritalStatus: '',
    type: '',
    idType: '',
    dateOfBirth: '',
    branch: ''
  });

  // Mengupdate formData setelah mendapatkan response API
  useEffect(() => {
    if (response?.data?.master) {
      setFormData({
        customerType: response.data.master?.customerType || '-',
        religion: response.data.master?.religion || '-',
        gender: response.data.master?.gender?.plain || '-',
        genderFormatted:
          response.data.master?.gender?.plain === 'M'
            ? 'Male'
            : response.data.master?.gender?.plain === 'F'
            ? 'Female'
            : '-',
        idType: response.data.master?.idStatus?.idType || '-',
        type: response.data.master?.idStatus?.type || '-',
        dateOfBirth: response.data.master?.birthDate?.plain
          ? format(parseISO(response.data.master.birthDate.plain), 'yyyy-MM-dd')
          : '-',
        age: response.data.master?.birthDate?.age?.toString() || '-',
        maritalStatus: response.data.master?.maritalStatus || '-',
        occupation: response.data.master?.occupation || '-',
        accountingId: response.data.master?.accountingId || '-',
        clientGroup: response.data.master?.group || '-',
        branch: response.data.master?.branch || '-'
      });
    }

    if (!id) {
      console.warn('ID tidak ditemukan di parameter URL.');
    }
  }, [response, id]);

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    // Reset error untuk field yang diubah
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));

    // Update genderFormatted jika gender berubah
    if (name === 'gender') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        genderFormatted:
          value === 'M'
            ? 'Male'
            : value === 'F'
            ? 'Female'
            : prevData.genderFormatted
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  // Fungsi untuk menyimpan data
  const handleCancel = () => {
    // Cek apakah ada perubahan data dengan membandingkan data saat ini dengan data awal
    const hasChanges =
      response?.data?.master &&
      (formData.customerType !== response.data.master.customerType ||
        formData.religion !== response.data.master.religion ||
        formData.gender !== response.data.master.gender.plain ||
        formData.idType !== response.data.master.idStatus.idType ||
        formData.type !== response.data.master.idStatus.type ||
        formData.dateOfBirth !==
          format(
            parseISO(response.data.master.birthDate.plain),
            'yyyy-MM-dd'
          ) ||
        formData.maritalStatus !== response.data.master.maritalStatus ||
        formData.occupation !== response.data.master.occupation ||
        formData.clientGroup !== response.data.master.group ||
        formData.branch !== response.data.master.branch);

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan data informasi pribadi? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        // Reset form ke data awal
        if (response?.data?.master) {
          setFormData({
            customerType: response.data.master.customerType,
            religion: response.data.master.religion,
            gender: response.data.master.gender.plain,
            genderFormatted:
              response.data.master.gender.plain === 'M' ? 'Male' : 'Female',
            idType: response.data.master?.idStatus?.idType,
            type: response.data.master.idStatus.type,
            dateOfBirth: format(
              parseISO(response.data.master.birthDate.plain),
              'yyyy-MM-dd'
            ),
            age: response.data.master.birthDate.age,
            maritalStatus: response.data.master.maritalStatus,
            occupation: response.data.master.occupation,
            accountingId: response.data.master.accountingId,
            clientGroup: response.data.master.group,
            branch: response.data.master.branch
          });
        }
      }
    });
  };

  const handleSave = async () => {
    // Reset dan validasi errors seperti sebelumnya
    const newErrors = {
      gender: '',
      religion: '',
      maritalStatus: '',
      type: '',
      idType: '',
      dateOfBirth: '',
      branch: ''
    };

    // Validasi field required
    if (!formData.gender || formData.gender === '')
      newErrors.gender = 'Jenis kelamin harus dipilih';

    if (!formData.religion || formData.religion === 'Pilih Agama')
      newErrors.religion = 'Agama harus dipilih';

    if (
      !formData.maritalStatus ||
      formData.maritalStatus === 'Pilih Status Perkawinan'
    )
      newErrors.maritalStatus = 'Status perkawinan harus dipilih';

    if (!formData.type || formData.type === 'Pilih Tipe Identifikasi')
      newErrors.type = 'Tipe identifikasi harus dipilih';

    if (!formData.idType) newErrors.idType = 'Nomor identifikasi harus diisi';

    if (!formData.dateOfBirth)
      newErrors.dateOfBirth = 'Tanggal lahir harus diisi';

    if (!formData.branch || formData.branch === 'Pilih Cabang')
      newErrors.branch = 'Cabang harus dipilih';

    // Set errors jika ada
    setErrors(newErrors);

    // Cek apakah ada error
    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi:
        'Anda yakin ingin menyimpan perubahan data informasi pribadi ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const dataToSend = {
            id: parseInt(id || '0', 10),
            gender: formData.gender,
            dateOfBirth: format(parseISO(formData.dateOfBirth), 'yyyy-MM-dd'),
            customerType: formData.customerType,
            religion: formData.religion,
            idNumber: formData.idType,
            idType: formData.type,
            maritalStatus: formData.maritalStatus,
            occupation: formData.occupation,
            clientGroup: formData.clientGroup
          };

          await editCustomer(dataToSend).unwrap();

          // Tampilkan alert success
          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data informasi pribadi berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Gagal memperbarui data:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi:
              error?.data?.message || 'Terjadi kesalahan, silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleEditClick = () => setIsEditing(true);

  // Loading and Error states
  if (isLoading) return <div>Loading...</div>;
  if (error || !response?.data?.master)
    return <div>No customer data found</div>;

  return (
    <Card className="w-100">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Informasi Pribadi</h3>
            <ForbiddenWrapper privilege="CUSTOMER_DATA_WRITE" isBlank={true}>
              {!isEditing && (
                <Button variant="ghost" size="sm" onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                </Button>
              )}
            </ForbiddenWrapper>
          </Col>
        </Row>

        {!isEditing && (
          <>
            <Row className="mb-3">
              <Col
                xs={12}
                md={4}
                className="d-flex flex-column border-end pe-3"
              >
                <span className="fw-bold text-muted">Tipe</span>
                <span className="fw-normal">
                  {formData.customerType || '-'}
                </span>
              </Col>
              <Col
                xs={12}
                md={4}
                className="d-flex flex-column border-end pe-3"
              >
                <span className="fw-bold text-muted">Jenis Kelamin</span>
                <span className="fw-normal">
                  {formData.genderFormatted === 'Male'
                    ? 'Laki-laki'
                    : formData.genderFormatted === 'Female'
                    ? 'Perempuan'
                    : '-'}
                </span>
              </Col>
              <Col xs={12} md={4} className="d-flex flex-column">
                <span className="fw-bold text-muted">Agama</span>
                <span className="fw-normal">{formData.religion || '-'}</span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Identifikasi</span>
                <span className="fw-normal">
                  {formData.idType
                    ? `${formData.idType} (${formData.type || '-'})`
                    : '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Tanggal Lahir</span>
                <span className="fw-normal">
                  {response.data.master.birthDate.date
                    ? `${response.data.master.birthDate.date} (${
                        response.data.master.birthDate.age || '-'
                      })`
                    : '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Status Perkawinan</span>
                <span className="fw-normal">
                  {formData.maritalStatus || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Pekerjaan</span>
                <span className="fw-normal">{formData.occupation || '-'}</span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">ID Akuntansi</span>
                <span className="fw-normal">
                  {formData.accountingId || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Jenis Pelanggan</span>
                <span className="fw-normal">{formData.clientGroup || '-'}</span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Cabang</span>
                <span className="fw-normal">{formData.branch || '-'}</span>
              </Col>
            </Row>
          </>
        )}

        {/* Mode edit */}
        {isEditing && (
          <>
            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tipe
                  </Form.Label>
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="radio"
                      name="customerType"
                      label="Individual"
                      value="Individual"
                      checked={formData.customerType === 'Individual'}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      type="radio"
                      name="customerType"
                      label="Business"
                      value="Business"
                      checked={formData.customerType === 'Business'}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Jenis Kelamin <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                  >
                    <option value="">Pilih Jenis Kelamin</option>
                    <option value="M">Laki-laki</option>
                    <option value="F">Perempuan</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Agama <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="religion"
                    value={formData.religion}
                    onChange={handleInputChange}
                    isInvalid={!!errors.religion}
                  >
                    <option value="Pilih Agama">Pilih Agama</option>
                    <option>Islam</option>
                    <option>Kristen</option>
                    <option>Hindu</option>
                    <option>Budha</option>
                    <option>Konghucu</option>
                    <option>Katolik</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.religion}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Status Perkawinan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="maritalStatus"
                    value={formData.maritalStatus}
                    onChange={handleInputChange}
                    isInvalid={!!errors.maritalStatus}
                  >
                    <option value="Pilih Status Perkawinan">
                      Pilih Status Perkawinan
                    </option>
                    <option>Married</option>
                    <option>Single</option>
                    <option>Divorced</option>
                    <option>Widowed</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.maritalStatus}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Jenis Pelanggan
                  </Form.Label>
                  <Form.Select
                    name="clientGroup"
                    value={formData.clientGroup}
                    onChange={handleInputChange}
                  >
                    <option>Pilih Jenis Pelanggan</option>
                    <option>Reguler</option>
                    <option>New</option>
                    <option>Unidentified</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tipe Identifikasi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    isInvalid={!!errors.type}
                  >
                    <option value="Pilih Tipe Identifikasi">
                      Pilih Tipe Identifikasi
                    </option>
                    <option>KTP</option>
                    <option>Passport</option>
                    <option>Student ID</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Nomor Identifikasi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="idType"
                    value={formData.idType}
                    onChange={handleInputChange}
                    isInvalid={!!errors.idType}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Tanggal Lahir <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    isInvalid={!!errors.dateOfBirth}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dateOfBirth}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Pekerjaan
                  </Form.Label>
                  <Form.Select
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleInputChange}
                  >
                    <option>Pilih Pekerjaan</option>
                    <option>Pegawai Swasta</option>
                    <option>Wiraswasta/Pengusaha</option>
                    <option>Pelajar/Mahasiswa</option>
                    <option>Lainnya</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className="fs-8 ps-0 mb-2"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Cabang <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="branch"
                    value={formData.branch}
                    onChange={handleInputChange}
                    isInvalid={!!errors.branch}
                  >
                    <option value="Pilih Cabang">Pilih Cabang</option>
                    <option>Mirza MS Dental Care</option>
                    <option>Cabang A</option>
                    <option>Cabang B</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.branch}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Menyimpan...' : 'Simpan'}
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InformasiPribadiCard;
