/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Card, Dropdown } from 'react-bootstrap';
import {
  CustomerTemplateDetailResponse,
  CustomerTemplateFieldDetail,
  AddTemplateFieldRequest
} from 'api/response/CustomerTemplateResponse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCopy,
  faTrash,
  faEllipsisVertical,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons';
import {
  useDeleteCustomerTemplateFieldMutation,
  useGetCustomerTemplateFieldByIdQuery,
  useUpdateTemplateFieldPositionMutation,
  useAddCustomerTemplateFieldMutation
} from 'api/customerTemplateApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { toast } from 'react-toastify';
import AddTemplateField from 'components/modals/AddTemplateFieldModal';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';
import { hasPrivilege } from 'slices/privilegeSlice';

interface BagianPedagangCardProps {
  onTambahBagian: (groupType: string) => void;
  fields: CustomerTemplateFieldDetail[];
  onRefetch: () => void;
  templateResponse?: CustomerTemplateDetailResponse;
}

const CustomToggle = React.forwardRef<any, any>(({ onClick }, ref) => (
  <ForbiddenWrapper privilege="CUSTOMER_TEMPLATE_WRITE" isBlank={true}>
    <Button
      ref={ref}
      variant="link"
      className="p-0 text-muted"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </Button>
  </ForbiddenWrapper>
));

const getFieldTypeId = (fieldTypeName: string): number => {
  const fieldTypeMapping: Record<string, number> = {
    'Single line text': 22,
    'Multiple line Text': 23,
    'Radio buttons': 24,
    Checkboxes: 25,
    'Dropdown list': 26,
    'Multi select list': 27,
    Date: 28
  };

  const id = fieldTypeMapping[fieldTypeName];
  if (!id) {
    throw new Error(`Field type tidak ditemukan untuk: ${fieldTypeName}`);
  }
  return id;
};

const SortableRow = ({
  field,
  onEdit,
  onDelete,
  onDuplicate
}: {
  field: CustomerTemplateFieldDetail;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (field: CustomerTemplateFieldDetail) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td>
        <div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
          <FontAwesomeIcon icon={faGripVertical} className="text-muted" />
        </div>
      </td>
      <td>{field.fieldName}</td>
      <td>
        {typeof field.fieldType === 'string'
          ? field.fieldType
          : field.fieldType?.name}
      </td>
      <td>
        <Dropdown align="end">
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            <ForbiddenWrapper
              privilege="CUSTOMER_TEMPLATE_WRITE"
              isBlank={true}
            >
              <Dropdown.Item onClick={() => onEdit(field.id)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onDuplicate(field)}>
                <FontAwesomeIcon icon={faCopy} className="me-2" />
                Duplikat
              </Dropdown.Item>
            </ForbiddenWrapper>
            <ForbiddenWrapper privilege="CUSTOMER_TEMPLATE_FULL" isBlank={true}>
              <Dropdown.Divider />
              <Dropdown.Item
                className="text-danger"
                onClick={() => onDelete(field.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Hapus
              </Dropdown.Item>
            </ForbiddenWrapper>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

const BagianPedagangCard: React.FC<BagianPedagangCardProps> = ({
  onTambahBagian,
  fields,
  onRefetch,
  templateResponse
}) => {
  const [deleteField] = useDeleteCustomerTemplateFieldMutation();
  const [updatePosition] = useUpdateTemplateFieldPositionMutation();
  const [addTemplateField] = useAddCustomerTemplateFieldMutation();
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [localFields, setLocalFields] =
    useState<CustomerTemplateFieldDetail[]>(fields);

  useEffect(() => {
    setLocalFields(fields);
  }, [fields]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const { data: fieldDetail, refetch } = useGetCustomerTemplateFieldByIdQuery(
    Number(selectedFieldId),
    {
      skip: !selectedFieldId,
      refetchOnMountOrArgChange: true
    }
  );

  const handleDelete = async (fieldId: string) => {
    showCustomAlert({
      icon: 'warning',
      label: 'Bagian akan dihapus',
      deskripsi: 'Apakah Anda yakin ingin menghapus bagian ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const response = await deleteField(Number(fieldId)).unwrap();
          if (response.code === 200) {
            await new Promise(resolve => setTimeout(resolve, 300));
            await onRefetch();

            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Bagian berhasil dihapus',
              buttonType: 'ok'
            });
          } else {
            throw new Error(response.message || 'Gagal menghapus bagian');
          }
        } catch (error) {
          console.error('Error deleting field:', error);
          toast.error(
            error instanceof Error ? error.message : 'Gagal menghapus bagian',
            { position: 'bottom-right' }
          );
        }
      }
    });
  };

  const handleEdit = async (fieldId: string) => {
    setSelectedFieldId(fieldId);
    await new Promise(resolve => setTimeout(resolve, 0));
    refetch();
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedFieldId(null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    try {
      const oldIndex = localFields.findIndex(field => field.id === active.id);
      const newIndex = localFields.findIndex(field => field.id === over.id);

      const newArrangement = arrayMove(localFields, oldIndex, newIndex);
      setLocalFields(newArrangement);

      await updatePosition({
        fieldId: Number(active.id),
        newPosition: newIndex + 1
      }).unwrap();

      await onRefetch();
    } catch (error) {
      setLocalFields(fields);
      console.error('Error updating position:', error);
      toast.error('Gagal mengubah posisi field', { position: 'bottom-right' });
    }
  };

  const handleDuplicate = async (field: CustomerTemplateFieldDetail) => {
    try {
      if (!templateResponse?.data?.id) {
        throw new Error('Template ID tidak ditemukan');
      }

      setSelectedFieldId(field.id);
      await new Promise(resolve => setTimeout(resolve, 0));
      const fieldDetailResponse = await refetch();
      const completeField = fieldDetailResponse.data?.data;

      setSelectedFieldId(null);

      if (!completeField) {
        throw new Error('Gagal mendapatkan detail field');
      }

      console.log('Complete Field:', completeField);

      const fieldTypeId =
        typeof completeField.fieldType === 'string'
          ? getFieldTypeId(completeField.fieldType)
          : completeField.fieldType?.id
          ? Number(completeField.fieldType.id)
          : null;

      if (!fieldTypeId) {
        throw new Error(
          `Field type ID tidak ditemukan untuk field ${completeField.fieldName}`
        );
      }

      const getUniqueFieldName = (baseName: string): string => {
        const existingFields = fields || [];
        let newName = `${baseName} (copy)`;
        let counter = 2;

        while (existingFields.some(f => f.fieldName === newName)) {
          newName = `${baseName} (copy ${counter})`;
          counter++;
        }

        return newName;
      };

      const uniqueFieldName = getUniqueFieldName(completeField.fieldName);

      showCustomAlert({
        icon: 'warning',
        label: 'Duplikasi bagian',
        deskripsi: 'Apakah Anda yakin ingin menduplikasi bagian ini?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            const duplicateData: AddTemplateFieldRequest = {
              customerTemplateId: Number(templateResponse.data.id),
              fieldName: uniqueFieldName,
              fieldType: fieldTypeId,
              fieldGroup: 'merchant',
              isRequired: completeField.isRequired || false,
              label: completeField.label,
              fieldSize: completeField.fieldSize || 'md',
              fieldTextLength: completeField.fieldTextLength || 255,
              customerTemplateFieldOption:
                completeField.customerTemplateFieldOption?.map(opt => ({
                  optionValue: opt.optionValue,
                  selectedChoice: opt.selectedChoice
                })) || []
            };

            console.log('Duplicate Data:', duplicateData);

            const response = await addTemplateField(duplicateData).unwrap();

            if (response.data) {
              await onRefetch();
              showCustomAlert({
                icon: 'success',
                label: 'Sukses!',
                deskripsi: 'Bagian berhasil diduplikasi',
                buttonType: 'ok'
              });
            } else {
              throw new Error(response.message || 'Gagal menduplikasi field');
            }
          } catch (error) {
            console.error('Error duplicating field:', error);
            toast.error(
              error instanceof Error
                ? error.message
                : 'Gagal menduplikasi field',
              { position: 'bottom-right' }
            );
          }
        }
      });
    } catch (error) {
      console.error('Error in handleDuplicate:', error);
      toast.error(
        error instanceof Error
          ? error.message
          : 'Gagal memproses duplikasi field',
        { position: 'bottom-right' }
      );
    }
  };

  return (
    <>
      <Card className="mt-2 p-2">
        <div className="container mt-4">
          <h3 className="mb-4">Bagian Pedagang</h3>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '40px' }}></th>
                  <th>NAMA BAGIAN</th>
                  <th>JENIS BAGIAN</th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody>
                <SortableContext
                  disabled={!hasPrivilege('CUSTOMER_TEMPLATE_WRITE')}
                  items={localFields.map(f => f.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {localFields.map(field => (
                    <SortableRow
                      key={field.id}
                      field={field}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onDuplicate={handleDuplicate}
                    />
                  ))}
                </SortableContext>
              </tbody>
            </Table>
          </DndContext>

          <div className="d-grid">
            <ForbiddenWrapper
              privilege="CUSTOMER_TEMPLATE_WRITE"
              isBlank={true}
            >
              <Button
                variant="outline-primary"
                onClick={() => onTambahBagian('Bagian Pedagang')}
                className="mt-3"
              >
                + Tambah Bagian
              </Button>
            </ForbiddenWrapper>
          </div>
        </div>
      </Card>

      {showEditModal && selectedFieldId && (
        <AddTemplateField
          show={showEditModal}
          handleClose={handleCloseEditModal}
          templateId={Number(templateResponse?.data?.id)}
          defaultGroupType="Bagian Pedagang"
          onSuccess={() => {
            onRefetch();
            handleCloseEditModal();
          }}
          isEdit={true}
          editData={fieldDetail?.data}
        />
      )}
    </>
  );
};

export default BagianPedagangCard;
