import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useUpdateBaseRoleInfoMutation } from 'api/roleApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

interface InformasiDasarCardProps {
  id?: string;
  code?: string;
  color?: string;
  description?: string;
  onUpdate?: () => void;
}

const RoleInformasiDasarCard = ({
  id,
  code,
  color,
  description,
  onUpdate
}: InformasiDasarCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCode, setEditedCode] = useState(code || '');
  const [editedColor, setEditedColor] = useState(color || '#E2F5FF');
  const [editedDescription, setEditedDescription] = useState(description || '');
  const colorInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [updateBaseRoleInfo] = useUpdateBaseRoleInfoMutation();

  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px'
  };

  const editIconStyle = {
    color: '#6B7280',
    cursor: 'pointer',
    fontSize: '16px',
    marginLeft: '16px'
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!editedCode.trim()) {
      newErrors.code = 'Nama peran wajib diisi';
    } else if (editedCode.trim().length < 3) {
      newErrors.code = 'Nama peran minimal 3 karakter';
    }

    if (!editedDescription.trim()) {
      newErrors.description = 'Deskripsi wajib diisi';
    } else if (editedDescription.trim().length < 5) {
      newErrors.description = 'Deskripsi minimal 5 karakter';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan ',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          if (!id) {
            throw new Error('ID tidak ditemukan');
          }

          const response = await updateBaseRoleInfo({
            id,
            data: {
              code: editedCode,
              color: editedColor,
              description: editedDescription
            }
          }).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Data berhasil diperbarui.',
              buttonType: 'ok'
            });
            setIsEditing(false);
            onUpdate?.();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Error updating role:', error);

          // Menangani pesan error dari API
          if (error.data?.message === 'Role Code Already Exists') {
            showCustomAlert({
              icon: 'warning',
              label: 'Terdapat nama peran keamanan yang sama',
              deskripsi:
                'Mohon ubah nama peran keamanan yang akan Anda tambahkan.',
              buttonType: 'ok'
            });
          } else {
            const errorMessage =
              error.data?.message ||
              'Terjadi kesalahan saat memperbarui informasi dasar';

            showCustomAlert({
              icon: 'error',
              label: 'Gagal',
              deskripsi: errorMessage,
              buttonType: 'ok'
            });
          }
        }
      }
    });
  };

  const handleCancel = () => {
    const isChanged =
      editedCode !== code ||
      editedColor !== color ||
      editedDescription !== description;

    if (!isChanged) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setEditedCode(code || '');
        setEditedColor(color || '#E2F5FF');
        setEditedDescription(description || '');
        setIsEditing(false);
      }
    });
  };

  const handleColorBoxClick = () => {
    if (isEditing && colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  useEffect(() => {
    setEditedCode(code || '');
    setEditedColor(color || '#E2F5FF');
    setEditedDescription(description || '');
  }, [code, color, description]);

  return (
    <Card className="mb-4">
      <Card.Body>
        <div style={sectionHeaderStyle}>
          <h3 className="d-flex align-items-center">
            Informasi Dasar
            <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
              {!isEditing && (
                <FontAwesomeIcon
                  icon={faPen}
                  style={editIconStyle}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </ForbiddenWrapper>
          </h3>
        </div>

        <div className="d-flex flex-column gap-1">
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <h5 className="text-body-secondary mb-1">
                  Nama Peran Keamanan
                  {isEditing && <span className="text-danger">*</span>}
                </h5>
                {isEditing ? (
                  <>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      value={editedCode}
                      onChange={e => setEditedCode(e.target.value)}
                      isInvalid={!!errors.code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.code}
                    </Form.Control.Feedback>
                  </>
                ) : (
                  <p className="mb-0 fw-medium">
                    {code || 'Nama Peran Keamanan'}
                  </p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <h5 className="text-body-secondary mb-2">Warna</h5>
                <div className="d-flex align-items-center gap-3">
                  <div
                    style={{
                      width: '100px',
                      height: '24px',
                      backgroundColor: isEditing
                        ? editedColor
                        : color || '#E2F5FF',
                      borderRadius: '4px',
                      cursor: isEditing ? 'pointer' : 'default',
                      border: isEditing ? '1px solid #B0B0B0' : 'none'
                    }}
                    onClick={handleColorBoxClick}
                  />
                  {isEditing && (
                    <div className="d-flex align-items-center gap-2">
                      <Form.Control
                        className="mt-5"
                        ref={colorInputRef}
                        type="color"
                        value={editedColor}
                        onChange={e => setEditedColor(e.target.value)}
                        style={{
                          width: '0',
                          height: '0',
                          padding: '0',
                          border: 'none',
                          visibility: 'hidden'
                        }}
                      />

                      <span
                        className="text-body-secondary"
                        style={{
                          backgroundColor: '#F3F4F6',
                          border: '2px solid #E5E7EB',
                          borderRadius: '10px',
                          padding: '4px 12px',
                          fontSize: '14px'
                        }}
                      >
                        {editedColor}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <h5 className="text-body-secondary mb-1">
              Deskripsi {isEditing && <span className="text-danger">*</span>}
            </h5>
            {isEditing ? (
              <>
                <Form.Control
                  className="mt-2"
                  as="textarea"
                  rows={3}
                  value={editedDescription}
                  onChange={e => setEditedDescription(e.target.value)}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </>
            ) : (
              <p className="mb-0">{description}</p>
            )}
          </div>
        </div>
      </Card.Body>
      {isEditing && (
        <Card.Footer className="d-flex justify-content-end gap-3">
          <Button
            variant="link"
            size="sm"
            className="text-danger p-0"
            onClick={handleCancel}
          >
            Batal
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="text-white ms-2"
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default RoleInformasiDasarCard;
