import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface CheckboxState {
  showFormTemplat: boolean;
  showInformasiPekerjaan: boolean;
  showLayananJamKerja: boolean;
  showPelayanan: boolean;
  showPenilaianUlasan: boolean;
  showGajiKompensasi: boolean;
  showLayanan: boolean;
  showJamKerja: boolean;
  showLayananTable: boolean;
  showUlasan: boolean;
}

interface Props {
  show: boolean;
  onHide: () => void;
  checkboxes: CheckboxState;
  onCheckboxChange: (updatedVisibility: CheckboxState) => void;
}

const CheckboxDetailPegawaiModal = ({
  show,
  onHide,
  checkboxes,
  onCheckboxChange
}: Props) => {
  // State sementara untuk menyimpan perubahan sebelum disimpan
  const [tempCheckboxes, setTempCheckboxes] =
    useState<CheckboxState>(checkboxes);

  // Reset tempCheckboxes ketika modal dibuka
  React.useEffect(() => {
    if (show) {
      setTempCheckboxes(checkboxes);
    }
  }, [show, checkboxes]);

  const handleCheckboxChange = (key: keyof CheckboxState) => {
    setTempCheckboxes(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSave = () => {
    onCheckboxChange(tempCheckboxes);
    showCustomAlert({
      icon: 'success',
      label: 'Berhasil',
      deskripsi: 'Pengaturan tampilan berhasil disimpan',
      buttonType: 'ok',
      onConfirm: onHide
    });
  };

  const handleCancel = () => {
    setTempCheckboxes(checkboxes); // Reset ke nilai awal
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pengaturan Tampilan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showInformasiPekerjaan"
              label="Informasi Pekerjaan"
              checked={tempCheckboxes.showInformasiPekerjaan}
              onChange={() => handleCheckboxChange('showInformasiPekerjaan')}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showLayananJamKerja"
              label="Layanan dan Jam Kerja"
              checked={tempCheckboxes.showLayananJamKerja}
              onChange={() => handleCheckboxChange('showLayananJamKerja')}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showPelayanan"
              label="Pelayanan"
              checked={tempCheckboxes.showPelayanan}
              onChange={() => handleCheckboxChange('showPelayanan')}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showPenilaianUlasan"
              label="Penilaian dan Ulasan"
              checked={tempCheckboxes.showPenilaianUlasan}
              onChange={() => handleCheckboxChange('showPenilaianUlasan')}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleCancel} style={{ color: 'red' }}>
          Batalkan
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckboxDetailPegawaiModal;
