import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';
import { Button, Col, Row } from 'react-bootstrap';
import { hasPrivilege } from 'slices/privilegeSlice';

interface RoleLevelAksesCardProps {
  accessData: {
    [menuCode: string]: {
      category: string;
      access: 'FULL' | 'WRITE' | 'READ' | 'NONE';
      description: string;
      serviceId?: string;
    }[];
  };
  isAccessModified: boolean;
  onCancel: () => void;
  onSave: () => void;
  onAccessChange: (menuCode: string, index: number) => void;
  roleCode?: string;
}

const RoleLevelAksesCard = ({
  accessData,
  isAccessModified,
  onCancel,
  onSave,
  onAccessChange,
  roleCode
}: RoleLevelAksesCardProps) => {
  // Fungsi helper untuk styling
  const getAccessColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FFE0DB';
      case 'WRITE':
        return '#C7EBFF';
      case 'READ':
        return '#D9FBD0';
      case 'NONE':
        return '#E3E6ED';
      default:
        return 'white';
    }
  };

  const getBorderColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#FABCB3';
      case 'WRITE':
        return '#96D9FF';
      case 'READ':
        return '#BEE8B4';
      case 'NONE':
        return '#CBD0DD';
      default:
        return '#ddd';
    }
  };

  const getTextColor = (access: string) => {
    switch (access) {
      case 'FULL':
        return '#B81800';
      case 'WRITE':
        return '#005585';
      case 'READ':
        return '#1C6C09';
      case 'NONE':
        return '#31374A';
      default:
        return '#333';
    }
  };

  const renderAccessTable = (menuCode: string, title: string) => {
    if (!accessData[menuCode]) return null;

    return (
      <table className="table table-sm mb-4">
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #dee2e6',
                borderTop: '1px solid #dee2e6',
                padding: '12px'
              }}
            >
              {title}
            </th>
          </tr>
          <tr>
            <th>KATEGORI AKSES</th>
            <th className="d-flex justify-content-center">
              {roleCode || 'ROLE'}
            </th>
          </tr>
        </thead>
        <tbody>
          {accessData[menuCode].map((item, index) => (
            <tr key={index}>
              <td>
                <span className="text-capitalize">
                  {item.category.toLowerCase()}
                </span>
                <small className="d-block text-muted">{item.description}</small>
              </td>
              <td>
                <button
                  disabled={!hasPrivilege('ROLE_MANAGEMENT_WRITE')}
                  onClick={() => onAccessChange(menuCode, index)}
                  style={{
                    backgroundColor: getAccessColor(item.access),
                    border: `1px solid ${getBorderColor(item.access)}`,
                    borderRadius: '5px',
                    padding: '8px 16px',
                    width: '100%',
                    cursor: hasPrivilege('ROLE_MANAGEMENT_WRITE')
                      ? 'pointer'
                      : 'default',
                    transition: 'all 0.2s',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    color: getTextColor(item.access),
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}
                >
                  {item.access}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Fungsi untuk mengubah akses

  return (
    <Row className="d-flex g-3 p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="mb-0">Level Akses</h3>
        <ForbiddenWrapper isBlank={true} privilege="ROLE_MANAGEMENT_WRITE">
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              disabled={!isAccessModified}
              onClick={onCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="primary"
              className="text-white"
              disabled={!isAccessModified}
              onClick={onSave}
            >
              Simpan Perubahan Akses
            </Button>
          </div>
        </ForbiddenWrapper>
      </div>
      <Col xs={12}>
        {Object.keys(accessData).map(menuCode =>
          renderAccessTable(
            menuCode,
            menuCode.charAt(0).toUpperCase() + menuCode.slice(1).toLowerCase()
          )
        )}
      </Col>
    </Row>
  );
};

export default RoleLevelAksesCard;
