import { Modal, Button } from 'react-bootstrap';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ColumnDef } from '@tanstack/react-table';

interface LogoutDevice {
  operatingSystem: string;
  browser: string;
  login: string;
}

interface LogoutAllDevicesModalProps {
  show: boolean;
  onHide: () => void;
  onLogout: () => void;
}

// Data dummy untuk tabel
const historiLoginData: LogoutDevice[] = [
  {
    operatingSystem: 'Mac OS 16.15.6',
    browser: 'Chrome V1.2',
    login: 'Thursday, 24 October, 2024 11:35 AM'
  },
  {
    operatingSystem: 'Windows 11',
    browser: 'Mozilla 5.2',
    login: 'Thursday, 24 October, 2024 11:35 AM'
  }
];

const logoutDevicesColumns: ColumnDef<LogoutDevice>[] = [
  {
    accessorKey: 'operatingSystem',
    header: 'OPERATING SYSTEM',
    cell: ({ row: { original } }) => original.operatingSystem,
    meta: {
      headerProps: { className: 'ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'browser',
    header: 'BROWSER',
    cell: ({ row: { original } }) => original.browser,
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    accessorKey: 'login',
    header: 'LOGIN',
    cell: ({ row: { original } }) => (
      <span className="text-primary">{original.login}</span>
    ),
    meta: {
      headerProps: { className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  }
];

const LogoutAllDevicesModal = ({
  show,
  onHide,
  onLogout
}: LogoutAllDevicesModalProps) => {
  const table = useAdvanceTable({
    data: historiLoginData,
    columns: logoutDevicesColumns,
    pageSize: 10,
    pagination: false,
    sortable: true
  });

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className="px-5 py-4 bg-modal-header">
        <Modal.Title>Logout Dari Semua Perangkat Berikut</Modal.Title>
        <div className="ms-auto">
          <Button variant="link" onClick={onHide}>
            Batalkan
          </Button>
          <Button variant="danger" onClick={onLogout}>
            Logout
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-5 py-4 bg-light">
        <AdvanceTableProvider {...table}>
          <div className="border-y border-translucent">
            <AdvanceTable
              tableProps={{
                className: 'phoenix-table fs-9',
                style: { minWidth: '768px' }
              }}
            />
          </div>
        </AdvanceTableProvider>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutAllDevicesModal;
