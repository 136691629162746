/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFileExport,
  faEnvelope,
  faTrashAlt,
  faFileInvoiceDollar,
  faCalendarPlus,
  faGear,
  faClipboard
} from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import {
  useGetServiceByIdQuery,
  useDeleteServiceMutation
} from 'api/serviceApi';
import { useGetServiceTemplateQuery } from 'api/serviceTemplateApi';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { detailLayananBreadcrumbItems } from 'data/commonData';
import { showCustomAlert } from 'helpers/showCustomAlert';
import DownloadTableModal from 'components/modals/DownloadTableModal';
// import ServiceProfileCard from 'components/cards/ServiceProfileCard';
import InformasiPribadiPegawaiCard from 'components/cards/InformasiPribadiPegawaiCard';
import AlamatKontakPegawaiCard from 'components/cards/AlamatKontakPegawaiCard';
import KontakDaruratPegawaiCard from 'components/cards/KontakDaruratPegawaiCard';
// import ServiceNotesCard from 'components/cards/ServiceNotesCard';
import InformasiPekerjaanCard from 'components/cards/InformasiPekerjaanCard';
import InformasiRemunerasiCard from 'components/cards/InformasiRemunerasiCard';
import GajiKompensasiCard from 'components/cards/GajiKompensasiCard';
import LayananCard from 'components/cards/LayananPegawaiCard';
import LayananPegawaiCard from 'components/cards/LayananPegawaiCard';
import CheckboxDetailPegawaiModal from 'components/modals/CheckboxDetailPegawaiModal';
import noCheckedBox from 'assets/img/spot-illustrations/404-illustration.png';
import LayananPegawaiTableCard from 'components/cards/LayananPegawaiTableCard';
import JamKerjaPegawaiCard from 'components/cards/JamKerjaPegawaiCard';
import UlasanPegawaiCard from 'components/cards/UlasanPegawaiCard';
import ServiceProfileCard from './Card/ServiceProfileCard';
import InformasiLayananCard from './Card/InformasiLayananCard';
import ServiceNotesCard from './Card/ServiceNotesCard';
import ServiceBookingConfigurationCard from './Card/ServiceBookingConfigurationCard';
import ServicePriceCard from './Card/ServicePriceCard';
import ServiceEmployeeCard from './Card/ServiceEmployeeCard';
import CheckboxDetailLayananModal from 'components/modals/CheckboxDetailLayananModal';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
// import ServiceTemplateCard from 'components/cards/ServiceTemplateCard';

const LayananDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const { data: serviceData, refetch } = useGetServiceByIdQuery(id || '');

  const [checkboxVisibility, setCheckboxVisibility] = useState({
    showServiceBookingConfiguration: true,
    showServicePrice: true,
    showServiceEmployee: true,
    showFormTemplat: true
  });

  const [checkboxChecked, setCheckboxChecked] = useState({
    showServiceBookingConfiguration: true,
    showServicePrice: true,
    showServiceEmployee: true,
    showFormTemplat: true
  });

  const [showConfigModal, setShowConfigModal] = useState(false);

  const [deleteService, { isLoading: isDeleting }] = useDeleteServiceMutation();

  const { data: templateData } = useGetServiceTemplateQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckboxChecked(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const allUnchecked = Object.values(checkboxChecked).every(value => !value);

  const handleConfirmDelete = async (reason: string, notes: string) => {
    try {
      await deleteService({
        data: [id || ''],
        deletionReason: reason,
        notes: notes
      }).unwrap();

      // Tutup modal
      setShowDeleteModal(false);

      showCustomAlert({
        icon: 'success',
        label: 'Sukses!',
        deskripsi: 'Layanan berhasil dihapus',
        buttonType: 'ok',
        onConfirm: () => {
          // Redirect ke halaman daftar layanan
          navigate('/layanan/daftar-layanan');
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: error?.data?.message || 'Gagal menghapus layanan',
        buttonType: 'ok'
      });
    }
  };

  if (!serviceData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PageBreadcrumb items={detailLayananBreadcrumbItems} />
      <ToastContainer position="bottom-right" />

      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Detail Layanan</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button
            variant="link"
            className="text-body px-0"
            onClick={() => setShowDownloadModal(true)}
          >
            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
            Unduh Data
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="dropdown-caret-none text-decoration-none px-1"
            >
              Tindakan Lainnya
              <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item>Atur Jadwal</Dropdown.Item>
              <Dropdown.Item>Atur Cuti</Dropdown.Item>
              <Dropdown.Item>Nonaktifkan</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="outline-danger"
            onClick={() => setShowDeleteModal(true)}
            disabled={isDeleting}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
            Hapus Layanan
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => setShowEmailModal(true)}
          >
            <FontAwesomeIcon icon={faEnvelope} className="me-2" />
            Kirim Email
          </Button>
          <Button variant="primary">
            <FontAwesomeIcon icon={faClipboard} className="me-2" />
            Buat Slip Gaji
          </Button>
        </Col>
      </Row>

      <Row className="g-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
          <Row className="g-3 mb-3">
            <ServiceProfileCard />
            <InformasiLayananCard />
            <ServiceNotesCard />

            {/* <InformasiPribadiPegawaiCard />
            <AlamatKontakPegawaiCard />
            <KontakDaruratPegawaiCard />
            <ServiceNotesCard /> */}
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={7}>
          <Row className="d-flex flex-column g-3">
            <div className="p-0 d-flex align-items-center justify-content-between">
              <div className="d-flex gap-3">
                {Object.entries(checkboxVisibility).map(([key, isVisible]) =>
                  isVisible ? (
                    <div
                      key={key}
                      className={`form-check border rounded-pill px-3 py-2 mt-1 ${
                        checkboxChecked[key as keyof typeof checkboxChecked]
                          ? 'border-primary bg-primary'
                          : 'border-secondary'
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCheckboxChecked(prev => ({
                          ...prev,
                          [key]: !prev[key as keyof typeof checkboxChecked]
                        }));
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input d-none"
                        id={key}
                        name={key}
                        checked={
                          checkboxChecked[key as keyof typeof checkboxChecked]
                        }
                        onChange={() => {}}
                      />
                      <label
                        className={`form-check-label mb-0 ${
                          checkboxChecked[key as keyof typeof checkboxChecked]
                            ? 'text-white'
                            : 'text-secondary'
                        }`}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                      >
                        {key === 'showServiceBookingConfiguration'
                          ? 'Booking'
                          : key === 'showServicePrice'
                          ? 'Harga Layanan'
                          : key === 'showServiceEmployee'
                          ? 'Pegawai'
                          : // : key === 'showJamKerja'
                          // ? 'Jam Kerja'
                          // : key === 'showLayananTable'
                          // ? 'Tabel Pelayanan'
                          // : key === 'showUlasan'
                          // ? 'Peringkat & Ulasan'
                          key === 'showFormTemplat'
                          ? 'Form Templat'
                          : 'null'}
                      </label>
                    </div>
                  ) : null
                )}
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={() => setShowConfigModal(true)}
                  className="px-2 py-2"
                >
                  <FontAwesomeIcon icon={faGear} className="fs-8" />
                </Button>
              </div>
            </div>

            {allUnchecked ? (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={noCheckedBox}
                  alt="No selections"
                  className="img-fluid w-50"
                />
                <h5 className="text-body mt-3 text-center">
                  Anda dapat menampilkan menu tambahan <br />
                  dengan memilih opsi menu diatas.
                </h5>
              </div>
            ) : (
              <>
                {checkboxVisibility.showServiceBookingConfiguration &&
                  checkboxChecked.showServiceBookingConfiguration && (
                    <ServiceBookingConfigurationCard />
                  )}
                {checkboxVisibility.showServicePrice &&
                  checkboxChecked.showServicePrice && <ServicePriceCard />}
                {checkboxVisibility.showServiceEmployee &&
                  checkboxChecked.showServiceEmployee && (
                    <ServiceEmployeeCard />
                  )}

                {/* {checkboxChecked.showFormTemplat &&
                  templateData?.data?.result
                    ?.filter(template => template.status)
                    .map(template => (
                      <ServiceTemplateCard
                        key={template.id}
                        template={template}
                        serviceId={id || ''}
                      />
                    ))} */}
              </>
            )}
          </Row>
        </Col>
      </Row>

      <CheckboxDetailLayananModal
        show={showConfigModal}
        onHide={() => setShowConfigModal(false)}
        checkboxes={checkboxVisibility}
        onCheckboxChange={setCheckboxVisibility}
      />

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={() => {
          // Implementasi download
        }}
        title="Unduh Data"
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={[{ name: serviceData?.data?.name || '' }]}
        onDelete={handleConfirmDelete}
        title="Hapus Layanan"
      />
    </div>
  );
};

export default LayananDetails;
