import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const JamKerjaPegawaiCard = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="mb-0">
            Jam Kerja <FontAwesomeIcon icon={faPencil} className="ms-2 fs-9" />
          </h3>
        </div>

        <div className="mb-4">
          <div className="text-body-secondary mb-2">Jadwal Kerja</div>
          <div>Berdasarkan hari dalam seminggu</div>
        </div>

        <div className="mb-4">
          <div className="text-body-secondary mb-2">
            Maksimal Booking Harian
          </div>
          <div>- Jam</div>
        </div>

        <Table responsive className="fs-9">
          <thead>
            <tr>
              <th>HARI</th>
              <th>JAM KERJA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Senin</td>
              <td>09:00 - 17:00</td>
            </tr>
            <tr>
              <td>Selasa</td>
              <td>09:00 - 17:00</td>
            </tr>
            <tr>
              <td>Rabu</td>
              <td>09:00 - 17:00</td>
            </tr>
            <tr>
              <td>Kamis</td>
              <td>09:00 - 17:00</td>
            </tr>
            <tr>
              <td>Jumat</td>
              <td>09:00 - 17:00</td>
            </tr>
            <tr>
              <td>Sabtu</td>
              <td>11:00 - 16:00</td>
            </tr>
            <tr>
              <td>Minggu</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default JamKerjaPegawaiCard;
