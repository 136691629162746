import { Modal, Form, Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useCreateAccountMutation } from 'api/accountApi'; // Import mutation hook
import { toast } from 'react-toastify';
import { useGetEmployeeQuery } from 'api/employeeApi';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useGetAllRolesQuery, useGetRoleForFormQuery } from 'api/roleApi';
import { RoleResponse } from 'api/response/RoleResponse';
import { useGetAccountsQuery } from 'api/accountApi'; // Tambahkan import ini

interface TambahAkunModalProps {
  show: boolean;
  onHide: () => void;
}

const TambahAkunModal = ({ show, onHide }: TambahAkunModalProps) => {
  const { data: employeeData, isLoading: isEmployeeLoading } =
    useGetEmployeeQuery({
      page: 1,
      limit: 100, // Sesuaikan dengan kebutuhan
      orderBy: 'firstName',
      direction: 'ASC'
    });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: roleData } = useGetRoleForFormQuery({
    page: 1
  });
  const [createAccount, { isLoading }] = useCreateAccountMutation();
  const {
    data: rolesData,
    isLoading: isRolesLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: rolesError
  } = useGetAllRolesQuery({
    page: 1,
    limit: 100
  });

  const initialFormData = {
    id: '',
    email: '',
    status: '',
    roleId: '',
    password: '',
    konfirmasiPassword: '',
    namaPegawai: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showKonfirmasiPassword, setShowKonfirmasiPassword] = useState(false);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Validasi email
    if (!formData.email.trim()) {
      newErrors.email = 'Email harus diisi';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Format email tidak valid';
    }

    // Validasi password
    if (!formData.password.trim()) {
      newErrors.password = 'Password harus diisi';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password minimal 8 karakter';
    }

    // Validasi konfirmasi password
    if (formData.password !== formData.konfirmasiPassword) {
      newErrors.konfirmasiPassword = 'Konfirmasi password tidak cocok';
    }

    // Validasi nama pegawai
    if (!formData.namaPegawai) {
      newErrors.namaPegawai = 'Nama pegawai harus dipilih';
    }

    // Validasi role - pastikan UUID valid
    if (!formData.roleId) {
      newErrors.roleId = 'Peran keamanan harus dipilih';
    }

    // Validasi status
    if (!formData.status) {
      newErrors.status = 'Status harus dipilih';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      showCustomAlert({
        icon: 'question',
        label: 'Akun akan disimpan',
        deskripsi: 'Apakah Anda yakin data akun sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            const accountData = {
              email: formData.email,
              employeeId: formData.namaPegawai,
              password: formData.password,
              status: formData.status === 'true',
              roleId: formData.roleId,
              placeholder: 'Optional description or note'
            };

            const response = await createAccount(accountData).unwrap();
            if (response.code === 200) {
              toast.success('Akun berhasil dibuat');
              onHide();
            } else {
              toast.error(response.message || 'Gagal membuat akun');
            }
          } catch (error) {
            console.error('Error creating account:', error);
            if (error && typeof error === 'object' && 'data' in error) {
              const errorData = error as { data?: { message?: string } };

              // Cek pesan error "Email Already Exists"
              if (errorData.data?.message === 'Email Already Exists') {
                showCustomAlert({
                  icon: 'error',
                  label: 'Terdapat alamat email yang sama',
                  deskripsi:
                    'Mohon ubah alamat email yang akan Anda tambahkan.',
                  buttonType: 'ok'
                });
              } else {
                toast.error(errorData.data?.message || 'Gagal membuat akun');
              }
            } else {
              toast.error('Gagal membuat akun');
            }
          }
        }
      });
    }
  };

  const handleCancel = () => {
    const isFormFilled = formData.email.trim() !== '' || '';

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Akun tidak akan disimpan',
        deskripsi: 'Apakah anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setFormData(initialFormData);
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      setFormData(initialFormData);
    }
  }, [show]);

  const statusOptions = [
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' }
  ];

  // Tambahkan query untuk mendapatkan semua akun
  const { data: allAccounts } = useGetAccountsQuery({
    page: 1,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  // Tambahkan fungsi handleEmployeeChange
  const handleEmployeeChange = (employeeId: string) => {
    // Skip pengecekan jika memilih opsi kosong
    if (!employeeId) {
      setFormData(prev => ({ ...prev, namaPegawai: employeeId }));
      return;
    }

    // Cek apakah employee sudah digunakan oleh akun lain
    const existingAccount = allAccounts?.data?.result?.find(
      account => account.employee?.id === employeeId
    );

    if (existingAccount) {
      showCustomAlert({
        icon: 'warning',
        label: 'Pegawai sudah terkait dengan akun lain',
        deskripsi: `Pegawai ini sudah terkait dengan akun ${existingAccount.email}. Apakah Anda yakin ingin mengubah kepemilikan akun pada pegawai ini?`,
        buttonType: 'yes-no',
        onConfirm: () => {
          setFormData(prev => ({ ...prev, namaPegawai: employeeId }));
        }
      });
    } else {
      // Jika employee belum digunakan atau dipilih opsi kosong, langsung update
      setFormData(prev => ({ ...prev, namaPegawai: employeeId }));
    }
  };

  return (
    <>
      <style>
        {`
          .vertical-divider {
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
          
        `}
      </style>
      <Modal show={show} onHide={handleCancel} size="lg" centered>
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="fw-bolder">Tambah Akun</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="primary"
              type="submit"
              form="tambahAkunForm"
              disabled={isLoading}
            >
              {isLoading ? 'Membuat...' : 'Buat Akun'}
            </Button>
          </div>
        </Modal.Header>
        <Form id="tambahAkunForm" onSubmit={handleSubmit}>
          <Modal.Body className="px-5 py-4 bg-light">
            <div className="mb-4">
              <Form.Group className="mb-3">
                <h4 className="mb-3">Informasi Akun</h4>
                <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                  Nama Akun <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Masukkan alamat email"
                  value={formData.email}
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Masukkan password"
                        value={formData.password}
                        onChange={e =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        isInvalid={!!errors.password}
                        style={{
                          paddingRight: '40px',
                          backgroundImage: 'none'
                        }}
                      />
                      <Button
                        variant="link"
                        className="position-absolute top-50 end-0 translate-middle-y text-body pe-3"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                        style={{ zIndex: 5 }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          className="fs-9"
                        />
                      </Button>
                    </div>
                    {errors.password && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                      Konfirmasi Password <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showKonfirmasiPassword ? 'text' : 'password'}
                        placeholder="Ketik ulang password"
                        value={formData.konfirmasiPassword}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            konfirmasiPassword: e.target.value
                          })
                        }
                        isInvalid={!!errors.konfirmasiPassword}
                        style={{
                          paddingRight: '40px',
                          backgroundImage: 'none'
                        }}
                      />
                      <Button
                        variant="link"
                        className="position-absolute top-50 end-0 translate-middle-y text-body pe-3"
                        onClick={() =>
                          setShowKonfirmasiPassword(!showKonfirmasiPassword)
                        }
                        type="button"
                        style={{ zIndex: 5 }}
                      >
                        <FontAwesomeIcon
                          icon={showKonfirmasiPassword ? faEye : faEyeSlash}
                          className="fs-9"
                        />
                      </Button>
                    </div>
                    {errors.konfirmasiPassword && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.konfirmasiPassword}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                  Nama Pegawai Terkait <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formData.namaPegawai}
                  onChange={e => handleEmployeeChange(e.target.value)}
                  isInvalid={!!errors.namaPegawai}
                  disabled={isEmployeeLoading}
                >
                  <option value="">Pilih nama pegawai terkait</option>
                  {employeeData?.data
                    ?.slice()
                    .sort((a, b) =>
                      (a.names?.fullName || '').localeCompare(
                        b.names?.fullName || ''
                      )
                    )
                    .map(employee => (
                      <option key={employee.id} value={employee.id}>
                        {employee.fullName || '(Nama tidak tersedia)'}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.namaPegawai}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                      Peran Keamanan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      value={formData.roleId}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          roleId: e.target.value
                        })
                      }
                      isInvalid={!!errors.roleId}
                      disabled={isRolesLoading}
                    >
                      <option value="">Pilih peran keamanan</option>
                      {rolesData?.data
                        ?.slice()
                        .sort((a: RoleResponse, b: RoleResponse) =>
                          a.code.localeCompare(b.code)
                        )
                        .map((role: RoleResponse) => (
                          <option key={role.id} value={role.id}>
                            {role.code}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.roleId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-9 mb-2 text-capitalize fw-bolder">
                      Status <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      value={formData.status}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          status: e.target.value
                        })
                      }
                      isInvalid={!!errors.status}
                    >
                      <option value="">Pilih status</option>
                      {statusOptions.map((option, index) => (
                        <option key={index} value={option.value.toString()}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default TambahAkunModal;
