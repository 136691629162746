import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const LayananPegawaiCard = () => {
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h3 className="mb-0">Layanan</h3>
        <FontAwesomeIcon icon={faPencil} className="cursor-pointer" />
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <strong>Nama Layanan</strong>
                </div>
                <span className="text-dark">
                  <strong>Biaya Tambahan</strong>
                </span>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>Layanan 2</div>
                <span className="badge bg-light text-dark">+12%</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>Layanan 3</div>
                <span className="badge bg-light text-dark">+0%</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>Layanan 4</div>
                <span className="badge bg-light text-dark">+0%</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>Layanan 5</div>
                <span className="badge bg-light text-dark">+100%</span>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LayananPegawaiCard;
