import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface AddTemplateModalProps {
  show: boolean;
  handleClose: () => void;
  onSubmit: (formData: {
    templateName: string;
    formType: string;
    status: boolean;
  }) => void;
  existingTemplates: { templateName: string }[];
}

const AddTemplateModal: React.FC<AddTemplateModalProps> = ({
  show,
  handleClose,
  onSubmit,
  existingTemplates
}) => {
  const [formData, setFormData] = useState({
    templateName: '',
    formType: 'Customizeable',
    status: true
  });

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    templateName: '',
    formType: '',
    status: ''
  });

  const checkForChanges = () => {
    return (
      formData.templateName.trim() !== '' ||
      formData.formType !== 'Customizeable' ||
      !formData.status
    );
  };

  const handleModalClose = () => {
    const hasChanges = checkForChanges();

    if (hasChanges) {
      showCustomAlert({
        icon: 'warning',
        label: 'Form templat tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm();
          handleClose();
        }
      });
    } else {
      resetForm();
      handleClose();
    }
  };

  const resetForm = () => {
    setFormData({
      templateName: '',
      formType: 'Customizeable',
      status: true
    });
    setValidated(false);
    setErrors({
      templateName: '',
      formType: '',
      status: ''
    });
  };

  const validateForm = () => {
    const newErrors = {
      templateName: '',
      formType: '',
      status: ''
    };

    const trimmedName = formData.templateName.trim();

    if (!trimmedName) {
      newErrors.templateName = 'Nama template harus diisi';
    } else if (trimmedName.length < 4) {
      newErrors.templateName = 'Nama template minimal 4 karakter';
    } else if (trimmedName.length > 50) {
      newErrors.templateName = 'Nama template maksimal 50 karakter';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidated(true);

    const trimmedName = formData.templateName.trim();

    const isDuplicate = existingTemplates.some(
      template =>
        template.templateName.toLowerCase() === trimmedName.toLowerCase()
    );

    if (isDuplicate) {
      showCustomAlert({
        icon: 'warning',
        label: 'Terdapat nama form templat yang sama',
        deskripsi: 'Mohon ubah nama form templat yang akan Anda tambahkan',
        buttonType: 'ok'
      });
      return;
    }

    if (validateForm()) {
      showCustomAlert({
        icon: 'question',
        label: 'Form templat akan disimpan',
        deskripsi: 'Apakah Anda yakin data form templat sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            await onSubmit({
              ...formData,
              templateName: trimmedName
            });
            showCustomAlert({
              icon: 'success',
              label: 'Berhasil!',
              deskripsi: 'Form templat berhasil ditambahkan',
              buttonType: 'ok',
              onConfirm: () => {
                resetForm();
                handleClose();
              }
            });
          } catch (error) {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal!',
              deskripsi: 'Gagal menambahkan form templat',
              buttonType: 'ok'
            });
          }
        }
      });
    }
  };

  const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      templateName: e.target.value
    });
  };

  const formTypeOptions = [
    'Customizeable',
    'Templat A',
    'Templat B'
    // Tambahkan opsi sesuai kebutuhan
  ];

  const statusOptions = [
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' }
  ];

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="me-auto fw-bolder">
            Tambah Form Templat
          </Modal.Title>
          <div className="d-flex">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleModalClose}
            >
              Batalkan
            </Button>
            <Button variant="primary" type="submit">
              Simpan
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="px-5 py-4 bg-light">
          <Form.Group className="mb-3">
            <h5 className="mb-3">Form Templat</h5>
            <Form.Label className="p-0 text-capitalize fs-9">
              Nama Template <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formData.templateName}
              onChange={handleTemplateNameChange}
              onBlur={e => {
                const trimmedValue = e.target.value.trim();
                setFormData(prev => ({
                  ...prev,
                  templateName: trimmedValue
                }));
              }}
              isInvalid={validated && !!errors.templateName}
              className={validated && !!errors.templateName ? 'is-invalid' : ''}
              required
              placeholder="Masukkan nama template"
            />
            {validated && errors.templateName && (
              <div className="invalid-feedback ms-2">{errors.templateName}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="p-0 text-capitalize fs-9">
              Tipe Form
            </Form.Label>
            <Form.Select
              value={formData.formType}
              onChange={e =>
                setFormData({
                  ...formData,
                  formType: e.target.value
                })
              }
            >
              {formTypeOptions.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Form.Select>
            {validated && errors.formType && (
              <div className="invalid-feedback ms-2">{errors.formType}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="p-0 text-capitalize fs-9">Status</Form.Label>
            <Form.Select
              value={formData.status.toString()}
              onChange={e =>
                setFormData({
                  ...formData,
                  status: e.target.value === 'true'
                })
              }
              required
            >
              {statusOptions.map((option, index) => (
                <option key={index} value={option.value.toString()}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default AddTemplateModal;
