import {
  faFileExport,
  faPlus,
  faTrash,
  faCopy,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  EmployeeTemplateParams,
  useGetEmployeeTemplateQuery,
  useAddEmployeeTemplateMutation,
  useDeleteEmployeeTemplateMutation,
  useDuplicateEmployeeTemplateMutation
} from 'api/employeeTemplateApi';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu,
  FilterItem
} from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';

import EmployeesTemplateTable, {
  employeesTemplateTablecolumns
} from 'components/tables/EmployeesTemplateTable';
import { layananFormTemplatBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import AddTemplateModal from 'components/modals/AddEmployeeTemplateModal';

const LayananTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState<EmployeeTemplateParams>({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });
  const {
    data: templateData,
    error,
    isLoading,
    refetch
  } = useGetEmployeeTemplateQuery(searchParams, {
    refetchOnMountOrArgChange: true
  });
  const [addEmployeeTemplate] = useAddEmployeeTemplateMutation();
  const [deleteEmployeeTemplate] = useDeleteEmployeeTemplateMutation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [hasShownError, setHasShownError] = useState(false);

  const table = useAdvanceTable({
    data: templateData?.data?.result || [],
    columns: employeesTemplateTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    if (error && !hasShownError) {
      console.error('Error loading template data:', error);
      toast.error('Error loading template data');
      setHasShownError(true);
    }
  }, [error, hasShownError]);

  useEffect(() => {
    if (templateData) {
      setHasShownError(false);
    }
  }, [templateData]);

  //Modal
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);

  // Create a debounced search handler
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setSearchParams((prev: EmployeeTemplateParams) => ({
        ...prev,
        page: 1,
        templateName: searchValue || undefined
      }));
      setCurrentPage(1);
    }, 300), // 300ms delay
    []
  );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    debouncedSearch(searchValue);
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await refetch();
      toast.success('Data berhasil diperbarui');
    } catch (error) {
      toast.error('Gagal memperbarui data');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleAddTemplate = async (formData: {
    templateName: string;
    formType: string;
    status: boolean;
  }) => {
    try {
      const response = await addEmployeeTemplate({
        templateName: formData.templateName,
        formType: formData.formType,
        status: formData.status
      }).unwrap();

      if (response.code === 200) {
        await refetch();
        return Promise.resolve();
      } else {
        return Promise.reject(new Error(response.message));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleDeleteTemplate = async () => {
    const selectedRows = table.getSelectedRowModel().rows;

    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih template yang akan dihapus');
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Form templat akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk menghapus form templat?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const deletePromises = selectedRows.map(async row => {
            const templateId = row.original.id;
            if (!templateId) {
              throw new Error('ID template tidak ditemukan');
            }

            const response = await deleteEmployeeTemplate(templateId).unwrap();
            if (response.code !== 200) {
              throw new Error(response.message || 'Gagal menghapus template');
            }
            return response;
          });

          await Promise.all(deletePromises);
          toast.success('Template berhasil dihapus');
          await refetch();
          table.resetRowSelection();
        } catch (error) {
          console.error('Error deleting template:', error);
          toast.error(
            error instanceof Error ? error.message : 'Gagal menghapus template'
          );
        }
      }
    });
  };

  const [duplicateEmployeeTemplate] = useDuplicateEmployeeTemplateMutation();

  const handleDuplicateTemplate = async () => {
    const selectedRows = table.getSelectedRowModel().rows;

    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih template yang akan diduplikasi');
      return;
    }

    if (selectedRows.length > 1) {
      toast.warning('Pilih hanya satu template untuk diduplikasi');
      return;
    }

    const selectedTemplate = selectedRows[0].original;

    // Cari jumlah template dengan nama yang sama
    const existingDuplicates = templateData?.data?.result.filter(template =>
      template.templateName.startsWith(`${selectedTemplate.templateName} copy`)
    );

    const duplicateCount = existingDuplicates ? existingDuplicates.length : 0;
    const newTemplateName =
      duplicateCount === 0
        ? `${selectedTemplate.templateName} copy`
        : `${selectedTemplate.templateName} copy (${duplicateCount + 1})`;

    showCustomAlert({
      icon: 'question',
      label: 'Duplikat form templat?',
      deskripsi: 'Form templat akan diduplikat.',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const response = await duplicateEmployeeTemplate({
            employeeTemplateId: selectedTemplate.id || '',
            employeeTemplateDuplicateName: newTemplateName
          }).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Form templat berhasil diduplikat.',
              buttonType: 'ok'
            });
            await refetch();
            table.resetRowSelection();
          } else {
            throw new Error(response.message);
          }
        } catch (error) {
          console.error('Error duplicating template:', error);
          toast.error(
            error instanceof Error
              ? error.message
              : 'Gagal menduplikasi template'
          );
        }
      }
    });
  };

  const handlePageChange = (page: number) => {
    // Reset row selection when changing pages
    table.resetRowSelection();

    setCurrentPage(page);
    setSearchParams((prev: EmployeeTemplateParams) => ({
      ...prev,
      page
    }));
  };

  // Tambahkan useEffect untuk memantau perubahan halaman
  useEffect(() => {
    // Reset row selection when page changes
    table.resetRowSelection();
  }, [currentPage, table]);

  // Ubah definisi state filters
  const [filters, setFilters] = useState<FilterMenu[]>([
    {
      label: 'Status',
      items: [
        {
          label: 'Active',
          value: 'true',
          checked: false,
          isSelected: false
        },
        {
          label: 'Inactive',
          value: 'false',
          checked: false,
          isSelected: false
        }
      ]
    }
  ]);

  // Update handler untuk filter
  const handleFilterChange = (label: string, selectedItems: FilterItem[]) => {
    if (label === 'Status') {
      setFilters(prevFilters =>
        prevFilters.map(menu =>
          menu.label === 'Status'
            ? {
                ...menu,
                items: menu.items.map(item => ({
                  ...item,
                  checked: selectedItems.some(
                    selected => selected.value === item.value
                  )
                }))
              }
            : menu
        )
      );

      // Update searchParams dengan tetap menggunakan string
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        status:
          selectedItems.length > 0
            ? selectedItems[0].value // Tetap gunakan string 'true' atau 'false'
            : undefined
      }));
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const shouldRefresh = location.state?.refresh;
    if (shouldRefresh) {
      refetch();
      navigate('.', { state: {}, replace: true });
    }
  }, [location.state?.refresh, refetch, navigate]);

  const [lastToastTime, setLastToastTime] = useState(0);

  useEffect(() => {
    // Check for empty data
    if (templateData && templateData.data.total === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [templateData, lastToastTime]);

  return (
    <div>
      <PageBreadcrumb items={layananFormTemplatBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="d-flex justify-content-between">
        <h2 className="mb-4">Form Templat</h2>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
            <div className="d-flex gap-3 align-items-center position-relative">
              <SearchBox
                placeholder="Cari"
                onChange={handleSearchInputChange}
                style={{ maxWidth: '200px' }}
              />
              <FilterButtonGroup
                menus={filters}
                onFilterChange={handleFilterChange}
              />
            </div>

            <div className="ms-auto d-flex align-items-center gap-3 flex-wrap">
              <Button variant="link" className="text-body px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Unduh Data
              </Button>
              <Button
                variant="link"
                className="p-0 text-body text-decoration-none"
                onClick={handleRefresh}
                disabled={isRefreshing}
              >
                <FontAwesomeIcon
                  icon={faUndo}
                  className={`me-2 ${isRefreshing ? 'fa-spin' : ''}`}
                />
                {isRefreshing ? 'Memperbarui...' : 'Refresh'}
              </Button>
              <Dropdown>
                <Dropdown.Menu align="end">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={handleDeleteTemplate}
                disabled={table.getSelectedRowModel().rows.length === 0}
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Hapus Form Template
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={handleDuplicateTemplate}
                disabled={table.getSelectedRowModel().rows.length !== 1}
              >
                <FontAwesomeIcon icon={faCopy} className="me-2" />
                Duplikat
              </Button>
              <Button variant="primary" size="sm" onClick={handleShowModal}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Tambah Form Template
              </Button>
            </div>

            {/* Komponen Modal */}
            <AddTemplateModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              onSubmit={handleAddTemplate}
              existingTemplates={templateData?.data?.result || []}
            />
          </div>
        </div>
        <div style={{ width: '95%' }} className="mx-auto">
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            {isLoading ? (
              <div className="text-center py-5">Loading...</div>
            ) : (
              <EmployeesTemplateTable
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={templateData?.data?.totalPages || 1}
                templateData={{
                  totalRecords: templateData?.data?.total || 0
                }}
              />
            )}
          </div>
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default LayananTemplate;
